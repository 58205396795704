<template>
  <v-card :loading="loading">
    <v-overlay
      :absolute="true"
      :value="loading"
      color="blue"
    ></v-overlay>
    <v-list dense>
      <v-list-item
        v-for="event in FACILITY_USER_EVENTS"
        :key="event.id"
      >
        <v-list-item-icon>
          <v-icon v-if="facility.next_contact_event && facility.next_contact_event.id === event.id">star</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-tooltip
            bottom
            color="primary"
            :disabled="!event.description"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                {{event.start}} - {{event.title}} ({{event.user.username}})</div>
            </template>
            <span v-if="event.description">{{event.description}}</span>
          </v-tooltip>

        </v-list-item-content>
        <v-list-item-action>
          <v-row>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="edit(event)"
            >
              <v-icon>edit</v-icon>
            </v-btn>

            <v-btn
              small
              color="red"
              class="white--text"
              @click="remove(event)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-spacer />
        <v-list-item-action>
          <v-btn
            color="green"
            class="white--text"
            small
            @click="add"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <EventEdit
      ref="eventEdit"
      :facility="facility"
      :type="1"
      v-on:event-changed="eventChanged"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EventEdit from "./FacilityEventEdit.vue";

export default {
  name: "NextContactList",
  props: ["facility"],
  components: { EventEdit },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["FACILITY_USER_EVENTS"]),
  },
  methods: {
    add() {
      this.$refs.eventEdit.openDialog(null);
    },
    remove(event) {
      this.loading = true;

      if (this.facility.next_contact_event.id === event.id) {
        this.facility.next_contact_event = null;
        this.facility.next_contact_at = null;
        this.facility.next_contact_purpose = null;
      }

      this.$store
        .dispatch("REMOVE_FACILITY_USER_EVENT", { userEvent: event })
        .then(() => {
          this.loading = false;

          if (
            this.facility.next_contact_event === null &&
            this.FACILITY_USER_EVENTS.length > 0
          ) {
            this.FACILITY_USER_EVENTS.every((item) => {
              if (item.id != event.id) {
                this.facility.next_contact_event = item;
                this.facility.next_contact_at = item.start;
                this.facility.next_contact_purpose = item.title;
                return false;
              }
              return true;
            });
          }
        });
    },
    edit(event) {
      this.$refs.eventEdit.openDialog(JSON.parse(JSON.stringify(event)));
    },
    eventChanged(event){
      if (this.facility.next_contact_event.id === event.id) {
        this.facility.next_contact_at = new Date(event.start);
        this.facility.next_contact_purpose = event.title;
      }
    },
    getFacilityUserEvents() {
      this.loading = true;
      this.$store
        .dispatch("GET_FACILITY_USER_EVENTS", { facilityId: this.facility.id })
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getFacilityUserEvents();
  },
  watch: {
    facility: function () {
      this.getFacilityUserEvents();
    },
  },
};
</script>


<style>
</style>
<template>
  <v-form @keyup.native.enter="filterTemplates">
    <v-row>
      <v-col>
        <v-text-field
          v-model="filter.question_like"
          label="pytanie"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="text-center">
        <v-btn
          class="primary"
          @click="filterTemplates()"
        >
          filtruj
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>
  </v-form>
</template>

<script>

export default {
  name: "AdminQaSearchForm",
  components: {},
  data: () => ({
    loading: false,
    filter: {
      only_visibile: false
    },
  }),
  computed: {

  },
  methods: {
    filterTemplates() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      this.$store.dispatch("FILTER_QA", { filter: filter });
    },
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
<template>
  <div>
    <span class="text-caption float-right grey--text text--darken-1">oa: {{ facility.updated_at }}</span>
    <h4 class="blue--text text--darken-2 pb-0 text-left pl-3"><v-btn @click="copyInfo" fab x-small dark depressed outlined color="primary" class="mr-2"><v-icon>content_copy</v-icon></v-btn> <span>{{facility.name}}</span><span v-if="facility.facility.starRate && facility.facility.starRate.length > 0" class="ml-2"><v-icon small class="mb-2" color="primary" v-for="index in facility.facility.starRate.length" :key="index">star</v-icon></span></h4>
    <v-simple-table dense id="info-table">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-right">Adres:</td>
            <td>{{ facility.facility.address }}<span v-if="facility.facility.voivodeship">, {{ facility.facility.voivodeship }}</span> <v-btn @click="googleSearch" fab x-small depressed icon absolute right color="success" class="mr-2"><v-icon>search</v-icon></v-btn></td>
          </tr>
          <tr v-if="facility.facility.phones">
            <td class="text-right">Telefony:</td>
            <td>
              <span
                v-for="phone in facility.facility.phones"
                :key="phone.id"
              >
                <v-chip
                  class="mr-2"
                  small
                  outlined
                  v-if="[2, 3, 4, 8, 9, 10, 11, 12].includes(phone.type.id)"
                >
                  {{ phone.number }}<span v-if="phone.description" class="ml-2">({{phone.description}})</span>
                </v-chip>
              </span>
            </td>
          </tr>
          <tr v-if="facility.facility.slug && facility.facility.cities.length > 0">
            <td class="text-right">Meteor-turystyka:</td>
            <td>
              <a
                target="_blank"
                :href="'https://meteor-turystyka.pl/'+facility.facility.slug+','+facility.facility.cities[0].city.slug+'.html'"
              >
                meteor-turystyka.pl/{{facility.facility.slug}},{{facility.facility.cities[0].city.slug}}.html
              </a>
            </td>
          </tr>

          <tr
            v-for="address in facility.facility.addresses"
            :key="address.id"
          >
            <template v-if="![3,6, 7, 8, 9, 10, 11, 12, 13].includes(address.type.id )">
              <td class="text-right">{{ address.type.name }}</td>
              <td v-if="[7, 8, 9].includes(address.type.id )"><a
                  target="_blank"
                  :href="'mailto:'+address.address"
                >{{address.address}}</a></td>
              <td v-else><a
                  target="_blank"
                  :href="'http://'+address.address"
                >{{address.address}}</a></td>
            </template>
          </tr>
          <tr v-if="facility.facility.latitude && facility.facility.longitude">
            <td class="text-right">GPS:</td>
            <td>
              {{ facility.facility.latitude }}
              {{ facility.facility.longitude }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    
    <v-card
      class="mt-2"
      outlined
    >
      <iframe
        :src="previewUrl"
        style="border:0;width:100%;min-height:600px;"
      />
    </v-card>
    <Snackbar ref="snackbar"/>
  </div>
</template>

<script>
import Snackbar from "../shared/Snackbar";

export default {
  name: "FacilityEditInfo",
  props: ["facility"],
  components: {Snackbar},
  data: () => ({}),
  computed: {
    previewUrl: function () {
      return (
        "https://panel.meteor24.pl/preview/facility/" +
        this.facility.facility.id
      );
    },
  },
  methods: {
    copyInfo(){
      const text = "[M-" + this.facility.id + "] " + this.facility.name;
      navigator.clipboard.writeText(text).then(
        () => {
          this.$refs.snackbar.success("Skopiowano do schowka: " + text);
          console.log("copy");
        },
        (err) => {
          console.error("Could not copy text: ", err);
      });
    },
    googleSearch(){
      const phrase = `${this.facility.name} ${this.facility.facility.address}`;
      window.open(`https://www.google.pl/search?ie=utf-8&oe=utf-8&q=${phrase}`);
    },
  },
  mounted() {},
};
</script>


<style>
#info-table td, #info-table th {
  height:28px!important;
}
</style>
<template>
  <v-form @keyup.native.enter="addAddress">
    <v-simple-table
      class="facility-edit-addresses-table"
      v-if="addAddressForm || addresses.length === 0"
      dense
    >
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <v-select
                :items="addressTypes"
                v-model="newAddress.type"
                item-text="name"
                return-object
                label="rodzaj"
                :error-messages="addressTypeErrors"
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model="newAddress.address"
                label="adres"
                :error-messages="addressErrors"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="newAddress.description"
                label="opis"
              ></v-text-field>
            </td>
            <td style="max-width:30px;">
              <v-text-field
                v-model="newAddress.priority"
                label="pr"
              ></v-text-field>
            </td>
            <td class="text-right action-cell">
              <v-btn
                color="success"
                @click="addAddress()"
                small
                class="mr-1"
              >
                <v-icon>done</v-icon>
              </v-btn>
              <v-btn
                color="warning"
                @click="addAddressForm=false"
                small
                v-if="addresses.length > 0"
              >
                <v-icon>not_interested</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table
      class="facility-edit-addresses-table"
      dense
      v-if="addresses.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>rodzaj</th>
            <th>adres</th>
            <th>opis</th>
            <th>p
              <v-tooltip
                bottom
                color="blue"
              >
                <template v-slot:activator="{ on, attrs }">

                  <v-icon
                    color="blue"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >help</v-icon>

                </template>
                <span>Priorytet. Im większa liczba priorytetu tym wyżej dany adres znajduje się na liście.</span>
              </v-tooltip>

            </th>
            <th class="text-right"><v-btn
                color="success"
                v-if="!addAddressForm"
                @click="addAddressForm = true"
                small
              ><v-icon>add</v-icon></v-btn></th>
          </tr>
        </thead>
        <tbody>
          <FacilityEditAddressessRow
            v-for="address in addresses"
            :key="address.id"
            :address="address"
            :addressTypes="addressTypes"
            v-on:address:remove="removeAddress($event)"
            v-on:address:update="updateAddress($event)"
            @address:sendMessage="sendMessage"
          />
        </tbody>
      </template>
    </v-simple-table>
    <SendMailDialog
      :facility="facility"
      ref="sendMailDialog"
    ></SendMailDialog>
    <Snackbar ref="snackbar" />
  </v-form>
</template>

<script>
import SendMailDialog from "./SendMail/SendMailDialog.vue";
import FacilityEditAddressessRow from "./FacilityEditAddressessRow";
import Snackbar from "./../../shared/Snackbar.vue";

import { mapGetters } from "vuex";
import { required, email, helpers } from "vuelidate/lib/validators";
const url = helpers.regex("url", /^[^.:@]+\..+$/);

export default {
  name: "FacilityEditAddressess",
  props: ["facility", "availableTypes", "validateTypeDuplication"],
  components: {
    FacilityEditAddressessRow,
    SendMailDialog,
    Snackbar,
  },
  data: () => ({
    newAddress: {
      type: {},
    },
    addAddressForm: false,
  }),
  validations() {
    if ([7, 8, 9, 11, 12, 13].includes(this.newAddress.type.id)) {
      return {
        newAddress: {
          address: { required, email },
          type: { required },
        },
      };
    } else if ([10, 14].includes(this.newAddress.type.id)) {
      return {
        newAddress: {
          address: { required },
          type: { required },
        },
      };
    } else {
      return {
        newAddress: {
          address: { required, url },
          type: { required },
        },
      };
    }
  },
  computed: {
    ...mapGetters(["ADDRESS_TYPES"]),
    addresses() {
      let addresses = this.facility.facility.addresses.filter((current) => {
        return (
          this.availableTypes.length > 0 &&
          this.availableTypes.includes(current.type.id)
        );
      });

      return addresses;
    },
    addressTypes() {
      let addressTypes = this.ADDRESS_TYPES.filter((current) => {
        return (
          this.availableTypes.length > 0 &&
          this.availableTypes.includes(current.id)
        );
      });

      return addressTypes;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.newAddress.address.$dirty) return errors;
      !this.$v.newAddress.address.required && errors.push("podaj adres");
      this.$v.newAddress.address.email != null &&
        !this.$v.newAddress.address.email &&
        errors.push("podaj email");
      this.$v.newAddress.address.url != null &&
        !this.$v.newAddress.address.url &&
        errors.push("podaj url");
      return errors;
    },
    addressTypeErrors() {
      const errors = [];
      if (!this.$v.newAddress.type.$dirty) return errors;
      !this.$v.newAddress.type.required && errors.push("rodzaj jest wymagany");
      return errors;
    },
  },
  methods: {
    addAddress: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.$v.$reset();
      this.newAddress.id = -Date.now();
      const exist = this.facility.facility.addresses.find(
        (address) =>
          address.id != this.newAddress.id &&
          address.type.id === this.newAddress.type.id
      );
      if (exist && this.validateTypeDuplication) {
        this.$refs.snackbar.error(
          "wybrany rodzaj adresu jest już wykorzystany"
        );
      } else {
        this.facility.facility.addresses.push(this.newAddress);
        this.newAddress = {
          type: {},
        };
        this.$emit("address:update");
      }
    },
    removeAddress: function (address) {
      this.facility.facility.addresses =
        this.facility.facility.addresses.filter((current) => {
          return current.id !== address.id;
        });
      this.$emit("address:update");
    },
    updateAddress: function (newAddress) {
      const exist = this.facility.facility.addresses.find(
        (address) =>
          address.id != newAddress.id && address.type.id === newAddress.type.id
      );
      if (exist && this.validateTypeDuplication) {
        this.$refs.snackbar.error(
          "wybrany rodzaj adresu jest już wykorzystany"
        );
      } else {
        const element = this.facility.facility.addresses.find(
          (address) => address.id === newAddress.id
        );
        Object.assign(element, newAddress);
        this.$emit("address:update");
      }
    },
    sendMessage(options) {
      this.$refs.sendMailDialog.openDialog(options);
    },
  },
  mounted() {
    this.$store.dispatch("GET_ADDRESS_TYPES");
  },
};
</script>
<style>
.action-cell {
  min-width: 130px;
}
.facility-edit-addresses-table td,
.facility-edit-addresses-table th {
  padding: 0 5px !important;
}
</style>
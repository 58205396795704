<template>
  <div>
    <v-simple-table
      dense
      v-if="sortedPropertyTypes.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>priorytet</th>
            <th>nazwa</th>
            <th></th>
          </tr>
        </thead>
        <draggable
          v-model="facility.facility.propertyTypes"
          tag="tbody"
          ghost-class="ghost"
          @change="checkMove"
        >
          <FacilityEditPropertyTypeRow
            v-for="facilityPropertyType in sortedPropertyTypes"
            :key="facilityPropertyType.id"
            :facilityPropertyType="facilityPropertyType"
            v-on:facilityPropertyType:remove="removeType($event)"
            v-on:facilityPropertyType:downgrade="downgradeType($event)"
            v-on:facilityPropertyType:upgrade="upgradeType($event)"
          />
        </draggable>
      </template>
    </v-simple-table>
    <v-form
      @keyup.native.enter="addType"
      @submit.prevent=""
      class="mt-3"
    >
      <v-row>
        <v-col md="10" v-if="availablePropertyTypes.length > 0" id="propertyTypeAutocomplete">
          <v-autocomplete
            v-model="newFacilityPropertyType"
            :items="availablePropertyTypes"
            item-text="name"
            return-object
            dense
            label="dodaj rodzaj"
            :menu-props="{ maxHeight: 450, offsetOverflow: true }"
          >
        
          <template v-slot:item="data">
                <v-list-item-content class="py-1">
                  <v-list-item-title>{{data.item.name}}</v-list-item-title>
                  
                </v-list-item-content>
              </template>
        </v-autocomplete>

        </v-col>
        <v-col md="2">
          <v-btn
            color="success"
            @click="addType()"
            small
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import FacilityEditPropertyTypeRow from "./FacilityEditPropertyTypeRow";
export default {
  name: "FacilityEditPropertyType",
  props: ["facility"],
  components: {
    FacilityEditPropertyTypeRow,
    draggable,
  },
  data: () => ({
    newFacilityPropertyType: {},
    facilityPropertyTypes: [],
    availablePropertyTypes: [],
  }),
  computed: {
    ...mapGetters(["PROPERTY_TYPES"]),
    sortedPropertyTypes() {
      return this.facility.facility.propertyTypes
        .slice()
        .sort((a, b) => a.priority - b.priority);
    },
  },
  methods: {
    addType() {
      if (!this.newFacilityPropertyType.id) {
        return;
      }

      if (
        this.facility.facility.propertyTypes.length === 0 &&
        this.newFacilityPropertyType.id === 2048
      ) {
        return;
      }

      let newType = {
        propertyType: this.newFacilityPropertyType,
        priority:
          this.sortedPropertyTypes.length > 0
            ? this.sortedPropertyTypes.at(-1).priority + 1
            : 1,
      };
      this.facility.facility.propertyTypes.push(newType);
      if (
        this.facility.facility.propertyTypes.length === 1 &&
        this.newFacilityPropertyType.id !== 32768
      ) {
        let defaultType = {
          propertyType: { id: 2048, name: "noclegi" },
          priority: 2,
        };
        this.facility.facility.propertyTypes.push(defaultType);
      }
      this.newFacilityPropertyType = {};
    },
    removeType: function (type) {
      this.facility.facility.propertyTypes =
        this.facility.facility.propertyTypes.filter((current) => {
          return current.propertyType.id !== type.propertyType.id;
        });
    },
    downgradeType: function (type) {
      let newPrirority = parseInt(type.priority) + 1;

      for (let key in this.facility.facility.propertyTypes) {
        if (
          parseInt(this.facility.facility.propertyTypes[key].priority) ===
            newPrirority &&
          this.facility.facility.propertyTypes[key].propertyType.id !==
            type.propertyType.id
        ) {
          this.facility.facility.propertyTypes[key].priority =
            parseInt(this.facility.facility.propertyTypes[key].priority) - 1;
        }
        if (
          this.facility.facility.propertyTypes[key].propertyType.id ===
          type.propertyType.id
        ) {
          this.facility.facility.propertyTypes[key].priority = newPrirority;
        }
      }
      this.sortPropertyTypes();
    },
    upgradeType: function (type) {
      let newPrirority = parseInt(type.priority) - 1;

      for (let key in this.facility.facility.propertyTypes) {
        if (
          parseInt(this.facility.facility.propertyTypes[key].priority) ===
            newPrirority &&
          this.facility.facility.propertyTypes[key].propertyType.id !==
            type.propertyType.id
        ) {
          this.facility.facility.propertyTypes[key].priority =
            parseInt(this.facility.facility.propertyTypes[key].priority) + 1;
        }
        if (
          this.facility.facility.propertyTypes[key].propertyType.id ===
          type.propertyType.id
        ) {
          this.facility.facility.propertyTypes[key].priority = newPrirority;
        }
      }
      this.sortPropertyTypes();
    },
    isFacilityPropertyType(propertyType) {
      for (let key in this.facility.facility.propertyTypes) {
        let type = this.facility.facility.propertyTypes[key];
        if (type.propertyType.id == propertyType.id) {
          return true;
        }
      }
      return false;
    },
    getAvailablePropertyTypes() {
      let available = [];

      for (let key in this.PROPERTY_TYPES) {
        let propertyType = this.PROPERTY_TYPES[key];
        if (!this.isFacilityPropertyType(propertyType) && ![4,16384].includes(propertyType.id)) {
          available.push(propertyType);
        }
      }
      this.availablePropertyTypes = available;
    },
    sortPropertyTypes() {
      this.facility.facility.propertyTypes =
        this.facility.facility.propertyTypes
          .slice()
          .sort((a, b) => a.priority - b.priority);
    },
    checkMove() {
      for (let key in this.facility.facility.propertyTypes) {
        this.facility.facility.propertyTypes[key].priority = parseInt(key) + 1;
      }
    },
  },
  mounted() {
    this.$store.dispatch("GET_PROPERTY_TYPES").then(() => {
      this.getAvailablePropertyTypes();
      this.sortPropertyTypes();
    });
  },
  watch: {
    "facility.facility.propertyTypes": function () {
      this.getAvailablePropertyTypes();
      //this.sortPropertyTypes();
      //  this.getAvailablePropertyTypes();
    },
  },
};
</script>

<style>
.ghost {
  opacity: 0.8;
  background: #c8ebfb;
}
.v-autocomplete__content .v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 26px;
}
.v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
  padding: 6px 0;
}  
</style>
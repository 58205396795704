<template>
  <v-form @keyup.native.enter="search" v-on:submit.prevent class="pr-5">
    
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="filter.name_like"
          label="nazwa"
          clearable
          dense
        ></v-text-field>
      </v-col>
 
      
      <v-col
        md="2"
        cols=12
        class="text-right"
      >
        <v-btn
          color="blue"
          dark
          @click="search()"
          :loading="loading"
        >
          szukaj
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  name: "LakeSearchForm",
  components: {},
  data() {
    return {
      filter: {
      },
      loading: false,
    };
  },
  computed: {

  },
  methods: {
    search() {
      this.loading = true;
      this.$store.dispatch("FILTER_LAKES", {
        filter: JSON.parse(JSON.stringify(this.filter)),
      });
      this.$store
        .dispatch("GET_LAKES")
        .then(() => {
          this.loading = false;
        });

       
    },
    clearForm(){
      this.filter = {external: {}};
    }
  },
  mounted() {
   
  },
  updated(){

  },
  watch: {
   
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1050"
    >
      <v-card>
        <v-container>
          <v-form>
            <v-row flat>
              <v-col sm=6>
                <v-text-field
                  label="nazwa"
                  v-model="data.name"
                  :error-messages="nameErrors"
                />
              </v-col>
              <v-col sm=4>
                <v-text-field
                  label="slug"
                  v-model="data.slug"
                />
              </v-col>
              <v-col sm=2>
                <v-checkbox
                  v-model="data.isTop"
                  label="top"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row flat>
              <v-col cols="6">
                <v-row flat>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - mianownik"
                      v-model="data.nameNominative"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - dopełniacz"
                      v-model="data.nameGenitive"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - celownik"
                      v-model="data.nameDative"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - biernik"
                      v-model="data.nameAccusative"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - narzędnik"
                      v-model="data.nameInstrumental"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - miejscownik"
                      v-model="data.nameLocative"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="nazwa - wołacz"
                      v-model="data.nameVocative"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">

                <v-row flat>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="długość 1"
                      v-model="data.longitude1"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="szerokość 1"
                      v-model="data.latitude1"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="długość 2"
                      v-model="data.longitude2"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="szerokość 2"
                      v-model="data.latitude2"
                    />
                  </v-col>

                </v-row>
                <v-row flat>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="obszar"
                      v-model="data.area"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="długość"
                      v-model="data.length"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="szerokość"
                      v-model="data.width"
                    />
                  </v-col>
                  <v-col sm=3>
                    <v-text-field
                      dense
                      label="głębokość"
                      v-model="data.depth"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-simple-table v-if="addCityForm || data.cities.length === 0">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-autocomplete
                        v-model="newCity.city"
                        name="city"
                        label="dodawane miasto"
                        :items="citiesSuggestions"
                        :loading="loading"
                        :search-input.sync="citySearch"
                        placeholder="wyszukaj miasto"
                        hide-no-data
                        :error-messages="cityErrors"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <strong>{{data.item.text}}</strong> ({{data.item.value.gmina.name}} / {{data.item.value.powiat.name}} / {{data.item.value.voivodeship.name}})
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>

                    </td>
                    <td>
                      <v-text-field
                        suffix="km"
                        label="promień"
                        v-model="newCity.radius"
                      />
                    </td>
                    <td class="text-left">
                      <v-btn
                        color="success"
                        @click="addCity()"
                        small
                        class="mr-1"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                      <v-btn
                        color="warning"
                        @click="addCityForm=false ; newCity={city:{}}"
                        v-if="data.cities.length > 0"
                        small
                      >
                        <v-icon>not_interested</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-simple-table
              dense
              v-if="data.cities.length > 0"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>miasto</th>
                    <th>promień</th>
                    <th class="text-right">
                      <v-btn
                        v-if="!addCityForm"
                        @click="addCityForm = true"
                        color="success"
                        small
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lakeCity in data.cities"
                    :key="lakeCity.id"
                  >
                    <td>
                      {{lakeCity.city.name}}
                    </td>
                    <td>
                      {{lakeCity.radius}} <span v-if="lakeCity.radius">km</span>
                    </td>
                    <td class="text-right city-action-cell">
                      <v-btn
                        color="error"
                        dark
                        small
                        @click="deleteCity(lakeCity)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>

            </v-simple-table>

          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-1"
              dark
              @click="save()"
            >
              zapisz
            </v-btn>
            <v-btn
              color="warning"
              @click="dialog=false"
            >
              anuluj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  name: "LakeForm",
  data: () => ({
    data: {
      cities: [],
    },
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
    addCityForm: false,
    citySearch: null,
    citiesSuggestions: [],
    newCity: {
      city: {},
    },
  }),
  validations: {
    data: {
      name: { required },
    },
    newCity: {
      city: {
        required,
        unique: function (value) {
          return !this.isCityUnique(value);
        },
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) return errors;
      !this.$v.data.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.newCity.city.$dirty) return errors;
      !this.$v.newCity.city.required && errors.push("wybierz miasto");
      !this.$v.newCity.city.unique && errors.push("miasto już istnieje");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    deleteCity(city) {
      this.data.cities = this.data.cities.filter(
        (current) => {
          return current.city.id !== city.city.id;
        }
      );
    },
    save() {
      /*this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }*/
      this.dialog = false;
    },
    addCity: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.$v.$reset();
      this.data.cities.push(this.newCity);
      this.newCity = {
        city: {},
      };
    },
    isCityUnique: function (newCity) {
      for (let key in this.data.cities) {
        let cityRow = this.data.cities[key];
        if (cityRow.city.id === newCity.id) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {},
  watch: {
    citySearch(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/cities?filter[name_like]=` + val, {})
        .then((response) => {
          var data = [];
          for (let key in response.data) {
            var city = response.data[key];
            data.push({
              text: city.name,
              value: city,
            });
          }

          this.citiesSuggestions = data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>


<style>
</style>
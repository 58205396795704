<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <InvoiceCreateEntry
      :invoice="invoice"
      :editEntry = "invoiceEntry"
      v-on:cancel="dialog = false"
      @save-entry="save" 
     
    />
  </v-dialog>
</template>

<script>
import InvoiceCreateEntry from "./InvoiceCreateEntry";
export default {
  props: ["invoice"],
  name: "InvoiceEditEntryDialog",
  components: {InvoiceCreateEntry},
  data: () => ({
    loading: false,
    dialog: false,
    invoiceEntry: null
  }),

  computed: {},
  methods: {
    openEditDialog(invoiceEntry){
      this.invoiceEntry = invoiceEntry;
      this.dialog  = true;
    },
    save(entry){
      this.$emit('save-entry',entry);
      this.dialog = false;
    }
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
<template>
  <v-footer
    fixed
    padless
    v-if="lock"
  >
    <v-card
      flat
      tile
      width="100%"
      class="red lighten-1 text-center"
    >
      <v-card-text class="white--text">
        UWAGA! Obiekt jest obecnie edytowany przez:
        <span
          class="font-weight-bold"
          v-for="row in list"
          :key="row.user.id"
        >
        <v-chip outlined dark class="ml-3">{{row.user.name}}</v-chip>
        </span>

      </v-card-text>
    </v-card>
    <v-fade-transition v-if="list[0].user.id !== CURRENT_USER.id">
      <v-overlay
        color="red"
        opacity="0.3"
      >
        
      </v-overlay>
    </v-fade-transition>
  </v-footer>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "FacilityEditLock",
  props: ["facility"],
  components: {},
  data: () => ({
    lock: false,
    interval: null,
    list: [],
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    init() {
      clearInterval(this.interval);
      this.setLock();
      this.interval = setInterval(() => {
        this.setLock();
      }, 20000);
    },
    setLock() {
      if (!this.facility.id) {
        return;
      }
      axios
        .get(`/facilities/${this.facility.id}/locks`, {})
        .then((response) => {
          if (response.data.status === false) {
            this.lock = true;
            this.list = response.data.list;
          } else {
            this.lock = false;
            this.list = [];
          }
        });
    },
  },
  watch: {
    facility() {
      this.lock = false;
      clearInterval(this.interval);
      this.init();
    },
  },
};
</script>


<style>
</style>
<template>
  <div :style="{'height': '94vh', 'width': '100%'}" >
    <GmapMap 
    :zoom="zoom"
    :center="currentPosition"
    ref="map-range"
    id="map-range">
    <gmap-circle
      :position="currentPosition"
      :radius="1"
      :center="currentPosition"
      :options="circlePointOptions"
    >
    </gmap-circle>
    <gmap-circle
      :position="currentPosition"
      :radius="10000"
      :center="currentPosition"
      :options="circleFirstOptions"
    >
    </gmap-circle>
    <gmap-circle
      :position="circleSecondOptions"
      :radius="20000"
      :center="currentPosition"
      :options="circleSecondOptions"
    >
    </gmap-circle>
  </GmapMap>
  </div>
</template>

<script>
//import { helpers } from 'gmap-vue';
//const { googleMapsApiInitializer } = helpers;

export default {
  name: "GeolocationRangeMap",
  components: {
  
  },
  props: ["facility"],
  data: () => ({
    circlePointOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 10,
      fillColor: "#FF0000",
      fillOpacity: 0.5
    },
    circleFirstOptions: {
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#000000",
      fillOpacity: 0.1
    },
    circleSecondOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.05
    },
    loading: false,
    zoom: 10,
    defaultHeight:700,
    currentZoom: 11.5,
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    currentPosition: {},
    center: {}
  }),
  computed: {
  },
  methods: {
    updateCurrentPosition(){
      this.currentPosition = {
        lat: parseFloat(this.facility.facility.latitude),
        lng: parseFloat(this.facility.facility.longitude)
      };
    
    }
  },
  watch: {

  },
  mounted() {
    this.updateCurrentPosition();
  },
};
</script>


<style>
#map-range {
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
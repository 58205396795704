<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      max-width="800"
    >
      <v-card>
        <v-card-title>Wczytanie nowego szablonu spowoduje utracenie zmian, kontynuować ?</v-card-title>
        <v-card-text>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="confirm()"
          >TAK</v-btn>
          <v-btn
            color="warning"
            @click="isDialogActive=false"
          >NIE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "SendMailChangeTemplateConfirmation",
  components: {
  },
  data: () => ({
    isDialogActive: false,
    template: {}
  }),
 
  computed: {
   
  },
  methods: {
    confirm(){
      this.isDialogActive = false;
      this.$emit('confirm',this.template);
    },
    openDialog(template) {
      this.template = template;
      this.isDialogActive = true;
    },
  },
  mounted() {},
};
</script>


<style>
</style>
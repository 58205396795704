<template>
  <v-card>
    <v-toolbar
      color="blue"
      dark
    >
      <v-toolbar-title>Tworzenie pozycji</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container>

        <v-form ref="form">
          <v-select
            v-model="entry.user"
            return-object
            :items="operators"
            :item-text="getUserName"
            :error-messages="userErrors"
            label="opiekun"
            required
            dense
          ></v-select>
          <v-select
            v-model="entry.invoice_entry_product"
            :items="invoiceProducts"
            :error-messages="productErrors"
            item-text="name"
            return-object
            label="produkt"
            required
            dense
          ></v-select>

          <v-select
            v-if="isDeveloperRequired"
            v-model="entry.developer"
            :items="operators"
            :error-messages="developerErrors"
            :item-text="getUserName"
            label="wykonawca"
            return-object
            dense
          ></v-select>

          <v-select
            v-model="entry.service"
            :items="owner.services"
            :item-text="getServiceName"
            label="powiązana usługa"
            return-object
            required
            dense
          ></v-select>
          <v-currency-field
            v-model="entry.amount"
            label="kwota netto"
            suffix="PLN"
            :value-as-integer="true"
            :error-messages="amountErrors"
          ></v-currency-field>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="blue darken-1"
        text
        v-on:click="clearForm(), $emit('cancel')"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-on:click="submit"
        color="blue darken-1"
        text
      > dodaj </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["invoice", "owner"],
  name: "InvoiceCreateEntry",
  data() {
    return {
      entry: {
        user: {},
        developer: {},
        invoice_entry_product: {},
        service: {},
        amount: 0,
      },
      operators: [],
      invoiceProducts: [],
    };
  },
  validations: {
    entry: {
      user: required,
      developer: required,
      invoice_entry_product: required,
      /*amount: { required, minValue: minValue(1) }*/
    },
  },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
    isDeveloperRequired() {
      return (
        this.entry &&
        this.entry.invoice_entry_product &&
        (this.entry.invoice_entry_product.id === 1 ||
          this.entry.invoice_entry_product.id === 5)
      );
    },
    amountErrors() {
      const errors = [];
      /*
      if (!this.$v.entry.amount.$dirty) return errors;
      !this.$v.entry.amount.required && errors.push("Kwota jest wymagana.")
      !this.$v.entry.amount.minValue && errors.push("Kwota jest wymagana.");*/
      return errors;
    },
    userErrors() {
      const errors = [];
      if (!this.$v.entry.user.$dirty) return errors;
      !this.$v.entry.user.required && errors.push("Opiekun jest wymagany.");
      return errors;
    },
    developerErrors() {
      const errors = [];
      if (!this.$v.entry.user.$dirty) return errors;
      !this.$v.entry.user.required && errors.push("Wykonawca jest wymagany.");
      return errors;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.entry.invoice_entry_product.$dirty) return errors;
      !this.$v.entry.invoice_entry_product.required &&
        errors.push("Typ produktu jest wymagany.");
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      if (this.invoice.invoice_entries.length > 0) {
        for (let key in this.invoice.invoice_entries) {
          var entry = this.invoice.invoice_entries[key];
          if (entry.service.id === this.entry.service.id) {
            return false;
          }
        }
      }

      this.$emit("add-entry", this.entry);

      //this.invoice.invoice_entries.push(this.entry);
      this.clearForm();
      this.$emit("entry-created");
    },
    getUserName(user) {
      return user.name + " " + user.surname;
    },
    getServiceName(service) {
      if (!service.type) {
        return;
      }
      return service.type.name + " - " + service.domain.domain;
    },
    clearForm() {
      this.entry = Object.assign(
        {},
        {
          user: {},
          developer: {},
          invoice_entry_product: {},
          service: {},
          amount: 0,
        }
      );

      this.operators.forEach((user) => {
        if (user.name + " " + user.surname === this.CURRENT_USER.name) {
          console.log("Fdasfa");
          this.entry.user = user;
        }
      });

      //this.$refs.form.reset();
      this.$v.$reset();
  
    },
    getOperators() {
      this.loading = true;
      axios
        .get(`/sitemanager/operators`)
        .then((response) => {
          this.operators = response.data;

          this.operators.forEach((user) => {
            if (user.name + " " + user.surname === this.CURRENT_USER.name) {
              this.entry.user = user;
            }
          });
        })
        .finally(() => (this.loading = false));
    },
    getInvoiceProducts() {
      this.loading = true;
      axios
        .get(`/sitemanager/invoices/products`)
        .then((response) => {
          this.invoiceProducts = response.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getOperators();
    this.getInvoiceProducts();
  },
  watch: {},
};
</script>


<style>
</style>
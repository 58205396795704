<template>
     <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-top-transition"
      max-width="600"
    >

      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Wysyłanie wiadomośći SMS do: {{ facility.name }}</v-toolbar-title>

        </v-toolbar>
        <FacilityEditSendSMS
          :facility="facility"
          :options="options"
          v-on:FacilitySendSMS:sended="dialog = false;successMessage('Wiadomość wysłana poprawnie')"
        />
      </v-card>
    </v-dialog>
</template>

<script>
import FacilityEditSendSMS from "./../FacilityEditSendSMS"
export default {
  name: "SendSMSDialog",
  props: ["facility"],
  data: () => ({
    dialog: false,
    options: {}
  }),
  components: {
    FacilityEditSendSMS
  },
  computed: {
  
  },
  mounted() {
   
  },
  methods: {
    openDialog(options) {
      this.options = options;
      this.dialog = true;
    },
    closeDialog() {
      this.options = {};
      this.dialog = false;
    }
   
  },
  watch: {
  
  },
};
</script>


<style>
.quick-text-button {
  min-width: 40px !important;
}
@media (max-width: 1300px) {
  .quick-text-button {
    min-width: 23px !important;
  }
}
</style>
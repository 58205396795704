<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >

      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="70"
        ></v-progress-circular>
      </v-overlay>

      <v-card>
        <v-toolbar
          color="blue"
          dark
          dense
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title dense>
            Kalendarz
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row class="fill-height">
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    dzisiaj
                  </v-btn>
                  <v-dialog
                    ref="dialog"
                    v-model="setDateDialog"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        wybierz dzień
                      </v-btn>
                    </template>
                    <v-date-picker
                      locale="pl"
                      v-model="setDate"
                      scrollable
                      @input="setDateDialog = false"
                    >
                    </v-date-picker>
                  </v-dialog>

                  <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                  >
                    <v-icon small>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                  >
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu
                    bottom
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dzień</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Tydzień</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Miesiąc</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>

              <v-sheet height="700">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :event-name="eventName"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  :interval-format="intervalFormat"
                  first-time="7:00"
                  interval-count="22"
                  interval-minutes="30"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                  :weekdays="weekdays"
                  locale="pl"
                  @mousedown:event="startDrag"
                  @mousedown:time="startTime"
                  @mousemove:time="mouseMove"
                  @mouseup:time="endDrag"
                  @mouseleave.native="cancelDrag"
                >
                  <template v-slot:event="{ event, timed, eventSummary }">
                    <div
                      class="v-event-draggable"
                      v-html="eventSummary()"
                    ></div>
                    <div
                      v-if="timed"
                      class="v-event-drag-bottom"
                      @mousedown.stop="extendBottom(event)"
                    ></div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                  >
                    <v-toolbar
                      :color="selectedEvent.color"
                      dark
                    >
                      <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span v-html="selectedEvent.details"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <EventEdit
      ref="eventEdit"
      v-on:remove="remove"
    />
  </div>
</template>

<script>
import EventEdit from "./UserCalendar/EventEdit";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    EventEdit,
  },
  name: "UserCalendar",
  data: () => ({
    dialog: false,
    error: false,
    loading: false,
    setDate: null,
    setDateDialog: false,
    focus: "",
    type: "week",
    typeToLabel: {
      month: "miesiąc",
      week: "tydzień",
      day: "dzień",
    },
    weekdays: [1, 2, 3, 4, 5],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
  }),
  computed: {
    ...mapGetters(["USER_EVENTS"]),
    ...mapGetters(["CURRENT_USER"]),
  },
  methods: {
    eventName(event) {
      return (
        (event.input.facility && event.input.facility.id
          ? "[" + event.input.facility.id + "] "
          : "") + (event.input.title ? event.input.title : "")
      );
    },
    open() {
      this.dialog = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      if (event.type === 2) {
        return "green";
      }
      return "blue";
      //return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ event }) {
      this.$refs.eventEdit.openDialog(event);
    },
    intervalFormat(locale) {
      return locale.time;
    },
    updateRange({ start, end }) {
      this.events = [];
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);

      this.loading = true;
      let filter = {
        user_id_eq: this.CURRENT_USER.id,
        start_gt: min,
        start_lt: max,
      };
      this.$store
        .dispatch("SET_USER_EVENTS_FILTER", {
          filter: filter,
        })
        .then(() => {
          this.$store.dispatch("GET_USER_EVENTS", {}).then(() => {
            this.USER_EVENTS.forEach((event) => {
              event.timed = true;
              event.start = moment(event.start).valueOf();
              event.end = moment(event.end).valueOf();
              this.events.push(event);
            });
            this.loading = false;
          });
        });

      /*
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }
      */
    },

    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          start: this.createStart,
          end: this.createStart,
          type: 1,
          status: 0,
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.createEvent && this.createStart !== null) {
        this.$refs.eventEdit.openDialog(this.createEvent);
      }

      if (this.dragEvent) {
        this.$refs.eventEdit.openDialog(this.dragEvent);
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    remove(event) {
      let rs = this.events.filter((current) => {
        return current.id !== event.event.id;
      });
      this.events = [...rs];
    },
  },
  mounted() {
    //this.$refs.calendar.checkChange();
  },
  watch: {
    setDate(){
      this.focus = this.setDate;
    }
  },
};
</script>

<style>
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}

.v-event-draggable::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: "";
}
</style>
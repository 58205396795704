import axios from "axios";

export default {
    state: {
        messageTemplateTypes: [],
        filter: {}
    },
    getters: {
        MESSAGE_TEMPLATE_TYPES: state => {
            return state.messageTemplateTypes;
        },
        MESSAGE_TEMPLATE_TYPES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_MESSAGE_TEMPLATE_TYPES: (state, payload) => {
            state.messageTemplateTypes = payload;
        },
    },
    actions: {
        GET_MESSAGE_TEMPLATE_TYPES: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                  .get(`messageTemplates/types`)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_MESSAGE_TEMPLATE_TYPES", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
    }
}
import axios from "axios";
import qs from 'qs'

export default {
    state: {
        cities: [],
        filter: {}
    },
    getters: {
        CITIES: state => {
            return state.cities;
        },
        CITIES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_CITIES: (state, payload) => {
            state.cities = payload;
        },
        SET_CITIES_FILTER: (state, payload) => {
            state.filter = payload;
        },
        UPDATE_CITY: (state,  payload) => {
            if (payload) {
                const element = state.cities.find(city => city.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_CITY: (state, payload) => {
            state.cities.push(payload);
        }
    },
    actions: {
        GET_CITIES: ({commit,state}) => {
            return new Promise((resolve, reject) => {
                let query = "?";
                if(state.filter){
                    query += "&"+ qs.stringify({filter: state.filter}, { encode: false });
                }
               
                axios
                  .get(`cities`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_CITIES", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
        FILTER_CITIES: ({commit},{filter}) => {
            commit("SET_CITIES_FILTER", filter);
        },
        UPDATE_CITY: ({ commit }, { city }) => {
            return new Promise((resolve, reject) => {
                axios
                .patch(`cities/${city.id}`, city)
                .then(({ data, status }) => {
                  if (status === 200) {     
                      commit("UPDATE_CITY", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
        ADD_CITY: ({ commit }, { city }) => {
            return new Promise((resolve, reject) => {
                axios
                .post(`cities`, city)
                .then(({ data, status }) => {
                  if (status === 200) {
                      commit("ADD_CITY", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        }
    }
}
<template>
  <v-row
    dense
    class="mt-5"
    v-if="FACILITY.id"
  >
    <v-col sm="2">
      <v-btn
        icon
        color="white"
        @click="goToSearchForm"
      >
        <v-icon dark> arrow_back </v-icon>
      </v-btn>
    </v-col>
    <v-col sm="3">
      <v-autocomplete
        placeholder="skocz do obiektu"
        dense
        
        dark
        outlined
        hide-no-data
        return-object
        v-model="jumpToFacility"
        item-text="value"
        :auto-select-first=true
        :items="facilitySuggestions"
        :search-input.sync="facilitySuggestionsSearch"
        class="text-white jumpToFacilityAutocomplete"
      ></v-autocomplete>

    </v-col>
    <v-col v-if="FACILITY_SEARCH.length > 1 && current >= 0">
      <v-btn
        icon
        color="white"
        @click="first"
      >
        <v-icon> skip_previous </v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click="prev"
      >
        <v-icon> chevron_left </v-icon>
      </v-btn>
      <span class="white--text">{{ current + 1 }} z {{ FACILITY_SEARCH.length }}</span>
      <v-btn
        icon
        color="white"
        @click="next"
      >
        <v-icon> navigate_next </v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click="last"
      >
        <v-icon> skip_next </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "FacilityEditSearchNavigation",
  components: {},
  data: () => ({
    facilitySuggestions: [],
    facilitySuggestionsLoading: false,
    jumpToFacility: {},
    facilitySuggestionsSearch: null,
  }),
  computed: {
    ...mapGetters(["FACILITY_SEARCH"]),
    ...mapGetters(["FACILITY"]),
    current: function () {
      let current = parseInt(
        Object.keys(this.FACILITY_SEARCH).find(
          (k) => this.FACILITY_SEARCH[k] === this.FACILITY.external_id
        )
      );
      return current;
    },
  },
  methods: {
    first: function () {
      if (this.FACILITY_SEARCH[0]) {
        this.$emit("facility-change");
        this.$router.push({
          name: "facilityEdit",
          params: { id: this.FACILITY_SEARCH[0] },
        });
      }
    },
    prev: function () {
      if (this.FACILITY_SEARCH[this.current - 1]) {
        this.$emit("facility-change");
        this.$router.push({
          name: "facilityEdit",
          params: { id: this.FACILITY_SEARCH[this.current - 1] },
        });
      }
    },
    next: function () {
      if (this.FACILITY_SEARCH[this.current + 1]) {
        this.$emit("facility-change");
        this.$router.push({
          name: "facilityEdit",
          params: { id: this.FACILITY_SEARCH[this.current + 1] },
        });
      }
    },
    last: function () {
      const length = this.FACILITY_SEARCH.length;
      if (length && this.FACILITY_SEARCH[length - 1]) {
        this.$emit("facility-change");
        this.$router.push({
          name: "facilityEdit",
          params: { id: this.FACILITY_SEARCH[length - 1] },
        });
      }
    },
    goToSearchForm: function () {
      this.$emit("facility-change");
      if(this.FACILITY_SEARCH.length > 0){
        this.$router.push({ name: "appDefault" });
      } else {
        this.$router.push({ name: "listingSearch" });
      }

    },
  },
  watch: {
    facilitySuggestionsSearch(val) {
      this.facilitySuggestionsLoading = true;
      let filter = "external_id_like";
      const pattern = /^\d+$/;
      if (!pattern.test(val)) {
        filter = "name_like";
      }
      axios
        .get(`/facilities?limit=20&filter[` + filter + `]=` + val, {})
        .then((response) => {
          var data = [];

          for (let key in response.data.result.items) {
            var row = response.data.result.items[key];
            row.value = row.external_id + " - " + row.name;
            data.push(row);
          }

          this.facilitySuggestions = response.data.result.items;
        })
        .finally(() => (this.facilitySuggestionsLoading = false));
    },
    jumpToFacility(facility) {
      if (facility.id) {
        this.$emit("facility-change");
        this.jumpToFacility = {};
        this.$router.push({
          name: "facilityEdit",
          params: { id: facility.external_id },
        });
      }
    },
  },
};
</script>


<style>
.jumpToFacilityAutocomplete input::placeholder {
  color: white!important;
  opacity: 1;
}
</style>
<template>
  <v-form ref="form">
    <v-row dense>
      <v-col>
        <v-select
          label="status"
          :items="statuses"
          item-text="name"
          item-value="id"
          v-model="filter.status_eq"
          clearable
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="nazwa"
          v-model="filter.name_like"
          dense
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="źródło"
          v-model="filter.source_like"
          dense
        ></v-text-field>
      </v-col>
      <v-col
        md="3"
        cols=12
        class="text-center py-0"
      >
        <v-btn
          color="blue"
          dark
          @submit="search()"
          @click="search()"
          :loading="loading"
        >
          szukaj
        </v-btn>
      </v-col>
    </v-row>
  </v-form>

</template>

<script>
export default {
  name: "RegistrationSearchForm",
  components: {},
  data: () => ({
    filter: {},
    loading: false,
    statuses: [
      { id: 0, name: "nowy" },
      { id: 1, name: "duplikat" },
      { id: 2, name: "dodany" },
    ],
  }),
  computed: {},
  methods: {
    search() {
      this.loading = true;

      this.$store
        .dispatch("FILTER_REGISTRATIONS", {
          filter: JSON.parse(JSON.stringify(this.filter)),
        })
        .then(() => {
          this.$store
            .dispatch("FIND_REGISTRATIONS", {
              filter: JSON.parse(JSON.stringify(this.filter)),
            })
            .then(() => {
              this.loading = false;
            });
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-toolbar 
      dense
      :flat="inline"
    >

      <v-menu offset-y>

        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="orange darken-2"
            class="white--text ma-5"
            v-bind="attrs"
            v-on="on"
            small
          >
            serwisy
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in portals"
            :key="item.name"
            link
          >
            <v-list-item-title
              v-text="item.name"
              @click="openGooglePortalSearch(item.phrase)"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        color="green"
        dark
        small
        @click="goToTargeoCoordinates"
      >
        targeo
      </v-btn>

      <v-btn
        class="ml-2"
        color="green"
        dark
        small
        @click="goToStreetView"
      >
        streetview
      </v-btn>

      <v-text-field
        label="adres"
        class="mx-2 my-0 mt-3"
        dense
        single-line
        v-model="address"
      >
      </v-text-field>

      <v-btn
        color="green"
        dark
        small
        @click="decodeCoordinatesFromAddress"
      >
        lokalizuj
      </v-btn>

      <v-btn
        color="green"
        class="ml-2"
        dark
        small
        @click="goToTargeoGecode"
      >
        lokalizuj na targeo
      </v-btn>

    </v-toolbar>
    <v-toolbar
      dense
      :flat="inline"
    >
      <v-label dense>współrzędne (stopnie dziesiętne):</v-label>
      <v-text-field
        label="N"
        class="mx-2 my-0 mt-2"
        dense
        single-line
        append-icon="north"
        v-model="facility.facility.longitude"
      >
      </v-text-field>

      <v-text-field
        label="E"
        class="mx-2 my-0 mt-2"
        dense
        single-line
        append-icon="east"
        v-model="facility.facility.latitude"
      >
      </v-text-field>

      <v-btn-toggle v-model="mapType" rounded >
        <v-btn small>
          gooogle
        </v-btn>
        <v-btn small>
          osm
        </v-btn>
      </v-btn-toggle>

      <v-slider
        class="pt-3 ml-3"
        min="10"
        max="16"
        label="zoom"
        thumb-color="red"
        :thumb-label="true"
        dense
        v-model="facility.facility.mapZoom"
      ></v-slider>
      <v-checkbox
        dense
        v-model="facility.facility.coordinatesVerified"
        label="sprawdzony GPS"
      ></v-checkbox>
    </v-toolbar>

    <v-card-text>
      <Leaflet
        v-if="mapType === 1"
        :longitude="facility.facility.longitude"
        :latitude="facility.facility.latitude"
        v-on:changeCoordinates="changeCoordinates"
        :height="inline ? 400 : 650"
      />
      <Google
      v-if="mapType === 0 "
        :longitude="facility.facility.longitude"
        :latitude="facility.facility.latitude"
        v-on:changeCoordinates="changeCoordinates"
        :height="inline ? 400 : 650"
      />
    </v-card-text>
    <v-card-actions v-if="!inline">
      <v-divider />
      <v-btn color="primary" @click="$emit('save-geolocation')"> ZAPISZ</v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import Leaflet from "./Geolocation/Leaflet";
import Google from "./Geolocation/Google";

export default {
  name: "FacilityEditGeolocation",
  props: ["facility","inline"],
  components: { Leaflet,Google },
  data: () => ({
    loading: false,
    address: "",
    mapType: 0
  }),
  computed: {
    portals() {
      return [
        {
          name: "google.com",
          phrase: `${this.facility.name} ${this.facility.facility.address}`,
        },
        {
          name: "booking.com",
          phrase: `${this.facility.name} ${this.facility.facility.address} site:booking.com`,
        },
        {
          name: "expedia.com",
          phrase: `${this.facility.name} ${this.facility.facility.address} site:www.expedia.com`,
        },
        {
          name: "airbnb.pl",
          phrase: `${this.facility.name} ${this.facility.facility.cities.length > 0 ? this.facility.facility.cities[0].city.name:''}  site:www.airbnb.pl`,
        },
        {
          name: "noclegi.pl",
          phrase: `${this.facility.name} ${this.facility.facility.address} site:noclegi.pl`,
        },
        {
          name: "nocowanie.pl",
          phrase: `${this.facility.name} ${this.facility.facility.address} site:nocowanie.pl`,
        },
        {
          name: "facebook",
          phrase: `${this.facility.name} ${this.facility.facility.address} site:facebook.com`,
        },        
        {
          name: "wszystkie",
          phrase: `*`,
        },
      ];
    },
  },
  methods: {
    goToTargeoGecode() {
      window.open(
        "https://mapa.targeo.pl/" +
          encodeURIComponent(this.facility.facility.address)
      );
    },
    goToTargeoCoordinates() {
      window.open(
        `http://mapa.targeo.pl/,24,${this.facility.facility.latitude},${this.facility.facility.longitude}`
      );
    },
    goToStreetView() {
      window.open(
        `https://www.google.com/maps?cbll=${this.facility.facility.latitude},${this.facility.facility.longitude}&layer=c`
      );
    },
    changeCoordinates(coordinates) {
      this.facility.facility.longitude = coordinates.longitude;
      this.facility.facility.latitude = coordinates.latitude;
    },
    openGooglePortalSearch(phrase) {
      if(phrase === '*'){
        this.openAllGooglePortalSearch();
      } else {
        window.open(`https://www.google.pl/search?ie=utf-8&oe=utf-8&q=${phrase}`);
      }
    },
    openAllGooglePortalSearch(){
      this.portals.forEach(item => {
        if(item.phrase !== "*"){
          this.openGooglePortalSearch(item.phrase);
        }
      });
    },
    decodeCoordinatesFromAddress() {
      this.loading = true;
      axios
        .get(`/tools/geolocalization?name=${this.address}`)
        .then((response) => {
          if (response.data.longitude && response.data.latitude) {
            this.facility.facility.longitude = response.data.longitude;
            this.facility.facility.latitude = response.data.latitude;
          }
          this.loading = false;
        })
        .catch(() => {});
    },
  },
  watch: {
    facility() {
      this.address = this.facility.facility.address;
    },
  },
  mounted() {
    this.address = this.facility.facility.address;

    if(!this.facility.facility.longitude || this.facility.facility.longitude.length === 0 && this.facility.facility.cities.length > 0){
      this.facility.facility.longitude = this.facility.facility.cities[0].city.longitude;
      this.facility.facility.latitude = this.facility.facility.cities[0].city.latitude;
    }
  },
};
</script>


<style>
</style>
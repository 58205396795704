import axios from "axios";

export default {
    state: {
        messageTemplateCategory: [],
        filter: {}
    },
    getters: {
        MESSAGE_TEMPLATE_CATEGORIES: state => {
            return state.messageTemplateCategory;
        },
        MESSAGE_TEMPLATE_CATEGORIES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_MESSAGE_TEMPLATE_CATEGORIES: (state, payload) => {
            state.messageTemplateCategory = payload;
        },
    },
    actions: {
        GET_MESSAGE_TEMPLATE_CATEGORIES: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                  .get(`messageTemplates/categories`)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_MESSAGE_TEMPLATE_CATEGORIES", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
    }
}
<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="850"
    >
      <v-card>
        <v-container>
          <v-form>
            <v-row flat>
              <v-col
                md=2
                sm=12
              >
                <v-text-field
                  label="swift"
                  v-model="data.swift"
                />
              </v-col>
              <v-col
                md=2
                sm=12
              >
                <v-text-field
                  label="iban"
                  v-model="data.iban"
                />
              </v-col>
              <v-col
                md=4
                sm=12
              >
                <v-text-field
                  label="numer konta"
                  v-model="data.number"
                  :error-messages="numberErrors"
                />
              </v-col>
              <v-col
                md=2
                sm=12
              >
                <v-select
                  label="waluta"
                  v-model="data.currency"
                  :items="BANK_ACCOUNT_CURRENCIES"
                  item-text="code"
                  item-value="id"
                  return-object
                  :error-messages="currencyErrors"
                />
              </v-col>
              <v-col
                md=2
                sm=12
              >
                <v-checkbox
                  v-model="data.public"
                  label="widoczny"
                ></v-checkbox>
              </v-col>
              <v-col md=12>
                <v-textarea
                  v-model="data.comment"
                  label="komentarz"
                  rows=1
                />
              </v-col>
              <v-col md=12>
                <v-textarea
                  v-model="data.receiver"
                  label="odbiorca"
                  rows=3
                />
              </v-col>
            </v-row>

          </v-form>
          <v-card-actions>
            <v-btn
              color="success"
              class="mr-1"
              dark
              small
              @click="save()"
            >
              <v-icon>done</v-icon>
            </v-btn>
            <v-btn
              color="warning"
              @click="dialog=false"
              small
            >
              <v-icon>not_interested</v-icon>
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
const bankAccountPattern = helpers.regex("slug", /^\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}$/);

export default {
  components: {},
  name: "ClientBankAccountForm",
  data: () => ({
    data: {},
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
  }),
  validations: {
    data: {
      number: { required, bankAccountPattern },
      currency: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["BANK_ACCOUNT_CURRENCIES"]),
    numberErrors() {
      const errors = [];
      if (!this.$v.data.number.$dirty) return errors;
      !this.$v.data.number.required && errors.push("podaj numer konta");
      !this.$v.data.number.bankAccountPattern && errors.push("podaj poprawny numer konta xx xxxx xxxx xxxx xxxx xxxx xxxx");
      return errors;
    },
    currencyErrors() {
      const errors = [];
      if (!this.$v.data.currency.$dirty) return errors;
      !this.$v.data.currency.required && errors.push("wybierz walutę");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.dialog = false;
      this.$emit("bankAccount:update", this.data);
    },
  },
  mounted() {},
};
</script>


<style>
</style>
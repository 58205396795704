<template>
  <div style="min-height:100px;">
    <CitySearchForm></CitySearchForm>
    <v-divider class="pt-5"></v-divider>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-btn
      color="success"
      @click="add()"
      small
      right
      absolute
      class="mr-3"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-simple-table v-if="CITIES.length > 0">
      <thead>
        <tr>
          <th class="text-left">
            ID
          </th>
          <th class="text-left">
            Nazwa
          </th>
          <th class="text-left">
            Gmina
          </th>
          <th class="text-left">
            Powiat
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="city in CITIES"
          :key="city.id"
        >
          <td>{{ city.id }}</td>
          <td>{{ city.name }}</td>
          <td>{{ city.gmina.name }}</td>
          <td>{{ city.powiat.name }}</td>

          <td style="text-align:right;">

            <v-btn
              depressed
              small
              color="primary"
              @click="showEditDialog(city)"
            >
              edytuj
            </v-btn>

          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <CityForm ref="cityForm" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CityForm from "./AdminCity/CityForm";
import CitySearchForm from "./AdminCity/CitySearchForm";

export default {
  name: "AdminCity",
  components: {
    CityForm,
    CitySearchForm,
  },
  data: () => ({
    dialogMessageTemplate: false,
    currentMessageTemplateEdit: {},
    loading: false,
  }),
  computed: {
    ...mapGetters(["CITIES"]),
  },
  methods: {
    showEditDialog(item) {
      this.$refs.cityForm.open(item);
    },

    add() {
      this.$refs.cityForm.open({
        lands: [],
        regions: []
      });
    },
  },
  mounted() {
    /*this.loading = true;
    this.$store.dispatch("GET_CITIES").then(() => {
      this.loading = false;
    });*/
  },
};
</script>


<style>
</style>
<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="800"
    :loading="loading"
  >

    <v-card>
      <v-toolbar
        dark
        color="blue"
        dense
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Czyszczenie cache serwisu</v-toolbar-title>

      </v-toolbar>
      <v-container class="mt-3">
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="adres url listy"
              placeholder="np. https://meteor-turystyka.pl/noclegi,krakow,0.html"
              v-model="url"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              :disabled="!url"
              color="primary"
              block
              :loading="loading"
              @click="clear()"
            >wyczyść</v-btn>
          </v-col>
        </v-row>

      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "ToolMeteorClearCacheDialog",
  props: [],
  data: () => ({
    dialog: false,
    url: null,
    loading: false,
    options: {},
  }),
  components: {},
  computed: {},
  mounted() {},
  methods: {
    
    clear(){
      this.loading = true;
      axios
        .get(`/tools/clearMeteorCache?url=${this.url}`)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          this.closeDialog();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.dialog = false;
        });
    },
    openDialog(options) {
      this.options = options;
      this.dialog = true;
    },
    closeDialog() {
      this.options = {};
      this.url = null;
      this.dialog = false;
    },
  },
  watch: {},
};
</script>

<template>
  <v-container>
    <v-card
      outlined
      class="py-6 px-2"
    >
      <v-row>
        <v-col md="8">
          <v-text-field
            v-model="facility.name"
            label="nazwa"
            dense
            clearable
            @change="$v.$touch()"
            @keydown="$v.$touch()"
            :error-messages="nameErrors"
          ></v-text-field>
        </v-col>
        <v-col md="4">

          <v-text-field
            v-model="facility.facility.slug"
            label="domena"
            clearable
            dense
            @change="$v.$touch()"
            :loading="checkingSlug"
            :error-messages="slugErrors"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-5" />
      <v-row>
        <v-col>
          <v-alert
            v-if="propertyTypeErrors.length > 0"
            type="error"
            dense
            outlined
          >
            <div
              v-for="error in propertyTypeErrors"
              :key="error"
            >
              {{error}}
            </div>
          </v-alert>
          <FacilityEditPropertyType :facility="facility" />
        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
const slug = helpers.regex("slug", /^([0-9a-z-]+)$/);

import FacilityEditPropertyType from "../FacilityEdit/FacilityEditPropertyType";

export default {
  name: "FacilityCreateName",
  components: { FacilityEditPropertyType },
  props: ["facility"],
  data: () => ({
    checkingSlug: false,
  }),
  validations: {
    facility: {
      name: {
        required,
      },
      facility: {
        propertyTypes: {
          required,
        },
        slug: {
          required,
          slug,
          async unique(value) {
            if (value === "") return true;
            this.checkingSlug = true;
            return new Promise((resolve, reject) => {
              axios
                .get(
                  "facilities?filter[external][slug_eq]=" +
                    value +
                    "&filter[external][id_neq]=" +
                    this.facility.facility.id
                )
                .then(({ data, status }) => {
                  if (status === 200) {
                    this.checkingSlug = false;
                    resolve(data.result.total_count === 0);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.facility.name.$dirty) return errors;
      !this.$v.facility.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
    propertyTypeErrors() {
      const errors = [];
      if (!this.$v.facility.facility.propertyTypes.$dirty) return errors;
      !this.$v.facility.facility.propertyTypes.required &&
        errors.push("wybierz rodzaj");
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (
        !this.$v.facility.facility.slug.$dirty ||
        this.$v.facility.facility.slug.$pending
      )
        return errors;
      !this.$v.facility.facility.slug.required &&
        errors.push("domena jest wymagana");
      !this.$v.facility.facility.slug.slug &&
        errors.push("domena musi składać się z cyfr i liter");
      !this.$v.facility.facility.slug.unique &&
        errors.push("domena jest zajęta");
      return errors;
    },
  },
  methods: {
    valid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      return true;
    },
  },
  mounted() {},
  watch: {
    "facility.facility.slug": function () {
      this.facility.facility.slug = this.facility.facility.slug
        .toLowerCase()
        .replace(/\u0142/g, "l")
        .normalize("NFKD")
        .replace(/[^0-9a-z-]/g, "");
    },
  },
};
</script>

<style>
</style>
import axios from "axios";
import qs from 'qs'


export default {
    state: {
        lakes: [],
        filter: {}
    },
    getters: {
        LAKES: state => {
            return state.lakes;
        },
        LAKES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_LAKES: (state, payload) => {
            state.lakes = payload;
        },
        SET_LAKES_FILTER: (state, payload) => {
            state.filter = payload;
        },
    },
    actions: {
        FILTER_LAKES: ({commit},{filter}) => {
            commit("SET_LAKES_FILTER", filter);
        },
        GET_LAKES: ({commit,state}) => {
            return new Promise((resolve, reject) => {
                let query = "?";
                if(state.filter){
                    query += "&"+ qs.stringify({filter: state.filter}, { encode: false });
                }
               
                axios
                  .get(`lakes`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_LAKES", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
    }
}
import axios from "axios";
import qs from 'qs'

export default {
    state: {
        facilityUserEvents: [],
        userEvents: [],
        filter: {}
    },
    getters: {
        FACILITY_USER_EVENTS: state => {
            return state.facilityUserEvents;
        },
        USER_EVENTS: state => {
            return state.userEvents;
        },
        USER_EVENTS_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_USER_EVENTS: (state, payload) => {
            state.userEvents = payload;
        },
        UPDATE_USER_EVENT: (state, payload) => {
            if (payload) {
                const element = state.userEvents.find(event => event.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_USER_EVENT: (state, payload) => {
            state.userEvents.push(payload);
        },
        REMOVE_USER_EVENT: (state, userEvent) => {
            let rs = state.userEvents.filter(current => {
                return current.id !== userEvent.id;
            });
            state.userEvents = [...rs];
        },
        SET_USER_EVENTS_FILTER: (state, payload) => {
            state.filter = payload;
        },

        SET_FACILITY_USER_EVENTS: (state, payload) => {
            state.facilityUserEvents = payload;
        },
        UPDATE_FACILITY_USER_EVENT: (state, payload) => {
            if (payload) {
                const element = state.facilityUserEvents.find(event => event.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_FACILITY_USER_EVENT: (state, payload) => {
            state.facilityUserEvents.push(payload);
        },
        REMOVE_FACILITY_USER_EVENT: (state, userEvent) => {
            let rs = state.facilityUserEvents.filter(current => {
                return current.id !== userEvent.id;
            });
            state.facilityUserEvents = [...rs];
        },
    },
    actions: {
        SET_USER_EVENTS_FILTER: ({ commit }, { filter }) => {
            commit("SET_USER_EVENTS_FILTER", filter);
        },
        GET_FACILITY_USER_EVENTS: ({ commit }, { facilityId }) => {
            return new Promise((resolve, reject) => {
                let query = "?";
                query += "&" + qs.stringify({
                    filter: {
                        facility_id_eq: facilityId
                    }
                }, { encode: false });

                axios
                    .get(`userEvents?` + query)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_FACILITY_USER_EVENTS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_USER_EVENTS: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                let query = "?";
                if (state.filter) {
                    query += "&" + qs.stringify({ filter: state.filter }, { encode: false });
                }

                axios
                    .get(`userEvents?` + query)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_USER_EVENTS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_USER_EVENT: ({ commit }, { userEvent }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`userEvents/${userEvent.id}`, userEvent)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("UPDATE_USER_EVENT", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_USER_EVENT: ({ commit }, { userEvent }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`userEvents`, userEvent)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_USER_EVENT", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_USER_EVENT: ({ commit }, { userEvent }) => {

            return new Promise((resolve, reject) => {
                axios
                    .delete(`userEvents/${userEvent.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_USER_EVENT", userEvent);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        UPDATE_FACILITY_USER_EVENT: ({ commit }, { userEvent }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`userEvents/${userEvent.id}`, userEvent)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("UPDATE_FACILITY_USER_EVENT", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_FACILITY_USER_EVENT: ({ commit }, { userEvent }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`userEvents`, userEvent)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_FACILITY_USER_EVENT", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_FACILITY_USER_EVENT: ({ commit }, { userEvent }) => {

            return new Promise((resolve, reject) => {
                axios
                    .delete(`userEvents/${userEvent.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_FACILITY_USER_EVENT", userEvent);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
}
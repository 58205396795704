<template>
  <div>
    <FacilityEditSubscription :facility="facility" />
  </div>          
</template>

<script>

import FacilityEditSubscription from "../../FacilityEdit/FacilityEditSubscription";

export default {
  name: "FacilityEditTabRelease",
  props: ["facility"],
  components: {
    FacilityEditSubscription
  },
  data: () => ({}),
  computed: {

  },
  methods: {
  
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >

      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Statystyki: {{ facility.name }}</v-toolbar-title>

        </v-toolbar>
        <StatsDashboard
          :facility="facility"
        />
      </v-card>
    </v-dialog>
</template>

<script>
import StatsDashboard from './StatsDashboard.vue';

export default {
  name: "StatsDashboardDialog",
  data: () => ({
    dialog: false,
    facility: {},
  }),
  components: {StatsDashboard},
  computed: {},
  mounted() {},
  methods: {
    openDialog(facility) {
      this.facility = facility;
      this.dialog = true;
    },
    closeDialog() {
      this.facility = {};
      this.dialog = false;
    }
  },
  watch: {},
};
</script>


<style>
.facility-answer-section table {
  border-collapse: collapse;
  margin:20px 0px;
}
.facility-answer-section table td {
  padding:.55rem;
  border: 1px solid #dee2e6;
}
.facility-answer-section img {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 1150px;
}
</style>
import axios from "axios";

export default {
    state: {
        userWorkScheduleException: [],
        filter: {}
    },
    getters: {
        USER_WORK_SCHEDULE_EXCEPTIONS: state => {
            return state.userWorkScheduleException;
        }
    },
    mutations: {
        SET_USER_WORK_SCHEDULE_EXCEPTIONS: (state, payload) => {
            state.userWorkScheduleException = payload;
        },
        ADD_USER_WORK_SCHEDULE_EXCEPTION: (state, payload) => {
            state.userWorkScheduleException.push(payload);
        },
        UPDATE_USER_WORK_SCHEDULE_EXCEPTION: (state, payload) => {
            if (payload) {
                const element = state.userWorkScheduleException.find(event => event.id === payload.id);
                Object.assign(element, payload);
            }
        },
        REMOVE_USER_WORK_SCHEDULE_EXCEPTION: (state, userWorkScheduleException) => {
            let rs = state.userWorkScheduleException.filter(current => {
                return current.id !== userWorkScheduleException.id;
            });
            state.userWorkScheduleException = [...rs];
        }
    },
    actions: {
        GET_USER_WORK_SCHEDULE_EXCEPTIONS: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`userWorkScheduleExceptions`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_USER_WORK_SCHEDULE_EXCEPTIONS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_USER_WORK_SCHEDULE_EXCEPTION: ({ commit }, { userWorkScheduleException }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`userWorkScheduleExceptions/${userWorkScheduleException.id}`, userWorkScheduleException)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("UPDATE_USER_WORK_SCHEDULE_EXCEPTION", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_USER_WORK_SCHEDULE_EXCEPTION: ({ commit }, { userWorkScheduleException }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`userWorkScheduleExceptions`, userWorkScheduleException)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_USER_WORK_SCHEDULE_EXCEPTION", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_USER_WORK_SCHEDULE_EXCEPTION: ({ commit }, { userWorkScheduleException }) => {

            return new Promise((resolve, reject) => {
                axios
                    .delete(`userWorkScheduleExceptions/${userWorkScheduleException.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_USER_WORK_SCHEDULE_EXCEPTION", userWorkScheduleException);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
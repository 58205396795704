<template>
  <div :style="{'height': `${height?height:defaultHeight}px`, 'width': '100%'}" v-if="currentPosition">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="currentPosition"
      :options="mapOptions"
      style="height: 100%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />

      <l-marker
        :lat-lng="currentPosition"
        :draggable="true"
        v-on:update:latLng="markerDragged"
      />
    </l-map>

  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "Leaflet",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: ["latitude","longitude",'height'],
  data: () => ({
    loading: false,
    zoom: 13,
    defaultHeight:700,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentZoom: 11.5,
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    currentPosition: false
  }),
  computed: {
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    markerDragged(marker){
      this.$emit('changeCoordinates',{latitude: marker.lat,longitude: marker.lng});
    }
  },
  watch: {
    latitude(){
      this.currentPosition = [this.latitude,this.longitude];
      console.log(this.currentPosition);
    },
    longitude(){
      this.currentPosition = [this.latitude,this.longitude];
    }
  },
  mounted() {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.currentPosition = [this.latitude,this.longitude];
    setTimeout(() => {window.dispatchEvent(new Event('resize'));}, 500); 
  },
};
</script>


<style>
</style>
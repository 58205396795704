import axios from "axios";

export default {
    state: {
        messageTemplates: [],
        filter: {}
    },
    getters: {
        MESSAGE_TEMPLATES: state => {
            if (!(Object.keys(state.filter).length === 0)) {
                return state.messageTemplates.filter(messageTemplate => {
                    for (let key in state.filter) {        
                        if (key == "name_like" && state.filter.name_like && state.filter.name_like.length > 2) {
                            if (!messageTemplate.name.toLowerCase().includes(state.filter.name_like.toLowerCase()))
                                return false;
                        }
                        if (key == "type_id_eq" && state.filter.type_id_eq !== null ) {
                            if (!(messageTemplate.type.id === state.filter.type_id_eq))
                                return false;
                        }
                        if (key == "category_id_eq" && state.filter.category_id_eq  !== null ) {
                            if (!(messageTemplate.category.id === state.filter.category_id_eq))
                                return false;
                        }
                    }
                    return true;
                });
            } else {
                return state.messageTemplates;
            }

        },
        MESSAGE_TEMPLATES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_MESSAGE_TEMPLATE_FILTER: (state, payload) => {
            state.filter = payload;
        },
        SET_MESSAGE_TEMPLATES: (state, payload) => {
            state.messageTemplates = payload;
        },
        UPDATE_MESSAGE_TEMPLATE: (state, payload) => {
            if (payload) {
                const element = state.messageTemplates.find(template => template.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_MESSAGE_TEMPLATE: (state, payload) => {
            state.messageTemplates.push(payload);
        },
        REMOVE_MESSAGE_TEMPLATE: (state, messageTemplate) => {
            let rs = state.messageTemplates.filter(current => {
                return current.id !== messageTemplate.id;
            });
            state.messageTemplates = [...rs];
        }
    },
    actions: {
        GET_MESSAGE_TEMPLATES: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`messageTemplates`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_MESSAGE_TEMPLATES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        FILTER_MESSAGE_TEMPLATE: ({ commit }, { filter }) => {
            commit("SET_MESSAGE_TEMPLATE_FILTER", filter);
        },
        UPDATE_MESSAGE_TEMPLATE: ({ commit }, { messageTemplate }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`messageTemplates/${messageTemplate.id}`, messageTemplate)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("UPDATE_MESSAGE_TEMPLATE", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_MESSAGE_TEMPLATE: ({ commit }, { messageTemplate }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`messageTemplates`, messageTemplate)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_MESSAGE_TEMPLATE", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_MESSAGE_TEMPLATE: ({ commit }, { messageTemplate }) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`messageTemplates/${messageTemplate.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_MESSAGE_TEMPLATE", messageTemplate);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
    }
}
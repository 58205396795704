<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-btn
      color="success"
      @click="addTemplate()"
      small
      right
      absolute
      class="mr-3"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-simple-table v-if="LANDS.length > 0">
      <thead>
        <tr>
          <th class="text-left">
            ID
          </th>
          <th class="text-left">
            Nazwa
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="land in LANDS"
          :key="land.id"
        >
          <td>{{ land.id }}</td>
          <td>{{ land.name }}</td>
   

          <td style="text-align:right;">

            <v-btn
              depressed
              small
              color="primary"
              @click="showEditDialog(land)"
            >
              edytuj
            </v-btn>

          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <LandForm ref="landForm" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LandForm from "./AdminLand/LandForm";

export default {
  name: "AdminLand",
  components: {
    LandForm,
  },
  data: () => ({
    dialogMessageTemplate: false,
    currentMessageTemplateEdit: {},
    loading: false
  }),
  computed: {
    ...mapGetters(["LANDS"]),
  },
  methods: {
    showEditDialog(item) {
      this.$refs.landForm.open(item);
    },
    
    addTemplate() {
      this.dialogMessageTemplate = true;
      this.currentMessageTemplateEdit = {};
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_LANDS").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>
<template>
  <v-card
    :loading="loading"
    flat
    rounded="0"
  >
    <v-toolbar
      dark
      dense
      color="orange darken-3"
    >
      <v-toolbar-title>Panel Klienta - Użytkownik</v-toolbar-title>

    </v-toolbar>
    <v-card-text>
      <v-form v-if="data">
        <v-text-field
          :value="data.login"
          prepend-icon="person"
          name="login"
          label="Login"
          type="text"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="data.password"
          prepend-icon="lock"
          name="password"
          label="Hasło"
          :append-icon="passwordShow ? 'visibility' : 'visibility_off'"
          @click:append="passwordShow = !passwordShow"
          :type="passwordShow ? 'text' : 'password'"
        ></v-text-field>

      </v-form>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange"
        class="white--text"
        @click="$emit('close:ClientPanelUser')"
      >
        anuluj
      </v-btn>
      <v-btn
        color="green"
        class="white--text"
        @click="save"
      >
        zapisz
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: "ClientPanelUser",
  components: {},
  props: ['facility'],
  data: () => ({
    loading: false,
    passwordShow: false,
    newPassword: null,
    data: null
  }),
  computed: {},
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(`facilityClientPanel/${this.facility.external_id}/users`)
        .then((response) => {
          this.data = response.data;
        })
        .finally(() => (this.loading = false));
    },
    save(){
      this.loading = true;
      axios
        .patch(`facilityClientPanel/${this.facility.external_id}/users`,this.data)
        .then((response) => {
          this.data = response.data;
        })
        .finally(() => (this.loading = false));
        this.$emit('close:ClientPanelUser');
    }
  },
  watch: {
    facility() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>


<style>
</style>
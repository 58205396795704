import Vue from 'vue'
import Vuetify from "./plugins/vuetify.js";
import App from './App.vue'

import router from "./plugins/router.js";
import acl from './plugins/acl.js'

import axios from "axios";
import store from "./stores/store";



Vue.config.productionTip = false


axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

let isRefreshing = false;
let subscribers = [];

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const {
      config,
      response: { status, data }
    } = err;

    const originalRequest = config;

    if (data.message === "Missing token" || data.message === "An authentication exception occurred.") {
      subscribers = [];
      if (localStorage.getItem('user/currentUser')) {
        localStorage.removeItem('user/currentUser');
      }
      router.push({ name: "login" });
      return Promise.reject(false);
    }

    if (originalRequest.url.includes("login_check")) {
      return Promise.reject(err);
    }

    if (status === 401 && data.message === "Expired token") {
      /*if (originalRequest.url.includes("users/me")) {
        return false;
      }*/
      if (!isRefreshing) {
        isRefreshing = true;
        store
          .dispatch("REFRESH_TOKEN")
          .then(({ status }) => {
            if (status === 200 || status == 204) {
              isRefreshing = false;
              onRefreshed();
            }
            
            subscribers = [];
          })
          .catch(error => {
            console.error(error);
          });
      }

      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(() => {
          resolve(axios(originalRequest));
        });
      });

      return requestSubscribers;
    }
    return Promise.reject(err);
  }
);

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed() {
  subscribers.map(cb => cb());
}


subscribers = [];

new Vue({
  acl,
  store,
  router,
  vuetify: Vuetify,
  render: h => h(App),
}).$mount('#app')

<template>
  <v-container fluid>
    <v-card :loading="loading" class="mt-0 pt-0">
      <v-toolbar
        dark
        color="green darken-4"
        class="mt-0"
        dense
      >
        <v-toolbar-title>Zasięg: {{ FACILITY.name }}</v-toolbar-title>

      </v-toolbar>
      <GeolocationRangeMap
        v-if="FACILITY.id"
        :facility="FACILITY"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import GeolocationRangeMap from "./../../FacilityEdit/Geolocation/RangeMap";

export default {
  name: "WindowGpsRange",
  components: {
    GeolocationRangeMap,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  methods: {
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>


<style>
</style>
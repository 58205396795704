import axios from "axios";

export default {
    state: {
        invoices: [],
        products: [],
        statuses: {
            0: "do wystawienia",
            1: "niezapłacona",
            2: "zapłacona",
            3: "skorygowana"
        },
        filter: {}
    },
    getters: {
        INVOICES: state => {
            return state.invoices;
        },
        INVOICE_STATUSES: state => {
            return state.statuses;
        },
        INVOICE_PRODUCTS: state => {
            return state.products;
        },
    },
    mutations: {
        SET_INVOICES: (state, payload) => {
            state.invoices = payload;
        },
        UPDATE_INVOICE: (state,  payload) => {
            if (payload) {
                const element = state.invoices.find(invoice => invoice.id === payload.id);
                if(element){
                    Object.assign(element, payload);
                }
            }
        },
        ADD_INVOICE: (state, payload) => {
            state.invoices.unshift(payload);
        },
        REMOVE_INVOICE: (state, invoice) => {
            let rs = state.invoices.filter(current => {
                return current.id !== invoice.id;
            });
            state.invoices = [...rs];
        },
        SET_INVOICE_PRODUCTS: (state, payload) => {
            state.products = payload;
        },
    },
    actions: {
        GET_INVOICES: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`invoices`)
                    .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_INVOICES", data);
                        resolve(true);
                    }
                    })
                    .catch(error => {
                    reject(error);
                });
            });        
        },
        FIND_INVOICES: ({commit}, { criteria }) => {
            let query = "?"
            for(let key in criteria) {
                if(criteria[key] != null){
                    query += "filter["+key+"]="+criteria[key]+"&";
                }
            }
            return new Promise((resolve, reject) => {
                axios
                    .get(`invoices`+query)
                    .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_INVOICES", data);
                        resolve(true);
                    }
                    })
                    .catch(error => {
                    reject(error);
                });
            });    
        },
        ADD_INVOICE: ({ commit }, { invoice }) => {
            return new Promise((resolve, reject) => {
                axios.post(`invoices`, invoice).then(response => {
                    commit("ADD_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        ADD_INVOICE_RELATED: ({ commit }, { invoice }) => {
            return new Promise((resolve, reject) => {
                axios.post(`invoices/related`, invoice).then(response => {
                    commit("ADD_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error.response.data.message);
                })
            });
        },
        UPDATE_INVOICE: ({ commit }, { invoice }) => {
            return new Promise((resolve, reject) => {
                axios.patch(`invoices/`+invoice.id, invoice).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        CORRECT_INVOICE: ({ commit }, { invoice,number }) => {
            return new Promise((resolve, reject) => {
                axios.patch(`invoices/`+invoice.id+`/correction`, {number:number}).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        ISSUE_INVOICE: ({ commit }, { invoice,number,issuedAt }) => {
            return new Promise((resolve, reject) => {
                axios.patch(`invoices/`+invoice.id+`/issue`, {number:number,issuedAt:issuedAt}).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        ADD_INVOICE_BY_PROFORMA: ({ commit }, { invoice }) => {
            return new Promise((resolve, reject) => {
                axios.patch(`invoices/`+invoice.id+`/createByProforma`, {invoice:invoice}).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        REMOVE_INVOICE: ({ commit }, { invoice }) => {
            return new Promise((resolve, reject) => {
                axios.delete(`invoices/`+invoice.id+``).then(response => {
                    commit("REMOVE_INVOICE", invoice);
                    resolve(response.data);
                }).catch((error) => { 
                    reject(error);
                })
            });
        },
        ADD_INVOICE_PAYMENT: ({ commit }, { invoice,payment }) => {
            return new Promise((resolve, reject) => {
                axios.post(`invoices/`+invoice.id+"/payments", payment).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch( error => {
                    if( error.response ){
                        reject(error.response.data);
                    }
                });
            });
        },
        REMOVE_INVOICE_PAYMENT: ({ commit }, { invoice,payment }) => {
            return new Promise((resolve, reject) => {
                axios.delete(`invoices/`+invoice.id+"/payments/"+payment.id, ).then(response => {
                    commit("UPDATE_INVOICE", response.data);
                    resolve(response.data);
                }).catch( error => {
                    if( error.response ){
                        reject(error.response.data);
                    }
                });
            });
        },
        GET_INVOICE_PRODUCTS: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`invoices/products`)
                    .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_INVOICE_PRODUCTS", data);
                        resolve(true);
                    }
                    })
                    .catch(error => {
                    reject(error);
                });
            });    
        },
    }
}
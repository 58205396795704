<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >

    <v-card>
      <v-toolbar
        dark
        dense
        color="grey darken-3"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Q&A - Wyszukiwanie</v-toolbar-title>

      </v-toolbar>
      <v-card-text>
        <v-form
          @keyup.native.enter="filterQa"
          v-on:submit.prevent="filterQa"
          class="mt-3"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="filter.question_like"
                label="pytanie"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="2"
              class="text-center"
            >
              <v-btn
                color="grey darken-3"
                class="white--text"
                @click="filterQa()"
              >
                filtruj
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
        </v-form>

        <v-list v-if="QA.length > 0">
          <v-list-item
            v-for="item in QA"
            :key="item.id"
            link
            @click="readQa(item)"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.question }}</v-list-item-title>
              <v-list-item-subtitle>aktualizacja: {{ item.updated_at }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>

      </v-card-text>
    </v-card>
    <QaReadDialog ref="qaReadDialog" />
  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";
import QaReadDialog from "./QaReadDialog";

export default {
  name: "QaSearchDialog",
  data: () => ({
    dialog: false,
    loading: false,
    filter: {
      only_visibile: true
    },
  }),
  components: { QaReadDialog },
  computed: {
    ...mapGetters(["QA"]),
  },
  mounted() {
    this.loading = true;
    this.filterQa();
    this.$store.dispatch("GET_QA").then(() => {
      this.loading = false;
    });
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    readQa(qa) {
      this.$refs.qaReadDialog.openDialog(qa);
    },
    filterQa() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      this.$store.dispatch("FILTER_QA", { filter: filter });
      return false;
    },
  },
  watch: {},
};
</script>


<style>
</style>
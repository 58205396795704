<template>
  <div>
    <v-dialog
      v-model="dialog"
      :loading="loading"
      max-width="850"
    >
      <v-card>
        <v-container>
          <v-form>
            <v-row flat>
              <v-col cols=6>
                <v-text-field
                  dense
                  label="login"
                  v-model="data.username"
                />
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  dense
                  v-model="data.active"
                  label="aktywny"
                ></v-checkbox>
              </v-col>

              <v-col cols=6>
                <v-text-field
                  dense
                  label="imię i nazwisko"
                  v-model="data.name"
                  :error-messages="nameErrors"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  label="email"
                  v-model="data.email"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  label="telefon"
                  v-model="data.phone"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  v-model="data.roles"
                  :items="roles"
                  small-chips
                  label="rangi"
                  multiple
                ></v-select>
              </v-col>

            </v-row>

          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-1"
              dark
              @click="save()"
            >
              zapisz
            </v-btn>
                        
            <v-btn
              color="primary"
              @click="openPasswordForm()"
            >
              zmień hasło
            </v-btn>

            <v-btn
              color="warning"
              @click="dialog=false"
            >
              anuluj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <UserPasswordForm ref="userPasswordForm"/>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UserPasswordForm from './UserPasswordForm'

export default {
  components: {UserPasswordForm},
  name: "UserForm",
  data: () => ({
    data: {},
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
    roles: [
      { value: "ROLE_ADMIN", text: "Administrator" },
      { value: "ROLE_SUPERVISOR", text: "Opiekun" },
      { value: "ROLE_ACCOUNTANT", text: "Księgowy" }
    ],
  }),
  validations: {
    data: {
      name: { required },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) return errors;
      !this.$v.data.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    openPasswordForm(){
      this.$refs.userPasswordForm.open(this.data);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("UPDATE_USER", {
          user: this.data,
        })
        .then(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
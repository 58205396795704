<template>
  <tr  @mouseover="hover = true" @mouseleave="hover = false" >
    <td>{{ facilityPropertyType.priority }}</td>
    <td class="">{{ facilityPropertyType.propertyType.name }}</td>
    <td class="text-right propertyType-action-cell">
      <v-btn v-if="hover" color="grey"  class="mr-1" dark x-small  depressed @click="upgrade()" :disabled="facilityPropertyType.priority === 1"> <v-icon>arrow_upward</v-icon> </v-btn>
      <v-btn v-if="hover" color="grey"  class="mr-1" dark x-small  depressed @click="downgrade()"> <v-icon>arrow_downward</v-icon> </v-btn>
      <v-btn v-if="hover" color="error" :disabled="facilityPropertyType.propertyType.id === 2048" x-small @click="deletePropertyType()"> <v-icon>delete</v-icon> </v-btn>
    </td>
  </tr>
</template>


<script>
export default {
  name: "FacilityEditPropertyTypeRow",
  props: ["facilityPropertyType"],
  components: {},
  data: () => ({
    hover: false,
  }),
  computed: {},
  methods: {
    deletePropertyType: function(){
      this.$emit('facilityPropertyType:remove', this.facilityPropertyType) 
    },
    upgrade: function(){
      if(this.facilityPropertyType.priority <= 1) return;
      this.$emit('facilityPropertyType:upgrade', this.facilityPropertyType) 
    },
    downgrade: function(){
      this.$emit('facilityPropertyType:downgrade', this.facilityPropertyType) 
    },
  },
  mounted() {},
};
</script>


<style>
.propertyType-action-cell {
 min-width:200px;
}
</style>
<template>

  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-toolbar
        color="blue"
        dark
        dense
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title dense>
          Dodaj obiekt
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col :cols="registration?8:12">
          <v-stepper
            v-model="currentStep"
            flat
            vertical
          >

            <template v-for="s in steps">
              <v-stepper-step
                :editable="currentStep > s.id"
                :key="s.id"
                :complete="currentStep > s.id"
                :step="s.id"
              >
                {{ s.name }}
              </v-stepper-step>

              <v-stepper-content
                class="pa-0"
                :key="`${s.id}-content`"
                :step="s.id"
              >

                <component
                  ref="currentStep"
                  :is="s.component"
                  :facility="newFacility"
                ></component>

                <v-btn
                  class="ma-3"
                  color="primary"
                  v-if="currentStep!==steps.length"
                  @click="nextStep"
                >
                  Dalej
                </v-btn>

                <v-btn
                  class="ma-3"
                  color="success"
                  @click="add"
                  v-if="currentStep===steps.length"
                >
                  Dodaj
                </v-btn>

              </v-stepper-content>

            </template>

          </v-stepper>

        </v-col>
        <v-col
          v-if="registration"
          cols=4
        >
          <v-card
            color="blue darken-4"
            dark
            class="ma-2"
          >
            <v-card-title>
              Zgłoszenie
            </v-card-title>

            <v-card-subtitle>{{ registration.created_at }}<span v-if="registration.source"> ({{registration.source}})</span></v-card-subtitle>
            <v-card-text class="white--text">
              <v-chip
                v-if="registration.facility"
                color="red"
                class="white--text mb-3"
              >prawdopodobnie istniejący obiekt: {{registration.facility.id}}</v-chip>
              <dl class="mb-2">
                <dt>nazwa</dt>
                <dd><strong>{{ registration.name }}</strong></dd>
              </dl>
              <dl class="mb-2" v-if="registration.property_type">
                <dt>rodzaj</dt>
                <dd><strong>{{ registration.property_type }}</strong></dd>
              </dl>
              <dl class="mb-2">
                <dt>adres</dt>
                <dd><strong>{{ registration.address }}, {{ registration.city_name }}</strong></dd>
              </dl>
              <dl class="mb-2">
                <dt>telefon</dt>
                <dd><strong>{{ registration.phone }}</strong></dd>
              </dl>
              <dl
                class="mb-2"
                v-if="registration.phone2"
              >
                <dt>telefon 2</dt>
                <dd><strong>{{ registration.phone2 }}</strong></dd>
              </dl>
              <dl
                class="mb-2"
                v-if="registration.email "
              >
                <dt>email</dt>
                <dd><strong>{{ registration.email }}</strong></dd>
              </dl>
              <dl
                class="mb-2"
                v-if="registration.www "
              >
                <dt>www</dt>
                <dd><strong>{{ registration.www }}</strong></dd>
              </dl>
              <dl
                class="mb-2"
                v-if="registration.client_name "
              >
                <dt>dodał</dt>
                <dd><strong>{{ registration.client_name }}<span v-if="registration.client_phone">, tel: {{ registration.client_phone }}</span></strong></dd>
              </dl>
            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

    </v-card>
    <Snackbar ref="snackbar" />

  </v-dialog>
</template>

<script>
import FacilityCreateName from "./FacilityCreateName";
import FacilityCreateLocation from "./FacilityCreateLocation";
import FacilityCreateContact from "./FacilityCreateContact";
import Snackbar from "./../../shared/Snackbar";

export default {
  name: "FacilityCreateForm",
  components: {
    FacilityCreateName,
    FacilityCreateLocation,
    FacilityCreateContact,
    Snackbar,
  },
  data: () => ({
    dialog: false,
    loading: false,
    registration: null,
    newFacility: {
      facility: {
        cities: [],
        phones: [],
        addresses: [],
        propertyTypes: [],
        longitude: 0.0,
        latitude: 0.0,
      },
    },
    currentStep: 1,
    steps: [
      {
        id: 1,
        name: "Nazwa",
        component: "FacilityCreateName",
      },
      {
        id: 2,
        name: "Lokalizacja",
        component: "FacilityCreateLocation",
      },
      {
        id: 3,
        name: "Kontakt",
        component: "FacilityCreateContact",
      },
    ],
  }),
  computed: {},
  methods: {
    showDialog() {
      this.dialog = true;
    },
    fillByRegistration(registration) {
      this.clearForm();
      this.registration = registration;
      this.newFacility.name = registration.name;
      if (registration.email) {
        this.newFacility.facility.addresses.push({
          id: -(Date.now() + Math.floor(Math.random() * 100)),
          address: registration.email,
          type: {
            id: 7,
            name: "email",
          },
        });
      }
      if (registration.www) {
        this.newFacility.facility.addresses.push({
          id: -(Date.now() + Math.floor(Math.random() * 100)),
          address: registration.www,
          type: {
            id: 5,
            name: "www",
          },
        });
      }
      if (registration.phone) {
        this.newFacility.facility.phones.push({
          id: -(Date.now() + Math.floor(Math.random() * 100)),
          number: this.autoFormatPhoneNumber(registration.phone),
          type: {
            id: 8,
            name: "telefon1",
          },
        });
      }
      if (registration.phone2) {
        let phone2Type = { id: 9, name: "telefon2" };
        if (
          registration.phone2.match(/^(50|51|53|57|60|66|69|72|73|78|79|88)/)
        ) {
          phone2Type = {id: 2, name: "komórkowy1"};
        }

        this.newFacility.facility.phones.push({
          id: -(Date.now() + Math.floor(Math.random() * 100)),
          number: this.autoFormatPhoneNumber(registration.phone2),
          type: phone2Type,
        });
      }
    },
    nextStep() {
      if (this.$refs.currentStep[this.currentStep - 1].valid()) {
        this.currentStep = this.currentStep + 1;
      }
    },
    add() {
      this.loading = true;

      this.$store
        .dispatch("ADD_FACILITY", {
          facility: this.newFacility,
        })
        .then((facility) => {
          this.loading = false;
          if (this.registration) {
            this.registration.facility = facility.data;
            this.registration.status = 2;
            this.$store
              .dispatch("UPDATE_REGISTRATION", {
                registration: this.registration,
              })
              .then(() => {
                this.$router.push({
                  name: "facilityEdit",
                  params: { id: facility.data.external_id },
                });
              });
          } else {
            this.$router.push({
              name: "facilityEdit",
              params: { id: facility.data.external_id },
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$refs.snackbar.error(error.response.data.message);
          } else {
            this.$refs.snackbar.error("Nie udało się zapisać danych");
          }
          this.loading = false;
        });
    },
    clearForm() {
      this.newFacility = {
        facility: {
          cities: [],
          phones: [],
          addresses: [],
          propertyTypes: [],
        },
      };
    },
    autoFormatPhoneNumber(number){
      if(number === null){
        return;
      }
      let newNumber = number.trim();     
      newNumber = newNumber.replace(/[^0-9\\+]/g, '');
      if(newNumber.length === 9){
        const local = [12,13,14,15,16,17,18,22,23,24,25,29,32,33,34,41,42,43,44,46,48,52,54,55,56,58,59,61,62,63,65,67,68,71,74,75,76,77,81,82,83,84,85,86,87,89,91,94,95];
        if(local.includes(parseInt(newNumber.slice(0,2)))){
          newNumber = newNumber.replace(/^(\d{2})(\d{3})(\d{2})?(\d{2})/g, '$1 $2 $3 $4');
        } else {
          newNumber = newNumber.replace(/^(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3');
        }
      }
      return newNumber;
    }  
  },
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
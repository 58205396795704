<template>
  <v-card :loading="loading">
    <v-toolbar color="blue" dark dense>
      <v-toolbar-title>{{ facility.name }} - Faktury</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('close')"
        >
          <v-icon>close</v-icon>
        </v-btn>
    </v-toolbar>

    <v-card-text>

      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="table-heading,table-thead,table-tbody,table-thead,table-tbody"
      ></v-skeleton-loader>
      
      <v-container v-if="!loading && INVOICES">
        <InvoiceListAdvanced :invoices="INVOICES" />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import InvoiceListAdvanced from "./Invoice/InvoiceListAdvanced";

export default {
  name: "FacilityEditInvoiceList",
  props: ["facility"],
  components: {
    InvoiceListAdvanced,
  },
  data: () => ({
    loading: false,
    subscriptionTab: null,
  }),
  computed: {
    ...mapGetters(["INVOICES"]),
  },
  methods: {},
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("FIND_INVOICES", {
        criteria: { facility_id_eq: this.facility.id },
      })
      .then(() => {
        this.loading = false;
      });
  },
  watch: {
    facility: function(){
      this.loading = true;
      this.$store
        .dispatch("FIND_INVOICES", {
          criteria: { facility_id_eq: this.facility.id },
        })
        .then(() => {
          this.loading = false;
        });
      }
  }  
};
</script>


<style>
</style>
<template>
  <v-container fluid v-if="invoices.length > 0">
    <v-alert
      color="green"
      border="left"
      elevation="1"
      colored-border
      icon="info"
    >
      Znaleziono faktur: {{invoices.length}}
    </v-alert>
    <v-card class="mb-3" v-for="invoice in invoices" :key="invoice.id" outlined>
      <v-card-title>
        <v-row>
          <v-col sm=8>
            <div :class="{ 'text-decoration-line-through': (invoice.status == 3) }">Faktura {{ invoice.number }}<span v-if="invoice.proforma_number"> [{{ invoice.proforma_number }}]</span> ({{ totalAmount(invoice.invoice_entries) + (invoice.related_invoices.length > 0 ? relatedInvoicesAmount(invoice.related_invoices) : 0) | formatAmount }} PLN)</div>
            <div v-if="invoice.status == 3" class="red--text">
              Faktura korygująca {{ invoice.correction_number }} <v-icon v-if="invoice.correction_delivered_at" color="red">verified</v-icon>
            </div>
          </v-col>
          <v-col sm=4 class="text-right"> 
 
            <v-chip v-if="invoice.facility.client.invoice_type === 0"  color="red" class="mr-2" dark >
              TRADYCYJNIE
            </v-chip>

            <v-chip v-if="invoice.is_proforma === 1"  color="red" outlined  class="mr-2">
              PROFORMA
            </v-chip>

            <v-chip v-if="invoice.related_invoices.length > 0"  color="blue darken-3" dark class="mr-2">
              ZWIĄZANA - GŁÓWNA
            </v-chip>

            <v-chip v-if="invoice.parent_invoice"  color="blue " dark class="mr-2">
              ZWIĄZANA
            </v-chip>

            <v-chip class="" :color="getStatusColor(invoice.status)" outlined small>
              {{ INVOICE_STATUSES[invoice.status] }}
            </v-chip>
          </v-col>
        </v-row>
       </v-card-title>

      <v-card-text>
        <v-row class="text-center">
          <v-col>Utworzono:<v-chip class="ma-2" color="green darken-4" label outlined>{{ invoice.created_at }}</v-chip></v-col>
          <v-col>Wystaw: <v-chip class="ma-2" color="green darken-4" label outlined>{{ invoice.to_be_issued_at }}</v-chip></v-col>
          <v-col v-if="invoice.issued_at">Wystawiono: <v-chip class="ma-2" color="green darken-4" label outlined>{{ invoice.issued_at }}</v-chip></v-col>
          <v-col>Termin płatności: <v-chip class="ma-2" color="green darken-4" label outlined>{{ invoice.to_be_payed_in }} dni</v-chip></v-col>
          <v-col v-if="invoice.payed_at">Zapłacono: <v-chip class="ma-2" color="green darken-4" label outlined>{{ invoice.payed_at }}</v-chip></v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-simple-table v-if="invoice.invoice_entries.length > 0">
          <thead>
            <tr>
              <th class="text-left">opiekun</th>
              <th class="text-left">nazwa pozycji</th>
              <th class="text-left">stawka VAT</th>
              <th class="text-left">kwota netto</th>
              <th class="text-left">kwota brutto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in invoice.invoice_entries" :key="entry.id">
              <td>{{ entry.user.username }}</td>
              <td>{{ entry.product.name }}</td>
              <td>{{ entry.product.vat }}%</td>
              <td v-if="invoice.related_invoices.length > 0">{{ entry.amount + relatedInvoicesAmount(invoice.related_invoices) | formatAmount }} PLN</td>
              <td v-else>{{ entry.amount | formatAmount }} PLN</td>
              <td v-if="invoice.related_invoices.length > 0">
                {{ (entry.amount +
                  (entry.amount * entry.product.vat) / 100) 
                  + (relatedInvoicesAmount(invoice.related_invoices) + relatedInvoicesTax(invoice.related_invoices)) | formatAmount }}
                PLN
              </td>
              <td v-else>
                {{
                  entry.amount +
                  (entry.amount * entry.product.vat) / 100  | formatAmount
                }}
                PLN
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              
              <td v-if="invoice.related_invoices.length > 0" class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) + relatedInvoicesAmount(invoice.related_invoices) | formatAmount }} PLN
              </td>
              <td v-else class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN
              </td>

              <td v-if="invoice.related_invoices.length > 0" class="font-weight-bold red--text" >
                {{ total(invoice.invoice_entries) + relatedInvoicesAmount(invoice.related_invoices) + relatedInvoicesTax(invoice.related_invoices) | formatAmount}} PLN
              </td>
              <td v-else class="font-weight-bold red--text" >
                {{ total(invoice.invoice_entries) | formatAmount}} PLN
              </td>
            </tr>
            <template v-if="invoice.invoice_payments.length > 0">
            <tr v-for="payment in invoice.invoice_payments" :key="payment.id" class="light-green lighten-4 green--text font-weight-bold">
              <td colspan="4" class="text-right">WPŁATA  {{ payment.payed_at }}<v-chip outlined color="green" small v-if="payment.transaction_id" class="ml-2"><v-icon>public</v-icon>{{ payment.transaction_id }}</v-chip></td>
              <td>{{ payment.amount | formatAmount }} PLN <v-icon v-if="!payment.transaction_id" @click="removePayment(invoice,payment)">close</v-icon></td>
            </tr>
            
            <tr >
              <td colspan="4" class="text-right">zapłacono</td>
              <td>{{ totalPaid(invoice.invoice_payments) | formatAmount }} PLN</td>
            </tr>

            <tr>
              <td colspan="4" class="text-right">pozostało</td>
              <td>{{ total(invoice.invoice_entries) - totalPaid(invoice.invoice_payments) | formatAmount }} PLN</td>
            </tr>
            </template>

          </tbody>
        </v-simple-table> 
        <v-container v-if="accountantMode && !invoice.parent_invoice">
          <InvoiceIssue :invoice="invoice" />
        </v-container>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

//import moment from "moment";
import InvoiceIssue from "./InvoiceIssue"

export default {
  components: {InvoiceIssue},
  props: ["invoices","accountantMode"],
  name: "InvoiceList",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["INVOICE_STATUSES"]),
  },
  mounted() {},
  methods: {
    totalPaid: function (payments) {
      if(payments.length === 0){
        return 0;
      }
      return payments.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalAmount: function (entries) {
      if(entries.length === 0){
        return 0;
      }
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    relatedInvoicesAmount: function (invoices) {
      return invoices.reduce((acc, val) => {
        return acc + this.totalAmount(val.invoice_entries);
      }, 0);
    },
    relatedInvoicesTax: function (invoices) {
      return invoices.reduce((acc, val) => {
        return acc + this.totalTax(val.invoice_entries);
      }, 0);
    },
    totalTax: function (entries) {
      if(entries.length === 0){
        return 0;
      }
      return entries.reduce((acc, val) => {
        let tax =
          (parseInt(val.amount) * parseInt(val.product.vat)) /
          100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      if(entries.length === 0){
        return 0;
      }
      return this.totalAmount(entries) + this.totalTax(entries);
    },
    getStatusColor(status){
      let colors = [];
      colors[0] = "orange darken-4";      
      colors[1] = "blue darken-4";      
      colors[2] = "green darken-4";  
      colors[3] = "red darken-4";  
      return colors[status]; 
    },
    removePayment(invoice,payment){
      this.$store
        .dispatch("REMOVE_INVOICE_PAYMENT", {
          invoice: invoice,
          payment: payment,
        })
        .then(() => {
          
        })
    }
  },
  watch: {},
};
</script>


<style>
</style>
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import 'leaflet/dist/leaflet.css';
import '@mdi/font/css/materialdesignicons.css'

import Vuelidate from 'vuelidate'
import moment from 'moment'
import VCurrencyField from 'v-currency-field'
import DatetimePicker from 'vuetify-datetime-picker'
import GmapVue from 'gmap-vue'

//import { VTextField } from 'vuetify/lib' 
//Vue.component('v-text-field', VTextField)

import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );


Vue.use(VCurrencyField, { 
	locale: 'pl-PL',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})

Vue.use(DatetimePicker);

Vue.filter('truncate', function (value, stop, clamp) {
  if (value) {
      return value.slice(0, stop) + (stop < value.length ? clamp || '...' : '')
  }
})

Vue.filter('formatDate', function(value,format) {
if (value) {
  return moment(String(value)).format(format);
}
});

Vue.filter('formatAmount', function(value) {
if (value) {
  return (value/100).toFixed(2);
} else {
  return 0.00;
}
});

Vue.use(Vuelidate);

Vue.use(Vuetify, {
  iconfont: "md"
});


Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyBV6WC1MzRTkbcdQ-zB-LYzUHGJLxdCjZc',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})


export default new Vuetify({
    theme: { dark : false }
})
import axios from "axios";
import qs from 'qs'

export default {
    state: {
        listings: [],
        info: {},
        filter: {}
    },
    getters: {
        LISTINGS: state => {
            return state.listings;
        },
        LISTINGS_FILTER: state => {
            return state.filter;
        },
        LISTINGS_INFO: state => {
            return state.info;
        }
    },
    mutations: {
        SET_LISTINGS: (state, payload) => {
            state.listings = payload;
        },
        SET_LISTINGS_INFO: (state, payload) => {
            state.info = payload;
        },
        SET_LISTINGS_FILTER: (state, payload) => {
            state.filter = payload;
        },
    },
    actions: {
        GET_LISTINGS: ({commit}, { filter }) => {
            let query = "?";
            commit("SET_LISTINGS_FILTER", filter);  
            query += "&"+ qs.stringify({filter: filter}, { encode: false });

            return new Promise((resolve, reject) => {
                axios
                  .get(`listings`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_LISTINGS", data.result);  
                        commit("SET_LISTINGS_INFO", data.info);  
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });

        },
    }
}
<template>
  <v-container v-if="invoices.length > 0" >
    <v-card
      class="mb-3"
      v-for="invoice in invoices"
      :key="invoice.id"
      outlined
      id="facility-invoice-list"
    >
      <v-card-title>
        <v-row>
          <v-col sm=8>
            <div :class="{ 'text-decoration-line-through': (invoice.status == 3) }">Faktura {{ invoice.number }} {{ invoice.proforma_number }} ({{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN)</div>
            <div v-if="invoice.status == 3" class="red--text">
              Faktura korygująca {{ invoice.correction_number }} 
            </div>
          </v-col>
          <v-col
            sm=4
            class="text-right"
          >
          <v-chip v-if="invoice.facility.client.invoice_type === 0"  color="red" class="mr-2" dark >
              TRADYCYJNIE
            </v-chip>

            <v-chip
              v-if="invoice.is_proforma === 1"
              color="red"
              outlined
              class="mr-2"
            >
              PROFORMA
            </v-chip>

            <v-chip
              class=""
              :color="getStatusColor(invoice.status)"
              outlined
              small
            >
              {{ INVOICE_STATUSES[invoice.status] }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row class="text-center">
          <v-col>Utworzono:<v-chip
              class="ma-2 d-block"
              color="green darken-4"
              label
              outlined
            >{{ invoice.created_at }}</v-chip>
          </v-col>
          <v-col>Wystaw: <v-chip
              class="ma-2 d-block"
              color="green darken-4"
              label
              outlined
            >{{ invoice.to_be_issued_at }}</v-chip>
          </v-col>
          <v-col v-if="invoice.issued_at">Wystawiono: <v-chip
              class="ma-2 d-block"
              color="green darken-4"
              label
              outlined
            >{{ invoice.issued_at }}</v-chip>
          </v-col>
          <v-col>Termin płatności: <v-chip
              class="ma-2 d-block"
              color="green darken-4"
              label
              outlined
            >{{ invoice.to_be_payed_in }} dni</v-chip>
          </v-col>
          <v-col v-if="invoice.payed_at">Zapłacono: <v-chip
              class="ma-2 d-block"
              color="green darken-4"
              label
              outlined
            >{{ invoice.payed_at }}</v-chip>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-simple-table v-if="invoice.invoice_entries.length > 0">
          <thead>
            <tr>
              <th class="text-left">opiekun</th>
              <th class="text-left">nazwa pozycji</th>
              <th class="text-left">stawka VAT</th>
              <th class="text-left">kwota netto</th>
              <th class="text-left">kwota brutto</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in invoice.invoice_entries"
              :key="entry.id"
            >
              <td>{{ entry.user.username }}</td>
              <td>{{ entry.product.name }}</td>
              <td>{{ entry.product.vat }}%</td>
              <td>{{ entry.amount | formatAmount }} PLN</td>
              <td>
                {{
                  entry.amount +
                  (entry.amount * entry.product.vat) / 100 | formatAmount
                }}
                PLN
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN
              </td>
              <td class="font-weight-bold red--text">
                {{ total(invoice.invoice_entries) | formatAmount}} PLN
              </td>
            </tr>
            <template v-if="invoice.invoice_payments.length > 0">
              <tr
                v-for="payment in invoice.invoice_payments"
                :key="payment.id"
                class="light-green lighten-4 green--text font-weight-bold"
              >
                <td
                  colspan="4"
                  class="text-right"
                >WPŁATA {{ payment.payed_at }}</td>
                <td>{{ payment.amount | formatAmount }} PLN</td>
              </tr>

              <tr>
                <td
                  colspan="4"
                  class="text-right"
                >zapłacono</td>
                <td>{{ totalPaid(invoice.invoice_payments) | formatAmount }} PLN</td>
              </tr>

              <tr>
                <td
                  colspan="4"
                  class="text-right"
                >pozostało</td>
                <td>{{ total(invoice.invoice_entries) - totalPaid(invoice.invoice_payments) | formatAmount }} PLN</td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

//import moment from "moment";

export default {
  components: {},
  props: ["invoices", "accountantMode"],
  name: "InvoiceListAdvanced",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["INVOICE_STATUSES"]),
  },
  mounted() {},
  methods: {
    totalPaid: function (payments) {
      return payments.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalAmount: function (entries) {
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalTax: function (entries) {
      return entries.reduce((acc, val) => {
        let tax = (parseInt(val.amount) * parseInt(val.product.vat)) / 100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      return this.totalAmount(entries) + this.totalTax(entries);
    },
    getStatusColor(status) {
      let colors = [];
      colors[0] = "orange";
      colors[1] = "orange darken-4";
      colors[2] = "green darken-4";
      return colors[status];
    },
  },
  watch: {},
};
</script>


<style>
#facility-invoice-list td,
#facility-invoice-list th,
#facility-invoice-list span  {
  font-size:16px!important;
}
#facility-invoice-list div  {
  font-size:20px!important;
}
</style>
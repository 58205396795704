<template>
  <div>
    <v-row
      v-for="text in user.mail_mini_templates"
      :key="text.id"
    >
        <v-col 
          sm=3
        >
          <v-text-field
            label="nazwa"
            v-model="text.name"
            dense
          ></v-text-field>
        </v-col>
        <v-col 
          sm=8
        >
          <v-text-field
            label="szablon"
            v-model="text.template"
            dense
          ></v-text-field>
        </v-col>
        <v-col 
          sm=1
        >
          <v-btn color="error" @click="remove(text)" small
                ><v-icon>delete</v-icon></v-btn>
  
        </v-col>
    </v-row> 

      <v-btn color="success" @click="add()" small
                ><v-icon>add</v-icon></v-btn
              >
  </div>          
</template>

<script>

export default {
  name: "UserEditTextButton",
  props: ["user"],
  components: {
    
  },
  data: () => ({

  }),
  computed: {

  },
  methods: {
    add: function() {
      if(this.user.mail_mini_templates === null){
        this.user.mail_mini_templates = [];
      }
      this.user.mail_mini_templates.push(
        {
          id: Date.now(), name: '', template: ''
        }
      );
    },
    remove: function(textButton){
      this.user.mail_mini_templates =
         this.user.mail_mini_templates.filter((current) => {
          return current.id !== textButton.id;
        });
    }
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
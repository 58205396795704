<template>
    <v-row dense class="mb-3">
      <v-col sm=12 v-if="subscription.invoices.length > 0">
        <InvoiceList :invoices="subscription.invoices" :subscription="subscription" :facility="facility" v-on:invoice-removed="removeInvoiceFromList"/>
      </v-col>

      <v-dialog
          v-model="isEditDialogActive"
          max-width="1200"
      >
        <InvoiceCreate :subscription="subscription" v-on:cancel="isEditDialogActive = false"  v-on:create-related="isEditDialogActive = false; createReletedInvoiceDialog = true" v-on:invoice-created="isEditDialogActive = false" :facility="facility" />
      </v-dialog>

      <v-dialog
          v-model="createReletedInvoiceDialog"
          max-width="1200"
      >
        <InvoiceRelatedCreate :subscription="subscription" v-on:cancel="createReletedInvoiceDialog = false" v-on:invoice-created="createReletedInvoiceDialog = false" :facility="facility" />
      </v-dialog>
    </v-row>
</template>

<script>
import InvoiceList from "../Invoice/InvoiceList";
import InvoiceCreate from "../Invoice/InvoiceCreate";
import InvoiceRelatedCreate from "../Invoice/InvoiceRelatedCreate";

export default {
  name: "SubscriptionInvoice",
  props: ["subscription","facility"],
  components: {
    InvoiceList,
    InvoiceCreate,
    InvoiceRelatedCreate
  },
  data: () => ({
    isEditDialogActive: false,
    createReletedInvoiceDialog: false
  }),
  computed: {},
  methods: {
    openNewInvoiceDialog(){
      this.isEditDialogActive = true;
    },
    removeInvoiceFromList(invoice){
      let rs = this.subscription.invoices.filter((current) => {
        return current.id !== invoice.id;
      });
      this.subscription.invoices = [...rs];
    }
  },
  mounted() {},
};
</script>


<style>
</style>
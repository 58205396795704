<template>
  <v-container>
    <v-card outlined class="pa-2 mb-3">
      <FacilityCreateSupervisor :facility="facility" />
    </v-card>

    <v-card outlined class="pa-2">
      <v-card-title>Telefony</v-card-title>
      <FacilityEditPhones :facility="facility" />
    </v-card>
    
    <v-card outlined class="pa-2 mt-3">
      <v-card-title>Adresy e-mail/www</v-card-title>
     
      <FacilityEditAddresses
        :facility="facility"
        :availableTypes=[1,2,3,4,5,6,7,8,9,10]
      />
    </v-card>


  </v-container>
</template>

<script>

import FacilityEditPhones from "../FacilityEdit/Phones/FacilityEditPhones";
import FacilityEditAddresses from "../FacilityEdit/FacilityEditAddresses";
import FacilityCreateSupervisor from "./FacilityCreateSupervisor"
export default {
  name: "FacilityCreateContact",
  components: { FacilityEditPhones, FacilityEditAddresses,FacilityCreateSupervisor },
  props: ["facility"],
  data: () => ({}),
  computed: {},
  methods: {  
    valid(){
      alert("validate");
    }},
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
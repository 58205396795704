<template>
  <v-form
    @keyup.native.enter="addCity"
    @submit.prevent=""
  >
    <v-simple-table v-if="addCityForm || facility.facility.cities.length === 0">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <v-autocomplete
                v-model="newCity.city"
                name="city"
                label="dodawane miasto"
                :items="citiesSuggestions"
                :loading="loading"
                :search-input.sync="citySearch"
                placeholder="wyszukaj miasto"
                hide-no-data
                :error-messages="cityErrors"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <strong>{{data.item.text}}</strong> [{{data.item.distance}}km] [{{data.item.postalCode}}] ({{data.item.value.gmina.name}} / {{data.item.value.powiat.name}} / {{data.item.value.voivodeship.name}})
                  </v-list-item-content>
                </template>
              </v-autocomplete>

            </td>
            <td class="text-left">
              <v-btn
                color="success"
                @click="addCity()"
                small
                class="mr-1"
              >
                <v-icon>done</v-icon>
              </v-btn>
              <v-btn
                color="warning"
                @click="addCityForm=false ; newCity={city:{}}"
                v-if="facility.facility.cities.length > 0"
                small
              >
                <v-icon>not_interested</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card
      v-if="newCity.city.id"
      outlined
      class="pa-2 mb-3"
    >
      <label>wybrane miasto: </label>
      <strong>{{newCity.city.name}}</strong> ({{newCity.city.gmina.name}} / {{newCity.city.powiat.name}} / {{newCity.city.voivodeship.name}})
    </v-card>
    <v-simple-table
      dense
      v-if="facility.facility.cities.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>priorytet</th>
            <th>miasto</th>
            <th>dystans</th>
            <th class="text-right">
              <v-btn
                v-if="!addCityForm"
                @click="addCityForm = true"
                color="success"
                small
              >
                <v-icon>add</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <draggable
          v-model="facility.facility.cities"
          tag="tbody"
          ghost-class="ghost"
          @change="checkMove"
        >
          <FacilityEditCityRow
            v-for="facilityCity in sortedCities"
            :key="facilityCity.id"
            :facilityCity="facilityCity"
            :facility="facility"
            v-on:facilityCity:remove="removeCity($event)"
            v-on:facilityCity:downgrade="downgradeCity($event)"
            v-on:facilityCity:upgrade="upgradeCity($event)"
          />
        </draggable>
      </template>
    </v-simple-table>
    <v-card
      v-if="facility.facility.cities[0]"
      outlined
      class="pa-2 mt-3 text-caption"
    >
      <div
        v-for="facilityCity in sortedCities"
        :key="facilityCity.id"
        class="mb-2"
      >
        <div>{{facilityCity.city.name}} - krainy</div>
        <span
          v-for="land in facilityCity.city.lands"
          :key="land.id"
        >
          {{land.name}},
        </span>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import { required } from "vuelidate/lib/validators";
import FacilityEditCityRow from "./FacilityEditCityRow";
export default {
  name: "FacilityEditCities",
  props: ["facility"],
  components: {
    FacilityEditCityRow,
    draggable,
  },
  data: () => ({
    newCity: {
      city: {},
    },
    addCityForm: false,
    citiesSuggestions: [],
    citySearch: null,
    loading: false,
  }),
  validations: {
    newCity: {
      city: {
        required,
        unique: function (value) {
          return !this.isCityUnique(value);
        },
        distance: function (value) {
          return !(this.calculateDistance(value) > 30);
        },
      },
    },
  },
  computed: {
    cityErrors() {
      const errors = [];
      if (!this.$v.newCity.city.$dirty) return errors;
      !this.$v.newCity.city.required && errors.push("wybierz miasto");
      !this.$v.newCity.city.unique && errors.push("miasto już istnieje");
      !this.$v.newCity.city.distance &&
        errors.push("wybrane miasto jest za daleko od obiektu (30km)");
      return errors;
    },
    sortedCities() {
      return this.facility.facility.cities
        .slice()
        .sort((a, b) => a.priority - b.priority);
    },
  },
  methods: {
    addCity: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.$v.$reset();
      this.newCity.priority =
        this.facility.facility.cities.length > 0
          ? this.facility.facility.cities.at(-1).priority + 1
          : 1;
      this.facility.facility.cities.push(this.newCity);
      this.newCity = {
        city: {},
      };
    },
    sortCities() {
      this.facility.facility.cities = this.facility.facility.cities
        .slice()
        .sort((a, b) => a.priority - b.priority);
    },
    isCityUnique: function (newCity) {
      for (let key in this.facility.facility.cities) {
        let cityRow = this.facility.facility.cities[key];
        if (cityRow.city.id === newCity.id) {
          return true;
        }
      }
      return false;
    },
    removeCity: function (city) {
      this.facility.facility.cities = this.facility.facility.cities.filter(
        (current) => {
          return current.city.id !== city.city.id;
        }
      );
    },
    downgradeCity: function (city) {
      let newPrirority = parseInt(city.priority) + 1;

      for (let key in this.facility.facility.cities) {
        if (
          parseInt(this.facility.facility.cities[key].priority) ===
            newPrirority &&
          this.facility.facility.cities[key].city.id !== city.city.id
        ) {
          this.facility.facility.cities[key].priority =
            parseInt(this.facility.facility.cities[key].priority) - 1;
        }
        if (this.facility.facility.cities[key].city.id === city.city.id) {
          this.facility.facility.cities[key].priority = newPrirority;
        }
      }
      this.sortCities();
    },
    upgradeCity: function (city) {
      let newPrirority = parseInt(city.priority) - 1;

      for (let key in this.facility.facility.cities) {
        if (
          parseInt(this.facility.facility.cities[key].priority) ===
            newPrirority &&
          this.facility.facility.cities[key].city.id !== city.city.id
        ) {
          this.facility.facility.cities[key].priority =
            parseInt(this.facility.facility.cities[key].priority) + 1;
        }
        if (this.facility.facility.cities[key].city.id === city.city.id) {
          this.facility.facility.cities[key].priority = newPrirority;
        }
      }
      this.sortCities();
    },
    calculateDistance: function (city) {
      if (!this.facility.facility.latitude) {
        return null;
      }
      function toRad(Value) {
        return (Value * Math.PI) / 180;
      }

      var R = 6371; // km
      var dLat = toRad(city.latitude - this.facility.facility.latitude);
      var dLon = toRad(city.longitude - this.facility.facility.longitude);
      var lat1 = toRad(this.facility.facility.latitude);
      var lat2 = toRad(city.latitude);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d.toFixed(1);
    },
    checkMove() {
      for (let key in this.facility.facility.cities) {
        this.facility.facility.cities[key].priority = parseInt(key) + 1;
      }
    },
  },
  mounted() {},
  watch: {
    citySearch(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/cities?filter[name_like]=` + val, {})
        .then((response) => {
          var data = [];
          for (let key in response.data) {
            var city = response.data[key];
            data.push({
              text: city.name,
              value: city,
              distance: this.calculateDistance(city),
              postalCode: city.postalCode,
            });
          }

          data.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

          this.citiesSuggestions = data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>


<style>
</style>
import axios from "axios";

export default {
    state: {
        tasks: [],
        filter: {}
    },
    getters: {
        TASKS: state => {
            return state.tasks;
        }
    },
    mutations: {
        SET_TASKS: (state, payload) => {
            state.tasks = payload;
        },
        UPDATE_TASK: (state,  payload) => {
            if (payload) {
                const element = state.tasks.find(domain => domain.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_TASK: (state, payload) => {
            state.tasks.push(payload);
        },
        REMOVE_TASK: (state, task) => {
            let rs = state.tasks.filter(current => {
                return current.id !== task.id;
            });

            state.tasks = [...rs];
        }
    },
    actions: {
        GET_TASKS: ({commit}) => {
          let { data } = axios.get(`tasks`);
          commit("SET_TASKS", data);
        },
        UPDATE_TASK: ({ commit }, { task, taskId }) => {
            return new Promise( (resolve, reject) => {
                let { data, status } =  axios.patch(`/api/tasks/${taskId}`, task);
                if (status === 204 || status === 200) {
                    commit("UPDATE_TASK", task);
                    resolve({ data, status });
                } else {
                    reject({ data, status });
                }
            });
        },
        ADD_TASK: ({ commit }, { task }) => {
            return new Promise( (resolve, reject) => {
                let { data, status } =  axios.post(`/api/tasks`, task);
                if (status === 204 || status === 200) {
                    commit("ADD_TASK", task);
                    resolve({ data, status });
                } else {
                    reject({ data, status });
                }
            });
        },
        DELETE_TASK: ({ commit }, { task }) => {
            return new Promise( (resolve, reject) => {
                let { data, status } = axios.delete(`/api/tasks/${task.id}`, task);
                if (status === 204 || status === 200) {
                    commit("REMOVE_TASK", task);
                    resolve({ data, status });
                } else {
                    reject({ data, status });
                }
            });
        }
    }
}
<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <v-card flat>

      <Sitemanager
        v-if="sitemanagerData"
        :owner="sitemanagerData"
        :facility="FACILITY"
      />
      <v-alert v-if="!sitemanagerData & !loading" type="error">Nie znaleziono usług WWW Meteor</v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import Sitemanager from "./../../FacilityEdit/ExternalService/ExternalServiceMeteorSites/Sitemanager.vue";

export default {
  name: "WindowSiteManager",
  components: {
    Sitemanager,
  },
  data: () => ({
    loading: false,
    sitemanagerData: false,
    dialog: true,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  methods: {
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
    getSitemanagerData() {
      axios
        .get(`/facilities/${this.FACILITY.external_id}/sitemanager`)
        .then((response) => {
          
          if(response.data.id){
            this.sitemanagerData = response.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.getSitemanagerData();
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>


<style>
</style>
export default {
    state: {
        regions: [],
        filter: {}
    },
    getters: {
        REGIONS: state => {
            return state.regions;
        },
        REGIONS_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_REGIONS: (state, payload) => {
            state.regions = payload;
        },
    },
    actions: {
        GET_REGIONS: ({commit}) => {
            return new Promise((resolve) => {
                const data = [
                    {id: 1, name: "morze"},
                    {id: 2, name: "góry"},
                    {id: 4, name: "jezioro"},
                    {id: 8, name: "rzeka"},
                    {id: 16, name: "las"},
                    {id: 32, name: "miasto"},
                    {id: 64, name: "miała miejscowość"},
                    {id: 128, name: "wieś"}
                ];
                commit("SET_REGIONS", data);
                resolve(true);
    
                
            });
        },
    }
}
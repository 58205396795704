<template>
  <v-row
    dense
    class="pb-5"
  >
    <v-col class="text-center">
      <v-btn
        small
        color="green"
        class="mr-2 white--text"
        v-if="invoice.is_proforma === 1 && invoice.status === 2"
        @click="createInvoice()"
        :loading="loadingRemoving"
      >
        zleć FV
      </v-btn>
      <v-btn
        small
        color="orange"
        class="mr-2 white--text"
        @click="email()"
        v-if="invoice.status !== 0 && isReadyToSend()"
      >
        wyślij email<span v-if="invoice.last_email_sent_at"> ponownie</span>
      </v-btn>
      <v-btn
        small
        color="blue"
        class="mr-2 white--text"
        @click="invoicePreview()"
        v-if="invoice.status !== 0"
      >
        podgląd pdf
      </v-btn>
      <v-btn
        small
        color="red"
        class="mr-2 white--text"
        v-if="invoice.status === 0 || (invoice.is_proforma === 1 && invoice.status != 2)"
        @click="remove()"
        :loading="loadingRemoving"
      >
        usuń
      </v-btn>
      <div
        v-if="invoice.last_email_sent_at"
        class="text-caption mt-3"
      >Wysłano dokumenty przez email: {{ invoice.last_email_sent_at }}</div>
    </v-col>
    <v-dialog
      v-model="sendInvoiceDialog"
      persistent
      max-width="600px"
    >
      <SendInvoice
        :invoice="invoice"
        @close="sendInvoiceDialog = false"
      ></SendInvoice>

    </v-dialog>
    <v-dialog
      v-model="createInvoiceDialog"
      max-width="1200"
    >
      <InvoiceCreate
        :subscription="subscription"
        v-on:cancel="createInvoiceDialog = false"
        v-on:invoice-created="createInvoiceDialog = false"
        :baseInvoice="invoice"
      />
    </v-dialog>
    <SubscriptionNewInvoiceCheckout
      v-on:subscriptionNewInvoiceCheckout:accepted="createInvoiceDialog = true"
      :facility="facility"
      :subscription="subscription"
      ref="subscriptionNewInvoiceCheckout"
    />
    <Snackbar ref="snackbar" />
  </v-row>

</template>

<script>
import moment from "moment";
import Snackbar from "../../../shared/Snackbar.vue";
import SendInvoice from "./../../../Invoice/InvoiceIssue/SendInvoice";
import InvoiceCreate from "./../Invoice/InvoiceCreate.vue";
import SubscriptionNewInvoiceCheckout from "./../Subscription/SubscriptionNewInvoiceCheckout.vue";

export default {
  props: ["invoice", "subscription","facility"],
  name: "InvoiceOptions",
  data() {
    return {
      loading: false,
      loadingRemoving: false,
      sendInvoiceDialog: false,
      createInvoiceDialog: false,
    };
  },
  components: {
    Snackbar,
    SendInvoice,
    InvoiceCreate,
    SubscriptionNewInvoiceCheckout
  },
  computed: {},
  methods: {
    createInvoice() {
      this.$refs.subscriptionNewInvoiceCheckout.openDialog();
      //this.createInvoiceDialog = true;
    },
    email() {
      this.sendInvoiceDialog = true;
    },
    invoicePreview() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `invoices/${this.invoice.id}/previews.pdf`
      );
    },
    isReadyToSend(){
      let issuedAt = moment(this.invoice.issued_at);
      let now = moment();
      return now.diff(issuedAt, "hours") > 0;
    },
    remove() {
      let toBeIssuedAt = moment(this.invoice.to_be_issued_at);
      let now = moment();
      let days = now.diff(toBeIssuedAt, "days");

      let createdAt = moment(this.invoice.created_at);
      let minutes = now.diff(createdAt, "minutes");
      console.log(minutes);
      console.log(days);
      if (days > -1 && (minutes > 5 && days === -1) && this.invoice.is_proforma === 0) {
        this.$refs.snackbar.error(
          "Nie można usunąć faktury. Skontatuj się bezpośrednio z księgowością."
        );
      } else {
        this.loadingRemoving = true;
        this.$store
          .dispatch("REMOVE_INVOICE", {
            invoice: this.invoice,
          })
          .then(() => {
            this.$emit("invoice-removed", this.invoice);
            this.loadingRemoving = false;
            this.$refs.snackbar.success("Faktura została usunięta");
          });
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
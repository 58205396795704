<template>
  <v-app>
    <v-container fill-height>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="70"
        ></v-progress-circular>
      </v-overlay>
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          md6
          lg5
          xl3
        >
          <v-alert
            color="error"
            :value="error"
            dark
          >
            Dane logowania są niepoprawne.
          </v-alert>
          <v-form @keyup.native.enter="login">
            <v-card class="elevation-12">
              <v-toolbar
                dark
                color="blue"
              >
                <v-toolbar-title>Logowanie</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  prepend-icon="person"
                  name="login"
                  label="Login"
                  type="text"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  prepend-icon="lock"
                  name="password"
                  label="Hasło"
                  type="password"
                ></v-text-field>
              </v-card-text>
              <v-divider light></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  @click.prevent="login()"
                >
                  Zaloguj
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    username: "",
    password: "",
    loading: false,
    error: false,
  }),
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("LOGIN", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.loading = false;
          this.$router.push({ path: "app" }).catch((err) => {console.log(err)});
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>
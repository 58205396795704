<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <AdminQaSearchForm />
    <v-btn
      color="success"
      @click="addTemplate()"
      small
      right
      absolute
      class="mr-3"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-simple-table v-if="QA.length > 0">
      <thead>
        <tr>
          <th class="text-left">
            Pytanie
          </th>
          
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in QA"
          :key="item.id"
        >
          <td>{{ item.question }}</td>

          <td style="text-align:right;">

            <v-btn
              depressed
              small
              color="primary"
              @click="showEditDialog(item)"
            >
              edytuj
            </v-btn>

            <v-btn
              depressed
              color="red"
              class="ml-2"
              dark
              small
              @click="remove(item)"
            >
              usuń
            </v-btn>

          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog
      v-model="dialogQaForm"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <AdminQaForm
        :qa="currentQaEdit"
        v-on:messageTemplateForm:close="dialogQaForm=false"
        @qaForm:close="dialogQaForm=false;"
      />
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminQaForm from "./AdminQa/Form";
import AdminQaSearchForm from "./AdminQa/SearchForm";
export default {
  name: "AdminQa",
  components: {
    AdminQaForm,
    AdminQaSearchForm
  },
  data: () => ({
    dialogQaForm: false,
    currentQaEdit: {},
    loading: false
  }),
  computed: {
    ...mapGetters(["QA"]),
  },
  methods: {
    showEditDialog(item) {
      this.dialogQaForm = true;
      this.currentQaEdit = item;
    },
    remove(item) {
      this.loading = true;
      this.$store
        .dispatch("REMOVE_QA", {
          qa: item,
        })
        .then(() => {
          this.loading = false;
          this.$emit("qaForm:close");
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            console.log(error.response.data.message);
          }
          this.loading = false;
        });
    },
    addTemplate() {
      this.dialogQaForm = true;
      this.currentMessageTemplateEdit = {};
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("FILTER_QA", { filter: {
      only_visibile: false
    } });
    this.$store.dispatch("GET_QA").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>
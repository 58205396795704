<template>
  <v-container fluid>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-card class="mt-3">
      <v-toolbar
        color="blue"
        dark
        dense
      >
        <v-toolbar-title dense>
          Zgłoszenia
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <RegistrationSearchForm />
      </v-container>
    </v-card>
    <v-card class="mt-3">
      <v-container fluid>

        <v-simple-table v-if="REGISTRATIONS.length > 0">
          <thead>
            <tr>
              <th></th>
              <th class="text-left">
                Wysłano
              </th>
              <th class="text-left">
                Nazwa
              </th>
              <th class="text-left">
                Lokalizacja
              </th>
              <th class="text-left">
                Telefon
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                www
              </th>
              <th class="text-left">
                Zgłaszający
              </th>
              <th class="text-left">
                Status
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="registration in REGISTRATIONS"
              :key="registration.id"
            >
              <td>
                <v-icon :color="getStatusColor(registration.status)">circle</v-icon>
              </td>
              <td>{{ registration.created_at }}<span v-if="registration.source"> ({{registration.source}})</span></td>
              <td>{{ registration.name }}</td>
              <td>{{ registration.address }}, {{ registration.city_name }}</td>
              <td>{{ registration.phone }}</td>
              <td>{{ registration.email }}</td>
              <td>{{ registration.www }}</td>
              <td>{{ registration.client_name }}<span v-if="registration.client_phone">, tel: {{ registration.client_phone }}</span></td>

              <td>
                <v-chip
                  v-if="registration.facility && registration.status !== 2"
                  color="red"
                  class="white--text"
                >istniejący: {{registration.facility.id}} 
                <v-icon class="ml-2 mr-1" right small  @click="openFacilityEditWindow(registration.facility.id)">arrow_forward_ios</v-icon>
              </v-chip>
                <v-chip
                  v-if="registration.facility && registration.status === 2"
                  color="blue"
                  class="white--text"
                >dodany: {{registration.facility.id}}
                <v-icon class="ml-2 mr-1" right small  @click="openFacilityEditWindow(registration.facility.id)">arrow_forward_ios</v-icon>
              </v-chip>


              </td>

              <td style="text-align:right;min-width:240px;">

                <v-btn
                  v-if="registration.status === 0"
                  depressed
                  small
                  color="green"
                  class="white--text"
                  @click="createFacility(registration)"
                >
                  nowy
                </v-btn>

                <v-btn
                  v-if="registration.status === 0"
                  depressed
                  small
                  color="orange"
                  class="white--text ml-1"
                  @click="duplicate(registration)"
                >
                  kopia
                </v-btn>

                <v-btn
                  v-if="registration.status === 0 || registration.status === 1"
                  depressed
                  small
                  color="red"
                  class="white--text ml-1"
                  @click="remove(registration)"
                >
                  usuń
                </v-btn>

              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <RegistrationSearchPagination />
      </v-container>
    </v-card>
    <Snackbar ref="snackbar" />
    <FacilityCreateForm ref="facilityCreateForm" />
    <RegistrationDuplicateForm ref="registrationDuplicateForm" />
  </v-container>
</template>

<script>
import Snackbar from "./../../shared/Snackbar";
import FacilityCreateForm from "./FacilityCreateForm";
import RegistrationSearchForm from "./Registration/RegistrationSearchForm"
import RegistrationSearchPagination from "./Registration/RegistrationSearchPagination"
import RegistrationDuplicateForm from "./Registration/RegistrationDuplicateForm"

import { mapGetters } from "vuex";

export default {
  name: "FacilityCreateRegistration",
  components: {
    Snackbar,
    FacilityCreateForm,
    RegistrationSearchForm,
    RegistrationSearchPagination,
    RegistrationDuplicateForm
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["REGISTRATIONS"]),
  },
  methods: {
    createFacility(registration) {
      this.$refs.facilityCreateForm.fillByRegistration(registration);
      this.$refs.facilityCreateForm.showDialog();
    },
    existConfirmation(registration) {
      this.loading = true;
      registration.status = 1;
      this.$store
        .dispatch("UPDATE_REGISTRATION", { registration: registration })
        .then(() => {
          this.loading = false;
        });
    },
    duplicate(registration){
      this.$refs.registrationDuplicateForm.openDialog(registration);
    },
    remove(registration){
      this.loading = true;
      this.$store
        .dispatch("REMOVE_REGISTRATION", { registration: registration })
        .then(() => {
          this.loading = false;
        });
    },
    getStatusColor(status){
      if(status === 0){
        return 'green';
      }
      if(status === 1){
        return 'orange';
      }
      if(status === 2){
        return 'blue';
      }
    },
    openFacilityEditWindow(id) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: id },
      });
      window.open(routeData.href, "_blank",'location=1');
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("FIND_REGISTRATIONS",{}).then(() => {
      this.loading = false;
    });
  },
  watch: {},
};
</script>

<style>
</style>
<template>
  <v-form>
    <v-row>
      <v-col
        md=2
        class="mt-0 pt-0"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-switch
                v-model="facility.internet"
                label=""
                inset
                dense
                :disabled="facility.priority == 0 && facility.internet == 0"
              ></v-switch>
            </div>
          </template>
          <span>do prezentacji</span>
        </v-tooltip>
      </v-col>
      <v-col>
        <v-text-field
          v-model="facility.priority"
          label="priorytet"
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          :error-messages="priorityErrors"
          dense
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="facility.priorityExtra"
          label="dodatkowy priorytet"
          dense
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="facility.imageLimit"
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          label="limit zdjęć"
          :error-messages="imageLimitErrors"
          dense
        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "FacilityEditFormPriority",
  props: ["facility"],
  components: {},
  data() {
    return {};
  },
  validations: {
    facility: {
      priority: {
        required,
        between: between(-3, 13),
      },
      imageLimit: {
        between: between(0, 40),
      },
    },
  },
  computed: {
    priorityErrors() {
      const errors = [];
      if (!this.$v.facility.priority.$dirty) return errors;
      !this.$v.facility.priority.required &&
        errors.push("priorytet jest wymagany");
      !this.$v.facility.priority.between && errors.push("liczba od -3 do 13");
      return errors;
    },
    imageLimitErrors() {
      const errors = [];
      if (!this.$v.facility.imageLimit.$dirty) return errors;
      !this.$v.facility.imageLimit.between && errors.push("liczba od 0 do 40");
      return errors;
    }
  },
  methods: {},
  watch: {
    'facility.priority': function(){
      if(this.facility.priority == 0){
        this.facility.internet = 0;
      }
      if(this.facility.priority > 0 && this.facility.internet == 0){
        this.facility.internet = 1;
      }
    }
  },
  mounted() {},
};
</script>


<style>
</style>
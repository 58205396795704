<template>
  <v-row>
    <v-col
      :cols="FACILITY_REGISTRATIONS.length > 1 ? 6 : 12"
      v-for="registration in FACILITY_REGISTRATIONS"
      :key="registration.id"
    >
      <v-card outlined>
        <v-card-title>{{ registration.created_at }}<span v-if="registration.source" class="ml-2">({{registration.source}})</span></v-card-title>
        <v-card-subtitle><v-icon :color="getStatusColor(registration.status)">circle</v-icon>

          <v-chip
            v-if="registration.status===2"
            :color="getStatusColor(registration.status)"
            small
            outlined
          >dodany do bazy</v-chip>
          <v-chip
            v-if="registration.status===1"
            :color="getStatusColor(registration.status)"
            small
            outlined
          >ponowne zgłoszenie</v-chip>
          <v-chip
            v-if="registration.status===0"
            :color="getStatusColor(registration.status)"
            small
            outlined
          >nierozpatrzone</v-chip>

        </v-card-subtitle>
        <v-card-text class="">
          <dl class="mb-2">
            <dt>nazwa</dt>
            <dd><strong>{{ registration.name }}</strong></dd>
          </dl>
          <dl
            class="mb-2"
            v-if="registration.property_type"
          >
            <dt>rodzaj</dt>
            <dd><strong>{{ registration.property_type }}</strong></dd>
          </dl>
          <dl class="mb-2">
            <dt>adres</dt>
            <dd><strong>{{ registration.address }}, {{ registration.city_name }}</strong></dd>
          </dl>
          <dl class="mb-2">
            <dt>telefon</dt>
            <dd><strong>{{ registration.phone }}</strong></dd>
          </dl>
          <dl
            class="mb-2"
            v-if="registration.phone2"
          >
            <dt>telefon 2</dt>
            <dd><strong>{{ registration.phone2 }}</strong></dd>
          </dl>
          <dl
            class="mb-2"
            v-if="registration.email "
          >
            <dt>email</dt>
            <dd><strong>{{ registration.email }}</strong></dd>
          </dl>
          <dl
            class="mb-2"
            v-if="registration.www "
          >
            <dt>www</dt>
            <dd><strong>{{ registration.www }}</strong></dd>
          </dl>
          <dl
            class="mb-2"
            v-if="registration.client_name "
          >
            <dt>dodał</dt>
            <dd><strong>{{ registration.client_name }}<span v-if="registration.client_phone">, tel: {{ registration.client_phone }}</span></strong></dd>
          </dl>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RegistrationList",
  props: ["facility"],
  components: {},
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["FACILITY_REGISTRATIONS"]),
  },
  methods: {
    getStatusColor(status) {
      if (status === 0) {
        return "green";
      }
      if (status === 1) {
        return "orange";
      }
      if (status === 2) {
        return "blue";
      }
    },
    getRegistrations(){
      this.loading = true;
      this.$store
      .dispatch("GET_FACILITY_REGISTRATIONS", { facilityId: this.facility.id })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
    }
  },
  mounted() {
    this.getRegistrations();
  },
  watch: {
    facility() {
      this.getRegistrations();
    },
  }
};
</script>


<style>
</style>
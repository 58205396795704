import axios from "axios";

export default {
    state: {
        userNotification: [],
        filter: {}
    },
    getters: {
        USER_NOTIFICATIONS: state => {
            return state.userNotification;
        }
    },
    mutations: {
        SET_USER_NOTIFICATIONS: (state, payload) => {
            state.userNotification = payload;
        },
        UPDATE_USER_NOTIFICATION: (state, payload) => {
            if (payload) {
                const element = state.userNotification.find(event => event.id === payload.id);
                Object.assign(element, payload);
            }
        },
        REMOVE_USER_NOTIFICATION: (state, userNotification) => {
            let rs = state.userNotification.filter(current => {
                return current.id !== userNotification.id;
            });
            state.userNotification = [...rs];
        }
    },
    actions: {
        GET_USER_NOTIFICATIONS: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`users/me/notifications`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_USER_NOTIFICATIONS", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_USER_NOTIFICATION: ({ commit }, { userNotification }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`userNotifications/${userNotification.id}`, userNotification)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("UPDATE_USER_NOTIFICATION", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_USER_NOTIFICATION: ({ commit }, { userNotification }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`userNotifications`, userNotification)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_USER_NOTIFICATION", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_USER_NOTIFICATION: ({ commit }, { userNotification }) => {

            return new Promise((resolve, reject) => {
                axios
                    .delete(`userNotifications/${userNotification.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_USER_NOTIFICATION", userNotification);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
<template>
  <v-container fluid> 
  
      <v-card :loading="loading" :disabled="loading">
      <v-toolbar color="green" dark dense>
        <v-toolbar-title>Wyszukaj faktury</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
            <InoviceSearchForm />
      </v-container>
    </v-card>

    <InvoiceSearchResult />

    
    
  </v-container>
</template>

<script>

import InoviceSearchForm from "./Invoice/InoviceSearchForm.vue"
import InvoiceSearchResult from "./Invoice/InvoiceSearchResult.vue"

export default {
  name: "Invoice",
  data() {
    return {
      loading: false,
    } 
  },   
  components: {
    InoviceSearchForm,
    InvoiceSearchResult
  },
};
</script>

<style>
</style>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-btn
      color="success"
      @click="addTemplate()"
      small
      right
      absolute
      class="mr-3"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-simple-table v-if="USERS.length > 0" dense>
      <thead>
        <tr>
          <th class="text-left" style="max-width:25px;">
            ID
          </th>
          <th class="text-left" style="max-width:25px;">
            Online
          </th>
          <th class="text-left">
            Nazwa
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in USERS"
          :key="user.id"
        >
          <td style="max-width:25px;">{{ user.id }}</td>
          <td style="max-width:25px;">
            <v-tooltip bottom  :color="activityColor(user)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="calculateMinutesFromNow(user.last_activity_at) !== null"
                  :color="activityColor(user)"
                  v-bind="attrs"
                  v-on="on"
                >circle</v-icon>
              </template>
              <span>{{ calculateFromNow(user.last_activity_at) }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-icon
              v-if="user.active == 1"
              color="green darken-2"
            >account_circle</v-icon>
            <v-icon
              v-if="user.active == 0"
              color="red darken-2"
            >account_circle</v-icon>
            {{ user.name }}
          </td>
          <td style="text-align:right;">

            <v-btn
              depressed
              small
              color="primary"
              @click="showEditDialog(user)"
            >
              edytuj
            </v-btn>

          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <UserForm ref="userForm" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import UserForm from "./AdminUser/UserForm";

export default {
  name: "AdminUser",
  components: {
    UserForm,
  },
  data: () => ({
    dialogMessageTemplate: false,
    currentMessageTemplateEdit: {},
    loading: false,
  }),
  computed: {
    ...mapGetters(["USERS"]),
  },
  methods: {
    showEditDialog(item) {
      this.$refs.userForm.open(item);
    },

    addTemplate() {
      this.dialogMessageTemplate = true;
      this.currentMessageTemplateEdit = {};
    },
    calculateMinutesFromNow(date) {
      if (!date) {
        return null;
      }
      let exp = moment(date);
      return moment().diff(exp, "minutes");
    },
    calculateFromNow(date) {
      if (!date) {
        return "";
      }
      moment.locale("pl");
      return moment(date).fromNow();
    },
    activityColor(user) {
      if (!user.last_activity_at) {
        return "";
      }
      let exp = moment(user.last_activity_at);
      let minutes =  moment().diff(exp, "minutes");
      return (minutes < 10) ? 'green' : (minutes < 30) ? 'orange' : 'red';
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_USERS").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>
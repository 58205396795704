<template>
  <v-card>
    <v-card-text class="pt-4">
      <v-row>
        <v-col>
          <v-menu
            v-model="correctionDeliveredAtDatepicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="correctionDeliveredAt"
                label="kiedy dostarczono"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="correctionDeliveredAt"
              @change="correctionDeliveredAtDatepicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field
            clearable
            v-model="correctionReceivedBy"
            label="osoba potwierdzająca"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-1"
        text
        @click="$emit('close')"
      >
        Anuluj
      </v-btn>
      <v-btn
        color="red darken-1"
        dark
        :loading="loading" 
        @click="correctionDelivered()"
      >
        zatwierdź
      </v-btn>

    </v-card-actions>
    <Snackbar ref="snackbar" />
  </v-card>

</template>

<script>
import Snackbar from "./../../shared/Snackbar.vue";

export default {
  components: { Snackbar },
  props: ["invoice"],
  name: "CorrectionDelivered",
  data: () => ({
    loading: false,
    correctionDeliveredAt: null,
    correctionDeliveredAtDatepicker: null,
    correctionReceivedBy: "",
  }),
  computed: {},
  mounted() {},
  methods: {
    correctionDelivered() {

      this.invoice.correction_delivered_at = this.correctionDeliveredAt+" przez: "+this.correctionReceivedBy;
      this.$store
        .dispatch("UPDATE_INVOICE", {
          invoice: this.invoice
        })
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
};
</script>


<style>
</style>
<template>
  <div>
    <div class="text-center">
      <v-btn
        color="green"
        class="white--text"
        @click="createInvoiceDialog=true"
      >
        nowa faktura
        <v-icon> add </v-icon>
      </v-btn>
    </div>

    <InvoiceList :invoices="owner.invoices" />

    <v-dialog
        v-model="createInvoiceDialog"
        max-width="1200"
    >
     <InvoiceCreate :owner="owner" v-on:cancel="createInvoiceDialog = false" v-on:invoice-created="createInvoiceDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import InvoiceList from "./Invoice/InvoiceList";
import InvoiceCreate from "./Invoice/InvoiceCreate";
export default {
  name: "SitemanagerInvoiceTab",
  props: ["owner","facility"],
  components: { InvoiceList, InvoiceCreate },
  data: () => ({
    loading: false,
    createInvoiceDialog: false
  }),
  computed: {},
  methods: {},
  watch: {
    owner() {},
  },
  mounted() {},
};
</script>


<style>
</style>
<template>
  <v-dialog
    v-model="dialog"
    width="700"
  >
    <v-card
      :loading="loading"
    >
      <v-card-text>
        <v-textarea
        :value="facility.legacy_www"
        auto-grow
        readonly
        ></v-textarea>

      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="dialog=false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "LegacyWww",
  components: {},
  props: ["facility"],
  data: () => ({
    dialog: false,
    loading: false,
    
  }),
  computed: {
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    
  },
  mounted() {
    
  },
  watch: {

  },
};
</script>

<style>
</style>
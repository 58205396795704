<template>
          <v-form>
        

            
            <v-simple-table v-if="addLandForm || data.lands.length === 0">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-autocomplete
                        v-model="newLand"
                        return-object
                        label="dodaj krainę"
                        item-text="name"
                        :items="landSuggestions"
                        :loading="loading"
                        :search-input.sync="landAutocomplete"
                        placeholder="wyszukaj krainę"
                        hide-no-data
                      >
                        
                      </v-autocomplete>

                    </td>
                    
                    <td class="text-left">
                      <v-btn
                        color="success"
                        @click="addLand()"
                        small
                        class="mr-1"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                      <v-btn
                        color="warning"
                        @click="addLandForm=false ; newLand={}"
                        v-if="data.lands.length > 0"
                        small
                      >
                        <v-icon>not_interested</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

             <v-simple-table
              dense
              v-if="data.lands.length > 0"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>kraina</th>
                    <th class="text-right">
                      <v-btn
                        v-if="!addLandForm"
                        @click="addLandForm = true"
                        color="success"
                        small
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="land in data.lands"
                    :key="land.id"
                  >
                    <td>
                      {{land.name}}
                    </td>
                    <td class="text-right city-action-cell">
                      <v-btn
                        color="error"
                        dark
                        small
                        @click="removeLand(land)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>

            </v-simple-table>

          </v-form>
         
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "CityLandForm",
  props: ["data"],
  data: () => ({
    error: false,
    loading: false,
    newLand: {},
    addLandForm: false,
    landAutocomplete: null,
    landSuggestions: []
  }),
  validations: {
    data: {
    
    },
  },
  computed: {
   
  },
  methods: {
    removeLand(land){
      this.data.lands = this.data.lands.filter(
        (current) => {
          return current.id !== land.id;
        }
      );
    },
    addLand(){
      this.data.lands.push(this.newLand);
      this.newLand = {};
    }
  },
  mounted() {},
  watch: {
    landAutocomplete(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/lands?filter[name_like]=` + val, {})
        .then((response) => {
          this.landSuggestions = response.data;
        })
        .finally(() => (this.loading = false));
    }
  },
};
</script>


<style>
</style>
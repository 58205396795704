import { render, staticRenderFns } from "./FacilityEditTabAttribute.vue?vue&type=template&id=0eefecbe&"
import script from "./FacilityEditTabAttribute.vue?vue&type=script&lang=js&"
export * from "./FacilityEditTabAttribute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
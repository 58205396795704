<template>
  <v-form>
    <FacilityEditCities :facility="facility" />
    <v-divider class="mt-8 mb-6"></v-divider>


    <FacilityEditAddressPostal :facility="facility" />




  </v-form>
</template>

<script>

import FacilityEditCities from "../FacilityEditCities";
import FacilityEditAddressPostal from "./../FacilityEditAddressPostal";

export default {
  name: "FacilityEditTabLocation",
  props: ["facility"],
  components: {
    FacilityEditCities,
    FacilityEditAddressPostal
  },
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {},
};
</script>


<style>
</style>
<template>
  <v-card
    :loading="loading"
    flat
    rounded="0"
  >
    <v-tabs
       background-color="blue darken-3"
      v-model="tab"
      dark
      v-if="owner"
    >
      <v-tab
        v-for="item in tabItems"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in tabItems"
        :key="item.tab"
      >
        <v-card
          class="mt-2 pa-2"
          flat
        >
          <component
            :is="item.component"
            :owner="owner"
            :facility="facility"
          ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
import SitemanagerInfoTab from "./Sitemanager/SitemanagerInfoTab";
import SitemanagerInvoiceTab from "./Sitemanager/SitemanagerInvoiceTab";
import SitemanagerEditTab from "./Sitemanager/SitemanagerEditTab";

export default {
  name: "Sitemanager",
  props: ["owner","facility"],
  components: { SitemanagerInfoTab, SitemanagerInvoiceTab, SitemanagerEditTab },
  data: () => ({
    loading: false,
    tabItems: [
      { tab: "Informacje", component: "SitemanagerInfoTab" },
      { tab: "Dane obiektu", component: "SitemanagerEditTab" },
      { tab: "Faktury", component: "SitemanagerInvoiceTab" },
    ],
    tab: null,
  }),
  computed: {},
  methods: {},
  watch: {
    owner() {},
  },
  mounted() {},
};
</script>


<style>
</style>
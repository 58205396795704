<template>

  <tr  @mouseover="hover = true" @mouseleave="hover = false">
    <td>{{ facilityCity.priority }}</td>
    <td class="py-2">
      
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
        {{ facilityCity.city.name }}
        </div>
      </template>
      <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>kod</th>
          <th>gmina</th>
          <th>powiat</th>
          <th>województwo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ facilityCity.city.postalCode }}</td>
          <td>{{ facilityCity.city.gmina.name }}</td>
          <td>{{ facilityCity.city.powiat.name }}</td>
          <td>{{ facilityCity.city.voivodeship.name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
    </v-tooltip>
    </td>
    <td>{{ distance }} km</td>
    
    <td class="text-right city-action-cell">
      <v-btn v-if="hover" color="grey"  class="mr-1" dark x-small fab depressed @click="upgrade()" :disabled="facilityCity.priority === 1"> <v-icon>arrow_upward</v-icon> </v-btn>
      <v-btn v-if="hover" color="grey"  class="mr-1" dark x-small fab depressed @click="downgrade()" :disabled="facilityCity.priority >= facility.facility.cities.length"> <v-icon>arrow_downward</v-icon> </v-btn>
      <v-btn v-if="hover" color="error" dark small @click="deleteCity()"> <v-icon>delete</v-icon> </v-btn>
    </td>
  </tr>
</template>


<script>
export default {
  name: "FacilityEditCityRow",
  props: ["facilityCity","facility"],
  components: {},
  data: () => ({
    hover: false,
  }),
  computed: {
    distance: function(){

      if(!this.facility.facility.latitude){
        return null;
      }
      function toRad(Value) 
      {
          return Value * Math.PI / 180;
      }

      var R = 6371; // km
      var dLat = toRad(this.facilityCity.city.latitude - this.facility.facility.latitude);
      var dLon = toRad(this.facilityCity.city.longitude-this.facility.facility.longitude);
      var lat1 = toRad(this.facility.facility.latitude);
      var lat2 = toRad(this.facilityCity.city.latitude);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d.toFixed(1);

    },
  },
  methods: {
    deleteCity: function(){
      this.$emit('facilityCity:remove', this.facilityCity) 
    },
    upgrade: function(){
      if(this.facilityCity.priority <= 1) return;
      this.$emit('facilityCity:upgrade', this.facilityCity) 
    },
    downgrade: function(){
      this.$emit('facilityCity:downgrade', this.facilityCity) 
    },
    
  },
  mounted() {},
};
</script>


<style>
.city-action-cell {
 min-width:171px;
}
</style>
<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="options.user_id_eq"
          :items="OPERATORS"
          :item-text="user => {return user.name + ' (' + user.username +')'}"
          item-value="id"
          label="doradca biznesowy"
          clearable
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-menu
          v-model="datepickerPayedAtGt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="options.payed_at_gt"
              label="od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            v-model="options.payed_at_gt"
            @change="datepickerPayedAtGt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="datepickerPayedAtLt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="options.payed_at_lt"
              label="do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            v-model="options.payed_at_lt"
            @change="datepickerPayedAtLt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="text-center"
      >
        <v-btn
          color="primary"
          class="white--text"
          :loading="loading"
          @click="generate()"
        >generuj</v-btn>

        <v-btn
          color="black"
          class="white--text ml-2"
          @click="download()"
          :disabled="report===null"
        ><v-icon>file_download</v-icon></v-btn>
      </v-col>
    </v-row>
    <div v-if="report">
      <v-card
        v-for="userRow in report"
        :key="userRow.user.id"
      >
        <v-card-title>{{userRow.user.name}}</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">id</th>
                  <th class="text-left">faktura</th>
                  <th class="text-left">zapłacono</th>
                  <th class="text-left">kwota</th>
                  <th class="text-left">poprzednio</th>
                  <th class="text-left">stały</th>
                  <th class="text-left">rozszerzenie</th>
                  <th class="text-left">nowy</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in userRow.entries"
                  :key="row.invoice.number"
                >
                  <td>
                    <v-btn
                      x-small
                      color="primary"
                      class="mr-2"
                      @click="openFacilityEditWindow(row.facility.id)"
                    >
                      <v-icon>arrow_forward</v-icon>
                    </v-btn>
                    {{row.facility.id}}
                  </td>
                  <td>FV {{row.invoice.number}}</td>
                  <td>{{row.invoice.payed_at}}</td>
                  <td>{{row.entry.amount | formatAmount}}<span v-if="(row.invoice.total_amount_payed - row.entry.amount) > 0"> ({{row.invoice.total_amount_payed |formatAmount}} / {{row.invoice.total_amount_payed - row.entry.amount |formatAmount}})</span></td>
                  <td :class="row.invoice.last_www === 1 ? 'yellow': ''">{{row.invoice.last_amount | formatAmount}}</td>
                  <td>{{row.commision.regular | formatAmount}}</td>
                  <td>{{row.commision.extension | formatAmount}}</td>
                  <td>{{row.commision.new | formatAmount}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{userRow.commisionTotal.regular | formatAmount }}</td>
                  <td>{{userRow.commisionTotal.extension | formatAmount }}</td>
                  <td>{{userRow.commisionTotal.new | formatAmount }}</td>
                </tr>
              </tbody>

            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import qs from "qs";

export default {
  name: "AdminReport",
  components: {},
  data: () => ({
    options: {},
    datepickerPayedAtLt: false,
    datepickerPayedAtGt: false,
    loading: false,
    report: null,
  }),
  computed: {
    ...mapGetters(["OPERATORS"]),
  },
  methods: {
    generate() {
      this.loading = true;
      const query = qs.stringify({ options: this.options }, { encode: false });
      axios.get(`admin/invoiceReports?` + query).then((res) => {
        this.report = res.data;
        this.loading = false;
      });
    },
    download() {
      const query = qs.stringify({ options: this.options }, { encode: false });
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `admin/invoiceReports/xlsx?` + query
      );
    },
    openFacilityEditWindow(id) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: id },
      });
      window.open(routeData.href, "_blank",'location=1');
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_OPERATORS").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>
<template>
  <div class="mb-0">

    <v-tooltip
      v-for="button in buttons"
      :key="button.name"
      top
      color="blue"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="mr-1 px-1 mb-1 quick-text-button"
          color="blue"
          dark
          @click="insertText(button.template)"
          v-bind="attrs"
          v-on="on"
        >
          {{ button.name }}
        </v-btn>
      </template>
      <span>{{ button.template }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SendMailMiniTemplates",
  data() {
    return {
      buttons: [],
    };
  },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  mounted() {
    this.$store.dispatch("GET_CURRENT_USER").then(() => {
      if (this.CURRENT_USER.mail_mini_templates) {
        this.buttons = this.CURRENT_USER.mail_mini_templates;
      }
    });
  },
  methods: {
    insertText(text) {
      this.$emit("insertMiniTemplate", text);
    },
  },
  watch: {
    CURRENT_USER() {
      if (this.CURRENT_USER.mail_mini_templates) {
        this.buttons = this.CURRENT_USER.mail_mini_templates;
      }
    },
  },
};
</script>


<style>
.quick-text-button {
  min-width: 40px !important;
}
@media (max-width: 1300px) {
  .quick-text-button {
    min-width: 23px !important;
  }
}
</style>
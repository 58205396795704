<template>
  <v-card :loading="loading">
    <v-toolbar
      color="blue"
      dark
      flat
      dense
    >
      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{CURRENT_USER.name}} - raport</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-3">
      <v-row>
        <v-col>
          <v-menu
            v-model="datepickerPayedAtGt"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="options.payed_at_gt"
                label="od"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="options.payed_at_gt"
              @change="datepickerPayedAtGt = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="datepickerPayedAtLt"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="options.payed_at_lt"
                label="do"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="options.payed_at_lt"
              @change="datepickerPayedAtLt = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="2"
          class="text-center"
        >
          <v-btn
            color="primary"
            class="white--text"
            :loading="loading"
            @click="generate()"
          >generuj</v-btn>
        </v-col>
      </v-row>
      <div v-if="report">
        <v-card
          v-for="userRow in report"
          :key="userRow.user.id"
        >
          <v-card-title>{{userRow.user.name}}</v-card-title>
          <v-card-subtitle>znaleziono faktur: {{userRow.entries.length}}</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">id</th>
                    <th class="text-left">faktura</th>
                    <th class="text-left">zapłacono</th>
                    <th class="text-left">kwota</th>
                    <th class="text-left">poprzednio</th>
                    <th class="text-left">stały</th>
                    <th class="text-left">rozszerzenie</th>
                    <th class="text-left">nowy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="row in userRow.entries"
                    :key="row.invoice.number"
                  >
                    <td>
                      <v-btn x-small color="primary" class="mr-2" :href="getFacilityUrl(row.facility.id)">
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                      {{row.facility.id}}
                    </td>
                    <td>FV {{row.invoice.number}}</td>
                    <td>{{row.invoice.payed_at}}</td>
                    <td>{{row.entry.amount | formatAmount}}</td>
                    <td>{{row.invoice.last_amount | formatAmount}}</td>
                    <td>{{row.commision.regular | formatAmount}}</td>
                    <td>{{row.commision.extension | formatAmount}}</td>
                    <td>{{row.commision.new | formatAmount}}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{userRow.commisionTotal.regular | formatAmount }}</td>
                    <td>{{userRow.commisionTotal.extension | formatAmount }}</td>
                    <td>{{userRow.commisionTotal.new | formatAmount }}</td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import qs from "qs";

export default {
  name: "UserReport",
  components: {},
  data: () => ({
    options: {},
    datepickerPayedAtLt: false,
    datepickerPayedAtGt: false,
    loading: false,
    report: null,
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  methods: {
    generate() {
      this.loading = true;
      const query = qs.stringify({ options: this.options }, { encode: false });
      axios.get(`users/me/invoiceReports?` + query).then((res) => {
        this.report = res.data;
        this.loading = false;
      });
    },
    getFacilityUrl(id) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: id },
      });
      return routeData.href;
    },
  },
  watch: {},
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_CURRENT_USER").then(() => {
      this.loading = false;
      this.userEdited = JSON.parse(JSON.stringify(this.CURRENT_USER));
    });
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-progress-circular
      indeterminate
      color="orange darken-3"
      v-if="loading"
    ></v-progress-circular>

    <v-card
      class="mx-auto"
      color="orange darken-3"
      dark
      v-if="data.id"
    >
      <v-card-title class="py-0 pt-1">
        <span class="text-h6 font-weight-light">Panel Klienta</span>
        <v-spacer></v-spacer>
        <v-btn
            class=""
            outlined
            rounded
            small
            right
            @click="userDialog=true"
          >
            ZMIEŃ HASŁO
          </v-btn>  
        <v-btn
            class="ml-2"
            outlined
            rounded
            small
            right
            @click="goToClientPanel()"
          >
            ZARZĄDZAJ
          </v-btn>  
      </v-card-title>

      <v-card-text class="font-weight-bold">

        <v-row>
          <v-col>
            <v-card-subtitle>ATRYBUTY</v-card-subtitle>
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="data.attributesPercent"
              color="white"
            >
              {{ data.attributesPercent }} %
            </v-progress-circular>
          </v-col>
          <v-col>
            <v-card-subtitle>ZDJĘCIA</v-card-subtitle>
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="(data.imageCount/facility.facility.imageLimit)*100"
              color="white"
            >
              {{ data.imageCount }} / {{facility.facility.imageLimit}}
            </v-progress-circular>
          </v-col>
          <v-col>
            <v-card-subtitle>OPCJE</v-card-subtitle>
            <v-list color="orange darken-3">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon v-if="data.enableReviews === 1">
                    check
                  </v-icon>
                  <v-icon v-if="data.enableReviews === 0">
                    block
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>opinie</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon v-if="data.enableSmsNotifications === 1">
                    check
                  </v-icon>
                  <v-icon v-if="data.enableSmsNotifications === 0">
                    block
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>potwierdzenia SMS</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>

      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="userDialog"
        transition="dialog-top-transition"
        max-width="600"
      >

      <ClientPanelUser v-on:close:ClientPanelUser="userDialog = false;" :facility="facility" />
      </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import ClientPanelUser from "./ExternalServiceClientPanel/ClientPanelUser.vue";

export default {
  name: "ExternalServiceClientPanel",
  components: { ClientPanelUser },
  props: ["facility"],
  data: () => ({
    loading: false,
    offerTypes: [
      { id: 1, name: "brak" },
      { id: 2, name: "gratis" },
      { id: 3, name: "demo" },
      { id: 4, name: "płatny" },
      { id: 5, name: "obiekt testowy" },
    ],
    offerLevels: [
      { id: 1, name: "podstawowy" },
      { id: 2, name: "rozszerzony" },
    ],
    data: {},
    userDialog: false
  }),
  computed: {},
  methods: {
    getData() {
      this.data = {};
      this.loading = true;
      axios
        .get(`facilityClientPanel/${this.facility.external_id}`)
        .then((response) => {
          this.data = response.data;
        })
        .finally(() => (this.loading = false));
    },
    getOfferTypeName(id) {
      const found = this.offerTypes.find((element) => element.id === id);
      return found ? found.name : "";
    },
    getOfferLevelName(id) {
      const found = this.offerLevels.find((element) => element.id === id);
      return found ? found.name : "";
    },
    goToClientPanel() {
      const url = "https://panel.meteor24.pl/admin/facility/visit?facility_id=";
      window.open(url + this.facility.external_id, "_blank");
    }
  },
  watch: {
    facility() {
      this.getData();
    },
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 1500);
  },
};
</script>


<style>
</style>
<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <InvoiceCreateEntry
      :invoice="invoice"
      :facilities="facilities"
      v-on:cancel="dialog = false"
      @save-entry="save"
    />
  </v-dialog>
</template>

<script>
import InvoiceCreateEntry from "./InvoiceRelatedEntry";

export default {
  props: ["invoice", "facilities"],
  name: "InvoiceRelatedEntryDialog",
  components: { InvoiceCreateEntry },
  data: () => ({
    loading: false,
    dialog: false,
    invoiceEntry: null,
  }),

  computed: {},
  methods: {
    open() {
      this.dialog = true;
    },
    save(entry) {
      this.$emit("save-entry", entry);
      this.dialog = false;
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
<template>
  <div>
    <v-alert 
    v-if="facility.people.length===0" 
    class="mt-3"  
    color="blue"
    border="left"
    elevation="2"
    colored-border
    icon="info"
    >Nie znaleziono osób</v-alert>
    <v-hover
        v-slot="{ hover }"
        open-delay="200"
        v-for="person in facility.people"
        :key="person.id"
      >
    <v-card
      :elevation="hover ? 7 : 0"
      flat
      outlined
      class="mb-3"
    >
      <v-btn
        color="error"
        fab
        x-small
        absolute
        bottom
        right
        @click="remove(person)"
        v-if="hover"
      >
        <v-icon>delete</v-icon>
      </v-btn>
      <PersonRow
        :person="person"
        @validation-error="validationError"
        @validation-success="validationSuccess"
        @send-sms="sendSms"
        @send-email="sendEmail"
      />
    </v-card>
  </v-hover>
    <v-btn
      color="success"
      @click="add()"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <SendSMSDialog :facility="facility" ref="sendSMSDialog"></SendSMSDialog>
    <SendMailDialog :facility="facility" ref="sendMailDialog"></SendMailDialog>
  </div>
</template>

<script>
import PersonRow from "./PersonRow.vue";
import SendSMSDialog from "../SendSMS/SendSMSDialog.vue";
import SendMailDialog from "../SendMail/SendMailDialog.vue";

export default {
  name: "PersonList",
  props: ["facility"],
  components: { PersonRow, SendSMSDialog, SendMailDialog },
  data: () => ({
    personTitle: ["", "Pan", "Pani"],
    validationErrors: [],
  }),
  computed: {},
  methods: {
    add() {
      let person = {
        id: -Date.now(),
        name: "",
        comment: "",
        email: "",
        title: null,
        phone: "",
      };
      this.facility.people.push(person);
    },
    remove(person) {
      this.facility.people = this.facility.people.filter((current) => {
        return current.id !== person.id;
      });
    },
    sendSms(person){
      let options = {
        selectReceiver: person.phone
      };
      this.$refs.sendSMSDialog.openDialog(options);
    },
    sendEmail(person){
      let options = {
        selectReceiver: person.email
      };
      this.$refs.sendMailDialog.openDialog(options);
    },
    validationError(id) {
      this.validationErrors.push(id);
    },
    validationSuccess(id) {
      this.validationErrors = this.validationErrors.filter(function (item) {
        return item !== id;
      });
    },
  },
  mounted() {},
  watch: {
    validationErrors() {
      if (this.validationErrors.length === 0) {
        this.$emit("valid");
      } else {
        this.$emit("not-valid");
      }
    },
  },
};
</script>


<style>
</style>
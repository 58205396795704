<template>
  <v-card flat>
    <v-tabs v-model="subscriptionTab" background-color="primary" dark show-arrows >
      <v-tab
        v-for="(subscription,index) in limitedSubscription"
        :key="subscription.id"
      >
        {{ makeTabTitle(subscription,index) }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="subscriptionTab">
      <v-tab-item
        v-for="(subscription) in limitedSubscription"
        :key="subscription.id"
      >
        <SubscriptionEdit :subscription="subscription" :facility="facility" :readOnly="readOnly"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
//import { mapGetters } from "vuex";
import SubscriptionEdit from "./Subscription/SubscriptionEdit";
import moment from "moment";

export default {
  name: "FacilityEditSubscription",
  props: {
    facility: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SubscriptionEdit,
  },
  data: () => ({
    subscriptionTab: 2,
    limit:-3,
  }),
  computed: {
    limitedSubscription(){
      return this.limit ? this.facility.subscriptions.slice(this.limit) : this.facility.subscriptions;
    }
  },
  methods: {
    makeTabTitle: function (subscription,index) {
      let title =
        ((subscription.start === null)?"..":moment(String(subscription.start)).format("YY")) +
        " / " +
        ((subscription.end === null)?"..":moment(String(subscription.end)).format("YY"));

      if(index === this.limitedSubscription.length-1){
        title = "BIEŻĄCE"
      }

      if(index === this.limitedSubscription.length-2){
        title = "POPRZEDNIE"
      }

      return title;
    },
  },
  mounted() {
    //this.subscriptionTab = this.facility.subscriptions.length-1;
    this.subscriptionTab = this.limitedSubscription.length -1;
  },
  watch: {
    limitedSubscription(){
      //this.$nextTick(() => {
        this.subscriptionTab = this.limitedSubscription.length -1 ;
      //});
    }
  }
};
</script>


<style>
</style>
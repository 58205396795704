<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-card-text>
      <StatsDashboardForm
        :restrictions="restrictions"
        @search="getData()"
      />
      <v-divider class="my-5"></v-divider>
      <v-row v-if="data">
        <v-col cols="7">
          <v-simple-table
            dense
            fixed-header
            height="670px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center font-weight-bold">
                    data
                  </th>
                  <th class="text-center font-weight-bold">
                    P
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >visibility</v-icon>
                      </template>
                      <span>{{ data.summary.eventView.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >image</v-icon>
                      </template>
                      <span>{{ data.summary.eventGallery.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >language</v-icon>
                      </template>
                      <span>{{ data.summary.eventWww.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >phone</v-icon>
                      </template>
                      <span>{{ data.summary.eventShowPhone.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >email</v-icon>
                      </template>
                      <span>{{ data.summary.eventMessage.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >event</v-icon>
                      </template>
                      <span>{{ data.summary.eventReservation.label }}</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >attach_money</v-icon>
                      </template>
                      <span>{{ data.summary.eventReservationMoneyTotal.label }}</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-center">
                    podsumowanie
                  </th>
                  <th class="text-center">

                  </th>
                  <th class="text-center">
                    {{ data.summary.eventView.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventGallery.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventWww.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventShowPhone.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventEmail.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventReservation.count }}
                  </th>
                  <th class="text-center">
                    {{ data.summary.eventReservationMoneyTotal.count / 100 }}
                  </th>
                </tr>
                <tr
                  v-for="entry in data.entries"
                  :key="entry.id"
                >
                  <td class="text-center">{{ formatDate(entry.date.date) }}</td>
                  <td class="text-center">{{ entry.facilityPriority }}</td>
                  <td class="text-center">{{ entry.eventView }}</td>
                  <td class="text-center">{{ entry.eventGallery }}</td>
                  <td class="text-center">{{ entry.eventWww }}</td>
                  <td :class="entry.eventShowPhone > 0 ? 'text-center light-green lighten-4 font-weight-bold' : 'text-center'">{{ entry.eventShowPhone }}</td>
                  <td :class="entry.eventMessage > 0 ? 'text-center light-green lighten-4 font-weight-bold' : 'text-center'">{{ entry.eventMessage }}</td>
                  <td :class="entry.eventReservation > 0 ? 'text-center light-blue lighten-4 font-weight-bold' : 'text-center'">{{ entry.eventReservation }}</td>
                  <td :class="entry.eventReservationMoneyTotal > 0 ? 'text-center light-blue lighten-4 font-weight-bold' : 'text-center'">{{ entry.eventReservationMoneyTotal / 100 }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col>
          <v-simple-table
            dense
            id="summaryTable"
          >
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-right font-weight-bold">okres</td>
                  <td>{{ restrictions.date_gteq }} - {{ restrictions.date_lteq }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">{{ data.summary.eventView.label }}</td>
                  <td>{{ data.summary.eventView.count }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">{{ data.summary.eventGallery.label }}</td>
                  <td>{{ data.summary.eventGallery.count }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">{{ data.summary.eventWww.label }}</td>
                  <td>{{ data.summary.eventWww.count }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">{{ data.summary.eventShowPhone.label }}</td>
                  <td>{{ data.summary.eventShowPhone.count }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">{{ data.summary.eventEmail.label }}</td>
                  <td>{{ data.summary.eventEmail.count }}</td>
                </tr>
                <tr v-if="data.summary.eventReservation.count > 0">
                  <td class="text-right font-weight-bold">{{ data.summary.eventReservation.label }}</td>
                  <td>{{ data.summary.eventReservation.count }}</td>
                </tr>
                <tr v-if="data.summary.eventReservationMoneyTotal.count > 0">
                  <td class="text-right font-weight-bold">{{ data.summary.eventReservationMoneyTotal.label }}</td>
                  <td>{{ data.summary.eventReservationMoneyTotal.count / 100 }} PLN</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center mt-5">
            <v-btn
              color="green"
              dark
              small
              @click="copyToCliboard()"
            >
              kopiuj do schowka
            </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-card-text>

  </v-card>
</template>

<script>
import axios from "axios";
import qs from "qs";
import moment from "moment";

import StatsDashboardForm from "./StatsDashboardForm";

export default {
  name: "StatsDashboard",
  props: {
    facility: {
      type: Object,
    },
  },
  data: () => ({
    loading: false,
    restrictions: {},
    data: false,
  }),
  components: { StatsDashboardForm },
  computed: {},
  methods: {
    getData() {
      this.loading = true;

      let restrictions = {
        restrictions: this.restrictions,
      };

      axios
        .get(
          `/stats/search?` +
            qs.stringify({ restrictions: restrictions }, { encode: false })
        )
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    copyToCliboard() {
      setTimeout(() => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(document.getElementById("summaryTable"));
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
      }, 200);
    },
  },
  watch: {
    facility: function () {},
  },
  mounted() {
    this.restrictions = {
      facility_id_eq: this.facility.id,
      date_lteq: moment().format("Y-MM-DD"),
      date_gteq: moment().add(-1, "year").format("Y-MM-DD"),
    };

    this.getData();
  },
};
</script>


<style>
</style>
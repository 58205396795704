<template>
  <tr @contextmenu="showTool" v-if="!editing" @mouseover="hover = true" @mouseleave="hover = false"
    @dblclick="editing = true">
    <PhoneRowTool :phone="phone" ref="phoneRowTool"
      @phone:sendMessage="(data) => this.$emit('phone:sendMessage', data)" />
    <td>{{ phone.number }}</td>
    <td>{{ phone.type.name }}</td>
    <td>{{ phone.description }}</td>
    <td class="text-right action-cell">
      <v-btn v-if="hover" color="primary" class="mr-1" dark small @click="editing = true"> <v-icon>edit</v-icon> </v-btn>
      <v-btn v-if="hover" color="error" dark small @click="deletePhone()"> <v-icon>delete</v-icon> </v-btn>
    </td>
  </tr>
  <tr v-else>
    <td style="max-width:130px;" class="px-1">
      <v-combobox :items="phoneNumbers" :search-input.sync="newPhone.number" @keydown="autoFormatPhoneNumber()"
        @change="autoFormatPhoneNumber()" @keydown.ctrl.86="newPhone.number = null" v-model="newPhone.number"
        label="numer telefonu" :error-messages="phoneNumberErrors"></v-combobox>
    </td>
    <td style="max-width:150px;" class="px-1">
      <v-select :items="PHONE_TYPES" v-model="newPhone.type" item-text="name" return-object label="rodzaj"
        :error-messages="phoneTypeErrors"></v-select>
    </td>
    <td class="px-1">
      <v-text-field v-model="newPhone.description" label="opis"></v-text-field>
    </td>
    <td class="text-right action-cell">
      <v-btn color="success" class="mr-1" dark small @click="save()"> <v-icon>done</v-icon> </v-btn>
      <v-btn color="warning" @click="editing = false" small>
        <v-icon>not_interested</v-icon>
      </v-btn>
    </td>
  </tr>
</template>


<script>
import { mapGetters } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
import PhoneRowTool from "./PhoneRowTool.vue";

const phonePattern = helpers.regex(
  "phonePattern",
  /^(\+\d{2}\s)?((\d{2}\s\d{3}\s\d{2}\s\d{2})|(\d{3}\s\d{3}\s\d{3}))$/
);

export default {
  name: "FacilityEditPhonesRow",
  props: ["phone", "phoneNumbers"],
  components: { PhoneRowTool },
  data: () => ({
    editing: false,
    hover: false,
    newPhone: {
      type: {}
    }
  }),
  /*validations: {
    newPhone: {
      number: { required, phonePattern },
      type: { required },
    },
  },*/
  validations() {
    if (([11,12].includes(this.newPhone.type.id))) {
      return {
        newPhone: {
          number: { required },
          type: { required },
        }
      };
    } else {
      return {
        newPhone: {
          number: { required, phonePattern },
          type: { required },
        }
      };
    }
  },
  computed: {
    ...mapGetters(["PHONE_TYPES"]),
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.newPhone.number.$dirty) return errors;
      !this.$v.newPhone.number.required && errors.push("telefon jest wymagany")
      this.$v.newPhone.number.phonePattern != null && !this.$v.newPhone.number.phonePattern && errors.push("wprowadź poprawny numer xx xxx xx xx lub xxx xxx xxx ")
        ;
      return errors;
    },
    phoneTypeErrors() {
      const errors = [];
      if (!this.$v.newPhone.type.$dirty) return errors;
      !this.$v.newPhone.type.required && errors.push("rodzaj jest wymagany")
        ;
      return errors;
    },
  },
  methods: {
    deletePhone: function () {
      this.$emit('phone:remove', this.phone)
    },
    save: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.editing = false;
      //this.phone = JSON.parse(JSON.stringify(this.newPhone));
      this.$emit('phone:update', this.newPhone)
    },
    autoFormatPhoneNumber() {
      if (this.newPhone.number === null) {
        return;
      }
      this.newPhone.number = this.newPhone.number.trim();
      if (this.newPhone.number[0] !== "+") {
        this.newPhone.number = this.newPhone.number.replace(/[^0-9\\+]/g, '');
        if (this.newPhone.number.length === 9) {
          const local = [12, 13, 14, 15, 16, 17, 18, 22, 23, 24, 25, 29, 32, 33, 34, 41, 42, 43, 44, 46, 48, 52, 54, 55, 56, 58, 59, 61, 62, 63, 65, 67, 68, 71, 74, 75, 76, 77, 81, 82, 83, 84, 85, 86, 87, 89, 91, 94, 95];
          if (local.includes(parseInt(this.newPhone.number.slice(0, 2)))) {
            this.newPhone.number = this.newPhone.number.replace(/^(\d{2})(\d{3})(\d{2})?(\d{2})/g, '$1 $2 $3 $4');
          } else {
            this.newPhone.number = this.newPhone.number.replace(/^(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3');
          }
        }
      }
    },
    showTool(e) {
      this.$refs.phoneRowTool.show(e);
    }
  },
  mounted() {
    this.newPhone = JSON.parse(JSON.stringify(this.phone));
  },
  watch: {
    'newPhone.number': function () {
      this.autoFormatPhoneNumber();
    }
  }
};
</script>


<style>
.action-cell {
  min-width: 140px;
}
</style>
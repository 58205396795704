<template>
  <v-menu
    v-model="showMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
  >
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        link
      >
        <v-list-item-title @click="callAction(item.action)">{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AddressRowTool",
  props: ["address"],
  data: () => ({
    x: 0,
    y: 0,
    showMenu: false,
    items: [
      {
        title: "kopiuj",
        action: "copy",
      },
    ],
  }),
  components: {},
  computed: {},
  mounted() {
    if (this.address.type.id) {
      if ([7, 8, 9].includes(this.address.type.id)) {
        this.items.push({
          title: "wyślij wiadomość",
          action: "sendMessage",
        });
      }
      if ([1, 2, 3, 4, 5, 6].includes(this.address.type.id)) {
        this.items.push({
          title: "otwórz stronę",
          action: "goToUrl",
        });
      }
    }
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    copyAction() {
      navigator.clipboard.writeText(this.address.address).then(
        () => {
          console.log("copy");
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    },
    sendMessageAction() {
      let options = {
        selectReceiver: this.address.address
      };
      this.$emit('address:sendMessage',options);
    },
    goToUrlAction() {
      window.open("http://"+this.address.address, "_blank");
    },
    callAction(action) {
      this[action + "Action"]();
    },
  },
  watch: {},
};
</script>

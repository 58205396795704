<template>
  <div>
    <v-progress-circular
      indeterminate
      color="#009587"
      v-if="loading"
    ></v-progress-circular>

    <v-card
      class=" mt-3 mx-auto"
      color="#009587"
      dark
      v-if="roomadminData.id"
    >
      <v-card-title class="py-0 pt-1">
       
        <span class="text-h6 font-weight-light">
          <v-badge
          :color="roomadminData.status === 2?'red':'green'"
          dot
        >
          Roomadmin
        </v-badge>
        </span>
        <v-btn
            class=""
            outlined
            rounded
            small
            right
            absolute
            @click="goToRoomadmin"
          >
            ZARZĄDZAJ
          </v-btn>  
      </v-card-title>

      <v-card-text class="font-weight-bold">

        <v-list
          color="#009587"
          class="py-0"
          dark
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                home
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>[{{roomadminData.id}}] {{roomadminData.name}}</v-list-item-title>
              <v-list-item-subtitle>typ oferty: {{ getOfferTypeName(roomadminData.offerType) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-icon>
              <v-icon>
                people
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>opiekun: {{ roomadminData.supervisor.name }} {{ roomadminData.supervisor.surname }}</v-list-item-title>
              <v-list-item-subtitle v-if="roomadminData.technicalSupervisor">specjalista: {{ roomadminData.technicalSupervisor.name }} {{ roomadminData.technicalSupervisor.surname }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>

        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-row
          dense
          no-gutters
        >
          <v-col v-if="roomadminData.publicAvailability">
            <v-icon class="mr-1">
              web
            </v-icon>
            <span class="subheading mr-2">aktywne serwisy</span>
          </v-col>
          <v-col v-if="roomadminData.onlineBookingEnabled">
            <v-icon class="mr-1">
              public
            </v-icon>
            <span class="subheading mr-2">rezerwacje online</span>
          </v-col>
          <v-col>
            <v-icon class="mr-1">
              add_circle
            </v-icon>
            <span class="subheading mr-2">{{roomadminData.createdAt}}</span>
          </v-col>

        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ExternalServiceRoomadmin",
  components: {},
  props: ["facility"],
  data: () => ({
    loading: false,
    offerTypes: [
      { id: 1, name: "brak" },
      { id: 2, name: "gratis" },
      { id: 3, name: "demo" },
      { id: 4, name: "płatny" },
      { id: 5, name: "obiekt testowy" },
    ],
    offerLevels: [
      { id: 1, name: "podstawowy" },
      { id: 2, name: "rozszerzony" },
    ],
    roomadminData: {},
  }),
  computed: {},
  methods: {
    getRoomadminData() {
      this.roomadminData = {};
      this.loading = true;
      axios
        .get(`/facilities/${this.facility.external_id}/roomadmin`)
        .then((response) => {
          this.roomadminData = response.data;
        })
        .finally(() => (this.loading = false));
    },
    getOfferTypeName(id) {
      const found = this.offerTypes.find((element) => element.id === id);
      return found ? found.name : "";
    },
    getOfferLevelName(id) {
      const found = this.offerLevels.find((element) => element.id === id);
      return found ? found.name : "";
    },
    goToRoomadmin(){
      const url = "https://roomadmin.pl/admin/facility/edit?id=";
      window.open(url+this.facility.roomadmin_id, '_blank');
    },
  },
  watch: {
    facility() {
      this.getRoomadminData();
    },
  },
  mounted() {
    this.getRoomadminData();
  },
};
</script>


<style>
</style>
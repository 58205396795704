import axios from "axios";

export default {
    state: {
        operators: [],
        filter: {}
    },
    getters: {
        OPERATORS: state => {
            return state.operators;
        }
    },
    mutations: {
        SET_OPERATORS: (state, payload) => {
            state.operators = payload;
        },
     
    },
    actions: {
        GET_OPERATORS: ({commit}) => {
          return new Promise((resolve, reject) => {
            axios
              .get(`users`)
              .then(({ data, status }) => {
                if (status === 200) {
                    commit("SET_OPERATORS", data);
                    resolve(true);
                }
              })
              .catch(error => {
                reject(error);
            });
          });
        },

    }
}
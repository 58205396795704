<template>
  <div>
    <ExternalServiceClientPanel :facility="facility" />
    <ExternalServiceRoomadmin :facility="facility" />
    <ExternalServiceMeteorSites :facility="facility" />
  </div>          
</template>

<script>
import ExternalServiceRoomadmin from '../ExternalService/ExternalServiceRoomadmin'
import ExternalServiceClientPanel from '../ExternalService/ExternalServiceClientPanel'
import ExternalServiceMeteorSites from '../ExternalService/ExternalServiceMeteorSites'
export default {
  name: "FacilityEditTabParameters",
  props: ["facility"],
  components: {
    ExternalServiceRoomadmin,
    ExternalServiceMeteorSites,
    ExternalServiceClientPanel
  },
  data: () => ({}),
  computed: {

  },
  methods: {
  
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
<template>
  <div>
    <PersonList :facility="facility" @valid="$emit('valid')" @not-valid="$emit('not-valid')"/>
  </div>
</template>

<script>

import PersonList from './../Person/PersonList.vue'

export default {
  name: "FacilityEditTabPeople",
  props: ["facility"],
  components: {
    PersonList
  },
  data: () => ({
   
  }),
  computed: {},
  methods: {
  },
  mounted() {},
};
</script>


<style>
</style>
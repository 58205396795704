<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card
      class="mt-2"
      :loading="loading"
    >
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col>
              <v-text-field
                label="nazwa"
                v-model="registration.name"
                :error-messages="nameErrors"
              
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="adres"
                v-model="registration.address"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="miejscowość"
                v-model="registration.city_name"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="telefon"
                v-model="registration.phone"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="telefon 2"
                v-model="registration.phone2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="email"
                v-model="registration.email"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="www"
                v-model="registration.www"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="osoba zgłaszająca"
                v-model="registration.client_name"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                label="źródło"
                v-model="registration.source"
                dense
                :items="sources"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        
        <v-spacer></v-spacer>

        <v-btn
          color="green"
          text
          @click="save"
        >
          DODAJ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "RegistrationTest",
  components: {},
  data: () => ({
    registration: {},
    dialog: false,
    loading: false,
    sources: ['meteor-turystyka.pl','roomadmin.pl','zgłoszenie telefoniczne']
  }),
  validations: {
    registration: {
      name: { required },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.registration.name.$dirty) return errors;
      !this.$v.registration.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
  },
  methods: {
    getItemText(item) {
      return item.name + " - " + item.external_id;
    },
    showDialog() {
      this.dialog = true;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      this.loading = true;
      this.registration.status = 0;
      this.$store
        .dispatch("ADD_REGISTRATION", { registration: this.registration })
        .then(() => {
          this.loading = false;
          this.clearForm();
          this.dialog = false;
        });

    },
    clearForm() {
  
      this.$refs.form.reset();
      this.$v.$reset();
    },
  },
  mounted() {

  },
  watch: {
   
  },
};
</script>

<style>
</style>
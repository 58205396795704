<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-container>
          <div v-if="client.invoice_data && client.invoice_data.length > 0">
            <div class="text-overline mb-4" >
              Dotychczasowe dane:
            </div>
            <p v>{{client.invoice_data}}</p>
          </div>
          <div class="font-weight-bold text-overline mb-4">
            Dane podawane przez GUS:
          </div>
          <p class="font-weight-bold">{{data.name}}</p>
          <p class="font-weight-bold">{{data.address}}</p>
          <v-card-title>Czy chcesz zapisać nowe dane?</v-card-title>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              @click="save()"
            >
              TAK
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialog = false"
            >
              NIE
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar
          color="error"
          dense
          dark
        >Uwaga!</v-toolbar>
        <v-card-text>
          <div
            v-if="error"
            class="text-subtitle-1 text-center pt-6"
          >{{error}}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="errorDialog = false;"
          >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  props: ["client"],
  name: "ClientDecodeTaxNumber",
  data() {
    return {
      data: {},
      dialog: false,
      errorDialog: false,
      error: false,
      loading: false,
    };
  },
  computed: {},
  methods: {
    save() {
      this.client.invoice_data = this.data.name + "\n" + this.data.address;
      this.dialog = false;
    },
    decode() {
      this.loading = true;
      axios
        .get(`/tools/decodeNip?nip=${this.client.tax_number}`)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          this.dialog = true;
        })
        .catch(() => {
          this.error =
            "Nie udało się pobrać danych. Upewnij się, że NIP jest prawidłowy";
          this.errorDialog = true;
        });
    },
  },
  mounted() {},
  watch: {
    client() {
      this.data = {};
    },
  },
};
</script>


<style>
</style>
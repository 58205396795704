<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      max-width="800"
    >
      <v-card>
        <v-card-title>Dodaj załącznik</v-card-title>
        <v-card-text>
          <v-chip-group
        v-model="templateCategory"
        mandatory
        class="mb-3"
      >
        <v-chip
          v-for="category in MESSAGE_TEMPLATE_CATEGORIES"
          :key="category.id"
          :value="category.id"
          active-class="green darken-1 white--text"
        >
          {{ category.name }}
        </v-chip>
      </v-chip-group>
      <v-autocomplete
        v-if="templates.length > 0"
        :items="templates"
        v-model="template"
        :search-input.sync="templateSearch"
        item-text="name"
        label="wybierz załącznik"
        return-object
        dense
      >
        <!--<template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.category.name"></v-list-item-subtitle>
          </v-list-item-content>
        </template>-->
      </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="add()"
          >dodaj</v-btn>
          <v-btn
            color="warning"
            @click="isDialogActive=false"
          >anuluj</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "SendMailAddAttachment",
  components: {},
  data: () => ({
    isDialogActive: false,
    templateCategory: 1,
    templateSearch: null,
    loading: false,
    template: null
  }),

  computed: {
    ...mapGetters(["MESSAGE_TEMPLATES"]),
    ...mapGetters(["MESSAGE_TEMPLATE_CATEGORIES"]),
    templates() {
      const templates = Object.assign([], this.MESSAGE_TEMPLATES);

      const filtered = [];
      templates.forEach((el) => {
        if (el.type.id !== 1 && this.templateCategory === el.category.id) {
          filtered.push(el);
        }
      });
      filtered.sort((a, b) => {
        return a.name > b.name;
      });
      return filtered;
    }
  },
  methods: {
    add() {
      if(this.template === null){
        return;
      }
      this.isDialogActive = false;
      this.$emit("attachment:add", this.template);
    },
    openDialog() {
      this.template = null;
      this.isDialogActive = true;
    }
  },
  mounted() {

  }
};
</script>


<style>
</style>
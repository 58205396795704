import axios from "axios";

export default {
  state: {
    currentUser: {},
    users: {},
    logged: false
  },
  getters: {
    CURRENT_USER: state => {
      return state.currentUser;
    },
    USERS: state => {
      return state.users;
    },
    LOGGED: state => {
      return state.logged;
    },
  },
  mutations: {
    SET_CURRENT_USER: (state, payload) => {

      state.currentUser = payload;
    },
    UPDATE_CURRENT_USER: (state, payload) => {
      state.currentUser = payload;
    },
    SET_USERS: (state, payload) => {
      state.users = payload;
    },
    UPDATE_USER: (state, payload) => {
      if (payload) {
        const element = state.users.find(user => user.id === payload.id);
        Object.assign(element, payload);
      }
    },
    SET_LOGGED: (state, payload) => {
      state.logged = payload;
    },
  },
  actions: {
    LOGIN: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`login_check`, payload)
          .then(({ data, status }) => {
            console.log(data);
            if (status === 200) {
              commit("SET_LOGGED", true);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    LOGOUT: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`logout`, payload)
          .then(({ data, status }) => {
            console.log(data);
            if (status === 200) {
              commit("SET_LOGGED", false);
              localStorage.removeItem('user/currentUser');
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    REFRESH_TOKEN: () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`token/refresh`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    UPDATE_USER: ({ commit }, { user }) => {
      return new Promise((resolve, reject) => {
        axios
          .patch(`users/${user.id}`, user)
          .then(({ data, status }) => {
            if (status === 200) {
              commit("UPDATE_USER", data);
              resolve({ data, status });
            }
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    UPDATE_CURRENT_USER: ({ commit }, { user }) => {
      return new Promise((resolve, reject) => {
        axios
          .patch(`users/${user.id}`, user)
          .then(({ data, status }) => {
            if (status === 200) {
              commit("UPDATE_CURRENT_USER", data);
              resolve({ data, status });
            }
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    INIT_USER: async ({ commit, getters,dispatch  }) => {
      dispatch('GET_LOGGED');
      return new Promise((resolve, reject) => {
        if (getters.LOGGED) {
          axios
            .get(`users/me`)
            .then(({ data, status }) => {
              if (status === 200) {

                //commit("UPDATE_FACILITY", data);
                commit("SET_CURRENT_USER", data);
                resolve({ data, status });
              }
            })
            .catch((error) => {
              reject(error);
            })
        } else {
          //reject(false);
          resolve({});
        }
      });
    },
    GET_CURRENT_USER: async ({ commit }) => {
      //if (localStorage.getItem('user/currentUser')) {
      // commit("SET_CURRENT_USER", JSON.parse(localStorage.getItem('user/currentUser')));
      //} else {
      let { data } = await axios.get(`users/me`);
      localStorage.setItem('user/currentUser', data.id);
      commit("SET_CURRENT_USER", data);
      //}
    },
    GET_LOGGED: ({ commit }) => {
      if (localStorage.getItem('user/currentUser')) {
        commit("SET_LOGGED", true);
      } else {
        commit("SET_LOGGED", false);
      }
    },
    GET_USERS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`users`)
          .then(({ data, status }) => {
            if (status === 200) {
              commit("SET_USERS", data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};
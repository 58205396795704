<template>
  <v-form @keyup.native.enter="search">
    <v-row dense>
      <v-col>
        <v-select
          v-model="filter.status_id_eq"
          :items="formDataStatuses"
          label="status"
          clearable
        ></v-select>

      </v-col>
      <v-col >
        <v-text-field
          v-model="filter.external_id_eq"
          label="powiązane id"
        ></v-text-field>
      </v-col>
     
      <v-col>
        <v-text-field
          v-model="filter.number_like"
          label="numer faktury"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-menu
          v-model="toBeIssuedAtFromDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.to_be_issued_at_from"
              label="wystaw od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.to_be_issued_at_from"
            @change="toBeIssuedAtFromDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="toBeIssuedAtToDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.to_be_issued_at_to"
              label="wystaw do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.to_be_issued_at_to"
            @change="toBeIssuedAtToDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="filter.user_id_eq"
          :items="OPERATORS"
          item-text="name"
          item-value="id"
          label="doradca biznesowy"
          clearable
        >
        </v-autocomplete>
      </v-col>  
    </v-row>
    <v-row dense>
     
      <v-col>
        <v-menu
          v-model="issuedAtFromDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.issued_at_from"
              label="wystawiono od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.issued_at_from"
            @change="issuedAtFromDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="issuedAtToDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.issued_at_to"
              label="wystawiono do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.issued_at_to"
            @change="issuedAtToDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="nextDebtContactAtFromDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.next_debt_contact_at_gt"
              label="monit od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.next_debt_contact_at_gt"
            @change="nextDebtContactAtFromDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="nextDebtContactAtToDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.next_debt_contact_at_lt"
              label="monit do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.next_debt_contact_at_lt"
            @change="nextDebtContactAtToDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="paidAtFromDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.paid_at_gt"
              label="zapłacono od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.paid_at_gt"
            @change="paidAtFromDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="paidAtToDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.paid_at_lt"
              label="zapłacono do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.paid_at_lt"
            @change="paidAtToDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="filter.payment_online"
          label="płatność online"
          :value="1"
        ></v-checkbox>
      </v-col>
      <v-col class="text-center">
        <v-btn
          dark
          color="mt-3 green"
          @click="search"
          :loading="loading"
        >
          szukaj
        </v-btn>
      </v-col>
    </v-row>

  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "InvoiceSearchForm",
  data() {
    return {
      filter: {
      },
      loading: false,
      toBeIssuedAtFromDatepicker: false,
      toBeIssuedAtToDatepicker: false,
      issuedAtFromDatepicker: false,
      issuedAtToDatepicker: false,
      paidAtFromDatepicker: false,
      paidAtToDatepicker: false,
      nextDebtContactAtFromDatepicker: false,
      nextDebtContactAtToDatepicker: false,
    };
  },
  computed: {
    ...mapGetters(["INVOICE_STATUSES"]),
    ...mapGetters(["OPERATORS"]),
    formDataStatuses() {
      var data = [];
      for (let key in this.INVOICE_STATUSES) {
        var service = this.INVOICE_STATUSES[key];
        data.push({
          text: service,
          value: parseInt(key),
        });
      }
      return data;
    },
  },
  mounted() {
    this.$store.dispatch("GET_OPERATORS");
    if (this.$acl.check("accountant")) {
      this.filter.status_id_eq = 0;
    }  
  },
  methods: {
    async search() {
      this.loading = true;
      await this.$store.dispatch("FIND_INVOICES", { criteria: this.filter });
      this.loading = false;
    },
  },
  watch: {},
};
</script>


<style>
</style>
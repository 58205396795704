<template>
  <v-card :loading="loading" min-height="820">
    <v-toolbar
      color="blue"
      dark
      flat
      dense
    >
      <v-toolbar-title>{{CURRENT_USER.name}} - ustawienia</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          centered
          slider-color="yellow"
        >
          <v-tab
            v-for="item in tabItems"
            :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in tabItems"
        :key="item.tab"
      >
        <v-card-text>
          <component
            :is="item.component"
            :user="userEdited"
          ></component>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="save()"
      > zapisz</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import UserEditQuickText from "./UserEdit/UserEditQuickText";
import UserEditQuickTextWww from "./UserEdit/UserEditQuickTextWww";
import UserEditMailMiniTemplate from "./UserEdit/UserEditMailMiniTemplate";
import UserEditSMSlMiniTemplate from "./UserEdit/UserEditSMSlMiniTemplate";
import UserEditOptions from "./UserEdit/UserEditOptions";
import UserEditWorkingSchedule from "./UserEdit/UserEditWorkingSchedule";

export default {
  name: "UserEditSettings",
  components: {
    UserEditQuickText,
    UserEditQuickTextWww,
    UserEditMailMiniTemplate,
    UserEditSMSlMiniTemplate,
    UserEditOptions,
    UserEditWorkingSchedule
  },
  data: () => ({
    loading: false,
    userEdited: {},
    tab: null,
    tabItems: [
      //{ tab: "Podstawowe", component: "FacilityEditInfo" },
      { tab: "Szybkie Teksty", component: "UserEditQuickText" },
      { tab: "Szybkie Teksty - Usługi WWW", component: "UserEditQuickTextWww" },
      { tab: "Mini Szablony", component: "UserEditMailMiniTemplate" },
      { tab: "Szablony SMS", component: "UserEditSMSlMiniTemplate" },
      { tab: "Godziny pracy", component: "UserEditWorkingSchedule" },
      { tab: "Opcje", component: "UserEditOptions" },
    ],
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  methods: {
    save() {
      this.loading = true;

      this.$store
        .dispatch("UPDATE_CURRENT_USER", {
          user: this.userEdited,
        })
        .then(() => {
          this.loading = false;
          this.$emit("user:updated");
        });
    },
  },
  watch: {},
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_CURRENT_USER").then(() => {
      this.loading = false;
      this.userEdited = JSON.parse(JSON.stringify(this.CURRENT_USER));
    });
  },
};
</script>

<style>
</style>
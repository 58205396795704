<template>
  <div>
    <v-menu
    v-if="$acl.check('admin')"
      v-model="addedAtDatepicker"
      :close-on-content-click="false"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          clearable
          v-model="facility.added_at"
          label="data ostatniego zgłoszenia"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:clear="date = null"
          dense
        ></v-text-field>
      </template>
      <v-date-picker
        locale="pl-pl"
        no-title
        v-model="facility.added_at"
        @change="addedAtDatepicker = false"
      ></v-date-picker>
    </v-menu>
    <v-text-field v-else
          v-model="facility.added_at"
          label="data ostatniego zgłoszenia"
          readonly
          dense
    ></v-text-field>
    
    <RegistrationList :facility="facility"/>

    <v-textarea
      class="mt-4"
      label="info"
      auto-grow
      rows="10"
      ref="info"
      id="info"
      v-model="facility.info"
      outlined
      @keydown.ctrl.68.prevent=""
      @keydown.ctrl.68="addEmptyCommantLine()"
    ></v-textarea>
    <v-btn
      v-if="facility.legacy_www"
      @click="openLegacyWww"
    >
      <v-icon>history</v-icon>
    </v-btn>
    <LegacyWww
      :facility="facility"
      ref="legacyWww"
    />
  </div>
</template>

<script>
import LegacyWww from "../Legacy/LegacyWww.vue";
import { mapGetters } from "vuex";
import moment from "moment";

import RegistrationList from "./../Registration/RegistrationList.vue"

export default {
  name: "FacilityEditTabInfo",
  props: ["facility"],
  components: { LegacyWww, RegistrationList },
  data: () => ({
    addedAtDatepicker: false,
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"])
  },
  methods: {
    addEmptyCommantLine(subscription) {
      let date = moment(date).format("YYYY-MM-DD H:mm");
      let line = "[" + date + "] " + this.CURRENT_USER.username + ", ";
      this.addCommentLine(line, subscription);
    },
    addCommentLine(line) {
      this.facility.info =
        line +
        "\n" +
        (this.facility.info === null ? "" : this.facility.info);
      this.focusComment();
      this.$nextTick(() => {
        this.$refs.info.$refs.input.setSelectionRange(
          line.length,
          line.length
        );
      });
    },
    focusComment() {
      setTimeout(() => {
        this.$refs.info.$refs.input.focus();
      });
    },
    openLegacyWww() {
      this.$refs.legacyWww.openDialog();
    },
  },
  mounted() {},
};
</script>


<style>
#info {
  font-size:12px;
  line-height: 1.5;
}
</style>
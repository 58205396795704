<template>
  <v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      color="blue"
      dark
      dense
    >
      <v-btn
        icon
        dark
        v-on:click="$emit('messageTemplateForm:close')"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title><span v-if="!form.name">Dodawanie szablonu</span><span v-if="form.name">Szablon - {{form.name}}</span></v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <form v-if="form">
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                name="name"
                label="Nazwa"
                v-model="form.name"
                :error-messages="nameErrors"
                required
                @input="$v.form.name.$touch()"
                @blur="$v.form.name.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                v-model="form.type"
                :items="MESSAGE_TEMPLATE_TYPES"
                item-text="name"
                return-object
                label="typ oferty"
                :error-messages="typeErrors"
                dense
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="form.category"
                :items="MESSAGE_TEMPLATE_CATEGORIES"
                :error-messages="categoryErrors"
                item-text="name"
                return-object
                label="kategoria oferty"
                dense
              >
              </v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.priority"
                label="priorytet (sortowanie)"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row
            dense
            v-if="form.type && form.type.id !== 2"
          >
            <v-col>
              <v-autocomplete
                v-model="form.attached_template_ids"
                :items="attachmentTemplates"
                item-text="name"
                item-value="id"
                clearable
                deletable-chips
                multiple
                small-chips
                dense
                label="załączniki"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                label="Tytuł"
                v-model="form.subject"
              ></v-text-field>
            </v-col>
            <v-col v-if="form.type && form.type.id === 2">
              <v-text-field
                label="Treść z pliku (nazwa pliku)"
                v-model="form.filepath"
              ></v-text-field>
            </v-col>
          </v-row>  
          <div v-if="!form.filepath || form.filepath.length === 0">
            <label>Treść</label>
            <ckeditor
              :editor="editor"
              v-model="form.content"
              :config="editorConfig"
              @ready="editorReady"
            ></ckeditor>
          </div>
        </v-container>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click="preview()"
      >
        podgląd
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        v-on:click="$emit('messageTemplateForm:close')"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-on:click="submit"
        color="blue darken-1"
        text
      > Zapisz </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ClassicEditor from "ckeditor5-build-decoupled-document-base64-imageresize";

import { mapGetters } from "vuex";

export default {
  props: ["messageTemplate"],
  name: "AdminMessageTemplateForm",
  data() {
    return {
      loading: false,
      form: false,
      expiresAtDatepicker: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        }
      },
    };
  },
  validations: {
    form: {
      name: { required },
      type: { required },
      category: { required },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Nazwa jest wymagana.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.form.type.$dirty) return errors;
      !this.$v.form.type.required && errors.push("Typ jest wymagany.");
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.form.category.$dirty) return errors;
      !this.$v.form.category.required &&
        errors.push("Kategoria jest wymagana.");
      return errors;
    },
    ...mapGetters(["MESSAGE_TEMPLATES"]),
    ...mapGetters(["MESSAGE_TEMPLATE_TYPES"]),
    ...mapGetters(["MESSAGE_TEMPLATE_CATEGORIES"]),
    attachmentTemplates() {
      const templates = Object.assign([], this.MESSAGE_TEMPLATES);
      return templates.filter((template) => {
        if (template.type.id === 2) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.loading = true;
      if (this.form.id) {
        this.$store
          .dispatch("UPDATE_MESSAGE_TEMPLATE", {
            messageTemplate: this.form,
          })
          .then(() => {
            this.loading = false;
            this.$emit("messageTemplateForm:close");
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              console.log(error.response.data.message);
            }
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch("ADD_MESSAGE_TEMPLATE", {
            messageTemplate: this.form,
          })
          .then(() => {
            this.loading = false;
            this.$emit("messageTemplateForm:close");
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              console.log(error.response.data.message);
            }
            this.loading = false;
          });
      }
    },
    preview() {
      if (this.form.id) {
        window.open(
          process.env.VUE_APP_API_ENDPOINT +
            `messageTemplates/${this.form.id}/previews.pdf`
        );
      }
    },
    editorReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_MESSAGE_TEMPLATE_TYPES").then(() => {
      this.$store.dispatch("GET_MESSAGE_TEMPLATE_CATEGORIES").then(() => {
        this.loading = false;
      });
    });

    this.form = Object.assign({}, this.messageTemplate);
  },
  watch: {
    messageTemplate: function (messageTemplate) {
      this.form = Object.assign({}, messageTemplate);
    },
  },
};
</script>

<style>
.ck-content {
  width: 100%;
  min-height: 350px;

  border: 1px solid var(--ck-color-base-border) !important;
  border-radius: var(--ck-border-radius) !important;
  max-height: 700px;

  display: flex;
  flex-flow: column nowrap;
}
</style>
<template>
    <v-card-text>
      <v-row dense>
        <v-col md="2">
          <v-select
            :items="personTitle"
            v-model="person.title"
            label="tytuł"
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="person.name"
            label="imię i nazwisko"
            :error-messages="nameErrors"
            dense
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            :append-icon="person.phone.length > 0 ? 'sms' : ''"
            @click:append="sendSms"
            v-model="person.phone"
            @change="$v.$touch()"
            label="telefon"
            :error-messages="phoneNumberErrors"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="5">
          <v-text-field
          :append-icon="person.email.length > 0 ? 'email' : ''"
            @click:append="sendEmail"
            v-model="person.email"
            @change="$v.$touch()"
            label="email"
            :error-messages="emailErrors"
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="person.comment"
            label="rola / komentarz"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
     
    </v-card-text>
</template>

<script>
import { required, email, helpers } from "vuelidate/lib/validators";
const phonePattern = helpers.regex(
  "phonePattern",
  /^(\d{2}\s\d{3}\s\d{2}\s\d{2})$|(\d{3}\s\d{3}\s\d{3})$/
);

export default {
  name: "PersonRow",
  props: ["person"],
  components: {},
  data: () => ({
    personTitle: ["", "Pan", "Pani"],
  }),
  validations: {
    person: {
      phone: {phonePattern},
      email: {email},
      name: {required},
    },
  },
  computed: {
    nameErrors(){
      const errors = [];
      if (!this.$v.person.name.$dirty) return errors;
      !this.$v.person.name.required && errors.push("imię i nazwisko jest wymagane")
      ;
      return errors;
    },
    phoneNumberErrors(){
      const errors = [];
      if (!this.$v.person.phone.$dirty) return errors;
      !this.$v.person.phone.phonePattern && errors.push("wprowadź poprawny numer xx xxx xx xx lub xxx xxx xxx")
      ;
      return errors;
    },
    emailErrors(){
      const errors = [];
      if (!this.$v.person.email.$dirty) return errors;
      !this.$v.person.email.email && errors.push("wprowadź poprawny adres email")
      ;
      return errors;
    },
  },
  methods: {
    sendSms(){
      this.$emit('send-sms',this.person);
    },
    sendEmail(){
      this.$emit('send-email',this.person);
    },
    autoFormatPhoneNumber(){
      if(this.person.phone === null){
        return;
      }
      this.person.phone = this.person.phone.trim();     
      //this.person.phone = this.person.phone.replace(/[^0-9\\+]/g, '');
      if(this.person.phone.length === 9){
        const local = [12,13,14,15,16,17,18,22,23,24,25,29,32,33,34,41,42,43,44,46,48,52,54,55,56,58,59,61,62,63,65,67,68,71,74,75,76,77,81,82,83,84,85,86,87,89,91,94,95];
        if(local.includes(parseInt(this.person.phone.slice(0,2)))){
          this.person.phone = this.person.phone.replace(/^(\d{2})(\d{3})(\d{2})?(\d{2})/g, '$1 $2 $3 $4');
        } else {
          this.person.phone = this.person.phone.replace(/^(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3');
        }
      }
    }  

  },
  mounted() {
    this.$v.$touch();
  },
  watch: {
    'person.phone': function(){
      this.autoFormatPhoneNumber();
    },
    '$v.$error': function(val){
      if(val === true){
        this.$emit('validation-error',this.person.id);
      } else {
        this.$emit('validation-success',this.person.id);
      }
    }
  }
};
</script>


<style>
</style>
<template>
  <v-container>
    <v-card
      outlined
      class="pa-2"
    >
      <v-card-title >Miasta</v-card-title>
      <v-alert v-if="cityErrors.length > 0" type="error" dense outlined>
        <div v-for="error in cityErrors" :key="error">
          {{error}}
          </div>
      </v-alert>
      <FacilityEditCities :facility="facility" />
    </v-card>

    <v-card
      class="mt-4 "
      outlined
    >
      <v-card-title>Adres</v-card-title>
      <v-card-text>
        <FacilityEditAddressPostal
          v-if="facility"
          :facility="facility"
        />
      </v-card-text>
    </v-card>

    <v-card
      class="mt-4 "
      outlined
      v-if="this.facility.facility.cities.length > 0 "
    >
      <v-card-title>Położenie</v-card-title>
      <v-card-text>
        <FacilityEditGeolocation
          v-if="facility"
          :facility="facility"
          :inline="true"
        />
      </v-card-text>
    </v-card>




  </v-container>
</template>

<script>
import FacilityEditAddressPostal from "../FacilityEdit/FacilityEditAddressPostal";
import FacilityEditCities from "../FacilityEdit/FacilityEditCities";
import FacilityEditGeolocation from "../FacilityEdit/FacilityEditGeolocation";

import { required } from "vuelidate/lib/validators";

export default {
  name: "FacilityCreateLocation",
  components: { FacilityEditAddressPostal, FacilityEditCities, FacilityEditGeolocation },
  props: ["facility"],
  data: () => ({}),
  validations: {
    facility: {
      facility: {
        cities: { required },
      }
    },
  },
  computed: {
    cityErrors() {
      const errors = [];
      if (!this.$v.facility.facility.cities.$dirty) return errors;
      !this.$v.facility.facility.cities.required  && errors.push("dodaj miasto");

      return errors;
    },
  },
  methods: {
    valid() {
      this.$v.$touch();
      console.log(this.$v.facility.facility.cities.required);
      if (this.$v.$anyError) {
        return false;
      }
      return true;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
<template>
  <div class="mb-0">
    <v-card-title class="pb-0 pt-1 text-subtitle-1 font-weight-bold">Potwierdzenie SMS</v-card-title>
    <v-card-text>
      <v-item-group
        multiple
        v-model="message.SMSConfirmationReceivers"
      >
        <v-item
          v-for="(item, i) in phones"
          :key="i"
          :value="item.number"
          v-slot="{ active, toggle }"
        >
          <v-chip
            filter
            class="mr-2"
            active-class="green darken-1 white--text"
            :input-value="active"
            @click="toggle"
          >
            {{ item.name }}
          </v-chip>
        </v-item>
      </v-item-group>
    </v-card-text>  
  </div>
</template>

<script>

export default {
  name: "SendMailSMSConfirmation",
  props: ["facility","message"],
  data() {
    return {

    };
  },
  computed: {
    phones() {
      let list = [];
      let phones = [];
      let facilityPhones = this.facility.facility.phones.filter((current) => {
        return [2, 3, 4, 6, 7].includes(current.type.id);
      });

      facilityPhones.forEach((phone) => {
        let number = phone.number.replace(/\s/g, "");
        if (!list.includes(number)) {
          list.push(number);
          phones.push({
            name: number + " - " + phone.type.name,
            number: number
          });
        }
      });

      let lastSubscription = Object.values(this.facility.subscriptions).slice(
        -1
      )[0];

      if (
        lastSubscription.client_phone &&
        lastSubscription.client_phone.length > 0
      ) {
        if (
          !list.includes(lastSubscription.client_phone.replace(/\s/g, ""))
        ) {
          list.push(lastSubscription.client_phone.replace(/\s/g, ""));
          let number = lastSubscription.client_phone.replace(/\s/g, "");
          phones.push({
            name: number + " - " + lastSubscription.client_name ,
            number: number
          });
        }
      }

      if (
        lastSubscription.client_phone2 &&
        lastSubscription.client_phone2.length > 0
      ) {
        if (
          !list.includes(lastSubscription.client_phone2.replace(/\s/g, ""))
        ) {
          list.push(lastSubscription.client_phone2.replace(/\s/g, ""));
          let number = lastSubscription.client_phone2.replace(/\s/g, "");
          phones.push({
            name: number + " - " + lastSubscription.client_name ,
            number: number
          });
        }
      }

      if (this.facility.people && this.facility.people.length > 0) {
        this.facility.people.forEach((person) => {
          let number = person.phone.replace(/\s/g, "");
          if (!list.includes(number)) {
            list.push(number);
          }
        });
      }

      return phones;
    },
  },
  mounted() {
   
  },
  methods: {
   
  },
  watch: {
   
  },
};
</script>


<style>

</style>
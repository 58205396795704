<template>
  <v-dialog
    v-model="dialog"
    width="700"
  >
    <v-card

      :loading="loading"
    >
      <v-card-text >
        <v-form ref="form" class="pt-4">
          <v-row >
            <v-col md="6">
              <v-text-field
                dense
                label="cel"
                v-model="event.title"
                :error-messages="titleErrors"
              ></v-text-field>
            </v-col>
            <v-col v-if="!type" md="6">
              <v-select
                :items="types"
                item-text="name"
                item-value="id"
                label="typ"
                v-model="event.type"
              ></v-select>
            </v-col>
            <v-col md="6">
              <DateTimePicker 
                label="start"
                v-model="start_date"
                defaultHour='8:00'
              ></DateTimePicker>
            </v-col>
            <v-col v-if="!type" md="6">
              <DateTimePicker 
                label="koniec"
                v-model="end_date"
                
              ></DateTimePicker>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="!facility">
              <v-autocomplete
                v-model="event.facility"
                name="facility"
                label="powiązany obiekt"
                return-object
                :item-text="getItemText"
                :items="facilitySuggestions"
                :loading="facilitySuggestionsLoading"
                auto-select-first
                :search-input.sync="facilitySuggestionsSearch"
                placeholder="powiązany obiekt"
                hide-no-data
              ></v-autocomplete>
            </v-col>

          </v-row>
          <v-row dense>
            <v-textarea
              v-model="event.description"
              outlined
              label="opis"
            ></v-textarea>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="event.id"
          color="red"
          text
          @click="remove"
        >
          <v-icon>delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          text
          @click="dialog=false"
        >
          ANULUJ
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save"
        >
          ZAPISZ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import DateTimePicker from "./../../../shared/DateTimePicker.vue"

export default {
  name: "EventEdit",
  components: {DateTimePicker},
  props: ["facility", "type"],
  data: () => ({
    event: {
      type: {},
      facility: {},
    },
    dialog: false,
    loading: false,
    start_date: null,
    end_date: null,
    types: [
      { id: 1, name: "kontakt" },
      { id: 2, name: "zadanie" },
    ],
    facilitySuggestions: [],
    facilitySuggestionsSearch: null,
    facilitySuggestionsLoading: false,
  }),
  validations: {
    event: {
      title: { required },
    },
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.event.title.$dirty) return errors;
      !this.$v.event.title.required && errors.push("tytuł jest wymagany");
      return errors;
    },
  },
  methods: {
    getItemText(item) {
      return item.name + " - " + item.external_id;
    },
    openDialog(event) {
 
      this.dialog = true;
      if (event) {
        this.event = event;
        this.start_date = moment(this.event.start).toDate();
        this.end_date = moment(this.event.end).toDate();

        if (
          this.event.id &&
          this.event.facility &&
          this.event.facility !== null
        ) {
          //this.facilitySuggestionsSearch = this.getItemText(this.event.facility);
          this.facilitySuggestions.push(this.event.facility);
        }
      } else {
        this.clearForm();
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.loading = true;
      if (!this.event.id || this.event.id < 0) {
        this.$store
          .dispatch("ADD_FACILITY_USER_EVENT", { userEvent: this.event })
          .then((createdEvent) => {
            this.event.id = createdEvent.data.id;
            this.loading = false;
            this.clearForm();
            this.dialog = false;
          });
      } else {
        this.$store
          .dispatch("UPDATE_FACILITY_USER_EVENT", { userEvent: this.event })
          .then(() => {
            this.loading = false;
            this.$emit('event-changed',this.event);
            this.clearForm();
            this.dialog = false;
          });
      }
    },
    remove() {
      this.loading = true;
      this.$store
        .dispatch("REMOVE_FACILITY_USER_EVENT", { userEvent: this.event })
        .then(() => {
          this.$emit("remove", { event: this.event });
          this.loading = false;
          this.dialog = false;
        });
    },
    clearForm() {
      this.event = {
        type: {},
        facility: {},
        start: null,
        end: null
      };

      if (this.facility) {
        this.event.facility = this.facility;
      }
      if (this.type) {
        this.event.type = this.type;
      }

      if (this.start_date) {
        this.start_date = null;
      }
      if (this.end_date) {
        this.end_date = null;
      }
      if(this.$refs.form){
        this.$refs.form.reset();
      }
      
      this.$v.$reset();
    },
  },
  mounted() {
    if (this.facility) {
      this.event.facility = this.facility;
    }
    if (this.type) {
      this.event.type = this.type;
    }
  },
  watch: {
    facilitySuggestionsSearch(val) {
      if (val === null || /-/.test(val)) {
        return;
      }

      this.facilitySuggestionsLoading = true;
      let filter = "external_id_like";
      const pattern = /^\d+$/;
      if (!pattern.test(val)) {
        filter = "name_like";
      }
      axios
        .get(`/facilities?limit=20&filter[` + filter + `]=` + val, {})
        .then((response) => {
          var data = [];

          for (let key in response.data.result.items) {
            var row = response.data.result.items[key];
            row.value = row.name;
            data.push(row);
          }

          this.facilitySuggestions = response.data.result.items;
        })
        .finally(() => (this.facilitySuggestionsLoading = false));
    },
    start_date: function () {
      if (!this.start_date) {
        return;
      }

      this.event.start = moment(this.start_date).valueOf();

      if (!this.end_date) {
        this.end_date = moment(this.start_date).add(15, "minutes").toDate();
      }
    },
    end_date: function () {
      if (!this.end_date) {
        return;
      }
      this.event.end = moment(this.end_date).valueOf();
    },
  },
};
</script>

<style>
</style>
<template>
  <v-container>
    <v-select
          v-model="facility.supervisor"
          :items="OPERATORS"
          item-text="name"
          return-object
          label="opiekun oferty"
          dense
        >
        </v-select>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FacilityCreateSupervisor",
  components: { },
  props: ["facility"],
  data: () => ({}),
  computed: {
    ...mapGetters(["OPERATORS"]),
    ...mapGetters(["CURRENT_USER"])
  },
  methods: {  
  }, 
  mounted() {
    this.$store.dispatch("GET_OPERATORS").then(() => {
      if (this.CURRENT_USER) {
        this.facility.supervisor = this.CURRENT_USER;
      }
    }); 
  },
  watch: {},
};
</script>

<style>
</style>
<template>
  <v-card>
    <v-toolbar color="blue" dark>
      <v-toolbar-title v-if="!editEntry">Tworzenie pozycji</v-toolbar-title>
      <v-toolbar-title v-if="editEntry">Edycja pozycji</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container>

        <v-form ref="form">
          <v-select
            v-model="entry.user"
            :items="OPERATORS"
            item-text="name"
            return-object
            :error-messages="userErrors"
            label="opiekun"
            required
            dense
          ></v-select>
          <v-select
            v-model="entry.product"
            :items="INVOICE_PRODUCTS"
            :error-messages="productErrors"
            item-text="name"
            item-value="id"
            label="produkt"
            return-object
            required
            dense
          ></v-select>

          <v-currency-field 
            v-model="entry.amount" 
            label="kwota netto"
            suffix="PLN"
            :value-as-integer="true"
            :error-messages="amountErrors"
          ></v-currency-field>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text v-on:click="clearForm(), $emit('cancel')">
        Anuluj
      </v-btn>
      <v-btn v-if="!editEntry" v-on:click="submit" color="blue darken-1" text> dodaj </v-btn>
      <v-btn v-if="editEntry" v-on:click="save" color="blue darken-1" text> zapisz </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { required,minValue } from "vuelidate/lib/validators";


export default {

  props: ["invoice","editEntry"],
  name: "InvoiceCreateEntry",
  data() {
    return {
      entry: {
        user: {},
        developer: {},
        product: {},
        service: {},
        amount: 0
      }
    };
  },
  validations: {
    entry: {
      user: {
        id: { required },
      },
      developer: {
        id: { },
      },
      product: {
        id: {required}
      },
      amount: { required, minValue: minValue(1000) }
    },
  },
  computed: {
    ...mapGetters(["OPERATORS"]),
    ...mapGetters(["INVOICE_PRODUCTS"]),
    ...mapGetters(["CURRENT_USER"]),
    amountErrors() {
      const errors = [];
      
      if (!this.$v.entry.amount.$dirty) return errors;
      !this.$v.entry.amount.required && errors.push("Kwota jest wymagana.")
      !this.$v.entry.amount.minValue && errors.push("Kwota musi być większa niż 10zł.");
      return errors;
    },
    userErrors() {
      const errors = [];
      if (!this.$v.entry.user.id.$dirty) return errors;
      !this.$v.entry.user.id.required && errors.push("Opiekun jest wymagany.");
      return errors;
    },
    developerErrors() {
      const errors = [];
      if (!this.$v.entry.user.id.$dirty) return errors;
      !this.$v.entry.user.id.required && errors.push("Wykonawca jest wymagany.");
      return errors;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.entry.product.id.$dirty) return errors;
      !this.$v.entry.product.id.required && errors.push("Typ produktu jest wymagany.");
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      if(this.invoice.invoice_entries.length > 0) {
        for (let key in this.invoice.invoice_entries) {
          var entry = this.invoice.invoice_entries[key];
          if(entry.product.id === this.entry.product.id){
            return false;
          }
        }
      }
      
       this.$emit('add-entry', this.entry)

      //this.invoice.invoice_entries.push(this.entry);
      this.clearForm();
      this.$emit("entry-created");
    },
    save(){
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      

      this.$emit('save-entry', this.entry)

    },
    serviceName(service) {
      return service.type.name + " - " + service.domain.domain;
    },
    clearForm(){
      if(this.editEntry){
        return;
      }
      this.entry = Object.assign({}, {
        user: {},
        developer: {},
        product: {},
        service: {},
        amount: 0
      });
      
      this.$refs.form.reset();
      this.$v.$reset();

      this.entry.user = JSON.parse(JSON.stringify(this.CURRENT_USER));
    }
  },
  mounted() {
    if(this.editEntry){
      this.entry = JSON.parse(JSON.stringify(this.editEntry));
    }
    this.$store.dispatch("GET_INVOICE_PRODUCTS").then();
    this.entry.user = JSON.parse(JSON.stringify(this.CURRENT_USER));
  },
  watch: {
    editEntry(editEntry){
      this.entry = JSON.parse(JSON.stringify(editEntry));
    }
  },
};
</script>


<style>
</style>
<template>
  <v-menu
    v-model="showMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
  >
    <v-list v-if="templates.length > 0">
      <v-list-item
        v-for="(item, index) in templates"
        :key="index"
        link
      >
        <v-list-item-title @click="selectTemplate(item)">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SendMailTemplatePicker",
  props: ["templates"],
  data: () => ({
    x: 0,
    y: 0,
    showMenu: false,
    items: [
      {
        title: "kopiuj",
        action: "copy",
      },
    ],
  }),
  components: {},
  computed: {},
  mounted() {
   
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    selectTemplate(template) {
      this.$emit('updateTemplate',template);
    }
  },
  watch: {},
};
</script>

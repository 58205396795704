<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <AdminMessageTemplateSearchForm />
    <v-btn
      color="success"
      @click="addTemplate()"
      small
      right
      absolute
      class="mr-3"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-simple-table v-if="MESSAGE_TEMPLATES.length > 0">
      <thead>
        <tr>
          <th class="text-left">
            Nazwa
          </th>
          <th class="text-left">
            Typ
          </th>
          <th class="text-left">
            Kategoria
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in MESSAGE_TEMPLATES"
          :key="item.id"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.type.name }}</td>
          <td>{{ item.category.name }}</td>

          <td style="text-align:right;">

            <v-btn
              depressed
              small
              color="primary"
              @click="showEditDialog(item)"
            >
              edytuj
            </v-btn>

            <v-btn
              depressed
              color="red"
              class="ml-2"
              dark
              small
              @click="remove(item)"
            >
              usuń
            </v-btn>

          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog
      v-model="dialogMessageTemplate"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <AdminMessageTemplateForm
        :messageTemplate="currentMessageTemplateEdit"
        v-on:messageTemplateForm:close="dialogMessageTemplate=false"
      />
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMessageTemplateForm from "./AdminMessageTemplate/Form";
import AdminMessageTemplateSearchForm from "./AdminMessageTemplate/SearchForm";
export default {
  name: "AdminMessageTemplate",
  components: {
    AdminMessageTemplateForm,
    AdminMessageTemplateSearchForm
  },
  data: () => ({
    dialogMessageTemplate: false,
    currentMessageTemplateEdit: {},
    loading: false
  }),
  computed: {
    ...mapGetters(["MESSAGE_TEMPLATES"]),
  },
  methods: {
    showEditDialog(item) {
      this.dialogMessageTemplate = true;
      this.currentMessageTemplateEdit = item;
    },
    remove(item) {
      this.loading = true;
      this.$store
        .dispatch("REMOVE_MESSAGE_TEMPLATE", {
          messageTemplate: item,
        })
        .then(() => {
          this.loading = false;
          this.$emit("messageTemplateForm:close");
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            console.log(error.response.data.message);
          }
          this.loading = false;
        });
    },
    addTemplate() {
      this.dialogMessageTemplate = true;
      this.currentMessageTemplateEdit = {};
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_MESSAGE_TEMPLATES").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>
<template>
  <v-row>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <v-col cols=10>
      <v-pagination
        v-if="paginationLength > 1"
        v-model="currentPage"
        :length="paginationLength"
        :total-visible="7"
        :disabled="loading"
      ></v-pagination>

    </v-col>
    <v-col cols=2>
      <v-select
        v-if="paginationLength > 1 || itemsPerPage > 20"
        :items="itemsPerPageOptions"
        label="wyników na stronie"
        v-model="itemsPerPage"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Pagination",
  data() {
    return {
      currentPage: 1,
      paginationLength: 1,
      loading: false,
      itemsPerPage: 20,
      itemsPerPageOptions: [20, 50, 100, 200, 500, 1000],
    };
  },
  computed: {
    ...mapGetters(["FACILITIES_PAGINATION"]),
    ...mapGetters(["FACILITIES_FILTER"]),
  },
  created() {
    this.paginationLength = Math.ceil(
      this.FACILITIES_PAGINATION.total / this.FACILITIES_PAGINATION.itemsPerPage
    );
  },
  methods: {
    paginatePage(pageNumber) {
      this.$store.dispatch("SET_FACILITIES_PAGINATION_CURRENT_PAGE", {
        currentPage: pageNumber,
      });
      this.loading = true;
      this.$store
        .dispatch("FIND_FACILITIES", {
          criteria: this.FACILITIES_FILTER,
          pageNumber: pageNumber,
        })
        .then(() => {
          this.loading = false;
          window.scrollTo(0, 0);
        });
    },
  },
  watch: {
    itemsPerPage(newItemsPerPage) {
      this.loading = true;
      this.$store.dispatch("SET_FACILITIES_PAGINATION_ITEMS_PER_PAGE", {
        itemsPerPage: newItemsPerPage,
      });
      this.$store
        .dispatch("FIND_FACILITIES", {
          criteria: this.FACILITIES_FILTER,
          pageNumber: 1,
        })
        .then(() => {
          this.loading = false;
          window.scrollTo(0, 0);
        });
    },
    currentPage(newVal) {
      this.paginatePage(newVal);
    },
    FACILITIES_PAGINATION() {
      this.currentPage = this.FACILITIES_PAGINATION.currentPage;
      this.paginationLength = Math.ceil(
        this.FACILITIES_PAGINATION.total /
          this.FACILITIES_PAGINATION.itemsPerPage
      );
    },
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-alert
      v-if="FACILITIES_PAGINATION.total===0"
      class="mt-3"
      color="blue"
      border="left"
      elevation="2"
      colored-border
      icon="info"
    >Nie znaleziono obiektów</v-alert>
    <v-card
      class="mt-2"
      v-if="FACILITIES.length > 0 "
    >
      <v-card-subtitle
        v-if="FACILITIES_PAGINATION.total"
        class="py-2"
      >
        Znaleziono obiektów: <strong>{{FACILITIES_PAGINATION.total}}</strong> (strona {{FACILITIES_PAGINATION.currentPage}} z {{paginationLength}})
      </v-card-subtitle>
      <v-data-table
        id="search-result-table"
        :headers="headers"
        :items="FACILITIES"
        :sort-desc="sortByDesc"
        hide-default-footer
        :items-per-page=99999
        :options.sync="options"
        :loading="loading"
        loader-height="5"
        :sort-by="sortBy"
        :custom-sort="customSort"
        dense
        @dblclick:row="editRow"
      >
        <template v-slot:[`item.index`]="{index}">
          {{ ((FACILITIES_PAGINATION.currentPage - 1) * FACILITIES_PAGINATION.itemsPerPage) +  index + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="text-align: right">
            <v-btn
              small
              depressed
              color="primary"
              @click="edit(item)"
              @click.prevent
              :href="getEditUrl(item)"
            >
              edytuj
            </v-btn>

            <!--<v-btn small depressed color="red" dark @click="removeDomain(item)">
              usuń
            </v-btn>-->
          </div>
        </template>
        <template v-slot:[`item.is_new_client`]="{ item }">
          <span v-if="item.is_new_client === 1">N</span>
        </template>
      </v-data-table>

      <Pagination />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "./FacilitySearchResult/Pagination";

export default {
  name: "FacilitySearchResult",
  components: {
    Pagination,
  },
  data() {
    return {
      headers: [
        { text: "#", value: "index", sortable: false, width: 36 },
        { text: "id", value: "external_id", width: 45 },
        { text: "P", value: "priority", width: 36 },
        { text: "+", value: "priority_extra", width: 35 },
        { text: "N", value: "is_new_client", width: 37 },
        { text: "Emisja do", value: "subscription_end", width: 90 },
        { text: "Nazwa", value: "name" },
        { text: "Następny kontakt", value: "next_contact_at", width: 126 },
        { text: "Cel kontaktu", value: "next_contact_purpose", width: 260 },
        { text: "Www do", value: "www_expires_at", width: 90 },
        { text: "", value: "actions", sortable: false },
      ],
      options: {
        
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["FACILITIES"]),
    ...mapGetters(["FACILITIES_PAGINATION"]),
    ...mapGetters(["FACILITIES_FILTER"]),
    ...mapGetters(["FACILITY_SEARCH_SORT_BY"]),
    ...mapGetters(["FACILITY_SEARCH_SORT_DESC"]),
    paginationLength() {
      return Math.ceil(
        this.FACILITIES_PAGINATION.total /
          this.FACILITIES_PAGINATION.itemsPerPage
      );
    },
    sortBy() {
      if (this.FACILITY_SEARCH_SORT_BY) {
        return this.FACILITY_SEARCH_SORT_BY;
      } else {
        return "next_contact_at";
      }
    },
    sortByDesc() {
      if (this.FACILITY_SEARCH_SORT_DESC) {
        return this.FACILITY_SEARCH_SORT_DESC;
      } else {
        return false;
      }
    },
  },
  methods: {
    editRow(row, { item }) {
      this.edit(item);
    },
    edit(item) {
      this.$router.push({
        name: "facilityEdit",
        params: { id: item.external_id },
      });
    },
    editInNewTab(item) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: item.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    getEditUrl(item) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: item.external_id },
      });
      return routeData.href;
    },
    sort() {
      this.loading = true;
      this.$store
        .dispatch("SORT_FACILITIES", {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        })
        .then(() => {
          
          this.$store.dispatch("FIND_FACILITIES", {}).then(() => {
            this.loading = false;
          });
        });
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index[0]] == "undefined") {
          return 1;
        }

        if (!isDesc[0]) {
          return a[index[0]] > b[index[0]] ? 1 : -1;
        } else {
          return b[index[0]] > a[index[0]] ? 1 : -1;
        }
      });
      return items;
    },
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.$nextTick(()=> {
          this.sort();
        }); 
      },
    },
  },
};
</script>


<style>
#search-result-table .text-start {
  padding: 0 5px !important;
}
</style>
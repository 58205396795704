<template>
  <v-form>
    <v-btn
      color="success"
      @click="openBankAccountForm()"
      absolute
      right
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-simple-table
      dense
      v-if="facility.facility.bankAccounts.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>swift</th>
            <th>iban</th>
            <th>numer konta</th>
            <th>waluta</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <ClientBankAccountRow
            v-for="bankAccount in facility.facility.bankAccounts"
            :key="bankAccount.id"
            :bankAccount="bankAccount"
            v-on:bankAccount:remove="removeBankAccount"
          />
        </tbody>
      </template>
    </v-simple-table>
    <ClientBankAccountForm
      v-on:bankAccount:update="addBankAccount"
      ref="bankAccountForm"
    />
    <div
      v-if="facility.facility.bankAccounts.length === 0"
      class="px-2"
    >brak numerów kont bankowych</div>
  </v-form>
</template>

<script>
import ClientBankAccountRow from "./ClientBankAccountRow";
import ClientBankAccountForm from "./ClientBankAccountForm";
import { mapGetters } from "vuex";

export default {
  name: "ClientBankAccount",
  props: ["facility"],
  components: {
    ClientBankAccountRow,
    ClientBankAccountForm,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["BANK_ACCOUNT_CURRENCIES"]),
  },
  methods: {
    addBankAccount: function (bankAccount) {
      bankAccount.id = -Date.now();
      this.facility.facility.bankAccounts.push(bankAccount);
    },
    openBankAccountForm: function () {
      this.$refs.bankAccountForm.open({});
    },
    removeBankAccount: function (bankAccount) {
      this.facility.facility.bankAccounts =
        this.facility.facility.bankAccounts.filter((current) => {
          return current.id !== bankAccount.id;
        });
    },
  },
  mounted() {
    this.$store.dispatch("GET_BANK_ACCOUNT_CURRENCIES");
  },
};
</script>

<style>
</style>
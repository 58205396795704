<template>
  <v-form @keyup.native.enter="filterTemplates">
    <v-row>
      <v-col>
        <v-text-field
          v-model="filter.name_like"
          label="nazwa"
          dense
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="filter.type_id_eq"
          :items="MESSAGE_TEMPLATE_TYPES"
          item-text="name"
          item-value="id"
          label="typ oferty"
          dense
          clearable
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="filter.category_id_eq"
          :items="MESSAGE_TEMPLATE_CATEGORIES"
          item-text="name"
          item-value="id"
          label="kategoria oferty"
          dense
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="2" class="text-center">
        <v-btn
          class="primary"
          @click="filterTemplates()"
        >
          filtruj
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminMessageTemplateSearchForm",
  components: {},
  data: () => ({
    loading: false,
    filter: {},
  }),
  computed: {
    ...mapGetters(["MESSAGE_TEMPLATE_TYPES"]),
    ...mapGetters(["MESSAGE_TEMPLATE_CATEGORIES"]),
  },
  methods: {
    filterTemplates() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      this.$store.dispatch("FILTER_MESSAGE_TEMPLATE", { filter: filter });
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_MESSAGE_TEMPLATE_TYPES").then(() => {
      this.$store.dispatch("GET_MESSAGE_TEMPLATE_CATEGORIES").then(() => {
        this.loading = false;
      });
    });
  },
};
</script>


<style>
</style>
<template>
  <v-form @keyup.native.enter="search">
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-row dense>
      <v-col md="6">
        <v-autocomplete
          v-model="filter.city_id_eq"
          name="city"
          label="miejscowość"
          item-value="id"
          :items="citiesSuggestions"
          :loading="loadingCitiesSuggestions"
          :search-input.sync="citySearch"
          placeholder="wyszukaj miasto"
          hide-no-data
          clearable
          no-filter
          dense
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <strong>{{data.item.text}}</strong> ({{data.item.value.gmina.name}} / {{data.item.value.powiat.name}} / {{data.item.value.voivodeship.name}})
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md=2 class="d-flex justify-center align-center">
        <v-checkbox
          v-model="filter.city_strict"
          label="tylko miasto główne"
          class="ma-0"
          value="1"
        ></v-checkbox>
      </v-col>
      <v-col md=1>
        <v-text-field
          v-model="filter.radius"
          label="promień"
          clearable
          dense
        ></v-text-field>
      </v-col>
      <v-col  md=1>
        <v-text-field
          v-model="filter.priority_eq"
          label="priorytet"
          clearable
          dense
        ></v-text-field>
      </v-col>
      <v-col md=2 class="d-flex justify-center align-center">
        <v-checkbox
          v-model="filter.is_active"
          label="tylko aktywne"
          class="ma-0"
          value="1"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filter.property_type_id_eq"
          :items="PROPERTY_TYPES"
          item-text="name"
          item-value="id"
          dense
          label="rodzaj"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filter.land_id_eq"
          :items="LANDS"
          item-text="name"
          item-value="id"
          dense
          label="kraina"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filter.voivodeship_id_eq"
          :items="VOIVODESHIPS"
          item-text="name"
          item-value="id"
          dense
          label="województwo"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filter.region_id_eq"
          :items="REGIONS"
          item-text="name"
          item-value="id"
          dense
          label="położenie"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col
        md="3"
        class="text-center"
      >
        <v-btn
          color="blue darken-2"
          dark
          @submit="search()"
          @click="search()"
          :loading="loading"
        >
          szukaj
        </v-btn>
        <v-btn
          :color="showStatistic?'green':'white'"
          :dark="showStatistic"
          class="ml-2"
          elevation="1"
          fab
          small
          @click="showStatistic=showStatistic?false:true"
        >
          <v-icon>leaderboard</v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="ml-2"
          elevation="1"
          fab
          small
          @click="clearForm"
        >
          <v-icon>cleaning_services</v-icon>
        </v-btn>
      </v-col>

    </v-row>
    <v-row v-if="LISTINGS_INFO.stats && showStatistic">
      <v-col>
        Znaleziono ofert: {{ LISTINGS_INFO.total }}
      </v-col>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(capacity,index) in LISTINGS_INFO.stats"
                  :key="index"
                >
                  {{index}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="capacity in LISTINGS_INFO.stats"
                  :key="capacity.id"
                >
                  {{capacity}}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "ListingSearchForm",
  components: {},
  data() {
    return {
      datepickerNextContactGt: false,
      datepickerNextContactLt: false,
      datepickerSubscriptionEndGt: false,
      datepickerSubscriptionEndLt: false,
      datepickerAgreedAtGt: false,
      datepickerAgreedAtLt: false,
      citySearch: null,
      citiesSuggestions: [],
      filter: {},
      showStatistic: false,
      loading: false,
      loadingCitiesSuggestions: false,
      loadingCitiesSuggestionsTimer: null,
    };
  },
  computed: {
    ...mapGetters(["PROPERTY_TYPES"]),
    ...mapGetters(["LANDS"]),
    ...mapGetters(["VOIVODESHIPS"]),
    ...mapGetters(["REGIONS"]),
    ...mapGetters(["LISTINGS_INFO"]),
    ...mapGetters(["LISTINGS_FILTER"]),
  },
  methods: {
    search() {
      this.loading = true;
      this.$store
        .dispatch("GET_LISTINGS", {
          filter: JSON.parse(JSON.stringify(this.filter)),
        })
        .then(() => {
          this.$store.dispatch("CLEAR_FACILITY_SEARCH");
          this.loading = false;
        });
    },
    clearForm() {
      this.filter = {};
    },
    searchCityById(id) {
      if (!id) return;

      this.loadingCitiesSuggestions = true;

      axios
        .get(`/cities?filter[id_eq]=` + id, {})
        .then((response) => {
          var data = [];
          for (let key in response.data) {
            var city = response.data[key];
            data.push({
              text: city.name,
              value: city,
              id: city.id,
            });
          }

          this.citiesSuggestions = data;
        })
        .finally(() => (this.loadingCitiesSuggestions = false));
    },
    loadCitySuggestions(val) {
      clearTimeout(this.loadingCitiesSuggestionsTimer);

      this.loadingCitiesSuggestionsTimer = setTimeout(() => {

        this.loadingCitiesSuggestions = true;
        axios
          .get(`/cities?filter[name_like]=` + val, {})
          .then((response) => {
            var data = [];
            for (let key in response.data) {
              var city = response.data[key];
              data.push({
                text: city.name,
                value: city,
                id: city.id,
              });
            }

            this.citiesSuggestions = data;
          })
          .finally(() => (this.loadingCitiesSuggestions = false));

      }, 500);
    },
  },
  mounted() {
    this.$store.dispatch("GET_PROPERTY_TYPES");
    this.$store.dispatch("GET_LANDS");
    this.$store.dispatch("GET_REGIONS");
    this.$store.dispatch("GET_VOIVODESHIPS");
    if (Object.keys(this.LISTINGS_FILTER).length > 0) {
      this.filter = Object.assign(this.filter, this.LISTINGS_FILTER);
      if (this.filter.city_id_eq) {
        this.searchCityById(this.filter.city_id_eq);
      }
    }
  },
  watch: {
    citySearch(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loadCitySuggestions(val);
    },
  },
};
</script>


<style>
</style>
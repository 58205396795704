<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="600"
  >

    <v-card v-if="notification.id">

      <v-card-subtitle class="pt-4">
        <div class="text-caption">
          UTWORZONO: {{ notification.created_at }}
        </div>
        <div class="text-body-1 pt-3">
          {{ notification.content }}
        </div>

      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          @click="dialog = false"
        >ok</v-btn>
        <v-btn
          v-if="notification.facility"
          color="primary"
          small
          @click="goToFacility(notification.facility)"
        >przejdź do obiektu {{ notification.facility.id }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserNotificationShowDialog",
  data: () => ({
    dialog: false,
    notification: {},
  }),
  components: {},
  computed: {},
  mounted() {},
  methods: {
    openDialog(notification) {
      this.notification = notification;
      this.dialog = true;
    },
    closeDialog() {
      this.notification = {};
      this.dialog = false;
    },
    goToFacility(facility){
      this.dialog = false;
      this.$router.push({
        name: "facilityEdit",
        params: { id: facility.id },
      });
    }
  },
  watch: {},
};
</script>


<style>
</style>
<template>
  <div class="mb-2">

    <v-tooltip
      v-for="button in buttons"
      :key="button.name"
      top
      color="blue"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="mr-1 px-1 mb-1 quick-text-button"
          color="blue"
          dark
          @click="insertText(button.template)"
          v-bind="attrs"
          v-on="on"
        >
          {{ button.name }}
        </v-btn>
      </template>
      <span>{{ button.template }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SendSMSQuickText",
  props: ["facility"],
  data() {
    return {
      buttons: [
        {
          name: "KONTAKT",
          template: "Proszę o pilny kontakt. Pozdrawiam, [user.name]",
        },
        {
          name: "POZDRAWIAM",
          template: "Pozdrawiam, [user.name]",
        },
        {
          name: "FAKTURA - B/Z",
          template:"Brak wpłaty, faktura z , kwota PLN, konto 78858900060000002676180001, tytułem: Reklama nr [facility.id], dział księgowości Meteor",
        },
        {
          name: "FAKTURA - W",
          template: "Faktura  z  na e-mailu, do zapłaty  PLN, konto 78858900060000002676180001, przelew tytułem:  Reklama nr [facility.id], Pozdrawiam, [user.name]",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  mounted() {
    if (this.CURRENT_USER.sms_mini_templates) {
        this.buttons = this.CURRENT_USER.sms_mini_templates;
      }
  },
  methods: {
    insertText(text) {

      this.$emit("insertText", this.renderTemplate(text));
    },
    renderTemplate(template){
      return template.replace(/\[user.name\]/g, this.CURRENT_USER.name)
                     .replace(/\[facility.id\]/g, this.facility.id);
    }
  },
  watch: {
  
  },
};
</script>


<style>
.quick-text-button {
  min-width: 40px !important;
}
@media (max-width: 1300px) {
  .quick-text-button {
    min-width: 23px !important;
  }
}
</style>
<template>
  <v-row
    dense
    v-if="invoices.length > 0"
    justify="center"
    class="mb-1"
  >
    <v-col cols=12>
      <v-data-table
        id="subscription-invoice-table"
        :headers="invoiceHeaders"
        :items="invoices"
        hide-default-footer
        dense
        :expanded.sync="expanded"
        sort-by="payed_at"
        :custom-sort="customSort"
        :items-per-page="15"
        show-expand
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            class="status-chip"
            :color="getStatusColor(item.status)"
            :disabled="item.correction_delivered_at !== null"
            outlined
            small
          >
            <span v-if="item.status !== 0">{{ INVOICE_STATUSES[item.status] }}</span><span v-if="item.status==0">wystaw {{item.to_be_issued_at}}</span>
            <v-icon
              v-if="item.status === 1 && item.invoice_payments.length > 0"
              color="green"
            >hourglass_top</v-icon>
          </v-chip>
        </template>
        <template v-slot:item.number="{ item }">
          <v-chip
            small
            color="red"
            v-if="item.is_proforma && !item.number"
            dark
            class="mr-1"
          >P</v-chip>
          <v-chip
            small
            color="blue darken-4"
            v-if="item.related_invoices.length > 0 "
            dark
            class="mr-1"
          >G</v-chip>
          <v-chip
            small
            color="blue"
            v-if="item.parent_invoice"
            dark
            class="mr-1"
          >Z</v-chip>

          {{ item.is_proforma && !item.number ? item.proforma_number  : item.correction_number ? item.correction_number : item.number }}
        </template>
        <template v-slot:item.total_amount="{ item }">
          <div v-if="item.correction_number">0</div>
          <div v-if="item.invoice_entries.length > 0 && !item.correction_number">
            {{ totalAmount(item.invoice_entries) | formatAmount }}
          </div>
        </template>
        <template v-slot:item.total="{ item }">
          <div v-if="item.correction_number">0</div>
          <div v-if="item.invoice_entries.length > 0 && !item.correction_number">
            {{ total(item.invoice_entries) | formatAmount }}
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <v-container v-if="item.status === 3">
              <v-row
                class="text-center"
                dense
                no-gutters
              >
                <v-col md="3">
                  <div class="text-caption">Skorygowano:</div>
                  <v-chip
                    class="ma-2"
                    color="black"
                    label
                    outlined
                  >{{ item.corrected_at }}</v-chip>
                </v-col>
                <v-col md="3">
                  <div class="text-caption">Faktura korygująca:</div>
                  <v-chip
                    class="ma-2"
                    color="black"
                    label
                    outlined
                  >{{ item.correction_number }}</v-chip>
                </v-col>
                <v-col
                  md="6"
                  v-if="item.correction_delivered_at"
                >
                  <div class="text-caption">Potwierdzono:</div>
                  <v-chip
                    class="ma-2"
                    color="black"
                    label
                    outlined
                  >{{ item.correction_delivered_at }}</v-chip>
                </v-col>

              </v-row>
              <v-divider class="mt-4"></v-divider>
            </v-container>
            <v-container>
              <v-row
                class="text-center"
                dense
              >
                <v-col>
                  <div class="text-caption">Utworzono:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.created_at }}</v-chip>
                </v-col>
                <v-col v-if="item.status === 3">
                  <div class="text-caption">Faktura:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.number }}</v-chip>
                </v-col>
                <v-col v-if="!item.issued_at">
                  <div class="text-caption">Wystaw:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.to_be_issued_at }}</v-chip>
                </v-col>
                <v-col v-if="item.issued_at">
                  <div class="text-caption">Wystawiono:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.issued_at }}</v-chip>
                </v-col>
                <v-col>
                  <div class="text-caption">Termin płatności:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.to_be_payed_in }} dni</v-chip>
                </v-col>
                <v-col v-if="item.payed_at">
                  <div class="text-caption">Zapłacono:</div>
                  <v-chip
                    class="ma-2"
                    color="green darken-4"
                    label
                    outlined
                  >{{ item.payed_at }}</v-chip>
                </v-col>

                <v-col cols="12">
                  <v-simple-table
                    v-if="item.invoice_entries.length > 0"
                    dense
                  >
                    <thead>
                      <tr>
                        <th class="text-center">opiekun</th>
                        <th class="text-center">nazwa pozycji</th>
                        <th class="text-center">stawka VAT</th>
                        <th class="text-center">netto</th>
                        <th class="text-center">brutto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="entry in item.invoice_entries"
                        :key="entry.id"
                      >
                        <td>{{ entry.user.username }}</td>
                        <td>{{ entry.product.name }}</td>
                        <td>{{ entry.product.vat }}%</td>
                        <td>{{ entry.amount | formatAmount }}</td>
                        <td>
                          {{entry.amount + (entry.amount * entry.product.vat) / 100 | formatAmount }}
                        </td>
                      </tr>
                      <tr v-if="item.related_invoices.length > 0 ">
                        <td></td>
                        <td>+ faktury związane</td>
                        <td></td>
                        <td>{{ relatedInvoicesAmount(item.related_invoices) | formatAmount }}</td>
                        <td>{{ relatedInvoicesAmount(item.related_invoices) + relatedInvoicesTax(item.related_invoices) | formatAmount }}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="font-weight-bold">
                          {{ (item.related_invoices.length > 0 ? totalAmount(item.invoice_entries) + relatedInvoicesAmount(item.related_invoices) : totalAmount(item.invoice_entries)) | formatAmount }}
                        </td>
                        <td class="font-weight-bold red--text">
                          {{ (item.related_invoices.length > 0 ? total(item.invoice_entries) + relatedInvoicesAmount(item.related_invoices) + relatedInvoicesTax(item.related_invoices) : total(item.invoice_entries)) | formatAmount }}
                        </td>
                      </tr>
                      <template v-if="item.invoice_payments && item.invoice_payments.length > 0">
                        <tr
                          v-for="payment in item.invoice_payments"
                          :key="payment.id"
                          class="light-green lighten-4 green--text font-weight-bold"
                        >
                          <td
                            colspan="4"
                            class="text-right"
                          >WPŁATA {{ payment.payed_at }}</td>
                          <td>{{ payment.amount | formatAmount }} PLN</td>
                        </tr>

                        <tr>
                          <td
                            colspan="4"
                            class="text-right"
                          >zapłacono</td>
                          <td>{{ totalPaid(item.invoice_payments) | formatAmount }} PLN</td>
                        </tr>

                        <tr v-if="(total(item.invoice_entries) - totalPaid(item.invoice_payments)) > 0">
                          <td
                            colspan="4"
                            class="text-right"
                          >pozostało</td>
                          <td>{{ total(item.invoice_entries) - totalPaid(item.invoice_payments) | formatAmount }} PLN</td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              <InvoiceOptions
                v-if="item.status !== 3 && !item.parent_invoice"
                :invoice="item"
                :subscription="subscription"
                :facility="facility"
                v-on:invoice-removed="removeInvoiceFromList"
              />
            </v-container>
          </td>
        </template>
        <template slot="body.append">
          <tr v-if="invoices.length > 1">
            <th></th>
            <th></th>
            <th class="text-center">suma</th>
            <th>{{ totalAmountSum | formatAmount }}</th>
            <th>{{ totalSum | formatAmount }}</th>
            <th></th>
            <th></th>
          </tr>
        </template>
      </v-data-table>

    </v-col>

  </v-row>
</template>

<script>
import InvoiceOptions from "./InvoiceOptions.vue";
import { mapGetters } from "vuex";

export default {
  components: { InvoiceOptions },
  props: ["invoices", "subscription", "facility"],
  name: "InvoiceList",
  data() {
    return {
      invoiceHeaders: [
        {
          text: "status",
          value: "status",
        },
        {
          text: "numer",
          value: "number",
        },
        {
          text: "netto",
          value: "total_amount",
        },
        {
          text: "brutto",
          value: "total",
        },
        {
          text: "wystawiono",
          value: "issued_at",
        },
        {
          text: "zapłacono",
          value: "payed_at",
        },
      ],
      expanded: [],
    };
  },
  computed: {
    ...mapGetters(["INVOICE_STATUSES"]),
    totalSum() {
      let sum = 0;
      this.invoices.forEach((invoice) => {
        if (!invoice.correction_number) {
          sum += this.total(invoice.invoice_entries);
        }
      });
      return sum;
    },
    totalAmountSum() {
      let sum = 0;
      this.invoices.forEach((invoice) => {
        if (!invoice.correction_number) {
          sum += this.totalAmount(invoice.invoice_entries);
        }
      });
      return sum;
    },
  },
  mounted() {},
  methods: {
    totalPaid: function (payments) {
      return payments.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalAmount: function (entries) {
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    relatedInvoicesAmount: function (invoices) {
      return invoices.reduce((acc, val) => {
        return acc + this.totalAmount(val.invoice_entries);
      }, 0);
    },
    relatedInvoicesTax: function (invoices) {
      return invoices.reduce((acc, val) => {
        return acc + this.totalTax(val.invoice_entries);
      }, 0);
    },
    totalTax: function (entries) {
      return entries.reduce((acc, val) => {
        let tax = (parseInt(val.amount) * parseInt(val.product.vat)) / 100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      return this.totalAmount(entries) + this.totalTax(entries);
    },
    getStatusColor(status) {
      let colors = [];
      colors[0] = "orange darken-4";
      colors[1] = "blue darken-4";
      colors[2] = "green darken-4";
      colors[3] = "red darken-4";
      return colors[status];
    },
    removeInvoiceFromList(invoice) {
      this.$emit("invoice-removed", invoice);
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index[0]] == "undefined") {
          return 1;
        }

        if (!isDesc[0]) {
          return a[index[0]] > b[index[0]] ? 1 : -1;
        } else {
          return b[index[0]] > a[index[0]] ? 1 : -1;
        }
      });
      return items;
    },
  },
  watch: {},
};
</script>


<style>
#invoiceListExpansionPanels .v-expansion-panel-content__wrap {
  padding: 0;
}
#subscription-invoice-table th,
#subscription-invoice-table td {
  padding: 0 2px;
}
.status-chip {
  padding: 0 6px !important;
}
</style>
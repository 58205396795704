<template>
  <div>
    <v-switch
      v-model="user.options.defaultNextContactSearch"
      label="wyszukaj na start moje obiekty do kontaktu"
    ></v-switch>
  </div>          
</template>

<script>

export default {
  name: "UserEditOptions",
  props: ["user"],
  components: {
    
  },
  data: () => ({

  }),
  computed: {

  },
  methods: {
    
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
<template>
  <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
       
          <v-card>
            <v-toolbar
              color="error"
              dark
            >Uwaga!</v-toolbar>
            <v-card-text>
              <div v-if="error" class="headline pt-6">{{error}}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog = false;errorAccepted()"
              >OK</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
</template>

<script>

export default {
  props: ["error"],
  name: "ErrorMessage",
  data() {
    return {
      dialog: false
    };
  },
  computed: {
  },
  methods: {
    errorAccepted(){
      this.$emit("error-accepted");
    }
  },
  mounted() {
    if(this.error){
        this.dialog = true;
      }
  },
  watch: {
    error: function(error){
      if(error){
        this.dialog = true;
      }
    }
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card>
        <v-container>
          <v-form>
            <v-row flat>
              <v-col cols=12>
                <v-text-field
                  dense
                  label="hasło"
                  v-model="newPassword"
                  :append-icon="newPasswordShow ? 'visibility' : 'visibility_off'"
                  @click:append="newPasswordShow = !newPasswordShow"
                  :type="newPasswordShow ? 'text' : 'password'"
                  :error-messages="newPasswordErrors"
                />
              </v-col>
              <v-col cols=12>
                <v-text-field
                  dense
                  label="powtórz hasło"
                  v-model="reNewPassword"
                  :append-icon="reNewPasswordShow ? 'visibility' : 'visibility_off'"
                  @click:append="reNewPasswordShow = !reNewPasswordShow"
                  :type="reNewPasswordShow ? 'text' : 'password'"
                  :error-messages="reNewPasswordErrors"
                />
              </v-col>
            </v-row>

          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-1"
              dark
              @click="save()"
            >
              zmień
            </v-btn>

            <v-btn
              color="warning"
              @click="dialog=false"
            >
              anuluj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  components: {},
  name: "UserPasswordForm",
  data: () => ({
    data: {},
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
    newPassword: "",
    newPasswordShow: false,
    reNewPassword: "",
    reNewPasswordShow: false,
  }),
  validations: {
    newPassword: {
      required,
      minLength: minLength(8),
    },
    reNewPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  computed: {
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required && errors.push("podaj hasło");
      !this.$v.newPassword.minLength &&
        errors.push("hasło powinno składać się z minimum 8 znaków");
      return errors;
    },
    reNewPasswordErrors() {
      const errors = [];
      if (!this.$v.reNewPassword.$dirty) return errors;
      !this.$v.reNewPassword.sameAsPassword &&
        errors.push("hasła muszą być takie same");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.data.password = this.newPassword;
      
      this.loading = true;
      this.$store
        .dispatch("UPDATE_USER", {
          user: this.data,
        })
        .then(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
<template>
  <v-form @keyup.native.enter="search">
    <v-row dense>
      <v-col
        md="1"
        class="py-0"
      >
        <v-text-field
          v-model="filter.id_eq"
          label="id"
          clearable
          dense
          :background-color="(filter.id_eq) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col
        md="1"
        class="py-0"
      >
        <v-text-field
          v-model="filter.priority_eq"
          label="pr"
          clearable
          dense
          append-outer-icon="attach_money"
          :background-color="(filter.priority_eq) ? 'light-blue lighten-5' : ''"
          @click:append-outer="onlyClientsFilter"
        ></v-text-field>
      </v-col>
      <v-col
        md="1"
        class="py-0"
      >
        <v-text-field
          v-model="filter.priority_extra_eq"
          label="pr +"
          clearable
          dense
          :background-color="(filter.priority_extra_eq) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col
        md="2"
        class="py-0"
      >
        <v-select
          v-model="filter.client_status_eq"
          :items="clientStatuses"
          label="typ klienta"
          clearable
          dense
          :background-color="(filter.client_status_eq) ? 'light-blue lighten-5' : ''"
        >
        </v-select>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerSubscriptionEndGt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.external.subscription_end_gt"
              label="emisja od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="subscriptionEndGtErrors"
              :background-color="(filter.external.subscription_end_gt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.external.subscription_end_gt"
            @change="datepickerSubscriptionEndGt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerSubscriptionEndLt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.external.subscription_end_lt"
              label="emisja do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="subscriptionEndLtErrors"
              :background-color="(filter.external.subscription_end_lt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.external.subscription_end_lt"
            @change="datepickerSubscriptionEndLt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        md="3"
        class="py-0"
      >
        <v-autocomplete
          v-model="filter.supervisor_id_eq"
          :items="operatorsFiltered"
          :item-text="user => {return user.name + ' (' + user.username +')'}"
          item-value="id"
          label="doradca biznesowy"
          clearable
          dense
          :background-color="(filter.supervisor_id_eq) ? 'light-blue lighten-5' : ''"
          :menu-props="{ maxHeight: 570, offsetOverflow: true }"
        >
        </v-autocomplete>
      </v-col>

    </v-row>
    <v-row dense>
      <v-col class="py-0">
        <v-text-field
          v-model="filter.client_name_like"
          label="zleceniodawca"
          clearable
          dense
          :background-color="(filter.client_name_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>

      <v-col class="py-0" md=1>
        <v-text-field
          v-model="filter.external.phone_like"
          label="telefon"
          clearable
          dense
          :background-color="(filter.external.phone_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>

      <v-col class="py-0">
        <v-text-field
          v-model="filter.external.email_like"
          label="e-mail"
          :background-color="(filter.external.email_like) ? 'light-blue lighten-5' : ''"
          clearable
          dense
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerNextContactGt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.next_contact_gt"
              label="kolejny kontakt od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="nextContactGtErrors"
              :background-color="(filter.next_contact_gt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.next_contact_gt"
            @change="datepickerNextContactGt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerNextContactLt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.next_contact_lt"
              label="kolejny kontakt do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="filter.next_contact_lt  = null"
              dense
              :background-color="(filter.next_contact_lt && filter.next_contact_lt.length > 0) ? 'light-blue lighten-5' : ''"
              :error-messages="nextContactLtErrors"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.next_contact_lt"
            @change="datepickerNextContactLt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        md="3"
        cols=12
        class="text-center py-0"
      >
        <v-btn
          color="blue"
          dark
          @submit="search()"
          @click="search()"
          :loading="loading"
        >
          szukaj
        </v-btn>
        <v-btn
          color="white"
          class="ml-2"
          elevation="1"
          @click="clearForm"
        >
          <v-icon>cleaning_services</v-icon>
        </v-btn>
        <v-btn
          :color="!advancedSearch?'white':'amber lighten-4'"
          class="ml-2"
          elevation="1"
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon v-if="!advancedSearch">expand_more</v-icon>
          <v-icon v-if="advancedSearch">expand_less</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="advancedSearch"
      dense
    >
      <v-col
        class="py-0"
        cols="1"
      >
        <v-text-field
          v-model="filter.roomadmin_id_eq"
          label="ra id"
          clearable
          dense
          :background-color="(filter.roomadmin_id_eq) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="filter.name_like"
          label="nazwa"
          clearable
          dense
          :background-color="(filter.name_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="filter.address_like"
          label="adres"
          clearable
          dense
          :background-color="(filter.address_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-autocomplete
          v-model="filter.external.city_id_eq"
          name="city"
          label="miejscowość"
          item-value="id"
          :items="citiesSuggestions"
          :loading="loadingCitiesSuggestions"
          :search-input.sync="citySearch"
          placeholder="wyszukaj miasto"
          hide-no-data
          clearable
          dense
          :background-color="(filter.external.city_id_eq) ? 'light-blue lighten-5' : ''"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <strong>{{data.item.text}}</strong> ({{data.item.value.gmina.name}} / {{data.item.value.powiat.name}} / {{data.item.value.voivodeship.name}})
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col class="py-0">
        <v-text-field
          v-model="filter.external.www_like"
          label="www"
          clearable
          dense
          :background-color="(filter.external.www_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="filter.external.description_like"
          label="opis zawiera"
          clearable
          dense
          :background-color="(filter.external.description_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
      <v-col
        md="4"
        cols=12
        class="py-0"
      >
        <ExtraFilter :filter="filter" />
      </v-col>
    </v-row>
    <v-row
      v-if="advancedSearch"
      dense
    >
      <v-col class="py-0">
        <v-checkbox
          v-model="filter.is_agreed"
          label="uzgodniony"
          dense
        ></v-checkbox>
      </v-col>
      <v-col class="py-0">
        <v-checkbox
          v-model="filter.is_not_agreed"
          label="nie uzgodniony"
          dense
        ></v-checkbox>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerAgreedAtGt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.agreed_at_gt"
              label="uzgodniono od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="agreedAtGtErrors"
              :background-color="(filter.agreed_at_gt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.agreed_at_gt"
            @change="datepickerAgreedAtGt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerAgreedAtLt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.agreed_at_lt"
              label="uzgodniono do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="agreedAtLtErrors"
              :background-color="(filter.agreed_at_lt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.agreed_at_lt"
            @change="datepickerAgreedAtLt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerAddedAtGt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.registration_at_gt"
              label="zgłoszenie od"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="addedAtGtErrors"
              :background-color="(filter.registration_at_gt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.registration_at_gt"
            @change="datepickerAddedAtGt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="datepickerAddedAtLt"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="filter.registration_at_lt"
              label="zgłoszenie do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              dense
              :error-messages="addedAtLtErrors"
              :background-color="(filter.registration_at_lt) ? 'light-blue lighten-5' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            :first-day-of-week="1"
            v-model="filter.registration_at_lt"
            @change="datepickerAddedAtLt = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0">
        <v-text-field
          v-model="filter.invoice_data_like"
          label="dane do faktury"
          clearable
          dense
          :background-color="(filter.invoice_data_like) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>

      <v-col class="py-0">
        <v-text-field
          v-model="filter.nip_eq"
          label="NIP"
          clearable
          dense
          :background-color="(filter.nip_eq) ? 'light-blue lighten-5' : ''"
        ></v-text-field>
      </v-col>
    </v-row>

  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

import ExtraFilter from "./FacilitySearch/ExtraFilter.vue";


export default {
  name: "FacilitySearchForm",
  components: {
    ExtraFilter
  },
  data() {
    return {
      datepickerNextContactGt: false,
      datepickerNextContactLt: false,
      datepickerSubscriptionEndGt: false,
      datepickerSubscriptionEndLt: false,
      datepickerAgreedAtGt: false,
      datepickerAgreedAtLt: false,
      datepickerAddedAtGt: false,
      datepickerAddedAtLt: false,
      clientStatuses: [
        { text: "wszyscy klienci", value: 2 },
        { text: "stali klienci", value: 0 },
        { text: "nowi klienci", value: 1 },
      ],
      filter: {
        external: {},
        priority_eq: "",
        supervisor_id_eq: null,
      },
      loading: false,
      loadingCitiesSuggestions: false,
      citiesSuggestions: [],
      citySearch: null,
      advancedSearch: false,
    };
  },
  validations: {
    filter: {
      next_contact_gt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.next_contact_lt ||
            this.filter.next_contact_lt.length === 0
          )
            return true;
          return new Date(val) <= new Date(this.filter.next_contact_lt);
        },
      },
      next_contact_lt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.next_contact_gt ||
            this.filter.next_contact_gt.length === 0
          )
            return true;
          return new Date(val) >= new Date(this.filter.next_contact_gt);
        },
      },
      agreed_at_gt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.agreed_at_lt ||
            this.filter.agreed_at_lt.length === 0
          )
            return true;
          return new Date(val) <= new Date(this.filter.agreed_at_lt);
        },
      },
      agreed_at_lt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.agreed_at_gt ||
            this.filter.agreed_at_gt.length === 0
          )
            return true;
          return new Date(val) >= new Date(this.filter.agreed_at_gt);
        },
      },
      registration_at_gt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.registration_at_lt ||
            this.filter.registration_at_lt.length === 0
          )
            return true;
          return new Date(val) <= new Date(this.filter.registration_at_lt);
        },
      },
      registration_at_lt: {
        range(val) {
          if (
            !val ||
            val.length === 0 ||
            !this.filter.registration_at_gt ||
            this.filter.registration_at_gt.length === 0
          )
            return true;
          return new Date(val) >= new Date(this.filter.registration_at_gt);
        },
      },
      external: {
        subscription_end_gt: {
          range(val) {
            if (
              !val ||
              val.length === 0 ||
              !this.filter.external.subscription_end_lt ||
              this.filter.external.subscription_end_lt.length === 0
            )
              return true;
            return (
              new Date(val) < new Date(this.filter.external.subscription_end_lt)
            );
          },
        },
        subscription_end_lt: {
          range(val) {
            if (
              !val ||
              val.length === 0 ||
              !this.filter.external.subscription_end_gt ||
              this.filter.external.subscription_end_gt.length === 0
            )
              return true;
            return (
              new Date(val) > new Date(this.filter.external.subscription_end_gt)
            );
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters(["OPERATORS"]),
    ...mapGetters(["FACILITIES_FILTER"]),
    ...mapGetters(["CURRENT_USER"]),
    ...mapGetters(["FACILITY_SEARCH_FORM_SETTINGS"]),
    nextContactGtErrors() {
      const errors = [];
      if (!this.$v.filter.next_contact_gt.$dirty) return errors;
      !this.$v.filter.next_contact_gt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    nextContactLtErrors() {
      const errors = [];
      if (!this.$v.filter.next_contact_lt.$dirty) return errors;
      !this.$v.filter.next_contact_lt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    subscriptionEndGtErrors() {
      const errors = [];
      if (!this.$v.filter.external.subscription_end_gt.$dirty) return errors;
      !this.$v.filter.external.subscription_end_gt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    subscriptionEndLtErrors() {
      const errors = [];
      if (!this.$v.filter.external.subscription_end_lt.$dirty) return errors;
      !this.$v.filter.external.subscription_end_lt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    agreedAtGtErrors() {
      const errors = [];
      if (!this.$v.filter.agreed_at_gt.$dirty) return errors;
      !this.$v.filter.agreed_at_gt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    agreedAtLtErrors() {
      const errors = [];
      if (!this.$v.filter.agreed_at_lt.$dirty) return errors;
      !this.$v.filter.agreed_at_lt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    addedAtGtErrors() {
      const errors = [];
      if (!this.$v.filter.registration_at_gt.$dirty) return errors;
      !this.$v.filter.registration_at_gt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    addedAtLtErrors() {
      const errors = [];
      if (!this.$v.filter.registration_at_lt.$dirty) return errors;
      !this.$v.filter.registration_at_lt.range &&
        errors.push("niepoprawny przedział dat");
      return errors;
    },
    operatorsFiltered() {
      return this.OPERATORS;
      /*if (this.$acl.check("admin")) {
        return this.OPERATORS;
      } else {
        return this.OPERATORS.filter((user) => {
          return user.id === this.CURRENT_USER.id;
        });
      }*/
    },
  },
  methods: {
    search() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      this.loading = true;

      
      this.$store
        .dispatch("SORT_FACILITIES", {
          sortBy: this.filter.supervisor_id_eq ? 'next_contact_at' : 'priority',
          sortDesc: this.filter.supervisor_id_eq ? false : true,
        })
        .then(() => {
          this.$store
          .dispatch("FILTER_FACILITIES", {
            filter: JSON.parse(JSON.stringify(this.filter)),
          })
          .then(() => {
            this.$store
              .dispatch("FIND_FACILITIES", {
                filter: JSON.parse(JSON.stringify(this.filter)),
              })
              .then(() => {
                this.loading = false;
              });
          });
        });
    },
    clearForm() {
      const filter = {
        external: {},
        priority_eq: "",
        supervisor_id_eq: null,
        is_not_agreed: false,
        is_agreed: false,
      };
      this.filter =  JSON.parse(JSON.stringify(filter));
        
      this.$store
        .dispatch("SORT_FACILITIES", {
          sortBy: 'priority',
          sortDesc: true,
        }).then(() => {
        this.search();
      });
    },
    onlyClientsFilter() {
      this.filter.priority_eq = "3-13";
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("GET_FACILITY_SEARCH_FORM_SETTINGS").then(() => {
      this.advancedSearch = this.FACILITY_SEARCH_FORM_SETTINGS.advancedSearch;
    });

    this.$store.dispatch("GET_OPERATORS").then(() => {
      if (Object.keys(this.FACILITIES_FILTER).length > 0) {
        this.filter = Object.assign(this.filter, this.FACILITIES_FILTER);
      } else {
        if (this.CURRENT_USER) {
          if (this.CURRENT_USER.options.defaultNextContactSearch) {
            this.filter.supervisor_id_eq = this.CURRENT_USER.id;
            this.filter.next_contact_lt = moment().format("Y-MM-DD");
            this.search();
          }
        }
      }
    });
  },
  updated() {},
  watch: {
    advancedSearch() {
      this.$store.dispatch("SET_FACILITY_SEARCH_FORM_SETTINGS", {
        settings: { advancedSearch: this.advancedSearch },
      });
    },
    citySearch(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loadingCitiesSuggestions = true;

      axios
        .get(`/cities?filter[name_like]=` + val, {})
        .then((response) => {
          var data = [];
          for (let key in response.data) {
            var city = response.data[key];
            data.push({
              text: city.name,
              value: city,
              id: city.id,
            });
          }

          this.citiesSuggestions = data;
        })
        .finally(() => (this.loadingCitiesSuggestions = false));
    },
    "filter.client_status_eq": function (status) {
      if (status !== null) {
        this.onlyClientsFilter();
      } else {
        this.filter.priority_eq = "";
      }
    },
    "filter.is_agreed": function (val) {
      if (val && this.filter.is_not_agreed) {
        this.filter.is_not_agreed = false;
      }
    },
    "filter.is_not_agreed": function (val) {
      if (val && this.filter.is_agreed) {
        this.filter.is_agreed = false;
      }
    },
  },
};
</script>


<style>
</style>
<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >

    <v-card>
      <v-toolbar
        dark
        dense
        color="deep-purple darken-3"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Współpracownicy</v-toolbar-title>

      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <CoworkersStatusCalendarVue ref="coworkersStatusCalendar" />
      </v-card-text>
    </v-card>

  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import CoworkersStatusCalendarVue from "./CoworkersStatusCalendar.vue";
export default {
  name: "CoworkersStatusDialog",
  data: () => ({
    dialog: false,
    loading: false,
    ss: false,
  }),
  components: { CoworkersStatusCalendarVue },
  computed: {
    ...mapGetters(["USERS"]),
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_USERS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.coworkersStatusCalendar.getData();
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    calculateMinutesFromNow(date) {
      if (!date) {
        return null;
      }
      let exp = moment(date);
      return moment().diff(exp, "minutes");
    },
    calculateFromNow(date) {
      if (!date) {
        return "";
      }
      moment.locale("pl");
      return moment(date).fromNow();
    },
    activityColor(user) {
      if (!user.last_activity_at) {
        return "";
      }
      let exp = moment(user.last_activity_at);
      let minutes = moment().diff(exp, "minutes");
      return minutes < 10 ? "green" : minutes < 30 ? "orange" : "red";
    },
  },
  watch: {},
};
</script>


<style>
</style>
<template>
  <v-card :loading="loading">
    <v-toolbar
      color="blue"
      dark
    >
      <v-toolbar-title>Tworzenie nowej faktury</v-toolbar-title>
    </v-toolbar>

    <v-card-text>

      <v-container>

        <v-simple-table v-if="invoice.invoice_entries && invoice.invoice_entries.length > 0">
          <thead>
            <tr>
              <th class="text-left">opiekun</th>
              <th class="text-left">wykonawca</th>
              <th class="text-left">usługa</th>
              <th class="text-left">nazwa pozycji</th>
              <th class="text-left">stawka VAT</th>
              <th class="text-left">kwota netto</th>
              <th class="text-left">kwota brutto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in invoice.invoice_entries"
              :key="entry.id"
            >
              <td>{{ entry.user.name }} {{ entry.user.surname }}</td>
              <td v-if="entry.developer">{{ entry.developer.name }} {{ entry.developer.surname }}</td>
              <td v-if="!entry.developer"> - </td>
              <td>
                <div v-if="entry.service.id">
                  {{ entry.service.type.name }}
                  <span v-if="entry.service.domain">- {{ entry.service.domain.domain }}</span>
                </div>
              </td>
              <td>{{ entry.invoice_entry_product.name }}</td>
              <td>{{ entry.invoice_entry_product.vat }}%</td>
              <td>{{ entry.amount | formatAmount}} PLN</td>
              <td>
                {{ (entry.amount + ((entry.amount * entry.invoice_entry_product.vat) / 100)) | formatAmount }} PLN
              </td>
              <td>
                <v-icon
                  style="cursor: pointer;"
                  v-on:click="removeEntry(entry)"
                >mdi-close</v-icon>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN
              </td>
              <td class="font-weight-bold red--text">
                {{ total(invoice.invoice_entries) | formatAmount }} PLN
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-center">
          <v-btn
            color="green"
            dark
            @click="isCreateEntryDialogActive=true"
          >dodaj pozycje

            <v-icon> add </v-icon>
          </v-btn>

          <v-dialog
            v-model="isCreateEntryDialogActive"
            max-width="800"
          >
            <InvoiceCreateEntry
              :owner="owner"
              :invoice="invoice"
              v-on:cancel="isCreateEntryDialogActive = false"
              v-on:entry-created="isCreateEntryDialogActive = false"
              v-on:add-entry="addEntry"
            />
          </v-dialog>

        </div>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="invoice.comment"
                label="komentarz dla klienta"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="invoice.comment_internal"
                label=" komentarz dla osoby wystawiającej"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="toBeIssuedAtDatepicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    v-model="invoice.to_be_issued_at"
                    label="kiedy wystawić"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="toBeIssuedAtErrors"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pl-pl"
                  no-title
                  v-model="invoice.to_be_issued_at"
                  @change="toBeIssuedAtDatepicker = false"
                ></v-date-picker>
              </v-menu>

            </v-col>
            <v-col>
              <v-text-field
                v-model="invoice.to_be_payed_in"
                label="termin płatnośći"
                :error-messages="toBePayedInErrors"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="invoice.is_proforma"
                :items="documentTypes"
                item-text="name"
                item-value="id"
                label="dokument"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>

      </v-container>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        v-on:click="$emit('cancel')"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-on:click="submit"
        color="blue darken-1"
        text
      > Zleć wystawienie </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { required, numeric, minValue } from "vuelidate/lib/validators";
import axios from 'axios';
import InvoiceCreateEntry from "./InvoiceCreateEntry";

export default {
  components: { InvoiceCreateEntry },
  props: ["owner"],
  name: "InvoiceCreate",
  data() {
    return {
      invoice: {
        to_be_payed_in: 7,
        is_proforma: 0,
        invoice_entries: [],
      },
      toBeIssuedAtDatepicker: false,
      isCreateEntryDialogActive: false,
      documentTypes: [
        { id: 0, name: "Faktura" },
        { id: 1, name: "Proforma" },
      ],
      error: false,
      loading: false,
    };
  },
  validations: {
    invoice: {
      to_be_payed_in: { required, numeric, minValue: minValue(3) },
      to_be_issued_at: { required },
      is_proforma: { required },
    },
  },
  computed: {
    toBePayedInErrors() {
      const errors = [];
      if (!this.$v.invoice.to_be_payed_in.$dirty) return errors;
      !this.$v.invoice.to_be_payed_in.required &&
        errors.push("Termin jest wymagany.");
      !this.$v.invoice.to_be_payed_in.numeric &&
        errors.push("podaj liczbę dni");
      !this.$v.invoice.to_be_payed_in.minValue &&
        errors.push("liczba musi być większa niż 2");
      return errors;
    },
    toBeIssuedAtErrors() {
      const errors = [];
      if (!this.$v.invoice.to_be_issued_at.$dirty) return errors;
      !this.$v.invoice.to_be_issued_at.required &&
        errors.push("Termin jest wymagany.");
      return errors;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      if (this.invoice.invoice_entries.length === 0) {
        this.error = "Brak pozycji na fakturze";
        return false;
      }

      this.loading = true;
      try {
        this.loading = true;
        axios
          .post(`/sitemanager/invoices`, this.invoice)
          .then((response) => {
            this.owner.invoices.unshift(response.data);
          })
          .finally(() => (this.loading = false));

        this.$emit("invoice-created");
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    addEntry: function (entry) {
      this.invoice.invoice_entries.push(entry);
    },
    removeEntry: function (entry) {
      let rs = this.invoice.invoice_entries.filter((currentEntry) => {
        return !(
          currentEntry.service.id === entry.service.id &&
          currentEntry.invoice_entry_product.id ===
            entry.invoice_entry_product.id
        );
      });

      this.invoice.invoice_entries = [...rs];
    },
    totalAmount: function (entries) {
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalTax: function (entries) {
      return entries.reduce((acc, val) => {
        let tax = (val.amount * parseInt(val.invoice_entry_product.vat)) / 100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      return this.totalAmount(entries) + this.totalTax(entries);
    },
  },
  mounted() {
    this.invoice.invoice_entries = [];
    this.invoice.owner = this.owner;
  },
  watch: {},
};
</script>


<style>
</style>
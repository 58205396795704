<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col class="py-0">
          <v-menu
            v-model="datepickerDateGt"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="restrictions.date_gteq"
                label="od"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              :first-day-of-week="1"
              v-model="restrictions.date_gteq"
              @change="datepickerDateGt = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="py-0">
          <v-menu
            v-model="datepickerDateLt"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="restrictions.date_lteq"
                label="do"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              :first-day-of-week="1"
              v-model="restrictions.date_lteq"
              @change="datepickerDateLt = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          md="2"
          class="text-center py-0"
        >
          <v-btn
            color="green"
            dark
            @submit="$emit('search')"
            @click="$emit('search')"
          >
            szukaj
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
export default {
  name: "StatsDashboardForm",
  props: {
    restrictions: {
      type: Object,
    },
  },
  data: () => ({
    datepickerDateLt: false,
    datepickerDateGt: false,
  }),
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
};
</script>


<style>
</style>
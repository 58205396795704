<template>
  <div>
    <v-toolbar
      v-if="LISTINGS.length > 0"
      dense
      rounded
      floating
      class="mt-2"
      max-width="160"
      style="position:fixed;bottom:20px;right:20px;z-index:1;"
    >
      <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        label="zaznacz id"
        v-model="highlightedId"
      ></v-text-field>

    </v-toolbar>

  <v-card
    class="mt-2"
    v-if="LISTINGS.length > 0"
  >


    <v-simple-table dense fixed-header >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">#</th>
            <th class="text-left">id</th>
            <th class="text-left">priorytet</th>
            <th class="text-left">+</th>
            <th class="text-left">Emisja do</th>
            <th class="text-left" style="min-width:300px;">Nazwa</th>
            <th class="text-left">Rodzaj</th>
            <th class="text-left">Linki + RA</th>
            <th class="text-left">Standard</th>
            <th class="text-left">Miejsca</th>
            <th class="text-left">Miasto 1</th>
            <th class="text-left">Miasto 2</th>
            <th class="text-left">Miasto 3</th>
            <th class="text-left">Miasto 4</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="listing in LISTINGS" 
            :key="listing.id" 
            @dblclick="edit(listing)" 
            :class="(highlightedId && listing.id == highlightedId)? 'amber selected' : (listing.active !== 1)? 'blue-grey lighten-3' : ''"
          >
            <td>{{listing.position}} </td>
            <td>{{listing.id}}</td>
            <td>{{listing.priority}}</td>
            <td>{{listing.priority_extra}}</td>
            <td>{{ listing.subscription_end}}</td>
            <td>{{ listing.name }}</td>
            <td>{{ listing.propertyType }}</td>
            <td>{{ listing.partner_points }}</td>
            <td>{{ listing.star_rate }}</td>
            <td>{{ listing.capacity }}</td>
            <td>{{ listing.cities[1] }}</td>
            <td><span v-if="listing.cities[2]">{{ listing.cities[2] }}</span></td>
            <td><span v-if="listing.cities[3]">{{ listing.cities[3] }}</span></td>
            <td><span v-if="listing.cities[4]">{{ listing.cities[4] }}</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "ListingSearchResult",
  components: {

  },
  data() {
    return {
      highlightedId: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["LISTINGS"]),
  },
  methods: {
    edit(item) {
      this.$router.push({
        name: "facilityEdit",
        params: { id: item.id },
      });
    },
    
  },
  mounted() {},
  watch: {
    highlightedId(){
      this.$nextTick(() => {
        if(document.getElementsByClassName('selected').length > 0){
          this.$vuetify.goTo('.selected',{
            offset:650
          })
        }
      });
    }
  },
};
</script>


<style>

</style>
<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card class="pb-5">
      <v-card-title>
        <v-container class="text-center">Zgłosznie do istniejącego obiektu:</v-container>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="ID obiektu"
          outlined
          v-model="facilityId"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="success"
          @click="save()"
        >potwierdzam</v-btn>
        <v-btn
          color="warning"
          @click="dialog=false;"
        >anuluj</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RegistrationDuplicateForm",
  components: {},
  data: () => ({
    loading: false,
    dialog: false,
    facilityId: null,
    registration: null,
  }),

  computed: {},
  methods: {
    openDialog(registration) {
      this.registration = registration;
      this.facilityId = null;
      if (registration.facility) {
        this.facilityId = registration.facility.id;
      }
      this.dialog = true;
    },
    save() {
      if(!this.facilityId){
        return;
      }
      this.loading = true;

      this.registration.facility = {
        id: this.facilityId
      };
      this.registration.status = 1;
      
      this.$store
        .dispatch("UPDATE_REGISTRATION", { registration: this.registration })
        .then(() => {
          this.loading = false;
        });

      this.dialog = false;
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
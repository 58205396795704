<template>
  <div>
    <v-progress-circular
      indeterminate
      color="blue darken-3"
      v-if="loading"
    ></v-progress-circular>

    <v-card
      class="mt-3 mx-auto"
      color="blue darken-3"
      dark
      v-if="sitemanagerData.id"
    >
      <v-card-title class="py-0 pt-1">
        <span class="text-h6 font-weight-light">Strony Meteor</span>
         <v-btn
            class=""
            outlined
            rounded
            small
            right
            absolute
            @click="sitemanagerDialog = true"
          >
            ZARZĄDZAJ
          </v-btn>  
      </v-card-title>

      <v-card-text class="font-weight-bold">

        <v-list
          class="py-0"
          color="blue darken-3"
          dark
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                person
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ sitemanagerData.name }}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item dense v-for="service in sitemanagerData.services" :key="service.id">
            <v-list-item-icon>
              <v-icon v-if="service.status === 1">
                check
              </v-icon>
              <v-icon v-if="service.status === 2">
                block
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ service.type.name }}<span v-if="service.domain"> - {{service.domain.domain}}</span></v-list-item-title>
              <v-list-item-subtitle v-if="service.expires_at">wygasa {{ service.expires_at}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>

        <v-row
          dense
          no-gutters
        >
          <v-col v-if="sitemanagerData.user">
            <v-icon class="mr-1">
              person
            </v-icon>
            <span class="subheading mr-2">opiekun: {{sitemanagerData.user.name}} {{sitemanagerData.user.surname}}</span>
          </v-col>
          <v-col >
              
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="sitemanagerDialog"
        fullscreen
        hide-overlay
        transition="dialog-top-transition"
      >

      <v-card>
        <v-toolbar
          dark
          dense
          color="blue darken-3"
        >
          <v-btn
            icon
            dark
            @click="sitemanagerDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Sitemanager: {{ sitemanagerData.name }}</v-toolbar-title>

      </v-toolbar>
        <Sitemanager :owner="sitemanagerData" :facility="facility"/>
      </v-card>
      </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import Sitemanager from './ExternalServiceMeteorSites/Sitemanager'
export default {
  name: "ExternalServiceRoomadmin",
  components: {Sitemanager},
  props: ["facility"],
  data: () => ({
    loading: false,
    sitesData: {},
    sitemanagerData: {},
    sitemanagerDialog: false
  }),
  computed: {},
  methods: {
    getMeteorSitesData() {
      this.sitemanagerData = {};
      this.loading = true;
      axios
        .get(`/facilities/${this.facility.external_id}/sitemanager`)
        .then((response) => {
          this.sitemanagerData = response.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    facility() {
      this.getMeteorSitesData();
    },
  },
  mounted() {

    setTimeout(() => {this.getMeteorSitesData();}, 500);
    
  },
};
</script>


<style>
</style>
<template>
  <v-row no-gutters>
    <v-col class="text-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            @click="openLinkManagerPreview()"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              :color="facility.link_manager_points <= 0?'orange':'green'"
              :content="facility.link_manager_points <= 0?'0':facility.link_manager_points"
            >
              <v-icon>link</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>linki partnerskie</span>
      </v-tooltip>
      <v-menu
        v-model="relatedForm"
        :close-on-content-click="false"
        :nudge-width="350"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              :color="facility.owner.facility_handles.length===0?'orange':'green'"
              :content="facility.owner.facility_handles.length===0?'0':facility.owner.facility_handles.length"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>share</v-icon>
                  </div>

                </template>
                <span>obiekty związane</span>
              </v-tooltip>

            </v-badge>
          </v-btn>
        </template>

        <v-card>

          <v-list>
            <v-list-item @keyup.native.enter="addFacility" v-if="facility.owner.facility_handles.length === 0 || facility.is_main">
              <v-autocomplete
                placeholder="wyszukaj obiekt (ID związanego)"
                hide-no-data
                return-object
                v-model="facilityToAdd"
                item-text="value"
                :items="facilityIdSuggestions"
                :search-input.sync="facilitySearch"
                :loading="loadingFacilityAutocomplete"
              ></v-autocomplete>
              <v-btn
                color="success"
                @click="addFacility"
                small
                class="ml-1"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item
              v-for="facilityHandle in facility.owner.facility_handles"
              :key="facilityHandle.id"
              :class="facilityHandle.id==facility.id?'facility-handles-main':''"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="facilityHandle.is_main"
                  color="blue"
                >
                  mdi-star
                </v-icon>
              </v-list-item-icon>

              <v-btn
                color="primary"
                class="mr-2"
                dark
                x-small
                @click="goToFacility(facilityHandle)"
              >
                <v-icon>start</v-icon>
              </v-btn>

              <v-btn
                v-if="facility.is_main"
                :disabled="(facilityHandle.is_main && facility.owner.facility_handles.length > 1) ? true : false"
                color="error"
                class="mr-2"
                dark
                x-small
                @click="removeFacility(facilityHandle)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <v-chip
                small
                color="info"
                class="mr-2"
                outlined
              >{{facilityHandle.priority}}</v-chip> 
              
              <v-tooltip bottom color="info">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{facilityHandle.external_id}} ({{facilityHandle.name}})
                  </span>
                  
                  </template>
                <span><v-icon color="white">location_on</v-icon> {{facilityHandle.address}}</span>
              </v-tooltip>
              
             

            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="relatedForm = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        depressed
        small
        @click="goToRoomadmin()"
        @contextmenu="checkRoomadmin()"
        @contextmenu.prevent=""
        class="ml-1"
        :loading="loadingRoomadminData"
      >
        <v-badge
          :color="facility.roomadmin_offer_type === 4?'green':'orange'"
          :content="facility.roomadmin_offer_type === 4?'4':'0'"
        >
          <span style="line-height:2;">RA {{facility.roomadmin_id}} </span>
        </v-badge>
      </v-btn>

      <v-btn
        depressed
        small
        @click="goTowwww()"
        @contextmenu="goTowwwwInNewWindow()"
        @contextmenu.prevent=""
        class="ml-1"
        :loading="loading"
      >

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-badge
                :color="wwwStatus < 1 ? 'orange':'green'"
                :content="wwwStatus"
              >
                <v-icon>language</v-icon>

              </v-badge>
            </div>

          </template>
          <span>usługi www</span>
        </v-tooltip>

      </v-btn>

    </v-col>
    <Snackbar ref="snackbar" />
    <v-dialog
      v-model="sitemanagerDialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
          dark
          dense
          color="blue darken-3"
        >
          <v-btn
            icon
            dark
            @click="sitemanagerDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Sitemanager: {{ sitemanagerData.name }}</v-toolbar-title>

        </v-toolbar>
        <Sitemanager :owner="sitemanagerData" :facility="facility" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Snackbar from "./../../shared/Snackbar.vue";
import Sitemanager from "./ExternalService/ExternalServiceMeteorSites/Sitemanager";
export default {
  name: "FacilityEditFormRelation",
  props: ["facility"],
  components: {
    Snackbar,
    Sitemanager,
  },
  data: () => ({
    relatedForm: false,
    loading: false,
    loadingFacilityAutocomplete: false,
    facilitySearch: null,
    facilityIdSuggestions: [],
    facilityToAdd: {},
    loadingRoomadminData: false,
    sitemanagerData: {},
    sitemanagerDialog: false,
  }),
  computed: {
    wwwStatus(){
      return this.facility.www_expires_at !== null ? (moment(this.facility.www_expires_at) > moment() ? '1' : '-1') : '0';
    }
  },
  methods: {
    addFacility() {
      if (!this.facilityToAdd.id) {
        return;
      }
      if (
        this.facility.owner.facility_handles.length === 0 &&
        this.facilityToAdd.id !== this.facility.id
      ) {
        this.facility.is_main = 1;
        this.facility.owner.facility_handles.push({
          id: this.facility.id,
          priority: this.facility.priority,
          external_id: this.facility.id,
          name: this.facility.name,
          address: this.facility.address,
          is_main: this.facility.is_main,
        });
      }

      this.facility.owner.facility_handles.push(this.facilityToAdd);
      this.facilityToAdd = null;
    },
    goToFacility(facility) {
      this.$emit("autosave");
      this.relatedForm = false;
      this.$router.push({
        name: "facilityEdit",
        params: { id: facility.external_id },
      });
    },
    goToMainFacility() {
      this.$emit("autosave");
      this.facility.owner.facility_handles.filter((current) => {
        if (current.is_main === 1) {
          this.$router.push({
            name: "facilityEdit",
            params: { id: current.external_id },
          });
        }
      });
    },
    goTowwww() {
      this.sitemanagerData = {};
      this.loading = true;
      axios
        .get(`/facilities/${this.facility.external_id}/sitemanager`)
        .then((response) => {
          this.sitemanagerData = response.data;
          if (this.sitemanagerData.id) {
            this.sitemanagerDialog = true;
          } else {
            this.$refs.snackbar.error("Nie znaleziono usług www");
          }
        })
        .finally(() => (this.loading = false));
    },
    goTowwwwInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilitySitemanager",
        params: { id: this.facility.external_id },
      });
      window.open(routeData.href, "_blank");   
    },
    goToRoomadmin() {
      if (this.facility.roomadmin_id === null) {
        this.loadingRoomadminData = true;
        axios
          .get(`/facilities/${this.facility.external_id}/roomadmin`)
          .then((response) => {
            let roomadminData = response.data;
            if (roomadminData.id) {
              this.facility.roomadmin_id = roomadminData.id;
              this.$refs.snackbar.error("Znaleziono konto Roomadmin");
            } else {
              this.$refs.snackbar.error("Nie znaleziono konta Roomadmin");
              return;
            }
          })
          .finally(() => (this.loadingRoomadminData = false));
      } else {
        const url = "https://roomadmin.pl/admin/facility/edit?id=";
        window.open(url + this.facility.roomadmin_id, "_blank");
      }
    },
    checkRoomadmin() {
        this.loadingRoomadminData = true;
        axios
          .get(`/facilities/${this.facility.external_id}/roomadmin`)
          .then((response) => {
            let roomadminData = response.data;
            if (roomadminData.id) {
              this.facility.roomadmin_id = roomadminData.id;
              this.$refs.snackbar.error("Znaleziono konto Roomadmin");
            } else {
              this.$refs.snackbar.error("Nie znaleziono konta Roomadmin");
              return;
            }
          })
          .finally(() => (this.loadingRoomadminData = false));
    },
    openLinkManagerPreview() {
      const url =
        "http://link-manager.meteor24.pl/application/report?owner_related_id=";
      window.open(url + this.facility.external_id, "_blank");
    },
    removeFacility(facility) {
      this.facility.owner.facility_handles =
        this.facility.owner.facility_handles.filter((current) => {
          return current.id !== facility.id;
        });

      if(facility.is_main === 1){
        this.facility.is_main = 0;
      }   
    },
  },
  mounted() {},
  watch: {
    facilitySearch(val) {
      //  if (this.loading) return
      this.loadingFacilityAutocomplete = true;
      let filter = "external_id_like";
      const pattern = /^\d+$/;
      if (!pattern.test(val)) {
        filter = "name_like";
      }
      axios
        .get(`/facilities?limit=20&filter[` + filter + `]=` + val, {})
        .then((response) => {
          var data = [];

          for (let key in response.data.result.items) {
            var row = response.data.result.items[key];
            row.value = row.external_id + " - " + row.name;
            data.push(row);
          }

          this.facilityIdSuggestions = response.data.result.items;
        })
        .finally(() => (this.loadingFacilityAutocomplete = false));
    },
  },
};
</script>


<style>
.facility-handles-main {
  background-color: #b3e5fc;
}
</style>
<template>
  <div>
    <FacilityEditEvent :facility="facility" />
  </div>          
</template>

<script>

import FacilityEditEvent from "../../FacilityEdit/FacilityEditEvent";

export default {
  name: "FacilityEditTabEvent",
  props: ["facility"],
  components: {
    FacilityEditEvent
  },
  data: () => ({
    
  }),
  computed: {
  },
  methods: {},
  mounted() {},
};
</script>


<style>
</style>
<template>
  <div>
    <v-btn
      icon
      dark
      @click="readById(3)"
    >
      <v-icon>paid</v-icon>
    </v-btn>
    <QaReadDialog ref="qaReadDialog" />
    <v-btn
      icon
      dark
      @click="openSearchDialog"
    >
      <v-icon>question_mark</v-icon>
    </v-btn>
    <QaSearchDialog ref="qaSearchDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QaSearchDialog from "./UserQa/QaSearchDialog.vue";
import QaReadDialog from "./UserQa/QaReadDialog.vue";

export default {
  name: "UserQa",
  components: { QaSearchDialog, QaReadDialog },
  data: () => ({}),
  computed: {
    ...mapGetters(["QA"]),
  },
  methods: {
    openSearchDialog(){
      this.$refs.qaSearchDialog.openDialog();
    },
    readById(id){
      const qa = this.QA.find(qa => qa.id === id);
      this.$refs.qaReadDialog.openDialog(qa);
      //this.$refs.qaSearchDialog.readById(id);
    }
  },
  mounted() {},
  watch: {},
};
</script>

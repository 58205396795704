<template>          
  <tr>
    <td>{{ bankAccount.swift }}</td>
    <td>{{ bankAccount.iban }}</td>
    <td>{{ bankAccount.number }}</td>
    <td>{{ bankAccount.currency.code }}</td>
    <td class="text-right action-cell">
      <v-btn color="primary" class="mr-1" dark small @click="edit(bankAccount)"> <v-icon>edit</v-icon> </v-btn>
      <v-btn color="error" dark small @click="deleteBankAccount(bankAccount)"> <v-icon>delete</v-icon> </v-btn>

      <ClientBankAccountForm v-on:bankAccount:update="bankAccountUpdate" ref="bankAccountForm" />

    </td>
  </tr>
</template>


<script>

import ClientBankAccountForm from "./ClientBankAccountForm"

export default {
  name: "ClientBankAccountRow",
  props: ["bankAccount"],
  components: {ClientBankAccountForm},
  data: () => ({
    editing: false,
  }),
  computed: {

  },
  methods: {
    deleteBankAccount: function(bankAccount){
      this.$emit('bankAccount:remove', bankAccount) 
    },
    edit: function(bankAccount){
      this.$refs.bankAccountForm.open(bankAccount);
    },
    bankAccountUpdate(data){
      Object.assign(this.bankAccount, data);
    },
  },
  mounted() {
    
  },
};
</script>


<style>
.action-cell {
  min-width:140px;
}
</style>
<template>
  <v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      color="blue"
      dark
      dense
    >
      <v-btn
        icon
        dark
        v-on:click="$emit('qaForm:close')"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title><span v-if="!form.question">Dodawanie pytania i odpowiedzi</span><span v-if="form.question">Q&A - {{form.question}}</span></v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <form v-if="form">
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                name="question"
                label="Pytanie"
                v-model="form.question"
                :error-messages="questionErrors"
                required
                @input="$v.form.question.$touch()"
                @blur="$v.form.question.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="2" >
              <v-switch
                v-model="form.visibility"
                label="widoczny"
              ></v-switch>
            </v-col>
          </v-row>

          <div >
            <label>Odpowiedź</label>
            <ckeditor
              :editor="editor"
              v-model="form.answer"
              :config="editorConfig"
              @ready="editorReady"
            ></ckeditor>
          </div>
        </v-container>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="blue darken-1"
        text
        v-on:click="$emit('qaForm:close')"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-on:click="submit"
        color="blue darken-1"
        text
      > Zapisz </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ClassicEditor from "ckeditor5-build-decoupled-document-base64-imageresize";


export default {
  props: ["qa"],
  name: "AdminQaForm",
  data() {
    return {
      loading: false,
      form: false,
      expiresAtDatepicker: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        }
      },
    };
  },
  validations: {
    form: {
      question: { required }
    },
  },
  computed: {
    questionErrors() {
      const errors = [];
      if (!this.$v.form.question.$dirty) return errors;
      !this.$v.form.question.required && errors.push("Pytanie jest wymagane.");
      return errors;
    }
  },
  methods: {
    submit() {

      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      this.loading = true;
      if (this.form.id) {
        console.log("loading");
        this.$store
          .dispatch("UPDATE_QA", {
            qa: this.form,
          })
          .then(() => {
            this.loading = false;
            this.$emit("qaForm:close");
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              console.log(error.response.data.message);
            }
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch("ADD_QA", {
            qa: this.form,
          })
          .then(() => {
            this.loading = false;
            this.$emit("qaForm:close");
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              console.log(error.response.data.message);
            }
            this.loading = false;
          });
      }
    },
    editorReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
  mounted() {
    this.form = Object.assign({}, this.qa);
  },
  watch: {
    qa: function (qa) {
      this.form = Object.assign({}, qa);
    },
  },
};
</script>

<style>
.ck-content {
  width: 100%;
  min-height: 350px;

  border: 1px solid var(--ck-color-base-border) !important;
  border-radius: var(--ck-border-radius) !important;
  max-height: 700px;

  display: flex;
  flex-flow: column nowrap;
}
</style>
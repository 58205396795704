<template>
  <v-container fluid class="pt-1"> 
  
      <v-card :loading="loading" :disabled="loading">
     
      <v-container fluid class="pb-0">
            <FacilitySearchForm />
      </v-container>
    </v-card>

    <FacilitySearchResult />

    
    
  </v-container>
</template>

<script>

import FacilitySearchForm from "./Facility/FacilitySearchForm.vue"
import FacilitySearchResult from "./Facility/FacilitySearchResult.vue"

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
    } 
  },   
  components: {
    FacilitySearchForm,
    FacilitySearchResult
  },
  mounted(){
    this.$store.dispatch("FACILITY_CLEAR_CURRENT");   
  }
};
</script>

<style>
</style>

<template>
  <div>
    <FacilityEditClient :facility="facility" />
  </div>          
</template>

<script>

import FacilityEditClient from "../../FacilityEdit/FacilityEditClient";

export default {
  name: "FacilityEditTabClient",
  props: ["facility"],
  components: {
    FacilityEditClient
  },
  data: () => ({
    
  }),
  computed: {
  },
  methods: {},
  mounted() {},
};
</script>


<style>
</style>
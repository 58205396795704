<template>
  <div>
    <FacilityEditPropertyType :facility="facility" />
  </div>          
</template>

<script>

import FacilityEditPropertyType from "../../FacilityEdit/FacilityEditPropertyType";

export default {
  name: "FacilityEditTabAttribute",
  props: ["facility"],
  components: {
    FacilityEditPropertyType
  },
  data: () => ({}),
  computed: {

  },
  methods: {
  
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-row dense>
      <v-col md="2" class="py-0">
        <v-select
          :items="personTitle"
          v-model="subscription.client_title"
          label="tytuł"
          dense
        ></v-select>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field
          v-model="subscription.client_name"
          label="imię i nazwisko"
          dense
        >
          <v-icon
            slot="append"
            color="primary"
            small
            @click="copyClientInfo"
          >
            content_copy
          </v-icon>
        </v-text-field>
      </v-col>
      
      <v-col md="2" class="py-0">
        <v-text-field
          v-model="subscription.client_phone"
          label="tel 1"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="2" class="py-0">
        <v-text-field
          v-model="subscription.client_phone2"
          label="tel 2"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="2" class="py-0">
        <v-menu
        v-model="clientHoursFromTimepicker"
        :close-on-content-click="false"
        :nudge-right="40"
       
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="subscription.client_hours_from"
            label="godzina od"
            readonly
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="clientHoursFromTimepicker"
          v-model="subscription.client_hours_from"
          full-width
          format="24hr"
          @click:hour="closeClientHoursFromTimepicker"
        ></v-time-picker>
      </v-menu>
        
      </v-col>
      <v-col md="2" class="py-0">
        <v-menu
          v-model="clientHoursToTimepicker"
          :close-on-content-click="false"
          :nudge-right="40"
        
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="subscription.client_hours_to"
              label="godzina do"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="clientHoursToTimepicker"
            v-model="subscription.client_hours_to"
            full-width
            format="24hr"
            @click:hour="closeClientHoursToTimepicker"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col md="8" class="py-0">
        <v-text-field
          v-model="subscription.client_description"
          label="opis (tytuł/status)"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar"/>
  </div>
</template>

<script>
import Snackbar from "../../../../shared/Snackbar.vue";
export default {
  name: "SubscriptionEditClient",
  props: ["subscription"],
  components: {
    Snackbar
  },
  data: () => ({
    personTitle: ["", "Pan", "Pani"],
    clientHoursFromTimepicker: false,
    clientHoursToTimepicker: false
  }),
  computed: {
   
  },
  methods: {
    closeClientHoursFromTimepicker(v){
      v = v < 10 ? '0'+v : v;
      this.subscription.client_hours_from = v+":00";
      this.clientHoursFromTimepicker = false
    },
    closeClientHoursToTimepicker(v){
      v = v < 10 ? '0'+v : v;
      this.subscription.client_hours_to = v+":00";
      this.clientHoursToTimepicker = false
    },
    autoFormatPhoneNumber(phoneNumber){
      phoneNumber = phoneNumber.trim().replace(/[^0-9\\+]/g, '');
      if(phoneNumber.length === 9){
        const local = [12,13,14,15,16,17,18,22,23,24,25,29,32,33,34,41,42,43,44,46,48,52,54,55,56,58,59,61,62,63,65,67,68,71,74,75,76,77,81,82,83,84,85,86,87,89,91,94,95];
        if(local.includes(parseInt(phoneNumber.slice(0,2)))){
          phoneNumber = phoneNumber.replace(/^(\d{2})(\d{3})(\d{2})?(\d{2})/g, '$1 $2 $3 $4');
        } else {
          phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3');
        }
      }
      return phoneNumber;
    },
    copyClientInfo(){
      const text = this.subscription.client_title + " " + this.subscription.client_name + " " + this.subscription.client_phone;
      navigator.clipboard.writeText(text).then(
        () => {
          this.$refs.snackbar.success("Skopiowano do schowka: " + text);
          console.log("copy");
        },
        (err) => {
          console.error("Could not copy text: ", err);
      });
    } 
  },
  mounted() {
  },
  watch: {
    'subscription.client_phone': function(){
      this.subscription.client_phone = this.autoFormatPhoneNumber(this.subscription.client_phone);
    },
    'subscription.client_phone2': function(){
      this.subscription.client_phone2 = this.autoFormatPhoneNumber(this.subscription.client_phone2);
    }
  }  
};
</script>


<style>
</style>
<template>

    <div class="mt-1" v-if="INVOICES.length > 0">
      <InvoiceList :invoices="INVOICES" :accountantMode="true"/>
    </div>

</template>

<script>
import { mapGetters } from "vuex";


import InvoiceList from "./InvoiceList";

export default {
  components: { InvoiceList },
  name: "InvoiceSearchResult",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["INVOICES"]),
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>


<style>
</style>
<template>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      fullscreen
      :loading="loading"
    >
      <v-card
        :loading="loading"
        class="mt-0 pt-0"
      >
        <v-toolbar
          dark
          color="blue"
          class="mt-0"
          dense
        >
          <v-toolbar-title>Podgląd emisji: {{ FACILITY.name }}</v-toolbar-title>

        </v-toolbar>
        <v-container>
        <FacilityEditSubscription
          v-if="FACILITY.name"
          :facility="FACILITY"
          :readOnly="true"
        />
      </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FacilityEditSubscription from "./../../FacilityEdit/FacilityEditSubscription";

export default {
  name: "WindowSubscriptionPreview",
  components: {
    FacilityEditSubscription,
  },
  data: () => ({
    loading: false,
    dialog: true,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  methods: {
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>


<style>
</style>
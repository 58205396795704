<template>
  <div>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <v-alert
      v-if="!facilityEdited.facility && !loading"
      type="error"
      class="ma-5"
    >Nie udało się załadować danych obiektu.</v-alert>
    <v-card
      prominent
      dense
      v-if="facilityEdited.facility "
      :color="facilityEdited.facility.internet==0?'blue-grey lighten-4':'white'"
    >
      <v-container
        fluid
        class="pb-0 pt-1"
      >
        <v-row
          class="mt-1"
          dense
        >
          <v-col
            md="9"
            sm=12
            cols=12
          >
            <FacilityEditFormBasic
              v-if="facilityEdited"
              :facility="facilityEdited"
              v-on:not-valid="valid=false"
              v-on:valid="valid=true"
            />
          </v-col>
          <v-col
            md="3"
            sm=12
            cols=12
            class="text-center"
          >
            <v-btn
              color="error"
              class="mr-2"
              dark
              @click="reset()"
            >
              <v-icon>block</v-icon>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>settings</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  @click="callAction(item.action)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="item.extraAction">
                    <v-btn depressed>
                      <v-icon @click.stop="callAction(item.extraAction);">open_in_new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              color="success"
              dark
              @click="save()"
            >
              <v-icon>done</v-icon>
            </v-btn>

            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>desktop_windows</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in windowsRatios"
                  :key="index"
                  @click="changeWindowsRatio(item.ratio)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            md="5"
            sm=12
            cols=12
            class="pb-0"
          >
            <v-row no-gutters>
              <v-col md="6">
                <FacilityEditFormPriority
                  v-if="facilityEdited.facility"
                  :facility="facilityEdited.facility"
                />
              </v-col>
              <v-col md="6">
                <FacilityEditFormRelation
                  v-if="facilityEdited"
                  :facility="facilityEdited"
                  @autosave="forceSave()"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="7"
            sm=12
            cols=12
            class="pb-0"
          >
            <FacilityEditFormNextContact
              v-if="facilityEdited"
              :facility="facilityEdited"
            />
          </v-col>

        </v-row>
      </v-container>
    </v-card>
    <v-card
      class=""
      flat
      v-if="facilityEdited.facility"
    >

      <v-container
        fluid
        class="grey lighten-3 pt-2"
      >
        <v-row dense>
          <v-col :sm="windowsRatio.left">
            <v-card elevation="3">
              <v-tabs
                v-model="tabLeft"
                grow
                class="mainTabs"
                show-arrows
                align-with-title
                background-color="blue-grey lighten-5"
                active-class="white"
                v-if="facilityEdited.facility"
              >
                <v-tab
                  v-for="item in tabLeftItems"
                  :key="item.tab"
                >
                  <v-badge
                    :color="item.tab==='Osoby' && facilityEdited.people.length > 0? 'green' : ''"
                    dot
                  >
                    {{ item.tab }}
                  </v-badge>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabLeft">
                <v-tab-item
                  v-for="item in tabLeftItems"
                  :key="item.tab"
                >
                  <v-card
                    class="mt-2 pa-2"
                    flat
                  >
                    <component
                      :is="item.component"
                      :facility="facilityEdited"
                      v-on:not-valid="valid=false"
                      v-on:valid="valid=true"
                    ></component>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>

          <v-col :sm="windowsRatio.right">
            <v-card elevation="3">
              <v-tabs
                show-arrows
                v-model="tabRight"
                grow
                align-with-title
                class="mainTabs"
                background-color="blue-grey lighten-5"
                active-class="white"
                v-if="facilityEdited.facility"
              >
                <v-tab
                  v-for="item in tabRightItems"
                  :key="item.tab"
                >
                  <v-tooltip
                    color="primary"
                    top
                    v-if="item.description"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.tab }}
                      </div>
                    </template>
                    <span>{{item.description}}</span>
                  </v-tooltip>
                  <div v-else>
                    {{ item.tab }}
                  </div>

                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabRight">
                <v-tab-item
                  v-for="item in tabRightItems"
                  :key="item.tab"
                >
                  <v-card
                    class="mt-2 pa-2"
                    flat
                  >
                    <component
                      :is="item.component"
                      :facility="facilityEdited"
                      @autosave="save()"
                    ></component>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>

            </v-card>
          </v-col>
        </v-row>

      </v-container>

      <v-btn
        v-if="dirty"
        color="success"
        dark
        fab
        fixed
        bottom
        right
        @click="save()"
      >
        <v-icon>done</v-icon>
      </v-btn>

    </v-card>

    <v-dialog
      v-model="rangeMapDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          dense
          color="green darken-4"
        >
          <v-btn
            icon
            dark
            @click="rangeMapDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Zasięg: {{ facilityEdited.name }}</v-toolbar-title>

        </v-toolbar>
        <GeolocationRangeMap :facility="facilityEdited" />

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogGpsEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          dense
          color="green darken-4"
        >
          <v-btn
            icon
            dark
            @click="dialogGpsEdit = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Współrzędne GPS: {{ facilityEdited.name }}</v-toolbar-title>

        </v-toolbar>
        <FacilityEditGeolocation
          :facility="facilityEdited"
          v-on:save-geolocation="dialogGpsEdit = false; forceSave()"
        />

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogGoogleMatch"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="orange darken-4"
        >
          <v-btn
            icon
            dark
            @click="dialogGoogleMatch = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Google Match: {{ facilityEdited.name }}</v-toolbar-title>

        </v-toolbar>
        <GoogleMatch :facility="facilityEdited" />

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sendMailDialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >

      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="sendMailDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Wysyłanie wiadomośći do: {{ facilityEdited.name }}</v-toolbar-title>

        </v-toolbar>
        <FacilityEditSendMail
          v-on:FacilitySendEmail:sended="sendMailDialog = false;successMessage('Wiadomość wysłana poprawnie')"
          :facility="facilityEdited"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sendSMSDialog"
      hide-overlay
      transition="dialog-top-transition"
      max-width="600"
    >

      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="sendSMSDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Wysyłanie wiadomośći SMS do: {{ facilityEdited.name }}</v-toolbar-title>

        </v-toolbar>
        <FacilityEditSendSMS
          :facility="facilityEdited"
          v-on:FacilitySendSMS:sended="sendSMSDialog = false;successMessage('Wiadomość wysłana poprawnie')"
        />
      </v-card>
    </v-dialog>
    <ToolMeteorClearCacheDialog ref="toolMeteorClearCacheDialog"/>
    <StatsDashboardDialog ref="statsDashboardDialog"/>
    <Snackbar ref="snackbar" />
    <FacilityEditLock
      v-if="facilityEdited.id"
      :facility="facilityEdited"
    />
    <FacilityEditTabGuard
      v-if="facilityEdited.id"
      :facility="facilityEdited"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FacilityEditInfo from "./FacilityEditInfo";

import FacilityEditFormBasic from "./FacilityEditFormBasic";
import FacilityEditFormPriority from "./FacilityEditFormPriority";
import FacilityEditFormNextContact from "./FacilityEdit/FacilityEditFormNextContact";
import FacilityEditFormRelation from "./FacilityEdit/FacilityEditFormRelation";
import FacilityEditSendMail from "./FacilityEdit/FacilityEditSendMail";
import FacilityEditSendSMS from "./FacilityEdit/FacilityEditSendSMS";

import FacilityEditTabRelease from "./FacilityEdit/Tab/FacilityEditTabRelease";

import FacilityEditTabClient from "./FacilityEdit/Tab/FacilityEditTabClient";
import FacilityEditTabAttribute from "./FacilityEdit/Tab/FacilityEditTabAttribute";

import FacilityEditTabInfo from "./FacilityEdit/Tab/FacilityEditTabInfo";
import FacilityEditTabNote from "./FacilityEdit/Tab/FacilityEditTabNote";
import FacilityEditTabEvent from "./FacilityEdit/Tab/FacilityEditTabEvent";
import FacilityEditTabParameters from "./FacilityEdit/Tab/FacilityEditTabParameters";
import FacilityEditTabAddress from "./FacilityEdit/Tab/FacilityEditTabAddress";
import FacilityEditTabLocation from "./FacilityEdit/Tab/FacilityEditTabLocation";
import FacilityEditTabPeople from "./FacilityEdit/Tab/FacilityEditTabPeople";

import ToolMeteorClearCacheDialog from "./FacilityEdit/Tool/ToolMeteorClearCacheDialog";
import StatsDashboardDialog from "./FacilityEdit/Stats/StatsDashboardDialog";

import FacilityEditLock from "./FacilityEdit/FacilityEditLock";
import FacilityEditTabGuard from "./FacilityEdit/FacilityEditTabGuard";

import Snackbar from "./../shared/Snackbar";
import GoogleMatch from "./../GoogleMatch";
import FacilityEditGeolocation from "./FacilityEdit/FacilityEditGeolocation";
import GeolocationRangeMap from "./FacilityEdit/Geolocation/RangeMap";

export default {
  name: "FacilityEdit",
  components: {
    FacilityEditFormBasic,
    FacilityEditFormPriority,
    FacilityEditFormNextContact,
    FacilityEditTabLocation,
    FacilityEditTabAddress,
    FacilityEditTabRelease,
    FacilityEditInfo,
    FacilityEditTabClient,
    FacilityEditTabAttribute,
    FacilityEditTabInfo,
    FacilityEditTabNote,
    FacilityEditTabEvent,
    FacilityEditTabParameters,
    FacilityEditTabPeople,
    FacilityEditSendMail,
    FacilityEditSendSMS,
    FacilityEditFormRelation,
    FacilityEditGeolocation,
    GeolocationRangeMap,
    FacilityEditLock,
    FacilityEditTabGuard,
    ToolMeteorClearCacheDialog,
    StatsDashboardDialog,
    GoogleMatch,
    Snackbar,
  },
  data: () => ({
    items: [
      {
        title: "Oferty, maile",
        action: "sendEmail",
        icon: "email",
        extraAction: "sendEmailInNewWindow",
      },
      { title: "Wyślij SMS", action: "sendSMS", icon: "message", extraAction: "sendSmsInNewWindow" },
      {
        title: "Google Match",
        action: "openGoogleMatch",
        icon: "search",
        extraAction: "openGoogleMatchInNewWindow",
      },
      { title: "Serwisy, GPS", action: "openGpsEdit", icon: "place",extraAction: "openGpsEditInNewWindow" },
      { title: "Mapa, zasięg", action: "openGpsRange", icon: "mode_standby", extraAction: "openGpsRangeInNewWindow" },
      {
        title: "Otwórz Statystyki",
        icon: "query_stats",
        action: "goToStatsPanel",
        extraAction: "openStatsInNewWindow"
      },
      {
        title: "Otwórz Panel Klienta",
        icon: "admin_panel_settings",
        action: "goToClientPanel",
      },
      {
        title: "Otwórz Panel Zapytań",
        icon: "message",
        action: "goToMessagePanel",
      },
      {
        title: "Wyczyść cache serwisu",
        icon: "cleaning_services",
        action: "openMeteorClearCacheTool",
      },
    ],
    tabLeftItems: [
      { tab: "Obiekt", component: "FacilityEditInfo" },
      { tab: "Klient", component: "FacilityEditTabClient" },
      { tab: "Osoby", component: "FacilityEditTabPeople" },
      { tab: "Info", component: "FacilityEditTabInfo" },
      { tab: "Oferty", component: "FacilityEditTabEvent" },
      { tab: "Usługi", component: "FacilityEditTabParameters" },
    ],
    tabRightItems: [
      { tab: "Lokalizacja", component: "FacilityEditTabLocation" },
      { tab: "Kontakt", component: "FacilityEditTabAddress" },
      { tab: "Rodzaje", component: "FacilityEditTabAttribute" },
      { tab: "Notatka", component: "FacilityEditTabNote" },
      {
        tab: "Emisje",
        component: "FacilityEditTabRelease",
        description: "dawne wydania",
      },
    ],
    windowsRatios: [
      {
        title: "50% / 50%",
        icon: "vertical_split",
        ratio: { left: 6, right: 6 },
      },
      {
        title: "25% / 75%",
        icon: "view_sidebar",
        ratio: { left: 4, right: 8 },
      },
      {
        title: "75% / 25%",
        icon: "view_sidebar",
        ratio: { left: 8, right: 4 },
      },
      {
        title: "100% / 100%",
        icon: "view_agenda",
        ratio: { left: 12, right: 12 },
      },
    ],
    tabRight: 4,
    tabLeft: null,
    rangeMapDialog: false,
    sendMailDialog: false,
    sendSMSDialog: false,
    dialogGoogleMatch: false,
    dialogGpsEdit: false,
    loading: false,
    facilityEdited: {},
    windowsRatio: {
      left: 6,
      right: 6,
    },
    valid: true,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
    dirty() {
      return (
        JSON.stringify(this.facilityEdited) !==
        JSON.stringify(this.facilityOriginal)
      );
    },
  },
  methods: {
    callAction(action) {
      this[action]();
    },
    openGoogleMatch() {
      this.dialogGoogleMatch = true;
    },
    openGoogleMatchInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityEditGoogleMatch",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    openGpsEdit() {
      this.dialogGpsEdit = true;
    },
    openGpsEditInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityEditGps",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    openGpsRangeInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityGpsRange",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    openGpsRange() {
      this.rangeMapDialog = true;
    },
    goToClientPanel() {
      const url = "https://panel.meteor24.pl/admin/facility/visit?facility_id=";
      window.open(url + this.facilityEdited.external_id, "_blank");
    },
    openMeteorClearCacheTool(){
      this.$refs.toolMeteorClearCacheDialog.openDialog();
    },
    goToMessagePanel() {
      const url =
        "http://panelv1.meteor24.pl/index.php?c=OpiekunQueries&obiekt_id=";
      window.open(url + this.facilityEdited.external_id, "_blank");
    },
    goToStatsPanel() {
      
      this.$refs.statsDashboardDialog.openDialog(this.facilityEdited);
    },
    openStatsInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityEditStats",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    sendEmail() {
      this.sendMailDialog = true;
    },
    sendEmailInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityEditSendEmail",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    sendSMS() {
      this.sendSMSDialog = true;
    },
    sendSmsInNewWindow() {
      let routeData = this.$router.resolve({
        name: "facilityEditSendSms",
        params: { id: this.facilityEdited.external_id },
      });
      window.open(routeData.href, "_blank");
    },
    forceSave() {
      if (this.dirty) {
        this.loading = true;
        const facilityToSave = this.facilityEdited;
        this.$store
          .dispatch("UPDATE_FACILITY_SILENT", {
            facility: facilityToSave,
          })
          .then(() => {
            if(this.$refs.snackbar){
              this.$refs.snackbar.success(
                "Dane obiektu " + facilityToSave.external_id + " zostały zapisane"
              );
            }
            this.loading = false;
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              this.$refs.snackbar.error(error.response.data.message);
            } else {
              this.$refs.snackbar.error(
                "Nie udało się zapisać danych obiektu" +
                  this.facilityEdited.external_id
              );
            }
            this.loading = false;
          });
      }
    },
    save() {
      if (!this.valid) {
        this.$refs.snackbar.error("Błąd walidacji");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("UPDATE_FACILITY", {
          facility: this.facilityEdited,
        })
        .then(() => {
          this.facilityEdited = JSON.parse(JSON.stringify(this.FACILITY));

          /*if(this.FACILITY.next_contact_event){
            this.facilityEdited.next_contact_event = this.FACILITY.next_contact_event;
          }*/
          this.setOrginalFacility();
          this.$refs.snackbar.success("Dane obiektu zostały zapisane");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$refs.snackbar.error(error.response.data.message);
          } else {
            this.$refs.snackbar.error("Nie udało się zapisać danych");
          }
          this.loading = false;
        });
    },
    updateFacility() {
      this.facilityEdited = JSON.parse(JSON.stringify(this.FACILITY));
      this.setOrginalFacility();
    },
    reset() {
      this.facilityEdited = JSON.parse(JSON.stringify(this.FACILITY));
      this.$refs.snackbar.success("Cofnięto wprowadzone zmiany");
    },
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
    changeWindowsRatio(ratio) {
      this.windowsRatio = ratio;
    },
    closeWindowHandler(event) {
      if (this.dirty) {
        event.preventDefault();
        event.returnValue = "";
        return event;
      }
    },
    functionalKeysHandler(e) {
      if (e.key === "F4") {
        this.goToClientPanel();
      }
      if (e.key === "F5") {
        this.openGpsRange();
        e.preventDefault();
      }
      if (e.key === "F7") {
        this.openGpsEdit();
        e.preventDefault();
      }
    },
    loadOwnerData() {
      if (!this.facilityEdited.owner) {
        this.facilityEdited.owner = {
          facility_handles: [],
        };
      } else {
        this.facilityEdited.owner.facility_handles.sort(
          (a, b) => parseFloat(a.external_id) - parseFloat(b.external_id)
        );
      }
    },
    setOrginalFacility() {
      this.facilityOriginal = JSON.parse(JSON.stringify(this.FACILITY));
      if (!this.facilityOriginal.owner) {
        this.facilityOriginal.owner = {
          facility_handles: [],
        };
      }
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_FACILITY_SEARCH");
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.facilityEdited = JSON.parse(JSON.stringify(this.FACILITY));
        this.setOrginalFacility();
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });

    window.addEventListener("beforeunload", this.closeWindowHandler);
  },
  created() {
    window.addEventListener("keydown", this.functionalKeysHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.functionalKeysHandler);
  },
  watch: {
    FACILITY: function () {
      this.updateFacility();
    },
    facilityEdited: function () {
      this.loadOwnerData();
    },
    $route() {
      this.loading = true;
      this.$store
        .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
        .then(() => {
          this.facilityEdited = JSON.parse(JSON.stringify(this.FACILITY));
          this.setOrginalFacility();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.facilityEdited = {};
          console.log(error);
        });
    },
  },
};
</script>

<style>
.mainTabs .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
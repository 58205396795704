<template>
  <v-card :loading="loading">
    <v-overlay
      :absolute="true"
      :value="loading"
      color="blue"
    ></v-overlay>
    <v-card-text>
    <v-simple-table v-if="facility.logs.length > 0 && !loading" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Data</th>
            <th class="text-left">Poprzednio</th>
            <th class="text-left">Zmieniono</th>
            <th class="text-left">Użytkownik</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in facility.logs" :key="log.id">
            <td>{{ log.created_at | formatDate('YYYY-MM-DD H:mm') }}</td>
            <td>{{ log.old_value }}</td>
            <td>{{ log.new_value }}</td>
            <td>{{ log.user.username }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card-text>
  </v-card>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "LogList",
  props: ["facility"],
  components: {  },
  data: () => ({
    loading: false,
  }),
  computed: {

  },
  methods: {
  
  },
  mounted() {

  },
  watch: {
    
  }
};
</script>


<style>
</style>
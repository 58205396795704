<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-container>
          <v-form>
            <v-row flat>
              <v-col sm=12>
                <v-text-field
                  label="nazwa"
                  v-model="data.name"
                  :error-messages="nameErrors"
                />
              </v-col>

            </v-row>

          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-1"
              dark
              @click="save()"
            >
              zapisz
            </v-btn>
            <v-btn
              color="warning"
              @click="dialog=false"
            >
              anuluj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  name: "LandForm",
  data: () => ({
    data: {},
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
  }),
  validations: {
    data: {
      name: { required },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) return errors;
      !this.$v.data.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.dialog = false;

    },
  },
  mounted() {},
  watch: {
  
  },
};
</script>


<style>
</style>
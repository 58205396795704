<template>
  <div>
    <v-card-title>{{owner.name}}</v-card-title>
    <v-card-subtitle v-if="owner.user">opiekun: {{owner.user.name }} {{owner.user.surname }}</v-card-subtitle>
    <v-divider></v-divider>

    <v-card-title>Usługi</v-card-title>
    <v-list dense>
      <v-list-item
        v-for="service in owner.services"
        :key="service.id"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-row class="pa-2">
            <v-col  class="text-center">
              <v-chip
                v-if="service.status === 1"
                color="green"
                outlined
              >
                AKTYWNA <div
                  class="ml-1"
                  v-if="service.expires_at"
                >do {{ service.expires_at | formatDate("Y-MM-DD")}}</div>
              </v-chip>
              <v-chip
                v-if="service.status === 2"
  
                color="red"
                outlined
              >
                NIEAKTYWNA
              </v-chip>
            </v-col>
            <v-col>
              {{ service.type.name }} - {{ service.domain.domain }}
            </v-col>
            <v-col class="text-left">
              <v-icon class="mr-2" v-if="service.type.id === 1 && [1,4,6,7].includes(service.project.id)" color="green">new_releases</v-icon>
              <v-btn 
                v-if="service.login_key"
                outlined
                
                color="blue"
                small
                @click="wpRemoteLogin(service.domain.domain,service.login_key)"
              >
                <v-icon >key</v-icon> logowanie
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>


     <div class="text-center py-3 mt-3">
      <v-btn
        color="green"
        dark
        @click="addComment"
      >
        dodaj komentarz
        <v-icon> add </v-icon>
      </v-btn>
    </div>  
    <v-textarea
      name="comment"
      
      label="Komentarz"
      auto-grow
      :value="owner.comment"
      readonly
      outlined
    ></v-textarea>
    <v-textarea
      name="comment"
      
      label="Finanse - Komentarz"
      auto-grow
      :value="owner.invoice_comment"
      readonly
      outlined
    ></v-textarea>
  <SitemanagerOwnerCommentDialog :owner="owner" ref="ownerCommentDialog" />
  </div>
</template>

<script>
import SitemanagerOwnerCommentDialog from "./SitemanagerOwnerCommentDialog"
export default {
  name: "SitemanagerInfoTab",
  props: ["owner","facility"],
  components: {SitemanagerOwnerCommentDialog},
  data: () => ({
    loading: false,
  }),
  computed: {},
  methods: {
    addComment(){
      this.$refs.ownerCommentDialog.show(); 
    },
    wpRemoteLogin(domain,key){
      const form = document.createElement('form');
      form.method = "POST";
      form.action = "https://"+domain;
      form.target = "_blank";
      
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = 'mrl_key';
      hiddenField.value = key;

      form.appendChild(hiddenField);
      document.body.appendChild(form);
      form.submit();
    }
  },
  watch: {
    owner() {},
  },
  mounted() {},
};
</script>


<style>
</style>
<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-card-title class="pb-0 pt-1 text-subtitle-1 font-weight-bold">Odbiorcy</v-card-title>
    <v-alert
      dense
      outlined
      type="error"
      v-if="receiversErrors.length > 0"
      class="mx-3"
    >
      {{ receiversErrors.join('\n') }}
    </v-alert>
    <v-card-text class="pb-0">
      <v-item-group
        multiple
        v-model="message.receivers"
      >
        <v-item
          v-for="(item, i) in addresses"
          :key="i"
          :value="item.email"
          v-slot="{ active, toggle }"
        >
          <v-chip
            filter
            class="mr-2 mb-2"
            active-class="green darken-1 white--text"
            :input-value="active"
            @click="toggle"
          >
            {{ item.email }}
            <span
              v-if="item.description"
              class="px-2"
            >
              <v-icon>label</v-icon>{{item.description}}
            </span>
          </v-chip>
        </v-item>
      </v-item-group>

    </v-card-text>

    <v-card-title class="my-0 pb-0 pt-1 text-subtitle-1 font-weight-bold">Szablony ofert</v-card-title>
    <v-card-text class="pb-0">
      <v-chip-group
        v-model="templateCategory"
        mandatory
        class="mb-3"
      >
        <v-chip
          v-for="category in MESSAGE_TEMPLATE_CATEGORIES"
          :key="category.id"
          :value="category.id"
          active-class="green darken-1 white--text"
          class="my-0"
          @contextmenu="event => categoryPicker(event,category)"
        >
          {{ category.name }}
        </v-chip>
      </v-chip-group>
      <v-autocomplete
        v-if="messageTemplates.length > 0"
        :items="messageTemplates"
        @input="changeTemplate"
        :value="message.template"
        :search-input.sync="templateSearch"
        item-text="name"
        label="wybierz szablon"
        return-object
        hide-details
        dense
      >
        <!--<template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.category.name"></v-list-item-subtitle>
          </v-list-item-content>
        </template>-->
      </v-autocomplete>
    </v-card-text>
    <v-card-title class="my-0 pb-2 pt-2 text-subtitle-1 font-weight-bold">Treść wiadomości</v-card-title>
    <v-card-text>
      <v-text-field
        label="tytuł"
        v-model="message.subject"
        :error-messages="subjectErrors"
        dense
      ></v-text-field>
      <v-alert
        dense
        outlined
        type="error"
        v-if="contentErrors.length > 0"
        class=" mt-5"
      >
        {{ contentErrors.join('\n') }}
      </v-alert>
      <SendMailMiniTemplates @insertMiniTemplate="insertMiniTemplate"></SendMailMiniTemplates>
      <div>
        <ckeditor
          :editor="editor"
          v-model="message.content"
          :config="editorConfig"
          ref="editor"
          @ready="editorReady"
        ></ckeditor>
      </div>
    </v-card-text>

    <v-card-text
      class="py-0"
      v-if="message.template.attached_template_ids && message.template.attached_template_ids.length > 0"
    >
      Załączniki:
      <v-chip
        v-for="templateId in message.template.attached_template_ids"
        :key="templateId"
        @click:close="removeAttachment(templateId)"
        @click="previewAttachment(templateId)"
        outlined
        close
        class="mr-1 mb-1"
      >
        <v-icon>attach_file</v-icon> {{getTemplateNameById(templateId)}}
      </v-chip>
      <v-btn
        class="success ml-2"
        fab
        x-small
        @click="addNewAttachment"
      ><v-icon>add</v-icon></v-btn>

    </v-card-text>

    <SendMailSMSConfirmation :facility="facility" :message="message"/>

    <v-card-actions>
      <v-btn
        color="primary"
        @click="send()"
        :loading="loading"
      > wyślij wiadomość </v-btn>

    </v-card-actions>
    <SendMailChangeTemplateConfirmation
      @confirm="confirmTemplateChange"
      ref="sendMailChangeTemplateConfirmation"
    ></SendMailChangeTemplateConfirmation>
    <SendMailAddAttachment
      ref="addAttachment"
      @attachment:add="addAtachment"
    />
    <SendMailTemplatePicker
      :templates="messageTemplates"
      ref="templatePicker"
      @updateTemplate="changeTemplate"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

import DecoupledEditor from "ckeditor5-build-decoupled-document-base64-imageresize";
//import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
//import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import SendMailChangeTemplateConfirmation from "./SendMail/SendMailChangeTemplateConfirmation.vue";
import SendMailAddAttachment from "./SendMail/SendMailAddAttachment.vue";
import SendMailMiniTemplates from "./SendMail/SendMailMiniTemplates.vue";
import SendMailTemplatePicker from "./SendMail/SendMailTemplatePicker.vue";
import SendMailSMSConfirmation from "./SendMail/SendMailSMSConfirmation.vue";

export default {
  name: "FacilityEditSendMail",
  props: {
    facility: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    SendMailChangeTemplateConfirmation,
    SendMailAddAttachment,
    SendMailMiniTemplates,
    SendMailTemplatePicker,
    SendMailSMSConfirmation
  },
  data: () => ({
    loading: false,
    subscriptionTab: null,
    templateCategory: 1,
    templateSearch: null,
    message: {
      receivers: [],
      template: {},
      SMSConfirmationReceivers: [],
      subject: "",
      content: "",
    },
    editorIsReady: false,
    editorObject: null,
    editor: DecoupledEditor,
    editorData: "",
    editorConfig: {
      height: "180px",
      toolbar: {
        shouldNotGroupWhenFull: true,
      },
    },
  }),
  validations: {
    message: {
      receivers: { required },
      subject: { required },
      content: { required },
    },
  },
  computed: {
    ...mapGetters(["MESSAGE_TEMPLATES"]),
    ...mapGetters(["MESSAGE_TEMPLATE_CATEGORIES"]),
    addresses() {
      let addresses = [];
      let addressesList = [];
      let facilityAddresses = this.facility.facility.addresses.filter(
        (current) => {
          return [7, 8, 9, 12, 13].includes(current.type.id);
        }
      );

      facilityAddresses.forEach((address) => {
        let email = address.address;
        if (!addresses.includes(email)) {
          addresses.push(email);
          addressesList.push({
            email: email,
            description: address.description,
          });
        }
      });

      if (this.facility.people && this.facility.people.length > 0) {
        this.facility.people.forEach((person) => {
          if (person.email.length > 0 && !addresses.includes(person.email)) {
            addresses.push(person.email);
            addressesList.push({
              email: person.email,
              description: person.name,
            });
          }
        });
      }

      return addressesList;
    },
    messageTemplates() {
      const templates = Object.assign([], this.MESSAGE_TEMPLATES);
      templates.sort((a, b) => {
        return a.category.id - b.category.id;
      });
      const filtered = [];
      templates.forEach((el) => {
        if (el.type.id !== 2 && this.templateCategory === el.category.id) {
          filtered.push(el);
        }
      });

      /*const grouped = [];
      let group = "";
      templates.forEach((el) => {
        if (el.type.id !== 2) {
          if (el.category.name !== group) {
            grouped.push({ header: el.category.name });
            grouped.push({ divider: true });
            group = el.category.name;
          }
          grouped.push(el);
        }
      });
      return grouped;*/
      return filtered;
    },
    /**
     * validation errors
     */
    receiversErrors() {
      const errors = [];
      if (!this.$v.message.receivers.$dirty) return errors;
      !this.$v.message.receivers.required && errors.push("wybierz odbiorce");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.message.subject.$dirty) return errors;
      !this.$v.message.subject.required &&
        errors.push("temat wiadomości jest wymagany");
      return errors;
    },
    contentErrors() {
      const errors = [];
      if (!this.$v.message.content.$dirty) return errors;
      !this.$v.message.content.required &&
        errors.push("treść wiadomości jest wymagana");
      return errors;
    },
  },
  methods: {
    send() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.loading = true;
      axios
        .post(`/facilities/${this.facility.id}/messages`, this.message)
        .then(() => {
          this.loading = false;
          this.reset();
          this.$emit("FacilitySendEmail:sended");
        });
    },
    getTemplateNameById(id) {
      const template = this.MESSAGE_TEMPLATES.find(
        (current) => current.id === id
      );
      return template.name;
    },
    editorReady(editor) {
      if (!this.editorIsReady) {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
        this.editorObject = editor;
      }
    },
    reset() {
      this.$v.$reset();
      const dfaultMessage = {
        receivers: [],
        template: {},
        subject: "",
        content: "",
      };
      this.message = JSON.parse(JSON.stringify(dfaultMessage));
    },
    confirmTemplateChange(template) {
      this.message.template = template;
      this.getRender();
    },
    changeTemplate(template) {
      if (this.message.content.length > 0) {
        this.templateSearch = this.message.template.name;
        this.message.template = JSON.parse(
          JSON.stringify(this.message.template)
        );
        this.$refs.sendMailChangeTemplateConfirmation.openDialog(
          JSON.parse(JSON.stringify(template))
        );
      } else {
        this.message.template = JSON.parse(JSON.stringify(template));
        this.getRender();
      }
    },
    getRender() {
      this.loading = true;
      axios
        .post(`/facilities/${this.facility.id}/templateRenders`, this.message)
        .then((response) => {
          this.loading = false;
          this.message.subject = response.data.subject;
          this.message.content = response.data.content;
        });
    },
    addNewAttachment() {
      this.$refs.addAttachment.openDialog();
    },
    addAtachment(template) {
      if (!this.message.template.attached_template_ids.includes(template.id)) {
        this.message.template.attached_template_ids.push(template.id);
      }
    },
    removeAttachment(id) {
      let rs = this.message.template.attached_template_ids.filter((current) => {
        return current !== id;
      });
      this.message.template.attached_template_ids = [...rs];
    },
    insertMiniTemplate(text) {
      if (!this.editorIsReady) {
        this.editorObject.model.change((writer) => {
          writer.insertText(
            text,
            this.editorObject.model.document.selection.getFirstPosition()
          );
        });
        this.editorObject.focus();
      }
    },
    selectReceiver(email) {
      this.addresses.forEach((addresses) => {
        if (email === addresses.email) {
          this.message.receivers.push(email);
        }
      });
    },
    previewAttachment(id) {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `messageTemplates/${id}/${this.facility.id}/previews.pdf`
      );
    },
    categoryPicker(event, category) {
      this.templateCategory = category.id;
      this.$refs.templatePicker.show(event);
    },
  },
  watch: {
    "message.template": function () {
      if (this.message.template.id) {
        //  this.getRender();
      }
    },
    templateCategory: function () {
      // this.reset();
    },
    facility: function () {
      this.message = {
        template: {},
        subject: "",
        content: "",
      };
    },
    "options.selectReceiver": function (email) {
      this.message.receivers = [];
      this.selectReceiver(email);
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_MESSAGE_TEMPLATE_CATEGORIES").then(() => {
      this.$store.dispatch("GET_MESSAGE_TEMPLATES").then(() => {
        this.loading = false;
      });
    });
    if (this.options.selectReceiver) {
      this.selectReceiver(this.options.selectReceiver);
    }
  },
};
</script>


<style>
.ck-editor .ck-editor__main .ck-content {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  min-height: 310px;
  max-height: 560px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}
</style>
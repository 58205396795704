<template>
  <v-form>
    <v-row>
      <v-col sm="1">
        <v-text-field
          label="ID"
          :value="facility.external_id"
          readonly
          dense
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="facility.name"
          label="nazwa"
          dense
          clearable
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          :error-messages="nameErrors"
        ></v-text-field>
      </v-col>
      <v-col sm="3">

        <v-text-field
          v-model="facility.facility.slug"
          label="subdomena"
          clearable
          dense
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          :loading="checkingSlug"
          :error-messages="slugErrors"
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-model="facility.facility.subscriptionEnd"
          label="emisja do"
          readonly
          @click:clear="date = null"
          append-icon="history"
          @click:append="showSubscriptionEndHistory()"
          dense
        ></v-text-field>
        <!--<v-menu
          v-model="subscriptionEndDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="facility.facility.subscriptionEnd"
              label="emisja do"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
              append-icon="history"
              @click:append="showSubscriptionEndHistory()"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            v-model="facility.facility.subscriptionEnd"
            @change="subscriptionEndDatepicker = false"
          ></v-date-picker> 
        </v-menu>-->
      </v-col>
    </v-row>
    <v-dialog
      v-model="facilityLogDialog"
      transition="dialog-top-transition"
      max-width="600"
    >

      <LogList :facility="facility" />
    </v-dialog>

  </v-form>
</template>

<script>
//import { mapGetters } from "vuex";
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
const slug = helpers.regex("slug", /^([0-9a-z-]+)$/);

import LogList from "./FacilityEdit/Log/LogList";

export default {
  name: "FacilityEditFormBasic",
  props: ["facility"],
  components: {
    LogList,
  },
  data: () => ({
    loading: false,
    checkingSlug: false,
    subscriptionEndDatepicker: false,
    facilityLogDialog: false,
  }),
  validations: {
    facility: {
      name: {
        required,
      },
      facility: {
        slug: {
          required,
          slug,
          async unique(value) {
            if (value === "") return true;
            this.checkingSlug = true;
            return new Promise((resolve, reject) => {
              axios
                .get(
                  "facilities?filter[external][slug_eq]=" +
                    value +
                    "&filter[external][id_neq]=" +
                    this.facility.facility.id
                )
                .then(({ data, status }) => {
                  if (status === 200) {
                    this.checkingSlug = false;
                    if (data.result.total_count === 0) {
                      this.$emit("valid");
                    } else {
                      this.$emit("not-valid");
                    }

                    resolve(data.result.total_count === 0);
                  }
                })
                .catch((error) => {
                  this.$emit("not-valid");
                  reject(error);
                });
            });
          },
        },
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.facility.name.$dirty) return errors;
      !this.$v.facility.name.required && errors.push("nazwa jest wymagana");
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (
        !this.$v.facility.facility.slug.$dirty ||
        this.$v.facility.facility.slug.$pending
      )
        return errors;
      !this.$v.facility.facility.slug.required &&
        errors.push("domena jest wymagana");
      !this.$v.facility.facility.slug.slug &&
        errors.push("domena musi składać się z cyfr i liter");
      !this.$v.facility.facility.slug.unique &&
        errors.push("domena jest zajęta");
      return errors;
    },
  },
  methods: {
    isSlugUnique: function (value) {
      console.log(value);
      return true;
    },
    showSubscriptionEndHistory() {
      this.facilityLogDialog = true;
    },
  },
  mounted() {},
  watch: {
    'facility.facility.slug': function(){
      this.facility.facility.slug = 
      this.facility.facility.slug
        .toLowerCase()
        .replace(/\u0142/g, "l")
        .normalize('NFKD')
        .replace(/[^0-9a-z-]/g, '');
    }
  }
};
</script>


<style>
</style>
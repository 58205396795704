<template>
  <div></div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Home',
  components: {
    
  },
  computed: {
      ...mapGetters(["CURRENT_USER"])
  },
  mounted(){
    this.$store.dispatch("GET_CURRENT_USER")
        .then(() => {
          this.$router.push({path: "app"})
        });
  },
}
</script>

<style>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"70"}})],1),_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue","dark":"","dense":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',{attrs:{"dense":""}},[_vm._v(" Kalendarz ")])],1),_c('v-container',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" dzisiaj ")]),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_vm._v(" wybierz dzień ")])]}}]),model:{value:(_vm.setDateDialog),callback:function ($$v) {_vm.setDateDialog=$$v},expression:"setDateDialog"}},[_c('v-date-picker',{attrs:{"locale":"pl","scrollable":""},on:{"input":function($event){_vm.setDateDialog = false}},model:{value:(_vm.setDate),callback:function ($$v) {_vm.setDate=$$v},expression:"setDate"}})],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Dzień")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Tydzień")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Miesiąc")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"700"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","event-name":_vm.eventName,"events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type,"interval-format":_vm.intervalFormat,"first-time":"7:00","interval-count":"22","interval-minutes":"30","weekdays":_vm.weekdays,"locale":"pl"},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"v-event-draggable",domProps:{"innerHTML":_vm._s(eventSummary())}}),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.details)}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('EventEdit',{ref:"eventEdit",on:{"remove":_vm.remove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
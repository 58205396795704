<template>
  <v-container fluid>

    <v-card
      :loading="loading"
      :disabled="loading"
      outlined
    >

      <v-form @keyup.native.enter="check">
        <v-row>
          <v-col sm=5>
            <v-radio-group v-model="options.level">
              <v-radio
                v-for="level in phrases"
                :key="level.id"
                :value="level"
                :label="level.name+' ('+level.phrases.length+')'"
              ></v-radio>
            </v-radio-group>

          </v-col>
          <v-col sm=2>
            <v-radio-group v-model="options.ttl">
              <v-radio
                v-for="item in ttl"
                :key="item.id"
                :value="item"
                :label="item.name"
              ></v-radio>
            </v-radio-group>

          </v-col>
          <v-col sm=2>
            <v-radio-group v-model="options.filter">
              <v-radio
                v-for="item in filter"
                :key="item.id"
                :value="item"
                :label="item.name"
              ></v-radio>
            </v-radio-group>
            <v-row v-if="options.filter.id && options.filter.id === 3">
              <v-col>
                <v-text-field
                  label="od"
                  dense
                  v-model="options.filterGt"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="do"
                  dense
                  v-model="options.filterLt"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="3"
            class="text-center mt-3"
          >
            <v-btn
              color="orange darken-4"
              dark
              @submit="check()"
              @click="check()"
            >
              sprawdź pozycję
            </v-btn>
            <v-btn
              class="ml-2"
              color="white"
              @click="copy()"
              v-if="list.length > 0"
            >
              kopiuj
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </v-card>

    <v-progress-linear
      v-model="progress.percent"
      height="25"
      class="mt-4 white--text"
      color="orange"
      v-if="list.length > 0"
      rounded
      :striped="this.loadingList"
    >
      <strong>sprawdzono: {{progress.checked}}/{{progress.all}} - {{ progress.percent }}%</strong>
    </v-progress-linear>

    <v-card>
      <div id="resultTable">
        <v-data-table
          v-if="list.length > 0"
          v-on:click:row="hideResult"
          dense
          hide-default-footer
          :headers="headers"
          :items="list"
          limit=999
          item-key="phrase"
          class="mt-4 elevation-1"
          disable-pagination
          sort-by="position"
        >
          <template v-slot:body.prepend="{ }">
            <tr v-if="showCopyHeader">
              <td>fraza</td>
              <td>pozycja</td>
              <td>serwis</td>
            </tr>
          </template>
          <template v-slot:item.position="{ item }">
            <v-chip
              :color="getPositionColor(item.position)"
              dark
              small
              outlined
              class="font-weight-bold"
              v-if="item.position !== false"
            >
              {{ item.position }}
            </v-chip>
            <v-progress-circular
              v-if="item.position === false"
              indeterminate
              color="orange"
              small
            ></v-progress-circular>
          </template>
          <template v-slot:item.domain="{ item }">
            <span v-if="item.domain">
              {{ item.domain }}
            </span>
          </template>

        </v-data-table>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["facility"],
  name: "Dashboard",
  data: () => ({
    loading: false,
    loadingList: false,
    showCopyHeader: false,
    options: {
      level: {},
      ttl: {},
      filter: {},
      filterGt: null,
      filterLt: null,
    },
    filter: [
      { id: 1, name: "najwyższe" },
      { id: 2, name: "wszystkie" },
      { id: 3, name: "wybrane" },
    ],
    ttl: [
      {
        id: 1,
        name: "ostatnie  100 dni",
        ttl: 2400
      },
      {
        id: 2,
        name: "7 dni",
        ttl: 168,
      },
      {
        id: 3,
        name: "1 dzień",
        ttl: 24
      },
    ],
    phrases: [],
    orginalList: [],
    list: [],
    listHeaders: [
      { text: "Fraza", value: "phrase" },
      { text: "Pozycja", value: "position" },
      { text: "Domena", value: "domain" },
      { text: "Ostatnio sprawdzowno", value: "ts" }
    ],
    progress: {
      percent: 0,
      all: null,
      checked: null,
    },
  }),
  components: {},
  computed:{
    headers(){
      let rs = this.listHeaders.filter(item => {
          return !this.showCopyHeader ? true : (item.value === "ts") ? false : true ;
      });
      return rs;
    }
  },
  mounted() {
    if (this.facility.id) {
      this.loadPhrases();
    }
  },
  methods: {
    loadPhrases() {
      this.loading = true;
      axios
        .get(`/facilities/` + this.facility.external_id + `/phrases`, {})
        .then((response) => {
          this.phrases = response.data;
          this.options.level = this.phrases[1];
          this.options.ttl = this.ttl[0];
          this.options.filter = this.filter[0];
        })
        .finally(() => (this.loading = false));
    },
    hideResult(item) {
      let rs = this.list.filter((current) => {
        return current.phrase !== item.phrase;
      });

      this.list = [...rs];
    },
    createList() {
      this.list = [];
      if (this.options.level.level < 5) {
        for (let key in this.phrases) {
          let level = this.phrases[key];
          if (level.level <= this.options.level.level) {
            for (let pk in level.phrases) {
              let phrase = level.phrases[pk];
              this.list.push({
                phrase: phrase,
                position: false,
                domain: false,
                hidden: false,
              });
            }
          }
        }
      } else {
        let level = this.phrases[this.options.level.level];
        if (level.level <= this.options.level.level) {
          for (let pk in level.phrases) {
            let phrase = level.phrases[pk];
            this.list.push({
              phrase: phrase,
              position: false,
              domain: false,
              hidden: false,
            });
          }
        }
      }
    },
    copy() {
      this.showCopyHeader = true;
      setTimeout(() => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(document.getElementById("resultTable"));
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        this.showCopyHeader = false;
      }, 200);

      /*
      let copyText = document.getElementById("resultTable").innerText;
      console.log(copyText);
      navigator.clipboard.writeText(copyText);
      const { ClipboardItem } = window;
      const blob = new Blob([copyText], { type: "text/html" });
      console.log(blob);
      
      navigator.clipboard.write({
        'text/html': blob,
      });
  */
    },
    check() {
      this.createList();
      this.countProgress();
      this.checkList();
    },
    checkList() {
      this.loadingList = true;
      axios
        .post(`/googleMatch/check`, {
          phrases: this.list,
          ttl: this.options.ttl.ttl
        })
        .then((response) => {
          this.list = response.data;
          this.orginalList = response.data;
          this.filterList();
          this.countProgress();
          if (this.progress.checked < this.progress.all) {
            setTimeout(() => {
              this.checkList();
            }, 5000);
          }
        })
        .finally(() => (this.loadingList = false));
    },
    filterList() {
      if (this.options.filter.id === 1) {
        let rs = this.list.filter((current) => {
          return current.position !== null && current.position < 11;
        });

        this.list = [...rs];
      }
      if (
        this.options.filter.id === 3 &&
        (this.options.filterGt !== null || this.options.filterLt !== null)
      ) {
        let rs = this.list.filter((current) => {
          if (current.position === null) {
            return false;
          }
          return (
            (this.options.filterGt === null ||
              (this.options.filterGt !== null &&
                current.position >= this.options.filterGt)) &&
            (this.options.filterLt === null ||
              (this.options.filterLt !== null &&
                current.position <= this.options.filterLt))
          );
        });

        this.list = [...rs];
      }
    },
    countProgress() {
      this.progress.all = this.list.length;
      this.progress.checked = 0;
      for (let key in this.list) {
        let row = this.list[key];
        if (row.position !== false) {
          this.progress.checked++;
        }
      }
      this.progress.percent = Math.round(
        (this.progress.checked / this.progress.all) * 100
      );
    },
    getPositionColor(position) {
      if (position === null) {
        return "red";
      }
      if (position <= 3) {
        return "green";
      } else {
        return "orange";
      }
    },
  },
  watch: {
    facility: function () {
      this.list = [];
      this.loadPhrases();
    },
    "options.filter": function () {
      this.list = this.orginalList;
      this.filterList();
    },
    "options.filterGt": function () {
      this.list = this.orginalList;
      this.filterList();
    },
    "options.filterLt": function () {
      this.list = this.orginalList;
      this.filterList();
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="mb-2">

    <v-tooltip
      v-for="button in buttons"
      :key="button.name"
      top
      color="blue"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          class="mr-1 px-1 mb-1 quick-text-button"
          color="blue"
          dark
          @click="insertText(button.template)"
          v-bind="attrs"
          v-on="on"
        >
          {{ button.name }}
        </v-btn>
      </template>
      <span>{{ button.template }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "CommentButtons",
  data() {
    return {
      buttons: [
        {
          name: "DATA",
          template: "",
        },
        {
          name: "BK",
          template: "bk",
        },
        {
          name: "ROZMOWA W SPRAWIE",
          template: "rozmowa w sprawie",
        },
        {
          name: "UZGADNIAM FAKTURE",
          template: "uzgadniam fakturę",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  mounted() {
    this.$store.dispatch("GET_CURRENT_USER").then(() => {
      if (this.CURRENT_USER.quick_texts) {
        this.buttons = this.CURRENT_USER.quick_texts;
      }
    });
  },
  methods: {
    insertText(text) {
      let date = moment(date).format("YYYY-MM-DD H:mm");
      let line =
        "[" + date + "] " + this.CURRENT_USER.username + ", " + text + " ";

      this.$emit("add-comment-line", line);
    },
  },
  watch: {
    CURRENT_USER() {
      if (this.CURRENT_USER.quick_texts) {
        this.buttons = this.CURRENT_USER.quick_texts;
      }
    },
  },
};
</script>


<style>
.quick-text-button {
  min-width: 40px !important;
}
@media (max-width: 1300px) {
  .quick-text-button {
    min-width: 23px !important;
  }
}
</style>
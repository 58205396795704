<template>
  <v-container v-if="invoices && invoices.length > 0">
    <v-card
      class="mb-3"
      v-for="invoice in invoices"
      :key="invoice.id"
      outlined
    >
      <v-card-title>
        <v-row>
          <v-col>
            <div v-if="invoice.number">Faktura {{ invoice.number }}</div>
          </v-col>
          <v-col class="text-right">

            <v-chip
              v-if="invoice.proforma === 1"
              class="ma-2"
              color="red"
              outlined
            >
              PROFORMA
            </v-chip>

            <v-chip
              class="ma-2"
              :color="getStatusColor(invoice.status)"
              outlined
            >
              {{ INVOICE_STATUSES[invoice.status] }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>Utworzono: <span class="font-weight-bold">{{ invoice.created_at }}</span></v-col>
          <v-col>Wystaw: <span class="font-weight-bold">{{ invoice.to_be_issued_at }}</span></v-col>
          <v-col v-if="invoice.issued_at">Wystawiono: <span class="font-weight-bold">{{ invoice.issued_at }}</span></v-col>
          <v-col>Termin płatności: <span class="font-weight-bold">{{ invoice.to_be_payed_in }} dni</span></v-col>
          <v-col v-if="invoice.payed_at">Zapłacono: <span class="font-weight-bold">{{ invoice.payed_at }}</span></v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-simple-table v-if="invoice.invoice_entries.length > 0">
          <thead>
            <tr>
              <th class="text-left">opiekun</th>
              <th class="text-left">wykonawca</th>
              <th class="text-left">usługa</th>
              <th class="text-left">nazwa pozycji</th>
              <th class="text-left">stawka VAT</th>
              <th class="text-left">kwota netto</th>
              <th class="text-left">kwota brutto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in invoice.invoice_entries"
              :key="entry.id"
            >
              <td>{{ entry.user.name }} {{ entry.user.surname }}</td>
              <td>
                <div v-if="entry.developer">{{ entry.developer.name }} {{ entry.developer.surname }}</div>
                <div
                  v-if="!entry.developer"
                  class="text-center"
                >-</div>
              </td>
              <td>
                <div v-if="entry.service">{{ entry.service.type.name }} <span v-if="entry.service.domain">- {{ entry.service.domain.domain }}</span></div>
                <div v-if="!entry.service"> - </div>
              </td>
              <td>{{ entry.invoice_entry_product.name }}</td>
              <td>{{ entry.invoice_entry_product.vat }}%</td>
              <td>{{ entry.amount | formatAmount }} PLN</td>
              <td>
                {{
                  entry.amount +
                  (entry.amount * entry.invoice_entry_product.vat) / 100 | formatAmount
                }}
                PLN
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN
              </td>
              <td class="font-weight-bold red--text">
                {{ total(invoice.invoice_entries) | formatAmount}} PLN
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-row
        dense
        class="pb-5"
      >
        <v-col class="text-center">
          <v-btn
            small
            color="orange"
            class="mr-2 white--text"
            :loading="loading"
            @click="email(invoice)"
            v-if="invoice.status !== 0"
          >
            wyślij email ponownie
          </v-btn>
          <v-btn
            small
            color="blue"
            class="mr-2 white--text"
            :loading="loading"
            @click="invoicePreview(invoice)"
            v-if="invoice.status !== 0"
          >
            podgląd pdf
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import axios from "axios";
import Snackbar from "../../../../../../shared/Snackbar.vue";
export default {
  components: { Snackbar },
  props: ["invoices"],
  name: "InvoiceList",
  data() {
    return {
      INVOICE_STATUSES: {
        0: "do wystawienia",
        1: "niezapłacona",
        2: "zapłacona",
      },
      loading: false,
    };
  },
  computed: {
    // ...mapGetters(["INVOICE_STATUSES"]),
  },
  mounted() {},
  methods: {
    totalAmount: function (entries) {
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalTax: function (entries) {
      return entries.reduce((acc, val) => {
        let tax =
          (parseInt(val.amount) * parseInt(val.invoice_entry_product.vat)) /
          100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      return this.totalAmount(entries) + this.totalTax(entries);
    },
    getStatusColor(status) {
      let colors = [];
      colors[0] = "orange";
      colors[1] = "orange darken-4";
      colors[2] = "green";
      return colors[status];
    },
    email(invoice) {
      this.loading = true;
      axios.post(`/sitemanager/invoices/${invoice.id}/messages`).then(() => {
        this.loading = false;
        this.$refs.snackbar.success("Faktura została wysłana.");
      });
    },
    invoicePreview(invoice) {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `sitemanager/invoices/${invoice.id}/previews.pdf`
      );
    },
  },
  watch: {},
};
</script>


<style>
</style>
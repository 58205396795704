<template>
  <v-card>
    <v-container>
      <v-card-title>Wybierz odbiorców</v-card-title>
      <v-progress-linear
        indeterminate
        color="green"
        v-if="loading"
      ></v-progress-linear>
      <v-item-group
        multiple
        v-model="receivers"
        v-if="emails.length > 0"
      >
        <v-item
          v-for="(item, i) in emails"
          :key="i"
          :value="item.address"
          v-slot="{ active, toggle }"
        >
          <v-chip
            filter
            class="mr-2 mb-2"
            active-class="green darken-1 white--text"
            :input-value="active"
            @click="toggle"
          >
            {{ item.address }} <span
              v-if="item.description"
              class="px-2"
            >
              <v-icon>label</v-icon>{{item.description}}
            </span>
          </v-chip>
        </v-item>
      </v-item-group>
      <v-divider class="mt-2"></v-divider>
      <v-checkbox
        label="załącz potwierdzenie zlecenia"
        v-model="options.attachConfirmation"
      ></v-checkbox>
      <v-checkbox
        v-if="invoice.is_proforma"
        class="mt-0"
        label="dodaj link do płatności online"
        v-model="options.paymentLink"
      ></v-checkbox>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="orange darken-1"
          text
          @click="$emit('close')"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="green darken-1"
          dark
          :loading = "sendingEmail"
          @click="sendEmail()"
        >
          Wyślij
        </v-btn>

      </v-card-actions>
    </v-container>
    <Snackbar ref="snackbar" />
  </v-card>

</template>

<script>
import axios from "axios";
import Snackbar from "./../../shared/Snackbar.vue";
import { mapGetters } from "vuex";

export default {
  components: { Snackbar },
  props: ["invoice"],
  name: "SendInvoice",
  data: () => ({
    emails: [],
    loading: false,
    receivers: [],
    options: {
      attachConfirmation: true
    },
    sendingEmail: false
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    getEmails() {
      const id = (this.invoice.facility)? this.invoice.facility.external_id : ((this.FACILITY.external_id)? this.FACILITY.external_id : null); 
      if(!id){
        return;
      }
      axios
        .get(`/facilities/` + id + `/emails`, {})
        .then((response) => {
          this.emails = response.data;
        })
        .finally(() => (this.loading = false));
    },
    sendEmail() {
      if (this.receivers.length === 0) {
        this.$refs.snackbar.error("Wybierz odbiorców");
      } else {
        this.sendingEmail = true;
        axios
          .post(`/invoices/${this.invoice.id}/messages`, {
            receivers: this.receivers,
            options: this.options
          })
          .then(() => {
            this.sendingEmail = false;
            this.$refs.snackbar.success("Email został poprawnie wysłany");
            this.$emit('close');
          });
      }
    },
  },
  watch: {},
};
</script>


<style>
</style>
<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col>
          <v-menu
            v-model="toBeIssuedAtDatepicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="invoiceEdited.to_be_issued_at"
                label="kiedy wystawić"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                :disabled="invoiceEdited.status != 0"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              :first-day-of-week="1"
              v-model="invoiceEdited.to_be_issued_at"
              @change="toBeIssuedAtDatepicker = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col>
          <v-text-field
            v-model="invoiceEdited.to_be_payed_in"
            label="termin płatnośći"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="numer faktury"
            v-model="invoiceEdited.number"
            :disabled="invoiceEdited.status !== 1"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-menu
            v-model="datepickerIssuedAt"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="invoiceEdited.issued_at"
                label="data wystawienia"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="invoiceEdited.status == 0"
                @click:clear="date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="invoiceEdited.issued_at"
              @change="datepickerIssuedAt = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row
        v-for="entry in invoiceEdited.invoice_entries"
        :key="entry.id"
      >

        <v-col>
          <v-select
            v-model="entry.product"
            :items="INVOICE_PRODUCTS"
            item-text="name"
            item-value="id"
            label="produkt"
            return-object
            required
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-currency-field
            v-model="entry.amount"
            label="kwota netto"
            suffix="PLN"
            :value-as-integer="true"
            dense
          ></v-currency-field>
        </v-col>

      </v-row>

    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange darken-1"
        text
        @click="reset(); $emit('close')"
      >
        Anuluj
      </v-btn>
      <v-btn
        color="green darken-1"
        dark
        :loading="loading"
        @click="save()"
      >
        Zapisz
      </v-btn>

    </v-card-actions>
    <Snackbar ref="snackbar" />
  </v-card>

</template>

<script>
import Snackbar from "./../../shared/Snackbar.vue";
import { mapGetters } from "vuex";

export default {
  components: { Snackbar },
  props: ["invoice"],
  name: "EditInvoice",
  data: () => ({
    loading: false,
    toBeIssuedAtDatepicker: false,
    datepickerIssuedAt: false,
    invoiceEdited: {}
  }),
  computed: {
    ...mapGetters(["INVOICE_PRODUCTS"]),
  },
  mounted() {
    this.$store.dispatch("GET_INVOICE_PRODUCTS").then();
    this.reset();
  },
  methods: {
    reset(){
      this.invoiceEdited = JSON.parse(JSON.stringify(this.invoice));
    },
    save(){
      this.loading = true;
      this.$store
        .dispatch("UPDATE_INVOICE", {
          invoice: this.invoiceEdited
        })
        .then(() => {
          this.$refs.snackbar.success("Dane zostały zapisane");
          this.loading = false;
          this.$emit('close');
        })
        .catch((error) => {
          console.log(error);
    
        });
    }
  },
  watch: {},
};
</script>


<style>
</style>
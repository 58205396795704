import axios from "axios";
import qs from 'qs'

export default {
    state: {
        facility: {},
        facilityLogs: [],
        facilityEdited: {},
        facilities: [],
        pagination: {
            currentPage: 1,
            itemsPerPage: 20
        },
        filter: {},
        search: {},
        sortBy: false,
        sortDesc: false,
        formSettings: {
            advancedSearch: false
        }
    },
    getters: {
        FACILITY: state => {
            return state.facility;
        },
        FACILITY_EDITED: state => {
            return state.facilityEdited;
        },
        FACILITIES: state => {
            return state.facilities;
        },
        FACILITIES_PAGINATION: state => {
            return state.pagination;
        },
        FACILITIES_FILTER: state => {
            return state.filter;
        },
        FACILITY_SEARCH: state => {
            return state.search;
        },
        FACILITY_LOGS: state => {
            return state.facilityLogs;
        },
        FACILITY_SEARCH_FORM_SETTINGS: state => {
            return state.formSettings;
        },
        FACILITY_SEARCH_SORT_BY: state => {
            return state.sortBy;
        },
        FACILITY_SEARCH_SORT_DESC: state => {
            return state.sortDesc;
        }
    },
    mutations: {
        SET_FACILITIES: (state, payload) => {
            state.facilities = payload;
        },
        SET_FACILITY: (state, payload) => {
            state.facility = payload;
        },
        CLEAR_CURRENT_FACILITY: (state) => {
            state.facility = {};
        },
        SET_FACILITY_EDITED: (state, payload) => {
            state.facilityEdited = payload;
        },
        SET_FACILITY_LOGS: (state, payload) => {
            state.facilityLogs = payload;
        },
        RESET_FACILITY_EDITED: (state) => {
            state.facility = state.facilityEdited;
        },
        UPDATE_FACILITY: (state,  payload) => {
            if (payload) {
                const element = state.facilities.find(facility => facility.id === payload.id);
                Object.assign(element, payload);
            }
           
        },
        ADD_FACILITY: (state, payload) => {
            state.facilities.unshift(payload);
        },
        /*
        REMOVE_FACILITY: (state, facility) => {
            let rs = state.facilities.filter(currentDomain => {
                return currentDomain.id !== facility.id;
            });

            state.facilities = [...rs];
        },*/
        UPDATE_FILTER: (state, filter) => {
            state.filter = filter;
        },
        UPDATE_SORT: (state, { sortBy, sortDesc }) => {
            state.sortBy = sortBy;
            state.sortDesc = sortDesc;
        },
        SET_FACILITIES_PAGINATION: (state, payload) => {
            state.pagination = payload;
        },
        SET_FACILITIES_PAGINATION_CURRENT_PAGE: (state, payload) => {
            state.pagination.currentPage = payload;
        },
        SET_FACILITIES_PAGINATION_ITEMS_PER_PAGE: (state, payload) => {
            state.pagination.itemsPerPage = payload;
        },
        SET_FACILITY_SEARCH: (state, payload) => {
            state.search = payload;
        },
        SET_FACILITY_SEARCH_FORM_SETTINGS: (state, payload) => {
            state.formSettings = payload;
        },

    },
    actions: {
        /*
        GET_FACILITIES_PER_PAGE: ({commit},{pageNumber}) => {
            var url = "/api/facilities/?page="+pageNumber;
            return fetch(url)
                .then(result => {
                    return result.json()
                })
                .then((result) => {
                    commit("SET_FACILITIES", result.items)
                    commit("SET_FACILITIES_PAGINATION", {
                        currentPage: result.current_page_number,
                        itemsPerPage: result.num_items_per_page,
                        total: result.total_count
                    });
                })
        },
        */
        RESET_FACILITY_EDITED:  ({commit}) => {
            commit("RESET_FACILITY_EDITED");
        },
        GET_FACILITY_BY_ID: async ({commit},{id}) => {
            let { data } = await axios.get(`facilities/`+id);
            commit("SET_FACILITY", data);
            commit("SET_FACILITY_EDITED", data);
        },
        GET_FACILITIES: ({commit}) => {
            return fetch(`/api/facilities`)
                .then(result => {
                    return result.json()
                })
                .then((result) => {
                    commit("SET_FACILITIES", result.items);
                    commit("SET_FACILITIES_PAGINATION", {
                        currentPage: result.current_page_number,
                        itemsPerPage: result.num_items_per_page,
                        total: result.total_count
                    });
                })
        },
       GET_FACILITY_SEARCH: ({commit}) => {
            if (localStorage.getItem('facility/search')) {
                commit("SET_FACILITY_SEARCH", JSON.parse(localStorage.getItem('facility/search')));
             }
        },
        GET_FACILITY_LOGS: ({ commit }, { id }) => {
            return new Promise((resolve, reject) => {
                axios
                .get(`facilities/${id}/logs`)
                .then(({ data, status }) => {
                  if (status === 200) {
                      commit("SET_FACILITY_LOGS", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
       GET_FACILITY_SEARCH_FORM_SETTINGS: ({commit}) => {
            if (localStorage.getItem('facility/search/settings')) {
                commit("SET_FACILITY_SEARCH_FORM_SETTINGS", JSON.parse(localStorage.getItem('facility/search/settings')));
             }
        },
        FIND_FACILITIES: ({commit,state}, { filter,pageNumber }) => {
            let query = "?"
            if(pageNumber){
                query += "page="+pageNumber+"&";
            }

            if(state.pagination.itemsPerPage){
                query += "limit="+state.pagination.itemsPerPage+"&";
            }

            if(state.sortBy){
                query += "sortBy="+state.sortBy+"&";
            }

            if(state.sortDesc){
                query += "sortDesc="+state.sortDesc+"&";
            }

            if(state.filter){
                filter = state.filter;
            }
            query += "&"+ qs.stringify({filter: filter}, { encode: false });

            return new Promise((resolve, reject) => {
                axios
                  .get(`facilities`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_FACILITIES", data.result.items);
                        localStorage.setItem('facility/search',JSON.stringify(data.list));
                        commit("SET_FACILITY_SEARCH", data.list);
                        commit("SET_FACILITIES_PAGINATION", {
                            currentPage: data.result.current_page_number,
                            itemsPerPage: data.result.num_items_per_page,
                            total: data.result.total_count
                        });
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });

        },
        UPDATE_FACILITY_SILENT: ({ commit }, { facility }) => {
            return new Promise((resolve, reject) => {
                axios
                .patch(`facilities/${facility.id}`, facility)
                .then(({ data, status }) => {
                  if (status === 200) {
                      //commit("UPDATE_FACILITY", data);
                      //commit("SET_FACILITY", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
        UPDATE_FACILITY: ({ commit }, { facility }) => {
            return new Promise((resolve, reject) => {
                axios
                .patch(`facilities/${facility.id}`, facility)
                .then(({ data, status }) => {
                  if (status === 200) {

                      //commit("UPDATE_FACILITY", data);
                      commit("SET_FACILITY", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
        ADD_FACILITY: ({ commit }, { facility }) => {
            return new Promise((resolve, reject) => {
                axios
                .post(`facilities`, facility)
                .then(({ data, status }) => {
                  if (status === 200) {
                      commit("ADD_FACILITY", data.result);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },

        /*
        DELETE_FACILITY: ({ commit }, { facility }) => {
            return new Promise(async (resolve, reject) => {
                let { data, status } = await axios.delete(`/api/facilities/${facility.id}`, facility);
                if (status === 204 || status === 200) {
                    commit("REMOVE_FACILITY", facility);
                    resolve({ data, status });
                } else {
                    reject({ data, status });
                }
            });
        },*/
        FACILITY_CLEAR_CURRENT: ({ commit }) => {
           commit("CLEAR_CURRENT_FACILITY");
        },
        FILTER_FACILITIES: ({ commit }, { filter }) => {
            commit("UPDATE_FILTER", filter);
        },
        SORT_FACILITIES: ({ commit }, { sortBy, sortDesc }) => {
            commit("UPDATE_SORT", { sortBy, sortDesc });
        },
        SET_FACILITIES_PAGINATION_CURRENT_PAGE: ({ commit }, { currentPage }) => {
            commit("SET_FACILITIES_PAGINATION_CURRENT_PAGE", currentPage);
        },
        SET_FACILITIES_PAGINATION_ITEMS_PER_PAGE: ({ commit }, { itemsPerPage }) => {
            commit("SET_FACILITIES_PAGINATION_ITEMS_PER_PAGE", itemsPerPage);
        },
        SET_FACILITY_SEARCH_FORM_SETTINGS: ({ commit }, { settings }) => {
            localStorage.setItem('facility/search/settings',JSON.stringify(settings));
            commit("SET_FACILITY_SEARCH_FORM_SETTINGS", settings);
        },
        CLEAR_FACILITY_SEARCH: ({commit}) => {
            if (localStorage.getItem('facility/search')) {
                localStorage.removeItem('facility/search')
            }
            commit("SET_FACILITY_SEARCH", {});
        },
    }
}
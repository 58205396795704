<template>
 <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color = "color"
      right
    >
      {{ text }}

    </v-snackbar>
</template>

<script>

export default {
  name: "Snackbar",
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      text: "",
      color: ""
    };
  },
  computed: {
  },
  methods: {
    show(data) {
      this.text = data.text;
      this.color = data.color || 'success'
      this.snackbar = true;
    },
    error(data) {
      this.text = data;
      this.color = 'error';
      this.snackbar = true;
    },
    success(data) {
      this.text = data;
      this.color = 'success';
      this.snackbar = true;
    }
  },
  mounted() {
    
  },
  watch: {
  
  },
};
</script>


<style>
</style>
<template>
  <v-container fluid>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
    <div class="text-center">
      <v-btn
        @click="openFacilityCreateFormDialog"
        color="green"
        class="white--text"
      >dodaj nowy obiekt</v-btn>
    </div>
    <v-btn
      elevation="2"
      fab
      fixed
      bottom
      right
      @click="openRegistrationTestDialog"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <FacilityCreateRegistration />
    <FacilityCreateForm ref="facilityCreateForm" />
    <Snackbar ref="snackbar" />
    <RegistrationTest ref="registrationTest" />
  </v-container>
</template>

<script>
import Snackbar from "./../shared/Snackbar";
import FacilityCreateForm from "./FacilityCreate/FacilityCreateForm";
import FacilityCreateRegistration from "./FacilityCreate/FacilityCreateRegistration";
import RegistrationTest from "./FacilityCreate/Registration/RegistrationTest.vue";

export default {
  name: "FacilityCreate",
  components: {
    FacilityCreateForm,
    FacilityCreateRegistration,
    Snackbar,
    RegistrationTest,
  },
  data: () => ({
    loading: false,
    newFacility: {
    },
  }),
  computed: {},
  methods: {
    openFacilityCreateFormDialog() {
      this.$refs.facilityCreateForm.showDialog();
    },
    openRegistrationTestDialog(){
      this.$refs.registrationTest.showDialog();
    }
  },
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
<template>
  <div>
    <form ref="form">
        <v-container>
          <v-row>
            <v-col >
              <v-text-field
                name="name"
                label="właściciel + nazwa (imię i nazwisko + w nawiasie nazwa firmy/obiektu)"
                v-model="form.name"
                :error-messages="nameErrors"
                required
                @input="$v.form.name.$touch()"
                @blur="$v.form.name.$touch()"
              ></v-text-field>
            </v-col>
           
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                name="email"
                label="email"
                v-model="form.email"
                :error-messages="emailErrors"
                @input="$v.form.email.$touch()"
                @blur="$v.form.email.$touch()"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                name="phone"
                label="telefon komórkowy"
                v-model="form.phone"
                :error-messages="phoneErrors"
                @input="$v.form.phone.$touch()"
                @blur="$v.form.phone.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md=8>
              <v-text-field
                name="invoice_data"
                label="dane do faktury"
                v-model="form.invoice_data"
                :error-messages="invoiceDataErrors"
                @input="$v.form.invoice_data.$touch()"
                @blur="$v.form.invoice_data.$touch()"
              ></v-text-field>
            </v-col>
            <v-col md=4>
              <v-text-field
                name="tax_id"
                label="NIP"
                v-model="form.tax_id"
                required
                :error-messages="taxIdErrors"
                @input="$v.form.tax_id.$touch()"
                @blur="$v.form.tax_id.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert type="info">Aktualne dane w MeteorCRM: NIP {{ facility.client.tax_number }}, {{ facility.client.invoice_data }}</v-alert>
        </v-container>
      </form>
      <div class="text-center">
      <v-btn
        color="primary"
        class="white--text"
        @click="save"
        :loading = "loading"
      >
       zapisz
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required, helpers, email, numeric } from "vuelidate/lib/validators";
import axios from 'axios'

const taxIdPattern = helpers.regex(
  "taxIdPattern",
  /^\d{10}$/
);

export default {
  name: "SitemanagerEditTab",
  props: ["owner","facility"],
  components: {  },
  data: () => ({
    loading: false,
    form: {}
  }),
  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: { required, numeric },
      invoice_data: { required },
      tax_id: { taxIdPattern },
    },
  },
  computed: {
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Telefon jest wymagany.");
      !this.$v.form.phone.numeric &&
        errors.push("Podaj poprawny numer telefonu.");
      return errors;
    },
    taxIdErrors() {
      const errors = [];
      if (!this.$v.form.tax_id.$dirty) return errors;
      !this.$v.form.tax_id.taxIdPattern &&
        errors.push("NIP musi składać się z 10 cyfr.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("Email jest wymagany.");
      !this.$v.form.email.email && errors.push("Wpisz poprawny adres email.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Nazwa jest wymagana.");
      return errors;
    },
    invoiceDataErrors() {
      const errors = [];
      if (!this.$v.form.invoice_data.$dirty) return errors;
      !this.$v.form.invoice_data.required && errors.push("Dane są wymagane.");
      return errors;
    },
  },
  methods: {
    save(){
      this.loading = true;
      axios
        .patch(`/sitemanager/owners/${this.owner.id}`,this.form)
        .then(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    owner: function (owner) {
      this.form = JSON.parse(JSON.stringify(owner));
    },
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.owner)); 
  },
};
</script>


<style>
</style>
<template>
  <v-container fluid>
    <div class="calendar">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <v-btn
                :disabled="todayIsStart || loading"
                v-on:click="prevPeriod"
                fab
                small
              >
                <v-icon>arrow_back_ios_new</v-icon>
              </v-btn>
            </div>
            <div class="col text-center">
              <v-btn
                :disabled="todayIsStart || loading"
                v-on:click="todayPeriod"
                fab
                small
              >
                <v-icon>restart_alt</v-icon>
              </v-btn>
            </div>
            <div class="col text-right">
              <v-btn
                :disabled="loading"
                v-on:click="nextPeriod"
                fab
                small
              >
                <v-icon>arrow_forward_ios</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <v-row
        no-gutters
        class="mt-6"
      >
        <v-col
          md=3
          lg="2"
        >
          <div class="text-center calendar-col-header">osoba</div>
          <div class="slots">
            <div
              class="mt-1"
              v-for="usersWorkScheduleExption in usersWorkScheduleExptions"
              :key="usersWorkScheduleExption.user.id"
              v-show="usersWorkScheduleExption.user.active"
            >
              <v-alert
                color="blue-grey lighten-4"
                dark
                dense
                class="ma-0 pa-0"
                outlined
              >
                <v-tooltip
                  bottom
                  :color="activityColor(usersWorkScheduleExption.user)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="activityColor(usersWorkScheduleExption.user)"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                    >circle</v-icon>
                  </template>
                  <span v-if="usersWorkScheduleExption.user.last_activity_at">online {{ calculateFromNow(usersWorkScheduleExption.user.last_activity_at) }}</span>
                </v-tooltip>
                <span
                  class="grey--text text--darken-3 ml-1"
                  style="font-size:14px;"
                >{{ usersWorkScheduleExption.user.name }}</span>
              </v-alert>
            </div>
          </div>
        </v-col>
        <div
          v-for="day in days"
          v-bind:key="day.start"
          :class='day.date === today.format("Y-MM-DD") ? "col calendar-col calendar-col-highlighted" : "col calendar-col"'
        >
          <div class="text-center calendar-col-header">{{ day.label }}</div>
          <div class="slots">
            <div
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>

            <div
              class="ma-1"
              v-for="usersWorkScheduleExption in usersWorkScheduleExptions"
              :key="usersWorkScheduleExption.user.id"
              v-show="usersWorkScheduleExption.user.active"
            >
              <template v-if="!day.weekend">
                <CalendarExceptionCell
                  v-if="usersWorkScheduleExption.exceptions[day.date]"
                  :exception="usersWorkScheduleExption.exceptions[day.date]"
                />
                <template v-if="!usersWorkScheduleExption.exceptions[day.date]">
                  <v-tooltip bottom color="blue-grey">
                    <template v-slot:activator="{ on, attrs }">
                      <v-alert
                        color="blue-grey"
                        dense
                        outlined
                        class="text-center ma-0 pa-0"
                        :style="CURRENT_USER.id === usersWorkScheduleExption.user.id ? 'cursor:pointer;' : ''"
                        v-bind="attrs"
                        v-on="on"
                        @click="addUsersWorkScheduleExption(day)"
                      >
                        <div
                          style="max-width:80px;margin:3px auto;"
                          v-if="usersWorkScheduleExption.user.work_schedule"
                          class="text-caption text-truncate"
                        >{{ usersWorkScheduleExption.user.work_schedule.standardStart }} - {{ usersWorkScheduleExption.user.work_schedule.standardEnd }}</div>
                        <div
                          style="max-width:80px;margin:3px auto;"
                          v-if="!usersWorkScheduleExption.user.work_schedule"
                          class="text-caption text-truncate"
                        >8:00 - 16:00</div>
                      </v-alert>
                    </template>
                    <p class="font-weight-bold text-center">standardowe godziny pracy</p>
                    <p
                      v-if="usersWorkScheduleExption.user.work_schedule"
                      class="mb-0 text-center"
                    >{{ usersWorkScheduleExption.user.work_schedule.standardStart }} - {{ usersWorkScheduleExption.user.work_schedule.standardEnd }}</p>
                    <p
                      v-if="!usersWorkScheduleExption.user.work_schedule"
                      class="mb-0 text-center"
                    >8:00 - 16:00</p>
                  </v-tooltip>
                </template>
              </template>
              <template v-else>
                <v-alert
                  color="blue-grey lighten-4"
                  dense
                  outlined
                  class="text-center ma-0"
                >

                </v-alert>
              </template>

            </div>
          </div>
        </div>
      </v-row>
    </div>
    <WorkingScheduleExceptionCreateDialog ref="workingScheduleExceptionCreateDialog" @updated="getData"/>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import CalendarExceptionCell from "./CoworkersStatusCalendar/CalendarExceptionCell.vue";
import WorkingScheduleExceptionCreateDialog from "./../UserEdit/UserEditWorkingSchedule/WorkingScheduleExceptionCreateDialog.vue";
export default {
  name: "CoworkersStatusCalendar",
  data: () => ({
    usersWorkScheduleExptions: {},
    today: moment(),
    days: [],
    limit: 7,
    start: moment(),
    end: moment(),
    todayIsStart: true,
    slots: [],
    loading: false,
    window: {
      width: 0,
      height: 0,
    },
  }),
  components: { CalendarExceptionCell, WorkingScheduleExceptionCreateDialog },
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  mounted() {},
  created() {
    //moment.locale("pl");

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    getData() {
      this.usersWorkScheduleExptions = {};
      this.loading = true;
      axios
        .get(`/users/workScheduleExceptions`)
        .then((response) => {
          this.usersWorkScheduleExptions = response.data;
        })
        .finally(() => (this.loading = false));
      this.calculateCalendar();
    },
    addUsersWorkScheduleExption(day){
      this.$refs.workingScheduleExceptionCreateDialog.openDialog();
      this.$refs.workingScheduleExceptionCreateDialog.addDate(day.date);
    },
    nextPeriod: function () {
      this.start.add(this.limit, "days");
      this.calculateCalendar();
    },
    todayPeriod: function () {
      this.start = moment(this.today);
      this.calculateCalendar();
    },
    prevPeriod: function () {
      this.start.subtract(this.limit, "days");
      this.calculateCalendar();
    },
    calculateCalendar: function () {
      this.days = [];

      var date = moment(this.start).weekday(0);
      for (var index = 0; index < this.limit; index++) {
        this.days.push({
          date: date.format("Y-MM-DD"),
          label: date.format("dd, D MMM"),
          weekend: date.day() == 6 || date.day() == 0,
        });
        date = date.add(1, "days");
      }
      this.checkIfStartIsToday();
      // this.getSlots();
    },
    checkIfStartIsToday: function () {
      if (
        moment(this.start).format("Y-MM-DD") ===
        moment(this.today).format("Y-MM-DD")
      ) {
        this.todayIsStart = true;
      } else {
        this.todayIsStart = false;
      }
    },
    calculateMinutesFromNow(date) {
      if (!date) {
        return 525600;
      }
      let exp = moment(date);
      return moment().diff(exp, "minutes");
    },
    calculateFromNow(date) {
      if (!date) {
        return "";
      }
      //moment.locale("pl");
      return moment(date).fromNow();
    },
    activityColor(user) {
      if (!user.last_activity_at) {
        return "grey";
      }
      let exp = moment(user.last_activity_at);
      let minutes = moment().diff(exp, "minutes");
      return minutes < 10 ? "green" : minutes < 30 ? "orange" : "red";
    },
    handleResize: function () {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  watch: {
    "window.width": function () {
      this.limit = 7;
      if (this.window.width < 500) {
        this.limit = 4;
      }
      if (this.window.width > 1600) {
        this.limit = 14;
      }

      this.calculateCalendar();
    },
  },
};
</script>
<style>
.calendar .v-alert {
  min-height: 28px;
  padding: 5px 10px;
}

.calendar-col {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.calendar-col-highlighted {
  background: #fff59d;
}

.calendar-col-highlighted .v-alert{
  background: #FFFFFF!important;
  opacity:0.9;
}
.calendar-col:last-of-type {
  border: 0;
}
.calendar-col-header {
  font-weight: bold;
}
</style>
<template>
  <div>
    <v-row>
      <v-col>
        <v-alert
          v-if="invoice.comment_internal"
          color="red darken-2 mb-3"
          outlined
        >
          <div class="red--text text--darken-2">
            <span class="font-weight-bold">komentarz dla wystawiającego:</span>
            {{invoice.comment_internal}}
          </div>
        </v-alert>
        <v-alert
          v-if="invoice.comment"
          color="green darken-2 mb-3"
          dark
          outlined
        >
          <div class="grey--text text--darken-3">
            <span class="font-weight-bold">komentarz dla klienta:</span>
            {{invoice.comment}}
          </div>
        </v-alert>
        <v-alert
          color="blue darken-2"
          dark
          outlined
        >
          <div class="grey--text text--darken-2">
            <p
              v-if="invoice.facility.external_id"
              class="font-weight-bold"
            ><v-btn small color="primary" class="mr-2" :href="getFacilityUrl(invoice.facility.external_id)">
              <v-icon>arrow_forward</v-icon>
            </v-btn>ID: {{ invoice.facility.external_id }}</p>            
            <p>DANE: {{ invoice.facility.client.invoice_data }}</p>
            <p>NIP: {{ invoice.facility.client.tax_number }}</p>
          </div>
        </v-alert>
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="invoice.status < 2 && $acl.check('accountant') && !invoice.related_invoices.length > 0"
          color="info"
          class="mr-2"
          @click="openEditDialog()"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="(invoice.status != 0 || (invoice.is_proforma && invoice.status === 1)) && invoice.facility.client.invoice_type === 0"
          color="white"
          class="mr-2"
          @click="envelope()"
        >
          <v-icon>mail_outline</v-icon>
        </v-btn>

        <v-btn
          v-if="(invoice.status != 0 || (invoice.is_proforma && invoice.status === 1)) && invoice.facility.client.invoice_type === 0"
          color="white"
          class="mr-2"
          @click="confirmation()"
        >
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn
          v-if="invoice.status == 0 && $acl.check('accountant')"
          dark
          color="orange darken-3"
          @click="openIssueDialog()"
        >wystaw
        </v-btn>
        <template v-if="(invoice.status != 0 || (invoice.is_proforma && invoice.status === 1)) &&  invoice.status != 3">
        <v-menu

          rounded
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="orange"
              class="mr-2 white--text"
              :loading="sendingEmail"
              v-bind="attrs"
              v-on="on"
            >
              email
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title @click="invoicePreview()">podgląd faktury</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="confirmation()"
            >
              <v-list-item-title>podgląd potwierdzenia</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              :class="invoice.last_email_sent_at?'orange--text':'green--text'"
              @click="email()"
            >
              <v-list-item-title>wyślij email{{ invoice.lastEmailSentAt }}<span v-if="invoice.last_email_sent_at"> ponownie</span></v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
        </template>
        <v-btn
          v-if="invoice.status == 1 && invoice.number !== ''  && $acl.check('accountant')"
          dark
          color="green"
          @click="payedDialog = true"
        >dodaj wpłatę
        </v-btn>
        
        <v-btn
          v-if="invoice.status != 0 &&  invoice.status != 3 && invoice.is_proforma === 0 && $acl.check('accountant')"
          dark
          color="red darken-3"
          class="ml-2"
          @click="correctionDialog=true"
        >korekta
        </v-btn>
        
        <v-btn
          v-if="invoice.status === 3 && invoice.is_proforma === 0 && invoice.correction_delivered_at === null && $acl.check('accountant')"
          dark
          color="red darken-3"
          class="ml-2"
          @click="correctionDeliveredDialog=true"
        >korekta dostarczona
        </v-btn>

        <v-badge
          v-if="invoice.status == 1"
          color="red"
          :content="debtInfoLines"
          overlap
          :value="debtInfoLines>0?1:0"
        >
          <v-btn
            v-if="invoice.status == 1"
            color="grey darken-3"
            class="ml-2 white--text"
            @click="openDebtInfo()"
          >
            windykacja 
          </v-btn>
        </v-badge>
        <v-btn
            v-if="(invoice.status === 0 || invoice.is_proforma === 1) && $acl.check('accountant')"
            color="red darken-3"
            class="ml-2 white--text"
            :loading="loadingRemove"
            @click="remove()"
          >
            usuń 
          </v-btn>
          <div v-if="invoice.last_email_sent_at" class="text-caption mt-3">Wysłano dokumenty przez email: {{ invoice.last_email_sent_at }}</div>
          <div v-if="invoice.correction_delivered_at" class="text-caption mt-3">Korekta odebrana: {{ invoice.correction_delivered_at }}</div>
      </v-col>
     
    </v-row>
  
    <v-dialog
      v-model="correctionDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-text class="pt-4">
          <v-text-field
            label="numer faktury"
            v-model="invoiceCorrectionNumber"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="correctionDialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="red darken-1"
            dark
            @click="correct()"
          >
            Koryguj
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="correctionDeliveredDialog"
      persistent
      max-width="600px"
    >
      <CorrectionDelivered :invoice="invoice" @close="correctionDeliveredDialog = false"/>
    </v-dialog>

    <v-dialog
      v-model="nameDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-text-field
                label="numer faktury"
                v-model="invoiceNumber"
              ></v-text-field>  
            </v-col>
            <v-col>
              <v-menu
                v-model="datepickerIssuedAt"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    v-model="issuedAt"
                    label="data wystawienia"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                    
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pl-pl"
                  no-title
                  v-model="issuedAt"
                  @change="datepickerIssuedAt = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="nameDialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue darken-1"
            dark
            @click="issue()"
          >
            Wystaw
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payedDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-currency-field
                label="kwota "
                suffix="PLN"
                v-model="newPayment.amount"
                :value-as-integer="true"
              ></v-currency-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="payedAtDatepicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    v-model="newPayment.payed_at"
                    label="Kiedy zapłacono"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pl-pl"
                  no-title
                  v-model="newPayment.payed_at"
                  @change="payedAtDatepicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="payedDialog = false"
            >
              Anuluj
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              @click="addPayment()"
            >
              Dodaj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sendInvoiceDialog"
      persistent
      max-width="600px"
    >
    <SendInvoice :invoice="invoice" @close="sendInvoiceDialog = false"></SendInvoice>
      
    </v-dialog>

    <v-dialog
      v-model="editInvoiceDialog"
      persistent
      max-width="800px"
    >
    <EditInvoice :invoice="invoice" @close="editInvoiceDialog = false"></EditInvoice>
      
    </v-dialog>

    <SubscriptionDebtInfo :subscription="invoice.subscription" :enableSave="true" ref="subscriptionDebtInfo" />
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import moment from 'moment';
import Snackbar from "./../shared/Snackbar";
import SendInvoice from "./InvoiceIssue/SendInvoice"
import EditInvoice from "./InvoiceIssue/EditInvoice"
import CorrectionDelivered from "./InvoiceIssue/CorrectionDelivered"
import SubscriptionDebtInfo from "./../Facility/FacilityEdit/Subscription/SubscriptionDebtInfo.vue"

export default {
  props: ["invoice"],
  name: "InvoiceIssue",
  data() {
    return {
      invoiceFile: null,
      nameDialog: false,
      //issueProformaDialog: false,
      correctionDialog: false,
      correctionDeliveredDialog: false,
      sendInvoiceDialog: false,
      editInvoiceDialog: false,
      payedDialog: false,
      payedAtDatepicker: false,
      invoiceNumber: "",
      issuedAt: null,
      invoiceCorrectionNumber: "",
      newPayment: {},
      loading: false,
      loadingRemove: false,
      sendingEmail: false,
      datepickerIssuedAt: false
    };
  },
  components: {
    Snackbar,
    SendInvoice,
    SubscriptionDebtInfo,
    CorrectionDelivered,
    EditInvoice
  },
  computed: {
    debtInfoLines(){
      let length = 0;  
      if(this.invoice && this.invoice.subscription.debt_info && this.invoice.subscription.debt_info.length > 0){
        length = this.invoice.subscription.debt_info.split(/\r\n|\r|\n/).length;
      }
      return length;
    }
  },
  methods: {
    async issue() {
      if (!this.invoiceNumber) {
        return false;
      }

      await this.$store.dispatch("ISSUE_INVOICE", {
        number: this.invoiceNumber,
        issuedAt: this.issuedAt,
        invoice: this.invoice,
      });

      this.nameDialog = false;
    },
    remove(){
      this.loadingRemove = true;
      this.$store
        .dispatch("REMOVE_INVOICE", {
          invoice: this.invoice,
        })
        .then(() => {
          this.loadingRemove = false;
        })
        .catch((error) => {
          this.loadingRemove = false;
          this.$refs.snackbar.error("Nie udało się usunąć faktury");
          console.log(error);
        });
    },
    /*issueProforma() {
      if (!this.invoiceNumber) {
        return false;
      }

      this.$store
        .dispatch("ISSUE_PROFORMA_INVOICE", {
          invoice: this.invoice,
          number: this.invoiceNumber
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });

      this.issueProformaDialog = false;
    },*/
    openIssueDialog(){
      this.invoiceNumber = "FV/";
      this.issuedAt = moment().format("YYYY-MM-DD");
      this.nameDialog = true;
    },
    /*openIssueProformaDialog(){
      this.invoiceNumber = "FV/";
      this.issueProformaDialog = true;
    },*/
    openDebtInfo(){
      this.$refs.subscriptionDebtInfo.openDialog();
    },
    addPayment() {
      this.$store
        .dispatch("ADD_INVOICE_PAYMENT", {
          invoice: this.invoice,
          payment: this.newPayment,
        })
        .then(() => {
          //this.$refs.snackbar.success("Dane zostały zapisane");
          this.payedDialog = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          /*
            if(error.response.data.message){
              this.$refs.snackbar.error(error.response.data.message);
            } else {
               this.$refs.snackbar.error("Nie udało się zapisać danych");
            }
            this.loading = false;
              */
        });

      /*
      await this.$store.dispatch("PAYED_INVOICE", {
        invoice: this.invoice,
        payed_at: this.payed_at
      });

      this.payedDialog = false;
      */
    },
    correct() {
      this.$store
        .dispatch("CORRECT_INVOICE", {
          invoice: this.invoice,
          number: this.invoiceCorrectionNumber,
        })
        .then(() => {
          //this.$refs.snackbar.success("Dane zostały zapisane");
          this.correctionDialog = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          /*
            if(error.response.data.message){
              this.$refs.snackbar.error(error.response.data.message);
            } else {
               this.$refs.snackbar.error("Nie udało się zapisać danych");
            }
            this.loading = false;
              */
        });
    },
    getFacilityUrl(id) {
      let routeData = this.$router.resolve({
        name: "facilityEdit",
        params: { id: id },
      });
      return routeData.href;
    },
    email() {
      this.sendInvoiceDialog = true;
    
    },
    openEditDialog() {
      this.editInvoiceDialog = true;
    
    },
    invoicePreview() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `invoices/${this.invoice.id}/previews.pdf`
      );
    },
    confirmation() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `facilities/${this.invoice.facility.external_id}/subscriptionConfirmations.pdf`
      );
    },
    envelope() {
      window.open(
        process.env.VUE_APP_API_ENDPOINT +
          `invoices/${this.invoice.id}/envelopes.pdf`
      );
    },
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
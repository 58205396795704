<template>
  <div :style="{'height': `${height?height:defaultHeight}px`, 'width': '100%'}" >

    <GmapMap 
    :zoom="zoom"
    :center="currentPosition"
    ref="map"
    id="map">
    <gmap-marker
      :position="currentPosition"
      :draggable="true"
      @dragend="markerDragged"
    >
    </gmap-marker>
  </GmapMap>

  </div>
</template>

<script>
//import { helpers } from 'gmap-vue';
//const { googleMapsApiInitializer } = helpers;

export default {
  name: "Google",
  components: {
  
  },
  props: ["latitude","longitude",'height'],
  data: () => ({
    loading: false,
    zoom: 13,
    defaultHeight:700,
    currentZoom: 11.5,
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    currentPosition: {},
    center: {}
  }),
  computed: {
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    markerDragged(marker){
      this.$emit('changeCoordinates',{latitude: marker.latLng.lat(),longitude: marker.latLng.lng()});
    },
    updateCurrentPosition(){
      this.currentPosition = {
        lat: parseFloat(this.latitude),
        lng: parseFloat(this.longitude)
      };
    
    }
  },
  watch: {
    latitude(){
      this.updateCurrentPosition();
    },
    longitude(){
     this.updateCurrentPosition();
    }
  },
  mounted() {
    this.updateCurrentPosition();
  },
};
</script>


<style>
#map {
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
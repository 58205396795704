import axios from "axios";
import qs from 'qs'


export default {
    state: {
        registrations: [],
        facilityRegistrations: [],
        filter: {},
        pagination: {
            currentPage: 1,
            itemsPerPage: 20
        },
    },
    getters: {
        REGISTRATIONS: state => {
            return state.registrations;
        },
        FACILITY_REGISTRATIONS: state => {
            return state.facilityRegistrations;
        },
        REGISTRATIONS_FILTER: state => {
            return state.filter;
        },
        REGISTRATIONS_PAGINATION: state => {
            return state.pagination;
        }
    },
    mutations: {
        SET_REGISTRATIONS: (state, payload) => {
            state.registrations = payload;
        },
        SET_FACILITY_REGISTRATIONS: (state, payload) => {
            state.facilityRegistrations = payload;
        },
        SET_REGISTRATIONS_FILTER: (state, payload) => {
            state.filter = payload;
        },
        SET_REGISTRATIONS_PAGINATION: (state, payload) => {
            state.pagination = payload;
        },
        SET_REGISTRATIONS_PAGINATION_CURRENT_PAGE: (state, payload) => {
            state.pagination.currentPage = payload;
        },
        SET_REGISTRATIONS_PAGINATION_ITEMS_PER_PAGE: (state, payload) => {
            state.pagination.itemsPerPage = payload;
        },
        UPDATE_REGISTRATION: (state,  payload) => {
            if (payload) {
                const element = state.registrations.find(registration => registration.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_REGISTRATION: (state, payload) => {
            state.registrations.unshift(payload);
        },
        REMOVE_REGISTRATION: (state, registration) => {
            let rs = state.registrations.filter(current => {
                return current.id !== registration.id;
            });
            state.registrations = [...rs];
        }
    },
    actions: {
        FILTER_REGISTRATIONS: ({commit},{filter}) => {
            commit("SET_REGISTRATIONS_FILTER", filter);
        },
        SET_REGISTRATIONS_PAGINATION_CURRENT_PAGE: ({ commit }, { currentPage }) => {
            commit("SET_REGISTRATIONS_PAGINATION_CURRENT_PAGE", currentPage);
        },
        SET_REGISTRATIONS_PAGINATION_ITEMS_PER_PAGE: ({ commit }, { itemsPerPage }) => {
            commit("SET_REGISTRATIONS_PAGINATION_ITEMS_PER_PAGE", itemsPerPage);
        },
        FIND_REGISTRATIONS: ({commit,state}, { filter,pageNumber }) => {
            let query = "?"
            if(pageNumber){
                query += "page="+pageNumber+"&";
            }

            if(state.pagination.itemsPerPage){
                query += "limit="+state.pagination.itemsPerPage+"&";
            }
            /*
            if(state.sortBy){
                query += "sortBy="+state.sortBy+"&";
            }

            if(state.sortDesc){
                query += "sortDesc="+state.sortDesc+"&";
            }
            */
            if(state.filter){
                filter = state.filter;
            }
            query += "&"+ qs.stringify({filter: filter}, { encode: false });

            return new Promise((resolve, reject) => {
                axios
                  .get(`registrations`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_REGISTRATIONS", data.items);
                        commit("SET_REGISTRATIONS_PAGINATION", {
                            currentPage: data.current_page_number,
                            itemsPerPage: data.num_items_per_page,
                            total: data.total_count
                        });
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });

        },
        GET_REGISTRATIONS: ({commit,state}) => {
            return new Promise((resolve, reject) => {
                let query = "?";
                if(state.filter){
                    query += "&"+ qs.stringify({filter: state.filter}, { encode: false });
                }
               
                axios
                  .get(`registrations`+query)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_REGISTRATIONS", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
        GET_FACILITY_REGISTRATIONS: ({commit},{facilityId}) => {
            return new Promise((resolve, reject) => {
                axios
                  .get(`facilities/`+facilityId+`/registrations`)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_FACILITY_REGISTRATIONS", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
        ADD_REGISTRATION: ({ commit }, { registration }) => {
            return new Promise((resolve, reject) => {
                axios
                .post(`registrations`, registration)
                .then(({ data, status }) => {
                  if (status === 200) {
                      commit("ADD_REGISTRATION", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
        UPDATE_REGISTRATION: ({ commit }, { registration }) => {
            return new Promise((resolve, reject) => {
                axios
                .patch(`registrations/${registration.id}`, registration)
                .then(({ data, status }) => {
                  if (status === 200) {     
                      commit("UPDATE_REGISTRATION", data);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        },
        REMOVE_REGISTRATION: ({ commit }, { registration }) => {
            return new Promise((resolve, reject) => {
                axios
                .delete(`registrations/${registration.id}`, registration)
                .then(({ data, status }) => {
                  if (status === 200) {     
                      commit("REMOVE_REGISTRATION", registration);
                      resolve({ data, status });
                  }
                })
                .catch((error) => { 
                    reject(error);
                })
            });
        }  
    }
}
<template>
  <v-dialog
    v-model="dialog"
    width="700"
  >
    <v-card :loading="loading">
      <v-toolbar
        dense
        color="warning"
        dark
      >Monitowanie</v-toolbar>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="4">
            <DateTimePicker label="następny monit" v-model="subscription.next_debt_contact_at">
            </DateTimePicker>
          </v-col>
        </v-row>
        <CommentButtons v-on:add-comment-line="addCommentLine($event, subscription)" />
        <v-textarea
          ref="debtInfo"
          @keydown.ctrl.68.prevent=""
          @keydown.ctrl.68="addEmptyCommantLine(subscription)"
          outlined
          v-model="subscription.debt_info"
          auto-grow
        ></v-textarea>

      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog=false"
        >
          zamknij
        </v-btn>
        <v-btn
          v-if="enableSave"
          color="primary"
          @click="save()"
        >
          zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CommentButtons from "../../../shared/CommentButtons";
import DateTimePicker from "../../../shared/DateTimePicker";
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionDebtInfo",
  components: { CommentButtons, DateTimePicker },
  props: ["subscription", "enableSave"],
  data: () => ({
    dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"]),
  },
  methods: {
    save() {
      this.loading = true;
      axios
        .patch(`/subscriptions/${this.subscription.id}/debtInfos`, this.subscription)
        .then(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
    openDialog() {
      this.dialog = true;
    },
    addEmptyCommantLine(subscription) {
      let date = moment(date).format("YYYY-MM-DD H:mm:ss");
      let line = "[" + date + "] " + this.CURRENT_USER.username + ", ";
      this.addCommentLine(line, subscription);
    },
    focusComment() {
      setTimeout(() => {
        this.$refs.debtInfo.$refs.input.focus();
      });
    },
    addCommentLine(line, subscription) {
      subscription.debt_info =
        line +
        "\n" +
        (subscription.debt_info === null ? "" : subscription.debt_info);
      this.focusComment();
      this.$nextTick(() => {
        this.$refs.debtInfo.$refs.input.setSelectionRange(
          line.length,
          line.length
        );
      });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style>
</style>
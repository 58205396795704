<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar
        dark
        color="green darken-4"
        class="mt-3"
      >
        <v-toolbar-title>Współrzędne GPS: {{ FACILITY.name }}</v-toolbar-title>

      </v-toolbar>
      <FacilityEditGeolocation
        v-if="FACILITY.id"
        :facility="FACILITY"
        @save-geolocation="save()"
      />
    </v-card>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import Snackbar from "./../../../shared/Snackbar.vue";
import FacilityEditGeolocation from "./../../FacilityEdit/FacilityEditGeolocation.vue";

export default {
  name: "WindowGpsEdit",
  components: {
    FacilityEditGeolocation,
    Snackbar,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  methods: {
    save(){
      this.loading = true;
      this.$store
        .dispatch("UPDATE_FACILITY", {
          facility: this.FACILITY,
        })
        .then(() => {
          this.$refs.snackbar.success("Dane obiektu zostały zapisane");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$refs.snackbar.error(error.response.data.message);
          } else {
            this.$refs.snackbar.error("Nie udało się zapisać danych");
          }
          this.loading = false;
        });
    },
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>


<style>
</style>
import axios from "axios";

export default {
    state: {
        phoneTypes: [],
        filter: {}
    },
    getters: {
        PHONE_TYPES: state => {
            return state.phoneTypes;
        },
        PHONE_TYPES_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_PHONE_TYPES: (state, payload) => {
            state.phoneTypes = payload;
        },
    },
    actions: {
        GET_PHONE_TYPES: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios
                  .get(`phoneTypes`)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        commit("SET_PHONE_TYPES", data);
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                });
            });
        },
    }
}
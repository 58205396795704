<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >

      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Wysyłanie wiadomośći do: {{ facility.name }}</v-toolbar-title>

        </v-toolbar>
        <FacilityEditSendMail
          v-on:FacilitySendEmail:sended="dialog = false;successMessage('Wiadomość wysłana poprawnie')"
          :facility="facility"
          :options="options"
        />
      </v-card>
    </v-dialog>
</template>

<script>
import FacilityEditSendMail from "./../FacilityEditSendMail"
export default {
  name: "SendMailDialog",
  props: ["facility"],
  data: () => ({
    dialog: false,
    options: {}
  }),
  components: {
    FacilityEditSendMail
  },
  computed: {
  
  },
  mounted() {
   
  },
  methods: {
    openDialog(options) {
      this.options = options;
      this.dialog = true;
    },
    closeDialog() {
      this.options = {};
      this.dialog = false;
    }
   
  },
  watch: {
  
  },
};
</script>


<style>
.quick-text-button {
  min-width: 40px !important;
}
@media (max-width: 1300px) {
  .quick-text-button {
    min-width: 23px !important;
  }
}
</style>
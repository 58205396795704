<template>
  <div>
    <v-card class="mt-2">
      <v-tabs
        background-color="blue darken-2"
        center-active
        dark
        dense
        flat
        v-model="activeComponent"
      >
       <v-tab
          v-for="item in navigation"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeComponent">
        <v-tab-item v-for="item in navigation"
            :key="item.tab"
        >
          <v-card class="mt-2 pa-2" flat> <component :is="item.component"></component></v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

  </div>
</template>

<script>
import AdminMessageTemplate from "./Admin/AdminMessageTemplate";
import AdminCity from "./Admin/AdminCity";
import AdminLand from "./Admin/AdminLand";
import AdminLake from "./Admin/AdminLake";
import AdminUser from "./Admin/AdminUser";
import AdminQa from "./Admin/AdminQa";
import AdminReport from "./Admin/AdminReport";

export default {
  name: "Admin",
  data: () => ({
    navigation: [
      { tab: "Szablony Wiadomości", component: "AdminMessageTemplate" },
      { tab: "Pomoc", component: "AdminQa" },
      { tab: "Miasta", component: "AdminCity" },
      { tab: "Krainy", component: "AdminLand" },
      { tab: "Jeziora", component: "AdminLake" },
      { tab: "Użytkownicy", component: "AdminUser" },
      { tab: "Raporty", component: "AdminReport" },
    ],
    activeComponent: false
  }),
  components: {
    AdminMessageTemplate,
    AdminCity,
    AdminLand,
    AdminLake,
    AdminUser,
    AdminQa,
    AdminReport
  },
  computed: {
  
  },
  mounted() {
  
  },
};
</script>

<style>
</style>

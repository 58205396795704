import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './router'
import store from "./../stores/store";

Vue.use(AclInstaller);


export default new AclCreate({
    initial: 'public',
    notfound: {
        path: '/app'
    },
    router,
    acceptLocalRules: true,
    globalRules: {
        public: new AclRule('public').or('supervisor').or('accountant').or('admin').generate(),
        supervisor: new AclRule('supervisor').or('accountant').or('admin').generate(),
        accountant: new AclRule('accountant').or('admin').generate(),
        admin: new AclRule('admin').generate()
    },
    middleware: async acl => {
        await store
        .dispatch("INIT_USER")
        .then(() => {
            const user = store.getters.CURRENT_USER;
            if (user && user.roles.includes("ROLE_ADMIN")) {
                acl.change('admin');
            }
            if (user && user.roles.includes("ROLE_SUPERVISOR")) {
                acl.change('supervisor');
            }
            if (user && user.roles.includes("ROLE_ACCOUNTANT")) {
                acl.change('accountant');
            }
        }).catch(error => {
            
        });
    }
});
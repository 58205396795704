<template>
  <v-container fluid>

    <v-card
      :loading="loading"
      :disabled="loading"
    >
      <v-toolbar
        color="light-blue darken-2"
        dark
        dense
      >
        <v-toolbar-title>Oferty</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <ListingSearchForm/>
      </v-container>
    </v-card>
    <ListingSearchResult/>
  </v-container>
</template>

<script>

import ListingSearchForm from "./ListingSearchForm"
import ListingSearchResult from "./ListingSearchResult"
export default {
  name: "ListingSearch",
  data() {
    return {
      loading: false,
    };
  },
  components: {ListingSearchForm,ListingSearchResult},
};
</script>

<style>
</style>

<template>
  <v-form @keyup.native.enter="addPhone">
    <v-simple-table v-if="addPhoneForm || facility.facility.phones.length === 0">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <v-combobox
                :items="phoneNumbers"
                :search-input.sync="newPhone.number"
                @keydown="autoFormatPhoneNumber()"
                @change="newPhone.number = newPhone.number.replace(/[^0-9\\+]/g, '')"
                v-model="newPhone.number"
                label="numer telefonu"
                :error-messages="phoneNumberErrors"
              ></v-combobox>
            </td>
            <td>
              <v-select
                :items="PHONE_TYPES"
                v-model="newPhone.type"
                item-text="name"
                return-object
                label="rodzaj"
                :error-messages="phoneTypeErrors"
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model="newPhone.description"
                label="opis"
              ></v-text-field>
            </td>
            <td class="text-right action-cell">
              <v-btn
                color="success"
                @click="addPhone()"
                small
                class="mr-1"
              ><v-icon>done</v-icon></v-btn>
              <v-btn
                color="warning"
                @click="addPhoneForm=false ; newPhone={}"
                v-if="facility.facility.phones.length > 0"
                small
              >
                <v-icon>not_interested</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table
      dense
      v-if="facility.facility.phones.length > 0"
    >
      <template v-slot:default>

        <thead>
          <tr>
            <th>numer telefonu</th>
            <th>rodzaj</th>
            <th>opis</th>
            <th class="text-right"><v-btn
                color="success"
                v-if="!addPhoneForm"
                @click="addPhoneForm = true"
                small
              ><v-icon>add</v-icon></v-btn></th>
          </tr>
        </thead>
        <tbody>
          <FacilityEditPhonesRow
            v-for="phone in facility.facility.phones"
            :key="phone.id"
            :phone="phone"
            v-on:phone:remove="removePhone($event)"
            v-on:phone:update="updatePhone($event)"
            :phoneNumbers="phoneNumbers"
            @phone:sendMessage="sendMessage"
          />
        </tbody>
      </template>
    </v-simple-table>
    <SendSMSDialog
      :facility="facility"
      ref="sendSMSDialog"
    ></SendSMSDialog>
  </v-form>
</template>

<script>
import FacilityEditPhonesRow from "./FacilityEditPhonesRow";
import SendSMSDialog from "./../SendSMS/SendSMSDialog.vue";
import { required, helpers } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

const phonePattern = helpers.regex(
  "phonePattern",
  /^(\+\d{2}\s)?((\d{2}\s\d{3}\s\d{2}\s\d{2})|(\d{3}\s\d{3}\s\d{3}))$/
);

export default {
  name: "FacilityEditPhones",
  props: ["facility"],
  components: {
    FacilityEditPhonesRow,
    SendSMSDialog,
  },
  data: () => ({
    addPhoneForm: false,
    newPhone: {
      type: {},
    },
  }),
  validations: {
    newPhone: {
      number: { required, phonePattern },
      type: {
        required,
        unique: function (value) {
          return !this.isTypeUnique(value);
        },
      },
    },
  },
  computed: {
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.newPhone.number.$dirty) return errors;
      !this.$v.newPhone.number.required && errors.push("telefon jest wymagany");
      !this.$v.newPhone.number.phonePattern &&
        errors.push("wprowadź poprawny numer xx xxx xx xx lub xxx xxx xxx");
      return errors;
    },
    phoneTypeErrors() {
      const errors = [];
      if (!this.$v.newPhone.type.$dirty) return errors;
      !this.$v.newPhone.type.required && errors.push("rodzaj jest wymagany");
      !this.$v.newPhone.type.unique && errors.push("rodzaj jest już zajęty");
      return errors;
    },
    phoneNumbers() {
      let numbers = [];
      this.facility.facility.phones.forEach(function (phone) {
        numbers.push(phone.number);
      });
      return numbers;
    },
    ...mapGetters(["PHONE_TYPES"]),
  },
  methods: {
    addPhone: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.$v.$reset();
      this.newPhone.id = -Date.now();
      this.facility.facility.phones.push(this.newPhone);
      this.newPhone = {
        type: {},
      };
      this.$emit("phone:update");
    },
    updatePhone: function (newPhone) {
      const element = this.facility.facility.phones.find(
        (phone) => phone.id === newPhone.id
      );
      Object.assign(element, newPhone);
      this.$emit("phone:update");
    },
    removePhone: function (phone) {
      this.facility.facility.phones = this.facility.facility.phones.filter(
        (current) => {
          return current.id !== phone.id;
        }
      );
      this.$emit("phone:update");
    },
    isTypeUnique: function (newPhoneType) {
      for (let key in this.facility.facility.phones) {
        let phoneRow = this.facility.facility.phones[key];
        if (phoneRow.type.id === newPhoneType.id) {
          return true;
        }
      }
      return false;
    },
    autoFormatPhoneNumber() {
      if (this.newPhone.number === null) {
        return;
      }
      this.newPhone.number = this.newPhone.number.trim();
      if (this.newPhone.number[0] !== "+") {
        this.newPhone.number = this.newPhone.number.replace(/[^0-9\\+]/g, '');
        if (this.newPhone.number.length === 9) {
          const local = [12, 13, 14, 15, 16, 17, 18, 22, 23, 24, 25, 29, 32, 33, 34, 41, 42, 43, 44, 46, 48, 52, 54, 55, 56, 58, 59, 61, 62, 63, 65, 67, 68, 71, 74, 75, 76, 77, 81, 82, 83, 84, 85, 86, 87, 89, 91, 94, 95];
          if (local.includes(parseInt(this.newPhone.number.slice(0, 2)))) {
            this.newPhone.number = this.newPhone.number.replace(/^(\d{2})(\d{3})(\d{2})?(\d{2})/g, '$1 $2 $3 $4');
          } else {
            this.newPhone.number = this.newPhone.number.replace(/^(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3');
          }
        }
      }
    },
    sendMessage(options) {
      this.$refs.sendSMSDialog.openDialog(options);
    },
  },
  mounted() {
    this.$store.dispatch("GET_PHONE_TYPES");
  },
  watch: {
    "newPhone.number": function () {
      this.autoFormatPhoneNumber();
    },
  },
};
</script>


<style>
</style>
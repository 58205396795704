<template>
  <div>
    <v-menu
      :nudge-width="500"
      max-width="500"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :color="userNotificationsUnread.length > 0 ? 'red' : ''"
            :content="userNotificationsUnread.length"
            overlap
          >
            <v-icon>notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-alert v-if="USER_NOTIFICATIONS.length === 0">brak powiadomień</v-alert>
        <v-list-item
          v-for="notification in USER_NOTIFICATIONS"
          :key="notification.id"
          @click="showNotification(notification)"
          background="orange"
          :class="notification.read_at === null ? 'notification-unread notification-unread-type-'+notification.type : 'notification-read'"
        >
          <v-list-item-avatar>
            <v-icon
              v-if="notification.type === 0"
              :color="notification.read_at === null ? 'blue' : ''"
            >info</v-icon>
            <v-icon
              v-if="notification.type === 1"
              :color="notification.read_at === null ? 'orange' : ''"
            >warning</v-icon>
            <v-icon
              v-if="notification.type === 2"
              :color="notification.read_at === null ? 'red' : ''"
            >error</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            {{ notification.content }}
            <div class="text-overline notification-time">
              {{ calculateFromNow(notification.created_at) }}
            </div>
          </v-list-item-title>
          <v-list-item-action>
            <v-icon
              v-if="notification.read_at === null && notification.type === 0"
              color="blue"
            >circle</v-icon>
            <v-icon
              v-if="notification.read_at === null && notification.type === 1"
              color="orange"
            >circle</v-icon>
            <v-icon
              v-if="notification.read_at === null && notification.type === 2"
              color="red"
            >circle</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <UserNotificationShowDialog ref="userNotificationShowDialog" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import UserNotificationShowDialog from "./UserNotification/UserNotificationShowDialog";

export default {
  name: "UserNotification",
  components: { UserNotificationShowDialog },
  data: () => ({
    interval: null
  }),
  computed: {
    ...mapGetters(["CURRENT_USER"]),
    ...mapGetters(["USER_NOTIFICATIONS"]),
    userNotificationsUnread() {
      return this.USER_NOTIFICATIONS.filter((current) => {
        return current.read_at === null;
      });
    },
  },
  methods: {
    showNotification(notification) {
      if (notification.read_at === null) {
        notification.read_at = moment().format("YYYY-MM-DD HH:mm");
        this.$store
          .dispatch("UPDATE_USER_NOTIFICATION", {
            userNotification: notification,
          })
          .then(() => {});
      }
      this.$refs.userNotificationShowDialog.openDialog(notification);
    },
    calculateFromNow(date) {
      moment.locale("pl");
      return moment(date).fromNow();
    },
    getNotifications() {
      this.loading = true;
      this.$store.dispatch("GET_USER_NOTIFICATIONS").then(() => {
        this.loading = false;
      });
    },
    init() {
      clearInterval(this.interval);
      this.getNotifications();
      this.interval = setInterval(() => {
        this.getNotifications();
      }, 20000);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {},
};
</script>

<style>
.notification-unread {
  /* background-color: #fdb5b5; */
  font-weight: bold;
}
.notification-read {
  /*background-color: #b3e5fc;*/
  opacity: 0.7;
}
.notification-unread .notification-time {
  color: #2196f3;
}

.notification-unread-type-0 .notification-time {
  color: #2196f3;
}
.notification-unread-type-1 .notification-time {
  color: #ff9800;
}
.notification-unread-type-2 .notification-time {
  color: #f44336;
}
</style>
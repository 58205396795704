<template>
  <v-form v-if="facility.client">
    <v-row dense class="mx-1">
      <v-col md="8">
        <v-text-field
          v-model="facility.client.name"
          label="właściciel"
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="facility.client.tax_number"
          label="NIP"
          @change="$v.$touch()"
          @keydown="$v.$touch()"
          :error-messages="taxNumberErrors"
        >
        
         <v-btn
            slot="append"
            color="info"
            small
            :disabled="facility.client.tax_number === null || facility.client.tax_number.length===0"
            @click="decodeTaxNumber()"
          >
            GUS <v-icon>download</v-icon>
          </v-btn>
        </v-text-field>
      </v-col>
    </v-row>
   
    <div class="text-right mb-2 pr-4" v-if="!addForm && (!checkoutMode || facility.client.invoice_type === 0)">
      <span v-if="facility.client.addresses.length === 0" class="px-5">brak adresów</span>
      <v-btn
        color="success"
        dark
        right
        small
        @click="addForm = true"
      >
          <v-icon>add</v-icon>
      </v-btn>
     
    </div>
    <v-card class="mb-3" v-if="addForm" outlined>
        <v-card-text>
          <v-text-field v-model="newAddress.name" label="nazwa" dense></v-text-field>
          <v-textarea v-model="newAddress.address" label="adres" auto-grow rows="2" dense :error-messages="newAddressAddressErrors"></v-textarea>
          <v-checkbox
           v-model="newAddress.is_for_correspondence"
            label="adres do druku kopert"
            :value=1
          ></v-checkbox>
       </v-card-text>
       <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                dark
         
                small
                @click="addAddress()"
              >
                 <v-icon>add</v-icon>
              </v-btn>
              <v-btn 
                color="warning" 
                @click="addForm=false" 
                small
              >
                <v-icon>not_interested</v-icon>
              </v-btn>
        
          </v-card-actions>
    </v-card>

    <v-row  class="mb-2" v-if="!checkoutMode || facility.client.invoice_type === 0">
      <v-col :sm="facility.client.addresses.length === 1?12:6"   v-for="address in facility.client.addresses" :key="address.id">
        <v-card outlined >
            <v-card-text>
              <v-text-field label="nazwa" dense v-model="address.name"></v-text-field>
              <v-textarea label="adres" dense v-model="address.address" auto-grow rows="4"></v-textarea>
              <v-checkbox
              v-model="address.is_for_correspondence"
                label="adres do druku kopert"
                :value=1
                dense
              ></v-checkbox>

            </v-card-text>
                <v-fab-transition>
                  <v-btn
                    color="error"
                    dark
                    absolute
                    top
                    right
                    small
                    @click="deleteAddress(address)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-fab-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-card outlined class="mb-6 pt-2" style="min-height:50px;" v-if="!checkoutMode">
      <ClientBankAccount :facility="facility"/>
    </v-card>
    <v-row class="mx-1" dense>
      <v-textarea
        :background-color="facility.client.invoice_type==1?'amber lighten-4':''"
        label="dane do faktury"
        v-model="facility.client.invoice_data"
        auto-grow
        rows="1"
        dense
      ></v-textarea>
    </v-row>
    <v-row dense>
      <v-col>
      <v-radio-group
        v-model="facility.client.invoice_type"
        row
        class="mx-1"
      >
        <v-radio
          label="faktura tradycyjnie"
          :value=0
          :key=0
        ></v-radio>
        <v-radio
          label="faktura tylko mailem"
          :value=1
          :key=1
        ></v-radio>
      </v-radio-group>
      </v-col>
      <v-col v-if="!checkoutMode">
             <v-menu
          v-model="lastUpdateAtDatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="facility.client.updated_at"
              label="zaktualizowano"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pl-pl"
            no-title
            v-model="facility.client.updated_at"
            @change="lastUpdateAtDatepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row 
      dense
      class="mx-1"
    >
      <v-textarea
        label="uwagi dotyczące obiektu"
        v-model="facility.client.invoice_comment"
        auto-grow
        rows="4"
        dense
      ></v-textarea>
    </v-row>
      <ClientDecodeTaxNumber :client="facility.client" ref="decodeTaxNumber" />
  </v-form>
</template>

<script>
import ClientDecodeTaxNumber from "./Client/ClientDecodeTaxNumber"
import ClientBankAccount from "./Client/ClientBankAccount"
import { required, helpers } from "vuelidate/lib/validators";
const nip = helpers.regex("nip", /^([0-9-]+)$/);

export default {
  name: "FacilityEditClient",
  props: ["facility","checkoutMode"],
  components: {ClientDecodeTaxNumber,ClientBankAccount},
  data: () => ({
    lastUpdateAtDatepicker: false,
    addForm: false,
    newAddress: {},
    types: [
      {id: 1, name:"Adres do druku kopert"}
    ]
  }),
  validations: {
    newAddress: {
      address: {required}
    },
    facility: {
      client: {
        tax_number: {nip}
      }
    }
  },
  computed: {
    taxNumberErrors(){
      const errors = [];
      if (!this.$v.facility.client.tax_number.$dirty) return errors;
      !this.$v.facility.client.tax_number.nip && errors.push("podaj prawidłowy nip")

      ;
      return errors;
    },
    newAddressAddressErrors(){
      const errors = [];
      if (!this.$v.newAddress.address.$dirty) return errors;
      !this.$v.newAddress.address.required && errors.push("podaj adres")

      ;
      return errors;
    },
  },
  methods: {
     deleteAddress: function(address){
      this.facility.client.addresses =
        this.facility.client.addresses.filter((current) => {
          return current.id !== address.id;
        });
    },
    addAddress(){
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.$v.$reset();
      this.newAddress.id = - Date.now();
      this.facility.client.addresses.push(this.newAddress);
      this.newAddress = {};
      this.addForm = false;
    },
    decodeTaxNumber(){
      this.$refs.decodeTaxNumber.decode();
    }
  },
  mounted() {
    if(!this.facility.client){
      this.facility.client = {};
    }
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-dialog
      v-model="paidInvoiceInfoDialog"
      max-width="880"
      persistent
    >
      <v-card class="pb-5">
        <v-card-title>
          <v-container class="text-center">Wybrana emisja posiada już opłacone faktury, czy na pewno chcesz wystawić kolejną?</v-container>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="priorityChangeDialog = true;paidInvoiceInfoDialog=false;"
          >tak, chce wystawić kolejną fakturę</v-btn>
          <v-btn
            color="warning"
            @click="paidInvoiceInfoDialog=false;"
          >nie, nie chce wystawiać kolejnej faktury</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDialogActive"
      max-width="800"
    >
      <v-card>
        <v-card-title>Czy podane dane klienta są poprawne ?</v-card-title>
        <v-card-text>
          <FacilityEditClient
            :facility="facility"
            :checkoutMode="true"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click="isConfirmationDialogActive=true"
          >potwierdzam, dane są aktualne</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isConfirmationDialogActive"
      max-width="710"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-container class="text-center"> Czy na pewno dane do faktury są aktualne?</v-container>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click="accepted()"
          >tak, potwierdzam, dane są aktualne</v-btn>
          <v-btn
            color="warning"
            @click="isConfirmationDialogActive=false;"
          >nie wiem, chce sprawdzić jeszcze raz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="priorityChangeDialog"
      max-width="410"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            Podaj nowy priorytet, obecny to: <strong class="red--text">{{facility.priority}}</strong>

          </v-container>
        </v-card-title>
        <v-card-text>
          <div style="max-width:150px;margin:0 auto;">
            <v-text-field
              v-model.number="newPriority"
              outlined
              label="nowy priorytet"
              @change="$v.$touch()"
              @keydown="$v.$touch()"
              :error-messages="priorityErrors"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>

          <v-btn
            color="success"
            @click="acceptedPriority()"
          >potwierdzam</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="priorityChangeDialog=false"
          >anuluj</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import FacilityEditClient from "../FacilityEditClient";
import moment from "moment";
import Snackbar from "./../../../shared/Snackbar";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "SubscriptionNewInvoiceCheckout",
  props: ["facility", "subscription"],
  components: {
    FacilityEditClient,
    Snackbar,
  },
  data: () => ({
    isDialogActive: false,
    paidInvoiceInfoDialog: false,
    isConfirmationDialogActive: false,
    priorityChangeDialog: false,
    newPriority: null,
  }),
  validations: {
    newPriority: {
      required,
      between: between(-3, 13),
    },
  },
  computed: {
    priorityErrors() {
      const errors = [];
      if (!this.$v.newPriority.$dirty) return errors;
      !this.$v.newPriority.required && errors.push("priorytet jest wymagany");
      !this.$v.newPriority.between && errors.push("liczba od -3 do 13");
      return errors;
    },
  },
  methods: {
    openDialog() {
      this.newPriority = null;
      if (this.validate()) {
        if (this.isSubscriptionHasPaidInvoice()) {
          this.paidInvoiceInfoDialog = true;
        } else {
          this.priorityChangeDialog = true;
        }
      }
    },
    acceptedPriority() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.facility.facility.priority = this.newPriority;
      this.$store
        .dispatch("UPDATE_FACILITY", {
          facility: this.facility,
        })
        .then(() => {
          this.priorityChangeDialog = false;
          this.isDialogActive = true;
        });
    },
    validate() {
      if (!this.isSmsPhoneEnabled()) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak numeru telefonu dla SMS FAKTURA"
        );
        return false;
      }

      // stały czy nowy

      if (
        !this.subscription.client_name ||
        this.subscription.client_name.length < 3
      ) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak zleceniodawcy"
        );
        return false;
      }
      if (!this.subscription.agreed_at) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak daty uzgodnienia"
        );
        return false;
      }
      if (!this.isSubscriptionValid()) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak aktualnej daty końca emisji"
        );
        return false;
      }

      if (this.subscription.client_status === null) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak wybranego typu klienta."
        );
        return false;
      }

      if (this.subscription.package === null) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak wybranego pakietu."
        );
        return false;
      }

      if (this.subscription.start === null || this.subscription.end === null) {
        this.$refs.snackbar.error(
          "Nie można wystawić faktury. Brak określonego czasu emisji."
        );
        return false;
      }

      return true;
    },
    isSubscriptionHasPaidInvoice() {
      let actvie = false;
      this.subscription.invoices.forEach((invoice) => {
        if (invoice.status === 2 && !invoice.is_proforma) {
          actvie = true;
        }
      });

      return actvie;
    },
    isSubscriptionValid() {
      let end = moment(this.facility.facility.subscriptionEnd);
      let now = moment(this.subscription.agreed_at);
      let days = now.diff(end, "days");
      if (days > 0) {
        return false;
      }
      return true;
    },
    isSmsPhoneEnabled() {
      if (this.facility.disable_invoice_sms_notifications === 1) {
        return true;
      }

      let actvie = false;
      this.facility.facility.phones.forEach((item) => {
        if (item.type.id === 6) {
          actvie = true;
        }
      });

      return actvie;
    },
    accepted() {
      this.facility.client.updated_at = moment().format("YYYY-MM-DD HH:mm");
      this.$store
        .dispatch("UPDATE_FACILITY_SILENT", {
          facility: this.facility,
        })
        .then(() => {
          this.$refs.snackbar.success(
            "Dane obiektu " + this.facility.external_id + " zostały zapisane"
          );
        });
      this.$emit("subscriptionNewInvoiceCheckout:accepted");
      this.isConfirmationDialogActive = false;
      this.isDialogActive = false;
    },
  },
  mounted() {},
};
</script>


<style>
</style>
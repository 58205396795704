<template>
  <div>
    <v-simple-table v-if="facility.events" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Data</th>
            <th class="text-left">Nazwa</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in facility.events" :key="event.id">
            <td>{{ event.created_at | formatDate('YYYY-MM-DD H:mm') }}</td>
            <td>{{ event.name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "FacilityEditEvent",
  props: ["facility"],
  components: {},
  data: () => ({
    
  }),
  computed: {},
  methods: {},
  mounted() {},
};
</script>


<style>
</style>
<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        dark
        color="orange darken-4"
        class="mt-3"
      >
        <v-toolbar-title>Google Match: {{ FACILITY.name }}</v-toolbar-title>

      </v-toolbar>
      <GoogleMatch
        :facility="FACILITY"
      />
    </v-card>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import Snackbar from "./../../../shared/Snackbar.vue";
import GoogleMatch from "./../../../GoogleMatch.vue";

export default {
  name: "WindowGoogleMatch",
  components: {
    GoogleMatch,
    Snackbar,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["FACILITY"]),
  },
  methods: {
    successMessage(message) {
      this.$refs.snackbar.success(message);
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("GET_FACILITY_BY_ID", { id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>


<style>
</style>
<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="950"
    >
      <v-card class="pt-3">
        <v-container>
          <v-form>
            <v-row flat dense>
              <v-col sm=3>
                <v-text-field
                  label="nazwa"
                  v-model="data.name"
                  :error-messages="nameErrors"
                  dense
                />
              </v-col>
              <v-col sm=3>
                <v-text-field
                  label="nazwa do linków"
                  v-model="data.slug"
                  dense
                />
              </v-col>

              <v-col sm=3>
                <v-text-field
                  label="nazwa odmieniona (miejscownik)"
                  v-model="data.nameLocative"
                  dense
                />
              </v-col>

              <v-col sm=3>
                <v-text-field
                  label="nazwa odmieniona (dopełniacz)"
                  v-model="data.nameGenitive"
                  dense
                />
              </v-col>

              <v-col sm=2>
                <v-text-field
                  label="długość geograficzna"
                  v-model="data.longitude"
                  dense
                />
              </v-col>

              <v-col sm=2>
                <v-text-field
                  label="szerokość geograficzna"
                  v-model="data.latitude"
                  dense
                />
              </v-col>

              <v-col sm=1>
                <v-text-field
                  label="zoom mapy"
                  v-model="data.mapZoom"
                  dense
                />
              </v-col>

              <v-col sm=1>
                <v-text-field
                  label="kod pocztowy"
                  v-model="data.postalCode"
                  dense
                />
              </v-col>
              <v-col sm=1>
                <v-select
                :items="centerRadiuses"
                v-model="data.centerRadius"
                dense
                ></v-select>
              </v-col>
              <v-col sm=5>
                <v-select
                  v-model="data.regions"
                  :items="REGIONS"
                  small-chips
                  chips
                  return-object
                  item-text="name"
                  attach
                  label="położenie"
                  multiple
                  dense
                ></v-select>
              </v-col>
              <v-col sm=4>
                <v-autocomplete
                  v-model="data.gmina"
                  name="gmina"
                  label="gmina"
                  return-object
                  item-text="name"
                  :items="gminaSuggestions"
                  :loading="loading"
                  :search-input.sync="gminaAutocomplete"
                  placeholder="wyszukaj gmine"
                  hide-no-data
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col sm=4>
                <v-autocomplete
                  v-model="data.powiat"
                  name="powiat"
                  label="powiat"
                  return-object
                  item-text="name"
                  :items="powiatSuggestions"
                  :loading="loading"
                  :search-input.sync="powiatAutocomplete"
                  placeholder="wyszukaj powiat"
                  hide-no-data
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col sm=4>
                <v-autocomplete
                  v-model="data.voivodeship"
                  name="voivodeship"
                  label="województwo"
                  return-object
                  item-text="name"
                  :items="voivodeshipSuggestions"
                  :loading="loading"
                  :search-input.sync="voivodeshipAutocomplete"
                  placeholder="wyszukaj województwo"
                  hide-no-data
                  dense
                >
                </v-autocomplete>
              </v-col>

            </v-row>

            <CityLandForm :data="data" />

          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-1"
              dark
              @click="save()"
            >
              zapisz
            </v-btn>
            <v-btn
              color="warning"
              @click="dialog=false"
            >
              anuluj
            </v-btn>

          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
import CityLandForm from "./CityLandForm";
const bankAccountPattern = helpers.regex(
  "slug",
  /^\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}$/
);

export default {
  components: { CityLandForm },
  name: "CityForm",
  data: () => ({
    data: {
      lands: [],
      regions: [],
      names: [],
    },
    centerRadiuses: [0.5,1,1.5,2],
    dialog: false,
    errorDialog: false,
    error: false,
    loading: false,
    gminaSuggestions: [],
    gminaAutocomplete: null,
    powiatSuggestions: [],
    powiatAutocomplete: null,
    voivodeshipSuggestions: [],
    voivodeshipAutocomplete: null,
  }),
  validations: {
    data: {
      name: { required },
    },
  },
  computed: {
    ...mapGetters(["REGIONS"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) return errors;
      !this.$v.data.name.required && errors.push("wprowadź nazwę");
      return errors;
    },
  },
  methods: {
    open(data) {
      this.data = JSON.parse(JSON.stringify(data));
      if (data.id) {
        this.gminaAutocomplete = data.gmina.name;
        this.powiatAutocomplete = data.powiat.name;
        this.voivodeshipAutocomplete = data.voivodeship.name;
      }
      this.setCityRegions(data);
      this.dialog = true;
    },
    setCityRegions(city) {
      this.data.regions = [];
      if (city.regionMask > 0) {
        this.REGIONS.forEach((region) => {
          if ((region.id & city.regionMask) === region.id) {
            this.data.regions.push(region);
          }
        });
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      this.loading = true;

      if (!this.data.id) {
        this.$store
          .dispatch("ADD_CITY", {
            city: this.data,
          })
          .then(() => {
            this.dialog = false;
            this.$emit("city:update", this.data);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch("UPDATE_CITY", {
            city: this.data,
          })
          .then(() => {
            this.dialog = false;
            this.$emit("city:update", this.data);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch("GET_REGIONS");
  },
  watch: {
    gminaAutocomplete(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/gminas?filter[name_like]=` + val, {})
        .then((response) => {
          this.gminaSuggestions = response.data;
        })
        .finally(() => (this.loading = false));
    },
    powiatAutocomplete(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/powiats?filter[name_like]=` + val, {})
        .then((response) => {
          this.powiatSuggestions = response.data;
        })
        .finally(() => (this.loading = false));
    },
    voivodeshipAutocomplete(val) {
      //  if (this.loading) return
      if (!val || val.length < 3) return;

      this.loading = true;

      axios
        .get(`/voivodeships?filter[name_like]=` + val, {})
        .then((response) => {
          this.voivodeshipSuggestions = response.data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>


<style>
</style>
<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="1200"
  >

    <v-card>

      <v-card-subtitle class="pt-4">
        <div class="text-caption">
          <span class="float-right">AKTUALIZACJA: {{ qa.updated_at }}</span>
        </div>
        <h1 class="text-header-1 py-5">
          {{ qa.question }}
        </h1>

        <div class="qa-answer-section" v-html="qa.answer"></div>
        
      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-3"
          class="white--text"
          small
          @click="dialog = false"
        >ok</v-btn>
       
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QaReadDialog",
  data: () => ({
    dialog: false,
    qa: {},
  }),
  components: {},
  computed: {},
  mounted() {},
  methods: {
    openDialog(qa) {
      this.qa = qa;
      this.dialog = true;
    },
    closeDialog() {
      this.qa = {};
      this.dialog = false;
    }
  },
  watch: {},
};
</script>


<style>
.qa-answer-section table {

  border-collapse: collapse;
  margin:20px 0px;
}
.qa-answer-section table td {
  padding:.55rem;
  border: 1px solid #dee2e6;
}
.qa-answer-section img {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 1150px;
}
</style>
<template>
 <v-footer fixed padless v-if="lock">
    <v-card
        flat
        tile
        width="100%"
        class="red lighten-1 text-center"
      >
      <v-card-text class="white--text">
        UWAGA! Obiekt jest obecnie edytowany w innej zakładce! 
      </v-card-text>
    </v-card>
     <v-fade-transition>
          <v-overlay
            color="red"
          >
            
          </v-overlay>
        </v-fade-transition>
 </v-footer>
</template>

<script>
export default {
  name: "FacilityEditTabGuard",
  props: ["facility"],
  components: {},
  data: () => ({
    lock: false,
    key: false,
    id: false,
    interval: false
  }),
  computed: {

  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    init() {
      if(typeof window.sessionStorage === "undefined"){
          return;
      }
      this.key = "SingleTabGuard" + this.facility.id;
      this.id = window.sessionStorage.tabID ? window.sessionStorage.tabID : window.sessionStorage.tabID = Math.random() + "";

      this.performCheck();
      this.interval = setInterval(() => {
          this.performCheck();
      }, 2000);
    },
    performCheck(){
      let date = new Date();
      let now = parseInt(date.getTime() / 1000);
      let tabs = window.localStorage.getItem(this.key)?JSON.parse(window.localStorage.getItem(this.key)):{};
      tabs[this.id] = now;
      let conflictFound = false;
      for (let id in tabs) {
          if (this.id !== id) {
              if (now - tabs[id] < 4) {
                  conflictFound = true;
                  
              } else {
                  delete tabs[id];
              }
          }
      }
      this.lock = conflictFound;
      window.localStorage.setItem(this.key, JSON.stringify(tabs));
    }
  },
  watch: {
    facility: function () {
      this.lock = false;
      this.key = false;
      this.id = false;
      clearInterval(this.interval);
      this.init();
    },
  },
};
</script>


<style>
</style>
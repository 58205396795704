<template>
  <div>
   <v-textarea
          label="notatka"
          auto-grow
          rows="30"
          v-model="facility.note"
        ></v-textarea>
  </div>          
</template>

<script>

export default {
  name: "FacilityEditTabNote",
  props: ["facility"],
  components: {
    
  },
  data: () => ({}),
  computed: {

  },
  methods: {
  
  },
  mounted() {
   
  },
};
</script>


<style>
</style>
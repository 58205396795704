<template>
  <v-row
    dense
    no-gutters
  >
    <v-col>
      <v-menu
        v-model="datepicker"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            readonly
            v-bind="attrs"
            v-on="on"
            
            dense
            @click="checkDate"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="pl-pl"
          no-title
          v-model="date"
          :first-day-of-week="1"
          @change="datepicker = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="5">
      <v-menu
        v-model="timepicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            readonly
            dense
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-model="time"
          full-width
          format="24hr"
          @change="timepicker = false"
          min="7:00"
          max="19:00"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "DateTimePicker",
  model: {
    prop: "datetime",
    event: "input",
  },
  components: {},
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    label: {
      type: [String],
      default: "",
    },
    defaultHour: {
      type: [String],
      default: null,
    },
    clearOldDateOnClick: {
      type: [Boolean],
      default: false,
    },
  },
  data: () => ({
    loading: false,
    date: null,
    datepicker: false,
    time: null,
    timepicker: false,
  }),
  computed: {},
  methods: {
    checkDate(){
      if(this.clearOldDateOnClick && moment(this.date) < moment()){
        this.date = null;
      }
    },
    emitChanges(){
      if(this.date == null || this.time == null){
        this.$emit('input', null);
      } else {
        this.$emit('input', this.date+" "+this.time);
      }
    },
    init(){
      if (!this.datetime) {
        this.date = null;
        this.time = null;
      } else {
        let datetime = moment(this.datetime);
        this.date = datetime.format("YYYY-MM-DD");
        this.time = datetime.format("H:mm");
      }
    }
  },
  mounted() {
   this.init();
  },
  watch: {
    datetime(){
      this.init();
    },
    date(){
      if(this.date && !this.time){
        if(this.defaultHour){
          this.time = this.defaultHour;
        } else {
          this.time = moment().format("H")+":00";
        }
      }
      this.emitChanges();
    },
    time(){
      if(!this.time && this.date){
        this.date = null;
      }  
      this.emitChanges();
    }
  },
};
</script>

<style>
</style>
<template>
  <v-dialog
    v-model="ownerCommentDialog"
    persistent
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col>
            <v-textarea
              name="comment"
              label="Komentarz"
              v-model="owner.comment"
              no-resize
              rows="10"
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col sm=11>
            <OwnerCommentButtons v-on:add-comment-line="addQuickTextCommentLine($event)" />
            <v-textarea
              label="Dodaj komentarz"
               rows="1"
              auto-grow
              v-model="newComment"
            ></v-textarea>
          </v-col>
          <v-col class="text-center">
            <v-btn
              class="mt-7"
              color="green darken-1"
              dark
              @click="addComment()"
            >
              dodaj
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="ownerCommentDialog = false"
          >
            OK
          </v-btn>

        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import OwnerCommentButtons from "./Owner/CommentButtons.vue";
import axios from "axios";

export default {
  components: {OwnerCommentButtons},
  props: ["owner"],
  name: "SitemanagerOwnerCommentDialog",
  data() {
    return {
      newComment: "",
      ownerCommentDialog: false,
      loading: false
    };
  },
  computed: {},
  methods: {
    show() {
      this.ownerCommentDialog = true;
    },
    addQuickTextCommentLine(line) {
      this.newComment = this.newComment===""?line:line + "\n" + this.newComment;
    },
    addComment(){
      this.loading = true;
      this.owner.comment = this.owner.comment===""?this.newComment:this.newComment + "\n" + this.owner.comment;

      axios
        .patch(`/sitemanager/owners/${this.owner.id}`,this.owner)
        .then((response) => {
          this.sitemanagerData = response.data;
        })
        .finally(() => {
          this.loading = false;
          this.newComment = "";  
        });

      
    }
  },
  mounted() {},
  watch: {},
};
</script>


<style>
</style>
import axios from "axios";

export default {
    state: {
        qa: [],
        filter: {}
    },
    getters: {
        QA: state => {
            if (!(Object.keys(state.filter).length === 0)) {
                return state.qa.filter(item => {
                    for (let key in state.filter) {        
                        if (key == "question_like" && state.filter.question_like && state.filter.question_like.length > 2) {
                            if (!item.question.toLowerCase().includes(state.filter.question_like.toLowerCase()))
                                return false;
                        }
                   
                        if (key == "only_visibile" && state.filter.only_visibile == true) {
                            if(item.visibility === 0){
                                return false;
                            }
                        }
                    }
                    return true;
                });
            } else {
                return state.qa;
            }

        },
        QA_FILTER: state => {
            return state.filter;
        }
    },
    mutations: {
        SET_QA_FILTER: (state, payload) => {
            state.filter = payload;
        },
        SET_QA: (state, payload) => {
            state.qa = payload;
        },
        UPDATE_QA: (state, payload) => {
            if (payload) {
                const element = state.qa.find(template => template.id === payload.id);
                Object.assign(element, payload);
            }
        },
        ADD_QA: (state, payload) => {
            state.qa.push(payload);
        },
        REMOVE_QA: (state, item) => {
            let rs = state.qa.filter(current => {
                return current.id !== item.id;
            });
            state.qa = [...rs];
        }
    },
    actions: {
        GET_QA: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`qa`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("SET_QA", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        FILTER_QA: ({ commit }, { filter }) => {
            commit("SET_QA_FILTER", filter);
        },
        UPDATE_QA: ({ commit }, { qa }) => {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`qa/${qa.id}`, qa)
                    .then(({ data, status }) => {
               

                        if (status === 200) {
                            commit("UPDATE_QA", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        ADD_QA: ({ commit }, { item }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`qa`, item)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("ADD_QA", data);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        REMOVE_QA: ({ commit }, { item }) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`qa/${item.id}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("REMOVE_QA", item);
                            resolve({ data, status });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
    }
}
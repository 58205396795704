<template>
  <v-menu
    v-model="showMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
  >
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        link
      >
        <v-list-item-title @click="callAction(item.action)">{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "PhoneRowTool",
  props: ["phone"],
  data: () => ({
    x: 0,
    y: 0,
    showMenu: false,
    items: [
      {
        title: "kopiuj",
        action: "copy",
      },
    ],
  }),
  components: {},
  computed: {},
  mounted() {
    if (this.phone.type.id) {
      if ([2,3,4,6,7].includes(this.phone.type.id)) {
        this.items.push({
          title: "wyślij wiadomość",
          action: "sendMessage",
        });
      }
    }
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    copyAction() {
      navigator.clipboard.writeText(this.phone.number).then(
        () => {
          console.log("copy");
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    },
    sendMessageAction() {
      let options = {
        selectReceiver: this.phone.number
      };
      this.$emit('phone:sendMessage',options);
    },
    goToUrlAction() {
      window.open("http://"+this.phone.number, "_blank");
    },
    callAction(action) {
      this[action + "Action"]();
    },
  },
  watch: {},
};
</script>
